 <template>
  <div class="dashBoard_box">
    <div class="info_box">
      <div class="review">
        <img class="icon-huomiao" src="../../image/火苗.png" alt="" />
        <div class="detail">
          <p class="info">被评价次数</p>
          <p class="num">1562</p>
        </div>
      </div>
      <div class="review">
        <img class="icon-huomiao" src="../../image/记录.png" alt="" />
        <div class="detail">
          <p class="record_info">已评审项目</p>
          <p class="record_num">1562</p>
        </div>
      </div>
      <div class="review">
        <img class="icon-huomiao" src="../../image/星星.png" alt="" />
        <div class="detail">
          <p class="star_info">专家星级</p>
          <p class="star_num">5星际</p>
        </div>
      </div>
    </div>
    <div class="chart">
      <div
        class="line"
        id="myChart"
        :style="{ width: '350px', height: '350px' }"
      ></div>
      <div
        class="pie"
        id="pie"
        :style="{ width: '350px', height: '350px' }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.drawLine();
    this.drawPie();
  },
  methods: {
    drawLine() {
      // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      let option;

      option = {
        title: {
          text: "已评审项目",
        },
        xAxis: {
          type: "category",
          data: ["一月", "二月", "三月", "四月", "五月", "六月", "七月"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [10, 20, 30, 40, 50, 60, 70],
            type: "line",
          },
        ],
      };

      option && myChart.setOption(option);
    },
    drawPie() {
      let myChart = this.$echarts.init(document.getElementById("pie"));
      // 绘制图表
      let option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.dashBoard_box {
  padding: 24px;
  .info_box {
    padding: auto;
    display: flex;
    img {
      width: 100px;
    }
    .review {
      width: 33%;
      display: flex;
      border-radius: 5%;
      margin-right: 20px;
      height: 150px;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      .detail {
        .info {
          font-weight: bolder;
          font-size: x-large;
        }
        .num {
          margin-top: 10px;
          font-size: larger;
          color: red;
        }
        .record_info {
          font-weight: bolder;
          font-size: x-large;
        }
        .record_num {
          margin-top: 10px;
          font-size: larger;
          color: orange;
        }
        .star_info {
          font-weight: bolder;
          font-size: x-large;
        }
        .star_num {
          margin-top: 10px;
          font-size: larger;
          color: blue;
        }
      }
    }
  }
  .chart {
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 20px;
    .line {
      margin-top: 30px;
      margin-left: 10px;
    }
    .pie {
      margin-top: 30px;
      margin-left: 60px;
    }
  }
}
</style>