<template>
  <div v-loading="news_loading" class="news-list-box">
    <div class="head-title">我的创作</div>
    <ul class="news-contain">
      <router-link
        :to="{
          path: '/GIA_associator/media_essay',
          query: { newId: newItem.id },
        }"
        class="new-item"
        v-for="newItem in newList"
        :key="newItem.id"
      >
        <div class="pic">
          <gia-img
            class="image"
            :size="'110'"
            :imgSrc="newItem.headPhoto"
            alt=""
          />
        </div>
        <div class="txt">
          <h3 class="title">
            <span class="titleSpan">{{ newItem.title }}</span>
            <span class="time">{{
              newItem.modifyTime || newItem.releaseDate
            }}</span>
          </h3>
          <p class="description">
            {{ newItem.firstSentence }}
          </p>
          <p class="article-status">
             审核状态：{{
            newItem.articleStatus == 0
              ? "审核中..."
              : newItem.articleStatus == 1
              ? "已发布"
              : "禁止展示"
          }}
          </p>
          <!-- <p class="description">
            {{ newItem.articleStatus }}
          </p> -->
        </div>
      </router-link>
    </ul>
    <div class="news-pager">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="totalSize"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import giaImg from "../../components/giaImg.vue";
export default {
  components: { giaImg },
  // pagesize--一页10条
  data() {
    //   请求获取新闻
    return {
      news_loading: true,
      totalSize: 0,
      newList: [],
      page: 1,
      size: 4,
    };
  },
  methods: {
    async getEssay(page) {
      const { size } = this.$data;
      this.$data.news_loading = true;
      try {
        const resultList = await this.$request.get({
          url: "/article/owner/list/",
          data: {
            page,
            size,
          },
        });
        this.$data.newList = resultList.list;
        this.$data.totalSize = resultList.totalCount;
        this.$data.news_loading = false;
      } catch (error) {
        this.$data.news_loading = false;
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getEssay(page);
    },
  },
  mounted() {
    this.getEssay(1);
  },
};
</script>
<style lang="less" scoped>
.news-list-box {
  height: 100%;
  overflow: hidden;
  .head-title {
    padding: 10px 0;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    border-bottom: 1px solid #cccccc46;
  }
  // 新闻列表
  .news-contain {
    background-color: #fff;
    margin: 0;
    height: calc(100% - 86px);
    padding: 0px 14px 0px 12px;
    overflow: auto;
    .new-item {
      // overflow: hidden;
      border-bottom: 2px solid #f3f5fa;
      padding: 20px 0;
      width: 100%;
      display: flex;
      .pic {
        width: 163px;
        height: 109px;
        overflow: hidden;
        margin-right: 22px;
        .image {
          display: block;
          width: 100%;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
      .txt {
        flex: 1;
        .title {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          width: 100%;
          margin-bottom: 22px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:hover {
            color: #337ab7;
          }
          .time {
            display: block;
            font-size: 18px;
            color: #86889a;
          }
          .titleSpan {
            display: block;
            width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .description {
          font-size: 16px;
          color: #787981;
          height: 85px;
          line-height: 1.5em;
          height: 3em;
          overflow: hidden;
        }
      }
    }
  }
  .news-pager {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>