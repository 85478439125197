<template>
  <div class="systemSettingBox" v-loading="loading">
    <div class="search-select">
      <slot name="search-select">
        <el-select
          size="mini"
          class="select-type"
          v-model="typeId"
          placeholder="请选择文章类型"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.type"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </slot>
    </div>
    <el-table
      v-loading="loading"
      class="showTable"
      :data="showProduceList"
      style="width: 90%"
    >
      <el-table-column
        v-for="item in tableList"
        :prop="item.key"
        align="center"
        :key="item.key"
        :label="item.name"
      >
      </el-table-column>
      <el-table-column ref="edit" width="250" label="封面图" align="center">
        <template slot-scope="scope">
          <div class="block">
            <el-image
              style="width: 120px; height: 80px"
              :src="scope.row.headPhoto"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column ref="edit" width="70" label="检阅🔍" align="center">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: '/manage/media_essay',
              query: { newId: scope.row.id },
            }"
            class="check-link"
            >查看</router-link
          >
        </template>
      </el-table-column>
      <el-table-column ref="edit" width="80" label="选中轮播" align="center">
        <template slot-scope="scope">
          <div
            @click="addImage(scope.row.headPhoto, scope.row.id)"
            class="check-link"
          >
            <el-button type="primary" icon="el-icon-check" circle></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="totalSize"
      >
      </el-pagination>
    </div>
    <h3>图片列表</h3>
    <div class="pictures">
      <div class="aPicture" v-for="item in images" :key="item.articleId">
        <el-image
          style="width: 100px; height: 100px"
          :src="item.url"
          :preview-src-list="imageSrc"
        >
        </el-image>
        <span
          class="el-icon-circle-close"
          @click="deleteImage(item.url)"
        ></span>
      </div>
    </div>
    <h3 style="margin: 20px 0">效果预览</h3>
    <div class="review">
      <slideImage :isShow="true" :images="images" />
    </div>
  </div>
</template>


<script>
import slideImage from "../../components/home/slidingImage.vue";
export default {
  components: {
    slideImage,
  },
  data() {
    return {
      loading: false, //加载中？
      images: [], //已有轮播图
      imageSrc: [], //大图片--路径纯享版
      showProduceList: [],
      passage_loading: false,
      totalSize: 0,
      size: 6,
      type: 1,
      page: 1,
      imageUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      typeList: [], // 搜索
      typeId: "",
      tableList: [
        { name: "作者", key: "authorName" },
        { name: "审核人员", key: "reviewerName" },
        { name: "文章标题", key: "title" },
        { name: "发布日期", key: "releaseDate" },
      ],
    };
  },
  watch: {
    typeId() {
      this.getEssay(1);
    },
  },
  methods: {
    handleCurrentChange(page) {
      this.$data.page = page;
      this.getEssay(page);
    },
    // 获取已有轮播图图片
    getImages: async function () {
      this.loading = true;
      const result = await this.$request.post({
        url: "/articlePhotoManagement/query",
      });

      this.images = result;
      this.imageSrc = [];
      for (let i = 0; i < result.length; i++) {
        this.imageSrc.push(result[i].url);
      }
      this.loading = false;
    },
    async getEssay(page) {
      const { size, typeId } = this.$data;
      this.$data.passage_loading = true;
      try {
        const resultList = await this.$request.postForm({
          url: `/article/list/1`,
          data: {
            type: typeId,
            page: page,
            size: size,
          },
        });

        this.$data.showProduceList = resultList.list;
        this.$data.totalSize = resultList.totalCount;
        this.$data.passage_loading = false;
      } catch (error) {
        this.$data.passage_loading = false;
      }
    },
    // 获取文章类型
    async getListType() {
      const resultList = await this.$request.get({
        url: "/article/all/type",
      });
      this.$data.typeList = resultList;
      this.$data.typeId = resultList[0].id;
    },
    // 添加轮播图图片
    async addImage(url, articleId) {
      this.$confirm("是否添加该文章及其对应图片在首页轮播图的展示", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const addSlidingImage = await this.$request.post({
          url: "/articlePhotoManagement/insert",
          data: {
            url,
            articleId,
          },
        });
        this.getImages();
        this.$message({
          type: "success",
          message: `添加成功!`,
        });
      });
    },
    // 删除轮播图图片
    async deleteImage(url) {
      this.$confirm("是否删除该图片及其对应文章在首页轮播图的展示", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await this.$request.post({
          url: `/articlePhotoManagement/delete?url=${url}`,
        });
        this.getImages();
        this.$message({
          type: "success",
          message: `删除成功!`,
        });
      });
    },
  },
  computed: {},
  async mounted() {
    this.getImages();
    await this.getListType();
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.systemSettingBox {
  overflow: hidden;
  padding: 30px;
  min-width: 1000px;
  overflow: scroll;
  .pagination {
    margin: 30px 0 0 0;
    transform: translate(35%);
  }
  .review {
    width: 100%;
    height: 500px;
  }
  .pictures {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    // width: 20%;
    // height: 300px;
    .aPicture {
      position: relative;
      margin: 0 25px 0 25px;
      width: 150px;
      height: 100px;
      overflow: hidden;
    }
    .el-icon-circle-close {
      position: absolute;
      right: 30px;
      color: rgb(255, 0, 0);
    }
  }
}
</style>