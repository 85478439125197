<template>
  <div>
    <div class="show-box">
      <p class="title">在线支付</p>
      <div style="
          border-left: 1px solid rgb(236, 236, 236);
          border-top: 1px solid rgb(236, 236, 236);
        ">
        <self-form-box v-for="(item, key) in payTable"
                       :key="key"
                       :labelText="item">
          {{ payInfo[key] || "无" }}
        </self-form-box>
        <div class="qrcode"
             ref="qrCodeUrl"></div>
      </div>
    </div>
  </div>
</template>
<script>
import selfFormBox from "../../components/selfFormBox.vue";
import QRCode from 'qrcodejs2'
export default {
  components: { selfFormBox },
  data () {
    return {
      payTable: {
        fee: "交费金额",
        orderNumber: "订单编号",
        payWay: "交费方式",
        year: "缴费年限",
        name: "姓名",
        mobliePhone: "手机号",
        email: "邮箱",
        note: "留言",
      },
      payInfo: {},
    };
  },
  methods: {
    async payFn (payOrderId) {
      const result = await this.$request.post({
        url: '/wx-pay/native/' + payOrderId
      });
      this.creatQrCode(result.codeUrl)
      this.checkPayStatus(payOrderId)
    },
    creatQrCode (url) {
      new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    checkPayStatus (payOrderId) {
      this.checkInterval = setInterval(async () => {
        var result = await this.$request.post({
          url: '/order/queryPayOrderInfo/' + payOrderId
        });
        if (+result.status === 1) {
          this.$message.success('支付成功')
          this.$router.push({
            name: "/GIA_associator/payment_record",
          })
          clearInterval(this.checkInterval)
        }
      }, 5000); // 每5秒钟询问一次支付状态
    }
  },
  mounted () {
    try {
      let payInfo = sessionStorage.getItem("payInfo");
      payInfo = JSON.parse(payInfo);
      console.log(payInfo['note']);
      const { name, email, mobliePhone } = this.$store.state.userInfo;
      if (!name) {
        throw "refresh";
      }
      const { fee, orderNumber, payOrderId, payWay, year, note } = payInfo;
      this.payFn(payOrderId)
      this.$data.payInfo = {
        fee: fee / 100 + "(元)",
        orderNumber,
        payOrderId,
        payWay,
        year,
        name,
        email,
        mobliePhone,
        note
      };
    } catch (error) {
      this.$router.push("/GIA_associator/renewal_member");
    }
  },
  beforeDestory () {
    clearInterval(this.checkInterval)
  }
};
</script>
<style lang="less" scoped>
.show-box {
  margin: 10px 80px;
  .title {
    padding: 20px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .tool-box {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  .qrcode {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgb(236, 236, 236);
    border-bottom: 1px solid rgb(236, 236, 236);
  }
}
</style>