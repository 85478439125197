<template>
  <div class="group_menber-box">
    <article-box :content="{ title: '单位会员' }">
      <h2>一、入会权益</h2>
      <tbody>
        <tr height="37" style="height: 28.2pt" class="firstRow">
          <td
            colspan="5"
            height="28"
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
          >
            <span style="font-size: 18px; font-weight: 600"
              >GISF公司会员会费及权益（2021年)</span
            >
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            rowspan="2"
            height="40"
            x:str=""
            style="
              border-bottom: 0.5pt solid rgb(255, 255, 255);
              border-top-color: rgb(255, 255, 255);
              border-right-color: rgb(255, 255, 255);
              border-left-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            会员级别
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="151"
          >
            级别
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="147"
          >
            G级（金质）会员
          </td>
          <td
            x:str=""
            align="center"
            valign="middle"
            style="

              border-color: rgb(255, 255, 255);
            "
            width="148"
          >
            S级（银质）会员
          </td>
          <td
            x:str=""
            align="center"
            valign="middle"
            style="

              border-color: rgb(255, 255, 255);
            "
            width="127"
          >
            B级（铜质）会员
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            价格/年
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            10万
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            5万
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="121"
          >
            1万
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            height="20"
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            会员人数
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="151"
          >
            免费会员数
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="147"
          >
            100人
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="148"
          >
            60人
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="121"
          >
            20人
          </td>
        </tr>
        <tr height="40" style="height: 30pt">
          <td
            rowspan="3"
            height="80"
            x:str=""
            style="
              border-bottom: 0.5pt solid rgb(255, 255, 255);
              border-top-color: rgb(255, 255, 255);
              border-right-color: rgb(255, 255, 255);
              border-left-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            品牌宣传
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://dl.GISF.org.cn/article/articleIndex.html?_ack=1&amp;typeClassVal=cGISF&amp;pageNum_p=1"
              target="_self"
              >CGISF</a
            >广告
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            免费广告1次/年
            <p><br style="text-indent: 2em; text-align: left" /></p>
            <p style="text-align: left; text-indent: 2em">内页广告5折</p>
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            内页广告8折
          </td>
          <td
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            <br />
          </td>
        </tr>
        <tr height="40" style="height: 30pt">
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://mp.weixin.qq.com/mp/profile_ext?action=home&amp;__biz=MjM5MTY5ODE4OQ==&amp;scene=124&amp;uin=&amp;key=&amp;devicetype=Windows+10+x64&amp;version=63030522&amp;lang=zh_CN&amp;a8scene=7&amp;fontgear=2"
              target="_self"
              >GISF公众号</a
            >+<a href="https://www.GISF.org.cn/" target="_self">官网</a>
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="147"
          >
            公众号宣传1次/年
            <p>
              <span
                style="text-align: left; text-indent: 2em; font-family: inherit"
                >（内容有限定）</span
              ><br />
            </p>
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="148"
          >
            公众号宣传1次/年
            <p>
              <span
                style="text-align: left; text-indent: 2em; font-family: inherit"
                >（内容有限定）</span
              ><br />
            </p>
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="127"
          >
            ——
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://www.GISF.org.cn/Membership/Group_Member/"
              target="_self"
              >官网LOGO展示</a
            >
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            有
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            有
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="121"
          >
            列表
          </td>
        </tr>
        <tr height="67" style="height: 50.4pt">
          <td
            rowspan="7"
            height="192"
            x:str=""
            style="
              border-bottom: 0.5pt solid rgb(255, 255, 255);
              border-top-color: rgb(255, 255, 255);
              border-right-color: rgb(255, 255, 255);
              border-left-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            活动参与
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://www.GISF.org.cn/Activities/ConferenceS/CTO_Club/"
              target="_self"
              >CTO Club</a
            >
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="147"
          >
            CTO同级别可加入
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="148"
          >
            <span style="font-size: 14px"
              >CTO同级别可优先申请加入总部CLUB，可加入城市CTO CLUB</span
            >
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="127"
          >
            ——
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://cncc.GISF.org.cn/web/html15/index.html?globalId=m8271748750546083841617255458379&amp;type=1"
              target="_self"
              >CNCC</a
            >参会优惠
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            8人免费参会名额
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            5人免费参会名额
          </td>
          <td
            x:str=""
            align="center"
            valign="middle"
            style="
              border-color: rgb(255, 255, 255);

            "
            width="127"
          >
            2人免费参会名额
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://cncc.GISF.org.cn/web/html15/index.html?globalId=m8271748750546083841617255458379&amp;type=1"
              target="_self"
              >CNCC</a
            >参展优惠
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="147"
          >
            标展7折优惠
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="148"
          >
            标展9折优惠
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="127"
          >
            ——
          </td>
        </tr>
        <tr height="40" style="height: 30pt">
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://www.GISF.org.cn/Activities/Training/ADL/"
              target="_self"
              >ADL</a
            >学科前沿讲习班
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            8人免费参会名额/年
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            5人免费参会名额/年
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            2人免费参会名额/年
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://www.GISF.org.cn/Activities/Training/TF/"
              target="_self"
              >TF</a
            >免费名额
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="147"
          >
            100人次/年
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="148"
          >
            60人次/年
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="127"
          >
            10人次/年
          </td>
        </tr>
        <tr height="40" style="height: 30pt">
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://www.GISF.org.cn/Membership/Group_Member/News/2021-01-08/721249.shtml"
              target="_self"
              >名企面对面</a
            >
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            1期/年
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            1期/年
          </td>
          <td
            x:str=""
            align="center"
            valign="middle"
            style="
              border-color: rgb(255, 255, 255);

            "
            width="127"
          >
            ——
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            x:str=""
            align="center"
            valign="middle"
            style="

              border-color: rgb(255, 255, 255);
            "
            width="151"
          >
            <a
              href="https://www.GISF.org.cn/wqxwcx/GISFlwz/2010-05-20/649578.shtml"
              target="_self"
              >走进高校</a
            >
          </td>
          <td
            x:str=""
            align="center"
            valign="middle"
            style="

              border-color: rgb(255, 255, 255);
            "
            width="147"
          >
            讲者优先安排
          </td>
          <td
            x:str=""
            align="center"
            valign="middle"
            style="

              border-color: rgb(255, 255, 255);
            "
            width="148"
          >
            ——
          </td>
          <td
            x:str=""
            align="center"
            valign="middle"
            style="

              border-color: rgb(255, 255, 255);
            "
            width="121"
          >
            ——
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            rowspan="2"
            height="40"
            x:str=""
            style="
              border-bottom: 0.5pt solid rgb(255, 255, 255);
              border-top-color: rgb(255, 255, 255);
              border-right-color: rgb(255, 255, 255);
              border-left-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            学术资源
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            在线资源
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            免费下载
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            免费下载
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            免费下载
          </td>
        </tr>
        <tr height="27" style="height: 20.4pt">
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="151"
          >
            <a
              href="https://dl.GISF.org.cn/index.html?_state=&amp;_ack=1"
              target="_self"
              >数图</a
            >
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="147"
          >
            无限量查询
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="148"
          >
            无限量查询
          </td>
          <td
            x:str=""
            style="

              border-color: rgb(255, 255, 255);
            "
            align="center"
            valign="middle"
            width="121"
          >
            无限量查询
          </td>
        </tr>
        <tr height="42" style="height: 31.5pt">
          <td
            height="31"
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="127"
          >
            企业校招
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="151"
          >
            可获得<a href="https://cspro.org/" target="_self">CSP</a>优秀
            <p>
              <span
                style="text-align: left; text-indent: 2em; font-family: inherit"
                >学生推荐信息</span
              ><br />
            </p>
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="147"
          >
            2000人/年（分批）
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="148"
          >
            1000人/年（分批）
          </td>
          <td
            x:str=""
            style="
              border-color: rgb(255, 255, 255);

            "
            align="center"
            valign="middle"
            width="undefined"
          >
            ——
          </td>
        </tr>
      </tbody>
      <h2>二、会费标准</h2>
      <section
        style="font-family: 微软雅黑; font-size: 16px"
        data-role="outer"
        label="Powered by 135editor.com"
      >
        <p
          style="

            line-height: 120%;
            font-family: 宋体, SimSun;
            font-size: 18px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun; font-size: 16px"
              >一、会费标准&nbsp;</span
            ></strong
          ><span style="font-family: 宋体, SimSun; font-size: 16px"
            ><span style="font-family: 宋体, SimSun"
              ><br />G级（金质）会员：10万元/年&nbsp;<br />S级（银质）会员：5万元/年&nbsp;<br />B级（铜质）会员：1万元/年&nbsp;<br />会员资格以自然年度计算，&nbsp;<br />即从每年1月1日至12月31日</span
            ></span
          ><span style="font-family: 宋体, SimSun; font-size: 14px"
            >&nbsp;</span
          >
        </p>
        <p
          style="

            line-height: 120%;
            font-family: 宋体, SimSun;
            font-size: 14px;
          "
        >
          <br /><strong
            ><span style="font-family: 宋体, SimSun; font-size: 16px"
              >二、交费金额与交费时间</span
            ></strong
          >
        </p>
        <table width="721" style="width: 698px" cellspacing="0" cellpadding="0">
          <colgroup>
            <col width="108" style="width: 108px" span="2" />
            <col width="188" style="width: 188px" />
            <col width="72" style="width: 72px" />
            <col width="245" style="width: 245px" />
          </colgroup>
          <tbody>
            <tr height="20" class="firstRow" style="height: 20px">
              <td
                width="108"
                height="20"
                align="center"
                valign="top"
                style="
                  border: 1px solid rgb(221, 221, 221);
                  background-color: transparent;
                "
              >
                <span style="font-family: 宋体; font-size: 16px">交费时间</span>
              </td>
              <td
                width="296"
                align="center"
                valign="top"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) black;
                  background-color: transparent;
                "
                colspan="2"
              >
                <span style="font-family: 宋体; font-size: 16px">交费金额</span>
              </td>
              <td
                width="317"
                align="center"
                valign="top"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) black;
                  background-color: transparent;
                "
                colspan="2"
              >
                <span style="font-family: 宋体; font-size: 16px"
                  >有效期截止日</span
                >
              </td>
            </tr>
            <tr height="49" style="height: 49px">
              <td
                width="108"
                height="159"
                align="center"
                valign="middle"
                style="
                  border-width: 0px 1px 1px;
                  border-style: none solid solid;
                  border-color: rgb(51, 51, 51) rgb(221, 221, 221)
                    rgb(221, 221, 221);
                  background-color: transparent;
                "
                rowspan="3"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >1～2月</span
                >
              </td>
              <td
                width="296"
                align="center"
                valign="top"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >G级（金质）会员<br />
                  10万元</span
                >
              </td>
              <td
                width="317"
                align="center"
                valign="middle"
                style="
                  border: 1px solid rgb(221, 221, 221);
                  background-color: transparent;
                "
                rowspan="3"
                colspan="2"
              >
                <p>
                  <span style="font-size: 16px"
                    ><span style="font-family: 宋体"
                      ><span style="color: rgb(51, 51, 51)"
                        >当年12月31日</span
                      ></span
                    ></span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    ><span style="font-family: 宋体"
                      ><span style="color: rgb(51, 51, 51)"
                        >（从交费当月起赠送纸质CGISF</span
                      ><span style="color: rgb(102, 102, 102)">）</span></span
                    ></span
                  >
                </p>
              </td>
            </tr>
            <tr height="60" style="height: 60px">
              <td
                width="296"
                height="60"
                align="center"
                valign="top"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >S级（银质）会员 <br />
                  5万元</span
                >
              </td>
            </tr>
            <tr height="50" style="height: 50px">
              <td
                width="296"
                height="50"
                align="center"
                valign="top"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >B级（铜质）会员 <br />
                  1万元</span
                >
              </td>
            </tr>
            <tr height="53" style="height: 53px">
              <td
                width="108"
                height="326"
                align="center"
                valign="middle"
                style="
                  border-width: 0px 1px 1px;
                  border-style: none solid solid;
                  border-color: rgb(51, 51, 51) rgb(221, 221, 221)
                    rgb(221, 221, 221);
                  background-color: transparent;
                "
                rowspan="6"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >3～8月</span
                >
              </td>
              <td
                width="296"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >G级（金质）会员<br />
                  10万元</span
                >
              </td>
              <td
                width="317"
                align="center"
                valign="middle"
                style="
                  border: 1px solid rgb(221, 221, 221);
                  background-color: transparent;
                "
                rowspan="3"
                colspan="2"
              >
                <p>
                  <span
                    style="
                      color: rgb(51, 51, 51);
                      font-family: 宋体;
                      font-size: 16px;
                    "
                    >当年12月31日</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: rgb(51, 51, 51);
                      font-family: 宋体;
                      font-size: 16px;
                    "
                    >（从交费当月起赠送纸质CGISF）</span
                  >
                </p>
              </td>
            </tr>
            <tr height="51" style="height: 51px">
              <td
                width="296"
                height="51"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >S级（银质）会员 <br />
                  5万元</span
                >
              </td>
            </tr>
            <tr height="52" style="height: 52px">
              <td
                width="296"
                height="52"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >B级（铜质）会员 <br />
                  1万元</span
                >
              </td>
            </tr>
            <tr height="60" style="height: 60px">
              <td
                width="296"
                height="60"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span style="color: rgb(51, 51, 51)"
                  ><span style="font-size: 16px"
                    ><span style="font-family: 宋体"
                      >G级（金质）会员<br />
                      15万元<strong>（推荐）</strong></span
                    ></span
                  ></span
                >
              </td>
              <td
                width="317"
                align="center"
                valign="middle"
                style="
                  border: 1px solid rgb(221, 221, 221);
                  background-color: transparent;
                "
                rowspan="3"
                colspan="2"
              >
                <p>
                  <span
                    style="
                      color: rgb(51, 51, 51);
                      font-family: 宋体;
                      font-size: 16px;
                    "
                    >次年12月31日</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: rgb(51, 51, 51);
                      font-family: 宋体;
                      font-size: 16px;
                    "
                    >（从当年7月起赠送纸质CGISF）</span
                  >
                </p>
              </td>
            </tr>
            <tr height="58" style="height: 58px">
              <td
                width="296"
                height="58"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span style="color: rgb(51, 51, 51)"
                  ><span style="font-size: 16px"
                    ><span style="font-family: 宋体"
                      >S级（银质）会员 <br />
                      7.5万元<strong>（推荐）</strong></span
                    ></span
                  ></span
                >
              </td>
            </tr>
            <tr height="52" style="height: 52px">
              <td
                width="296"
                height="52"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span style="color: rgb(51, 51, 51)"
                  ><span style="font-size: 16px"
                    ><span style="font-family: 宋体"
                      >B级（铜质）会员 <br />
                      1.5万元<strong>（推荐）</strong></span
                    ></span
                  ></span
                >
              </td>
            </tr>
            <tr height="61" style="height: 61px">
              <td
                width="108"
                height="177"
                align="center"
                valign="middle"
                style="
                  border-width: 0px 1px 1px;
                  border-style: none solid solid;
                  border-color: rgb(51, 51, 51) rgb(221, 221, 221)
                    rgb(221, 221, 221);
                  background-color: transparent;
                "
                rowspan="3"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >9～12月</span
                >
              </td>
              <td
                width="296"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >G级（金质）会员<br />
                  10万元</span
                >
              </td>
              <td
                width="317"
                align="center"
                valign="middle"
                style="
                  border: 1px solid rgb(221, 221, 221);
                  background-color: transparent;
                "
                rowspan="3"
                colspan="2"
              >
                <p>
                  <span
                    style="
                      color: rgb(51, 51, 51);
                      font-family: 宋体;
                      font-size: 16px;
                    "
                    >次年12月31日</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: rgb(51, 51, 51);
                      font-family: 宋体;
                      font-size: 16px;
                    "
                    >（从次年1月起赠送纸质CGISF）</span
                  >
                </p>
              </td>
            </tr>
            <tr height="64" style="height: 64px">
              <td
                width="296"
                height="64"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >S级（银质）会员 <br />
                  5万元</span
                >
              </td>
            </tr>
            <tr height="52" style="height: 52px">
              <td
                width="296"
                height="52"
                align="center"
                valign="middle"
                style="
                  border-width: 1px 1px 1px 0px;
                  border-style: solid solid solid none;
                  border-color: rgb(221, 221, 221) rgb(221, 221, 221)
                    rgb(221, 221, 221) rgb(51, 51, 51);
                  background-color: transparent;
                "
                colspan="2"
              >
                <span
                  style="
                    color: rgb(51, 51, 51);
                    font-family: 宋体;
                    font-size: 16px;
                  "
                  >B级（铜质）会员 <br />
                  1万元</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <p style=" line-height: 120%">&nbsp;</p>
        <p style=" line-height: 120%">
          <strong
            ><span style="font-family: 宋体, SimSun; font-size: 16px"
              >三、交费方式</span
            ></strong
          ><span style="font-family: 宋体, SimSun; font-size: 16px"
            ><br />银行转账（不支持支付宝对公转账）：<br />开户行：北京银行北京大学支行<br />户名：中国计算机学会<br />账号：01090519500120109702028<br />转账留言：***单位会费</span
          >
        </p>
      </section>
    </article-box>
  </div>
</template>
<script>
import articleBox from "../../../components/home/articleBox.vue";
export default {
  components: { articleBox },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.group_menber-box {
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
      padding: 5px 0;
      td {
        border: 1px solid #ddd !important;
      }
    }
  }
}
</style>