<template>
  <div class="payRule"
       v-loading="isSuccess">
    <el-table :data="tableData"
              style="width: 95%"
              :default-sort="{ prop: 'date', order: 'ascending' }"
              align="center"
              max-height="450">
      <el-table-column prop="kind"
                       label="职务"
                       width="120"> </el-table-column>
      <el-table-column prop="fee"
                       label="费用"
                       width="120"> </el-table-column>
      <el-table-column prop="createTime"
                       label="创建时间"
                       width="180">
      </el-table-column>
      <el-table-column prop="modifyTime"
                       label="修改时间"
                       width="180">
      </el-table-column>
      <el-table-column prop="note"
                       label="备注"
                       width="180"> </el-table-column>
      <el-table-column ref="edit"
                       label="编辑"
                       width="55"
                       align="center">
        <template slot-scope="scope">
          <el-popover ref="editBox"
                      placement="left">
            <div>
              <el-row>
                <span class="label-title">收费金额：</span>
                <el-input size="small"
                          style="width: 70%"
                          v-model="editForm.fee"></el-input>
              </el-row>
              <el-row>
                <span class="label-title">缴费人员：</span>
                <el-input size="small"
                          style="width: 70%"
                          v-model="editForm.kind"></el-input>
              </el-row>
              <el-row><span class="label-title">备注：</span>
                <el-input size="small"
                          style="width: 70%"
                          v-model="editForm.note"></el-input>
              </el-row>

              <el-button slot="reference"
                         type="primary"
                         size="mini"
                         @click="updateItem(scope.row)">更新</el-button>
            </div>
            <el-button slot="reference"
                       class="editButton"
                       size="mini"
                       type="primary"
                       icon="el-icon-edit"
                       @click="editItem(scope.$index, tableData)"></el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column fixed="right"
                       label="删除"
                       width="80">
        <template slot-scope="scope">
          <el-button @click.native.prevent="deleteRow(scope.$index, tableData)"
                     type="text"
                     size="small">
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-popover ref="buildBox"
                placement="top">
      <div class="build-box">
        <el-row>
          <div class="build-title">收费金额：</div>
          <el-input size="big"
                    style="width: 100%"
                    v-model="editForm.fee"></el-input>
        </el-row>
        <el-row>
          <div class="build-title">缴费人员：</div>
          <el-input size="big"
                    style="width: 100%"
                    v-model="editForm.kind"></el-input>
        </el-row>
        <el-row>
          <div class="build-title">备注：</div>
          <el-input size="big"
                    style="width: 100%"
                    v-model="editForm.note"></el-input>
        </el-row>

        <el-button slot="reference"
                   type="primary"
                   size="mini"
                   class="build-confirm"
                   @click="buildItem()">确定</el-button>
      </div>
      <el-button slot="reference"
                 class="build-new"
                 size="mini"
                 type="primary"
                 icon="el-icon-edit">新建</el-button>
    </el-popover>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isSuccess: false, //加载中？
      tableData: [], //展示数据
      editForm: {
        fee: 100, //
        kind: "职称", //
        note: "备注",
      },
    };
  },
  methods: {
    // 点击编辑时获取原有收费标准
    editItem (index, rows) {
      this.editForm.fee = rows[index].fee;
      this.editForm.kind = rows[index].kind;
      this.editForm.note = rows[index].note;
    },
    // 点击更新收费标准
    updateItem: async function (e) {
      let { fee, kind, note } = this.$data.editForm;
      const result = await this.$request.post({
        url: "/chargeStandard/update",
        data: {
          id: e.id,
          fee,
          kind,
          note,
        },
      });

      if (result) {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getChargeWays();
      } else {
        this.$message({
          type: "info",
          message: "修改失败",
        });
      }
    },
    // 点击新建收费标准
    buildItem: async function () {
      let { fee, kind, note } = this.$data.editForm;
      const result = await this.$request.post({
        url: "/chargeStandard/create",
        data: {
          fee,
          kind,
          note,
        },
      });

      if (result) {
        this.$message({
          type: "success",
          message: "新建收费标准成功",
        });
        this.getChargeWays();
      } else {
        this.$message({
          type: "info",
          message: "新建收费标准失败",
        });
      }
    },
    // 获取全部收费标准
    getChargeWays: async function () {
      this.isSuccess = true;
      const result = await this.$request.post({
        url: "/chargeStandard/get",
      });

      this.tableData = result;
      this.isSuccess = false;
    },

    deleteRow (index, rows) {
      this.$confirm("此操作将永久删除该收费标准, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let id = rows[index].id;
          const result = await this.$request.post({
            url: `/chargeStandard/delete?id=${id}`,
          });

          rows.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted () {
    this.getChargeWays();
  },
};
</script>
<style lang="less" scoped>
.payRule {
  margin: 50px;
  padding: 10px;
  min-width: 1000px;
  overflow: scroll;
  box-shadow: 2px 2px 5px rgba(17, 34, 51, 0.1);
  .build-new {
    margin-top: 30px;
    margin-left: 50%;
    transform: translate(-50%);
  }
}
.label-title {
  display: inline-block;
  background-color: rgb(255, 255, 255);
  height: 30px;
  // vertical-align: top;
  border-radius: 2px;
  line-height: 30px;
  text-align: left;
  width: 28%;
  color: #657085;
}
.build-box {
  width: 400px;
  .build-confirm {
    margin: 25px 0 0 0;
    margin-left: 50%;
    transform: translate(-50%);
  }
  .build-title {
    display: block;
    background-color: rgb(255, 255, 255);
    height: 30px;
    border-radius: 2px;
    line-height: 30px;
    text-align: left;
    width: 70px;
    color: #7c7d7e;
  }
}
</style>