<template>
    <div class="expertSelect">
        <div class="receiveMessage">
            <div class="topSearch" style="min-width: 300px">
                <h2>抽取成功后的专家信息在下面显示：</h2>
                <div>
                    <div class="receiveFormPlace">

                        <el-table :data="expertList">


                            <el-table-column prop="expertId" align="center" label="专家id" width="90">
                            </el-table-column>

                            <el-table-column prop="expertName" align="center" label="专家姓名" width="150">
                            </el-table-column>

                            <el-table-column prop=" expertNumber" align="center" label="专家号" width="100">
                            </el-table-column>

                            <!--    <el-table-column prop=" expertNumber" align="center" label="身份证号" width="100">
                            </el-table-column>     -->

                            <el-table-column prop="email" align="center" label="邮箱" width="150">
                            </el-table-column>

                        </el-table>

                    </div>
                </div>
                <div>

                    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                </div>
            </div>

        </div>
        <span style="color: green; font-size: small;">
            使用方法：选择专家分组ID、项目ID、抽取人数、发送的消息（可输入文字），点击右边的蓝色按钮开始抽取，抽取成功后，信息显示在上方表格。抽取方法为：在组内随机抽取
        </span>
        <div class="inputContainer">
          
            <div>
                <label class="inputLabel">选择分组id：</label>
                <select v-model="groupId">
                    <option value="">请选择</option>
                    <option v-for="group in groups" :value="group.id">{{ group.id }}：{{ group.groupName }}</option>
                </select>
            </div>

            <div>
                <label class="inputLabel">选择项目ID：</label>
                <select v-model="projectId">
                    <option value="">请选择</option>
                    <option v-for="Group in Groups" :value="Group.id">{{ Group.id }}：{{ Group.projectName }}</option>
                </select>
            </div>

            

            <label class="inputLabel">抽取人数：</label>
            <input v-model="total" placeholder="请输入整数">

            <label class="inputLabel">发送的消息：</label>
            <input v-model="message" placeholder="可输入字符">

            <el-button type="primary" icon="el-icon-s-promotion"
                @click="setExperts(groupId, projectId, total, message)">开始抽取专家</el-button>
        </div>
        <div>

            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        </div>
    </div>
</template>
  
<script>
export default {
    name: "expertSelect",
    data() {
        return {
            // Your data properties here
            expertId: 0,
            expertName: "",
            idNumber: "",
            expertNumber: "",
            email: "",
            expertList: [], //从后端获取的数据数组
            totalList: [],
            groups: [],// 将groups声明为一个空数组，用来存储获取到的分组数据
            Groups: [],//项目数据
        }

    },
    methods: {

        getAllGroups: async function () {

// 获取待处理或已处理的会员等级（头衔）变动列表
this.isSuccess = true;
const result = await this.$request.get({
  url: '/group/allGroup',

});

console.log(result); // 添加这一行
this.groups = result.list; // 修改部分：将获取到的分组数据赋值给groups数组

console.log(this.groups);
this.isSuccess = false;

},

ShowPageChange: async function (page, size) {
            this.isSuccess = true;

            const result = await this.$request.get({
                url: `/project/allproject?page=${page}&size=${size}`,
            });

            console.log(result);
           
            this.Groups = result.list;

            console.log(this.Groups);
            this.isSuccess = false;
        },





        async setExperts(groupId, projectId, total, message) {
            try {
                const response = await this.$request.post({
                    url: '/expert/manager/selectExpert',
                    data: {
                        groupId,
                        projectId,
                        total,
                        message
                    },
                });
                this.expertList = response;
                // 处理返回的数据
                console.log(response);
                this.$message.success('抽取成功');
                // 根据需要进行其他操作

            } catch (error) {
                // 发生错误时的处理。
                console.error(error);
            }
        }

    },
    mounted() {
        this.getAllGroups();
        this.ShowPageChange(1, 10000);
    }
};
</script>
  
<style scoped>
.inputContainer {
    margin-top: 40px;
}
</style>
  