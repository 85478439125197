<template>
  <div class="about-page">
    <left-menu-nav :nowRoute="nowRoute" />
    <router-view class="route-contain"></router-view>
  </div>
</template>
<script>
import leftMenuNav from "../../components/home/leftMenuNav.vue";
export default {
  components: { leftMenuNav },
  data() {
    return {
      nowRoute: {
        title: "关于GISF",
        route: "/about",
        children: [
          {
            title: "学会简介",
            to: "/introduct",
          },
          {
            title: "组织架构",
            to: "/institutionalFramework",
          },
          {
            title: "学会章程",
            to: "/purpose_charter",
          },
          {
            title: "如何入会",
            to: "/howtojion",
          },
          {
            title: "发展历程",
            to: "/history",
          },
          {
            title: "理事会",
            to: "/council",
          },
          {
            title: "学会大事记",
            to: "/Recording",
          },
          {
            title: "年报",
            to: "/annual_report",
          },
          // {
          //  title: "学会宗旨章程",
          //   to: "/purpose_charter",
          // },
        ],
      },
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.about-page {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  .route-contain {
    width: 1000px;
  }
}
</style>