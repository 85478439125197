<template>
  <div v-loading="isSuccess">
    <div class="showPlace">
      <div class="confirm">
        <p class="title">GISF会员资格确认函</p>
        <ul>
          <li>
            <p>尊敬的 {{ name }} {{ sex == 1 ? "先生" : "女士" }}: </p>

          </li>
          <li>
            <p>
              祝贺您成为广东省工业软件学会（简称GISF）会员，您的会员证号为
              <span style="display: inline-block;width: 100px">
                {{ vipNumber }},
              </span>
              请您登录会员系统后，下载会员卡，您的会员资格有效期：
              <span style="display: inline-block;width: 160px">
                {{expiredTime}}
              </span>
              止。
            </p>
          </li>
          <li>
            <p>
              为确保您能收到GISF所寄刊物，如有信息不全或变更，请您及时登录GISF网站，进入“个人信息维护”更新您的信息。
              <p style="margin: 18px 0">
                您的地址：
                {{address}}，
              </p>
              GISF将通过邮局为您寄送资料
            </p>
          </li>
        </ul>
      </div>
    </div>
    <el-button class="downLoad"
               @click="getConfirmation"
               type="primary">下载</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isSuccess: false,
      name: "",
      sex: "",
      vipNumber: "",
      expiredTime: "",
      address: "",
    };
  },
  methods: {
    async getConfirmation () {
      this.$message({
        message: "自动生成PDF中，请耐心等待",
        type: "success",
      });
      this.isSuccess = true;
      await this.$request.postPDF({
        url: "/downloadPdf/Confirmation",
        pdfName: "GIA_会员资格确认函",
      });
      this.isSuccess = false;
    },
    async getMess () {
      const result = await this.$request.get({
        url: "/user/info",
      });
      this.name = result.name;
      this.sex = result.sex.id;
      this.expiredTime = result.endTime;
      this.address = result.address;
      this.vipNumber = result.vipNumber;
    },
  },
  mounted () {
    this.getMess();
  },
};
</script>

<style scoped>
.confirm ul {
  list-style: none;
}
.confirm li {
  line-height: 1.5em;
  margin: 1em 0 1em 2em;
}
.confirm li li p {
  font-weight: 600;
  margin-left: 2em;
  line-height: 2em;
}
.confirm {
  width: 630px;
}
.showPlace {
  width: 700px;
  height: 660px;
  margin: 20px 0 0;
  overflow-y: auto;
  padding: 40px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(136, 136, 136, 0.319);
  box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.5);
  margin-left: 50%;
  transform: translate(-50%);
}
.downLoad {
  margin: 20px 0 0 50%;
  transform: translate(-50%);
}
.confirm .title {
  text-align: center;
  font-weight: bold;
  margin: 0 0 20px;
  transform: translate(30px);
}
</style>