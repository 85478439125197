<template>
  <div>
    <div class="newsBox">
      <div class="nB_leftNews">
        <div class="nB_lN_Top">
          <div class="title">
            <p>GISF聚焦</p>
            <div class="bottomLine"></div>
          </div>
          <router-link to="/news/Media_list"
                       class="moreNews">More</router-link>
        </div>
        <ul class="nB_lN_T_newsList">
          <li class=""
              v-for="(oneNew, id) in showNewList"
              :key="id">
            <router-link :to="{
                path: '/news/media_essay',
                query: { newId: oneNew.id },
              }"
                         class="nB_lN_T_nL_aNew">
              <div class="nB_lN_T_nL_aN_thisNewTitle">
                <i class="el-icon-setting"></i>
                <p class="titleSpan">{{ oneNew.title }}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <div class="nB_rightNews" v-if="!isShow">
        <ul>
          <li>
            <router-link to="/" class="">
              <i class="el-icon-coin"></i>
              <span>会员交费</span>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <i class="el-icon-place"></i>
              <span>推荐会员</span>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <i class="el-icon-message"></i>
              <span>GIA投稿</span>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <i class="el-icon-search"></i>
              <span>奖励查询</span>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <i class="el-icon-s-order"></i>
              <span>GIA推荐会议/期刊目录</span>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <i class="el-icon-plus"></i>
              <span>加入兄弟学会</span>
            </router-link>
          </li>
        </ul>
      </div> -->
    </div>
    
  </div>
</template>
<script>
export default {
  props: {
    isShow: Boolean, //用于判断：true：管理端展示。false：首页
    showNewList: Array, //接受的展示数组
  },
  data () {
    return {
      // showNewList: [
      //   {
      //     title: "GIA 2021年第12期出版——“开源芯片及敏捷开发技术”",
      //     newsId: "100",
      //   },
      //   {
      //     title: "元宇宙、量子计算、计算艺术…CNCC线上参会，热门论坛有直播！",
      //     newsId: "100",
      //   },
      // ],
    };
  },
  methods: {},
};
</script>
<style scoped lang='less'>
.newsBox {
  // border: 1px solid #000;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .nB_leftNews {
    width: 1140px;
    .nB_lN_Top {
      display: flex;
      justify-content: space-between;
      .title {
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }
        .bottomLine {
          width: 80px;
          height: 4px;
          background-color: #123;
        }
      }
      .moreNews {
        margin-top: 15px;
      }
    }
    .nB_lN_T_newsList {
      background-color: #fff;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 17px;
      height: 472px;
      border: 2px solid rgba(118, 118, 118, 0.2);
      li {
        .nB_lN_T_nL_aNew {
          display: flex;
          justify-content: space-between;
          color: rgb(33, 34, 35);
          .nB_lN_T_nL_aN_thisNewTitle {
            display: flex;
            line-height: 40px;
            height: 40px;
            i {
              color: rgba(0, 255, 255, 0.463);
              font-size: 24px;
              display: block;
              margin-right: 10px;
              margin-top: 7px;
            }
            .titleSpan {
              display: block;
              width: 1100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .nB_lN_T_nL_aN_thisNewTime {
            line-height: 41px;
            height: 40px;
          }
        }
        .nB_lN_T_nL_aNew:hover {
          color: rgb(0, 31, 233);
          i {
            color: rgb(0, 31, 233);
          }
        }
      }
    }
  }
  .nB_rightNews {
    width: 350px;
    ul {
      margin-top: 54px;
      li {
        width: 350px;
        height: 70px;
        background-image: linear-gradient(
          to right,
          #58abff 0%,
          #3d93fd 21%,
          #3d6dfd 100%
        );
        margin-bottom: 10px;
        a {
          display: flex;
          justify-content: space-between;
          text-align: left;
          line-height: 70px;
          font-weight: 600;
          font-size: 20px;
          color: #fff;
          i {
            display: block;
            flex: 2;
            text-align: center;
            font-size: 40px;
            height: 70px;
            line-height: 70px;
          }
          span {
            display: block;
            flex: 5;
          }
        }
      }
      li:hover {
        box-shadow: 2px 3px 5px #123;
      }
    }
  }
}

</style>