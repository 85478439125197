
<template>
  <div v-loading="loading" class="GIAIntroduction">
    <div class="GI_introductionTitle">
      <i @click="$router.back(1)" class="back el-icon-arrow-left"></i>
      <div>
        <span class="article-title">{{ content.title }}</span>
        <div class="audit-btn">
          <el-button @click="() => auditArtice(1)" size="small"
            >审核通过</el-button
          >
          <el-button @click="() => auditArtice(2)" size="small" type="danger"
            >拒绝审核</el-button
          >
        </div>
      </div>
      <div class="GI_secondTitle">
        <i>{{ content.releaseDate }}</i>
      </div>
    </div>
    <div v-html="content.context" class="GI_introductionContent"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: {
        context: "",
      },
      loading: true,
    };
  },
  methods: {
    // 审核
    async auditArtice(result) {
      const { newId: id } = this.$route.query;
      switch (result) {
        case 1:
          this.$confirm(
            "是否通过该文章的审核?通过后文章将可以在首页被访问",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(async () => {
            await this.$request.postForm({
              url: "/article/audit/" + id,
              data: {
                result: result,
                node:""
              },
            });
            this.$message({
              type: "success",
              message: `审核成功!`,
            });
            this.$router.replace("/manage/auditPassage");
          });
          break;
        case 2:
          this.$prompt("输入审核不通过原因", "审批意见", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          }).then(async({ value: node }) => {
            await this.$request.postForm({
              url: "/article/audit/" + id,
              data: {
                result: result,
                node:node
              },
            });
            this.$message({
              type: "success",
              message: `审核成功!`,
            });
            this.$router.replace("/manage/auditPassage");
          });
          break;
        default:
          break;
      }
    },
  },
  async mounted() {
    try {
      const { newId } = this.$route.query;
      const result = await this.$request.get({
        url: "/article/query/" + newId,
      });
      this.$data.content = result;
      this.$data.loading = false;
    } catch (error) {
      this.$data.loading = false;
    }
  },
};
</script>
<style lang="less" scoped>
.GIAIntroduction {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1000px;
  overflow: scroll;
  .GI_introductionTitle {
    margin-top: 30px;
    text-align: center;
    border-bottom: 2px solid #cccccc60;
    position: relative;
    .back {
      left: 20px;
      position: absolute;
      font-size: 36px;
      font-weight: 600;
      color: #757575;
      cursor: pointer;
    }
    .article-title {
      font-weight: 600;
      font-size: 30px;
    }
    .audit-btn {
      position: absolute;
      display: flex;
      right: 50px;
      top: calc(50% - 30px);
    }
    .GI_secondTitle {
      margin-left: 50%;
      transform: translate(-50%);
      width: 250px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-around;
      color: #888;
    }
  }
  .GI_introductionContent {
    padding: 10px 20px;
    box-sizing: border-box;
    height: calc(100% - 122px);
    overflow: auto;
  }
}
</style>
<style>
.GI_introductionContent img {
  max-width: 100%;
}
</style>