<template>
  <div class="manage" v-loading="isSuccess">
    <div class="memberMessage">
      <div class="topSearch">
        <div>
          <span>姓名:</span>
          <el-input
            v-model="searchMember"
            type="text"
            size="normal"
            placeholder=" 请输入会员姓名"
            style="width: 200px"
          />
        </div>
        <div>
          <span>提交时间:</span>
          <el-date-picker
            v-model="commitTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            align="right"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchMess"
          >搜索</el-button
        >
      </div>
      <div class="threeChangeButton">
        <div class="leftFlow">
          <el-select
            v-model="chosenType"
            placeholder="请选择"
            size="mini"
            width="10px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            size="mini"
            icon="el-icon-download"
            type="primary"
            plain
            @click="flowOutAll"
            >导出全部</el-button
          >
        </div>
        <div class="rightBUtton">
          <el-button
            size="mini"
            icon="el-icon-download"
            type="primary"
            plain
            @click="flowOut"
            >导出选中</el-button
          >
          <el-button
            type="primary"
            size="mini"
            class="changeButton buttonDelete"
            icon="el-icon-delete"
            @click="deleteRow"
            >删除</el-button
          >
          <el-button type="primary" plain size="mini" @click="ShowAll"
            >展示全部</el-button
          >
        </div>
      </div>
      <div class="memberFormPlace">
        <el-table
          :data="showTableData"
          style="width: 100%; overflow: hidden; margin-bottom: 20px"
          max-height="450"
          @select="selectRow"
          @select-all="selectRow"
          stripe
          class="tableBox"
        >
          <el-table-column fixed="left" type="selection" />

          <el-table-column
            v-for="item in tableList"
            :prop="item.key"
            align="center"
            :key="item.key"
            :label="item.name"
          >
          </el-table-column>
          <el-table-column ref="edit" label="导出申请表" align="center">
            <template slot-scope="scope">
                <el-button
                  slot="reference"
                  class="editButton"
                  size="mini"
                  type="primary"
                  icon="el-icon-download"
                  @click="applyPDF(scope.row.id)"
                ></el-button>
            </template>
          </el-table-column>
          <el-table-column ref="edit" label="账号管理" align="center">
            <template slot-scope="scope">
              <el-popover ref="editBox" placement="left">
                <div class="">
                  <el-row class="ban">
                    <p>账号恢复原因：</p>
                    <el-input
                      size="small"
                      style="width: 90%"
                      v-model="agreeReason"
                    ></el-input>
                  </el-row>
                  <el-button
                    slot="reference"
                    type="primary"
                    size="mini"
                    @click="checkMem(scope.row.id,0,agreeReason)"
                    >恢复</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  class="editButton"
                  size="mini"
                  type="primary"
                  icon="el-icon-check"
                ></el-button>
              </el-popover>
              <el-popover ref="editBox" placement="left">
                <div>
                  <el-row class="ban" >
                    <p style='display:block;'>账号禁用原因：</p>
                    <el-input
                      size="small"
                      style="width: 90%"
                      v-model="disagreeReason"
                    ></el-input>
                  </el-row>
                  <el-button
                    slot="reference"
                    type="primary"
                    size="mini"
                    @click="checkMem(scope.row.id,1,disagreeReason)"
                    >禁用</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  class="editButton"
                  size="mini"
                  type="primary"
                  icon="el-icon-close"
                ></el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column ref="edit" label="任职" align="center">
            <template slot-scope="scope">
              <el-popover ref="editBox" placement="left">
                <div class="termBox">
                  <el-row class="el-row">
                    <span class="label-title">操作会员：</span>
                    <el-input
                      disabled
                      size="small"
                      style="width: 70%"
                      v-model="editForm.name"
                    ></el-input>
                  </el-row>
                  <el-row class="el-row">
                    <span class="label-title">当前等级：</span>
                    <el-input
                      disabled
                      size="small"
                      style="width: 70%"
                      v-model="editForm.rankBefore"
                    ></el-input>
                  </el-row>
                  <el-row class="el-row">
                    <span class="label-title">新等级：</span>
                    <el-select
                      v-model="editForm.rankAfter"
                      placeholder="请选择新职称"
                      size="small"
                      width="300"
                    >
                      <el-option
                        v-for="item in rankLists"
                        :key="item.id"
                        :label="item.rank"
                        :value="item.rank"
                        style="padding-left: 20px"
                      >
                      </el-option>
                    </el-select>
                  </el-row>
                  <el-row class="el-row">
                    <span class="label-title">有效期：</span>
                    <el-date-picker
                      size="mini"
                      v-model="editForm.effectTime"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="effectTimePicker"
                      style="width: 210px"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-row>

                  <el-button
                    slot="reference"
                    type="primary"
                    size="mini"
                    @click="handleNewRank()"
                    >更新</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  class="editButton"
                  size="mini"
                  type="primary"
                  icon="el-icon-coordinate"
                  :disabled="scope.row.number == '非正式会员'"
                  @click="editItem(scope.row)"
                ></el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column ref="edit" label="升级正式会员" align="center">
            <template slot-scope="scope">
              <el-popover ref="editBox" placement="left">
                <div class="termBox">
                  <el-row class="el-row">
                    <span class="label-title">操作会员：</span>
                    <el-input
                      disabled
                      size="small"
                      style="width: 70%"
                      v-model="editForm.name"
                    ></el-input>
                  </el-row>
                  <el-row class="el-row">
                    <span class="label-title">新等级：</span>
                    <el-input
                      disabled
                      size="small"
                      style="width: 70%"
                      placeholder="普通会员"
                    ></el-input>
                  </el-row>
                  <el-row class="el-row">
                    <span class="label-title">有效期：</span>
                    <el-date-picker
                      size="mini"
                      v-model="toFormal.effectTime"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="effectTimePicker"
                      style="width: 210px"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-row>

                  <el-button
                    slot="reference"
                    type="primary"
                    size="mini"
                    @click="handleToFormal()"
                    >更新</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  class="editButton"
                  size="mini"
                  type="primary"
                  icon="el-icon-coordinate"
                  :disabled="scope.row.number !== '非正式会员'"
                  @click="editItem(scope.row)"
                ></el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import StuMana
// import pubsub from 'pubsub-js'
export default {
  name: "memberMEssages",
  data() {
    return {
      agreeReason:"",               //恢复 原因
      disagreeReason:"",            //禁用原因
      isSuccess: false,               //加载中？
      pageSize: 20,                  //每页数据量
      pageSizes: [ 20, 30, 50,100], //可选每页数据量
      showTableData: [],         //展示的数据
      total: 0,                 //全部数据量
      pageNow: 1,                 //当前所处页面
      selectedRow: [],         //选中的会员的会员号数组
      searchMember: "",         //搜索输入的会员名
      commitTime: "",         //时间搜索
      editMemberId: "",         //编辑行会员id
      isSearching: false,         //当前是否为展示搜索信息---以此来决定点击分页切换时调用的接口
      allSearchType: 2,         //0-2对应（个人会员分页/企业会员分页/个人+企业）
      // 按时间搜索方法
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 选择任命会员有效时长
      effectTimePicker: {
        shortcuts: [
          {
            text: "半年",
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 183);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "一年",
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "三年",
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options: [
        {
          value: "0",
          label: "全部个人会员",
        },
        {
          value: "1",
          label: "全部企业会员",
        },
        {
          value: "2",
          label: "所有成员",
        },
      ],
      chosenType: "",
      // 展示列表头
      tableList: [
        { name: "会员", key: "name" },
        { name: "会员号", key: "number" },
        { name: "工作单位", key: "workPlace" },
        { name: "联系方式", key: "tele" },
        { name: "生效时间", key: "startTime" },
        { name: "有效期至", key: "endTime" },
        { name: "会员等级", key: "rank" },
      ],
      // 头衔列表
      rankLists: [],
      // 会员等级修改信息
      editForm: {
        name: "",
        number: "",
        rankBefore: "rankBefore",
        rankAfter: "",
        effectTime: [],
      },
      toFormal: {
        userId:'',
        effectTime: [],
      }
    };
  },
  computed: {},
  methods: {
    async checkMem(id,num,reason) {
      if(num==0){
        this.$message({
          message: "恢复账号成功",
          type: "success",
        });
      }else{
        this.$message({
          message: "禁用账号成功",
          type: "error",
        });
      }
      this.isSuccess=true;
      const result = await this.$request.post({
        url: '/manager/setAccount',
        data: {
          userId:id, //
          status: num, //
          reason: reason
        }
      });
      this.isSuccess=false;
    },
    async applyPDF(id) {
      this.$message({
        message: "自动生成PDF中，请耐心等待",
        type: "success",
      });
      this.isSuccess=true;
      const result = await this.$request.postPDF({
        url: `/downloadPdf/Application?userId=${id}`,
        pdfName: "申请表",
      });
      this.isSuccess=false;
    },
    // val为选择的每页长度
    handleSizeChange(val) {
      this.pageSize = val;
      // 判断是日期筛选状态的分页还是全部会员信息的分页
      if (this.isSearching) {
        this.searchMessByTime(this.pageNow, this.pageSize);
      } else {
        this.showPageChange(this.pageNow, this.pageSize);
      }
    },
    // val为选择的第几页
    handleCurrentChange(val) {
      // 页面跳转函数
      this.pageNow = val;
      // 判断是日期筛选状态的分页还是全部会员信息的分页
      if (this.isSearching) {
        this.searchMessByTime(this.pageNow, this.pageSize);
      } else {
        this.showPageChange(this.pageNow, this.pageSize);
      }
    },
    // 选中行的记入数组selectedRow
    selectRow(selection) {
      let selectArr = [];
      if (selection.length > 0) {
        for (var i = 0; i < selection.length; i++) {
          selectArr.push(selection[i].id);
        }
      }
      this.selectedRow = selectArr;
    },
    // 单击编辑进入会员详情页面
    handleEdit(index, rowMessage) {
      this.editMemberId = rowMessage.number;
    },
    // 点击编辑时获取原有收费标准
    editItem(row) {
      this.editForm.rankBefore = row.rank;
      this.editForm.number = row.number;
      this.editForm.name = row.name;
      this.toFormal.name = row.name;
      this.toFormal.userId = row.id;
      // this.editForm.note = rows[index].note;
    },
    async handleNewRank() {
      if (this.editForm.number == "") {
        this.$message({
          type: "error",
          message: "未获取到该会员的会员账号！!",
        });
      } else if (this.editForm.rankAfter == "") {
        this.$message({
          type: "error",
          message: "请选择该会员的新职称！",
        });
      } else if (this.editForm.effectTime.length == 0) {
        this.$message({
          type: "error",
          message: "请选择该会员新职称的有效期！",
        });
      } else {
        let { number, rankAfter, effectTime } = this.editForm;

        const result = await this.$request.post({
          url: "/manager/updateInfo",
          data: {
            number,
            rank: rankAfter,
            startTime: effectTime[0],
            endTime: effectTime[1],
          },
        });

        this.showPageChange(this.pageNow, this.pageSize);
        this.$message({
          type: "success",
          message: "职位变动成功！",
        });
      }
    },
    async handleToFormal() {
      if (this.toFormal.userId == "") {
        this.$message({
          type: "error",
          message: "未获取到该会员的会员账号！!",
        });
      } else if (this.toFormal.effectTime.length == 0) {
        this.$message({
          type: "error",
          message: "请选择该会员的有效期！",
        });
      } else {
        let { userId, effectTime } = this.toFormal;
        const result = await this.$request.post({
          url: "/manager/become/vip",
          data: {
            userId,
            startTime: effectTime[0],
            endTime: effectTime[1],
          },
        });

        this.showPageChange(this.pageNow, this.pageSize);
        this.$message({
          type: "success",
          message: "会员身份变动成功！",
        });
      }
    },
    // 批量删除选中会员
    deleteRow() {
      if (this.selectedRow.length > 0) {
        this.$confirm("此操作将永久删除该会员信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const result = await this.$request.post({
              url: "/manager/deleteUsers",
              data: {
                ids: this.selectedRow,
              },
            });

            this.$message({
              type: "success",
              message: "删除成功!",
            });
            if (this.isSearching) {
              this.searchMessByTime(this.pageNow, this.pageSize);
            } else {
              this.showPageChange(this.pageNow, this.pageSize);
            }
          })
          .catch(() => {
            this.$message({
              type: "success",
              message: "操作取消!",
            });
          });
      } else {
        this.$alert("请先选择要删除的数据", "未选择数据", {
          confirmButtonText: "确定",
        });
      }
    },
    // 管理会员页面搜索函数（不带分页）-名字模糊搜索-名字+时间搜索-时间搜索
    searchMess: async function () {
      this.isSearching = true;
      // 情况1:姓名+时间搜索
      if (this.searchMember != "" && this.commitTime) {
        this.isSuccess = true;
        const result = await this.$request.post({
          url: "/manager/searchUsersByName",
          data: {
            name: this.searchMember,
            startTime: this.commitTime[0],
            endTime: this.commitTime[1],
          },
        });
        if (result.length == 0) {
          this.$message({
            message: "在此时间段内查无此会员信息！",
            type: "error",
          });
        }
        this.isSuccess = false;
        this.showTableData = result;
        this.total = result.length;
      } else if (this.searchMember != "") {
        this.isSuccess = true;
        // 情况2:单单姓名搜
        try{
          const result = await this.$request.post({
            url: "/manager/searchUsersByName",
            data: {
              name: this.searchMember,
            },
          });
          this.isSuccess = false;
          this.showTableData = result;
          this.total = result.length;
        }catch(e){
          this.isSuccess = false;
        }
      }else {
        // 情况3:单单时间搜索
        this.searchMessByTime(this.pageNow, this.pageSize);
      }
    },
    // 会员管理页面搜索--时间搜索（带分页）传入参数:当前页数/每页个数
    searchMessByTime: async function (pageNow, pageSize) {
      this.isSuccess = true;
        const result = await this.$request.post({
          url: `/manager/searchUsersByTime?page=${pageNow}&size=${pageSize}`,
          data: {
            startTime: this.commitTime?this.commitTime[0]:null,
            endTime: this.commitTime?this.commitTime[1]:null,
          },
        });
  
        if (result.totalCount == 0) {
          this.$message({
            message: "在此时间段内查无会员信息！",
            type: "error",
          });
        }
        // 修改展示数据/总共数据量
        this.isSuccess = false;
        this.showTableData = result.list;
        this.total = result.totalCount;
    },
    // 搜索完点击【全部】来调用展示回全部会员信息
    ShowAll() {
      this.isSearching = false;
      this.pageNow = 1;
      this.pageSize = 5;
      this.showPageChange(this.pageNow, this.pageSize);
    },
    // 导出选中类型全部会员数据
    flowOutAll: async function () {
      let chosenType = this.chosenType;

      if (chosenType == "") {
        this.$message({
          message: "请选择导出会员类型",
          type: "warning",
        });
      } else {
        let excelName=''
        if(chosenType==0){
          excelName='个人会员汇总'
        }else if(chosenType==1){
          excelName='企业会员汇总'
        }else{
          excelName='全部学会会员'
        }
        const result = await this.$request.postExcel({
          url: `/manager/exportAllUserExcel?type=${chosenType}`,
          excelName
        });
      }
    },
    // 导出选中会员数据
    flowOut: async function () {
      const result = await this.$request.postExcel({
        url: "/manager/exportUserExcel",
        data: {
          ids: this.selectedRow,
          // ids: [18,21,23,22]
        },
        excelName:'部分会员'
      });
    },
    // 展示全部（个人、会员、个人+会员）时点击切换
    showPageChange: async function (page, size) {
      this.isSuccess = true;
      let allSearchType = this.allSearchType;
      try{
        const result0 = await this.$request.post({
          url: `/manager/getUsersInPage?type=${allSearchType}`,
          data: {
            page,
            size,
          },
        });
        this.total = result0.totalCount;
        this.showTableData = result0.list;
      }catch(e){
        this.total = 0;
        this.showTableData = [];
      }
      // 修改展示数据/总共数据量
      this.isSuccess = false;
    },
    async getAllRank() {
      // 获取全部职位信息
      const result1 = await this.$request.post({
        url: "/rank/showRanks",
      });
      this.rankLists = result1;
    },
  },
  mounted() {
    this.isSearching = false;
    this.showPageChange(this.pageNow, this.pageSize);
    this.getAllRank();
  },
};
</script>

<style lang="css" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.el-button {
  padding: 5px 10px;
}
.manage {
  min-width: 1000px;
  overflow: scroll;
  background-color: #fff;
}
.title {
  margin-top: 30px;
  width: 300px;
  height: 70px;
  background-color: #4facfe;
  border-radius: 10px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  line-height: 70px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-shadow: -1px -2px 1px rgb(0, 0, 0);
}
.topSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.threeChangeButton {
  display: flex;
  justify-content: space-between;
}
.changeButton {
  background-color: transparent;
  margin: 10px 2px;
}
.buttonDelete {
  border: 1px solid red;
  color: red;
}
.memberFormPlace * {
  text-align: center;
}
.memberMessage {
  box-shadow: 2px 2px 5px rgba(17, 34, 51, 0.4);
  padding: 50px 50px;
}
.memberFormPlace a {
  text-decoration: none;
  color: #123;
  width: 60px;
  margin-right: 5px;
}
.termBox {
  width: 340px;
  padding: 10px 20px;
  border: 1px solid rgba(147, 178, 209, 0.701);
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.333);
}
.el-row {
  display: flex;
  height: 40px;
  justify-content: space-between;
}

.el-row .label-title {
  width: 70px;
  line-height: 30px;
}
.ban{
  display: block;
  width: 340px;
  min-height: 60px;
}
</style>
