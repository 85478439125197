<template>
  <div v-loading="isSuccess"
       class="audit-box">
    <div class="search-nav">
      <div class="search-select">
        <slot name="search-select">
          <el-select size="mini"
                     class="select-type"
                     v-model="typeId"
                     placeholder="请选择文章类型">
            <el-option v-for="item in typeList"
                       :key="item.id"
                       :label="item.type"
                       :value="item.id">
            </el-option>
          </el-select>
        </slot>
      </div>
    </div>
    <el-table class="showTable"
              :data="showProduceList"
              style="width: 80%">
      <el-table-column v-for="item in tableList"
                       :prop="item.key"
                       align="center"
                       :key="item.key"
                       :label="item.name">
      </el-table-column>
      <el-table-column ref="edit"
                       width="50"
                       label="检阅"
                       align="center">
        <template slot-scope="scope">
          <router-link :to="{
              path: '/manage/media_essay',
              query: { newId: scope.row.id },
            }"
                       class="check-link">查看</router-link>
        </template>
      </el-table-column>
      <el-table-column ref="edit"
                       label="选中首页"
                       width="200"
                       align="center">
        <template slot-scope="scope">
          <div class="edit-tools">
            <el-button type="primary"
                       icon="el-icon-check"
                       size="small"
                       @click="addPassage(scope.row.id)"
                       circle></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="page"
                     :page-size="size"
                     layout="total, prev, pager, next, jumper"
                     :total="totalSize">
      </el-pagination>
    </div>
    <div>
      <div class="showPassageList">
        <h2 class="title">选中列表</h2>
        <el-table class="showTable"
                  :data="chosenHomePassage"
                  style="width: 60%">
          <el-table-column prop="title"
                           label="Title"
                           width="450">
          </el-table-column>
          <el-table-column ref="edit"
                           label="删除"
                           width="60"
                           align="center">
            <template slot-scope="scope">
              <div class="edit-tools">
                <el-button type="danger"
                           icon="el-icon-close"
                           size="small"
                           @click="deletePassage(scope.row.id)"
                           circle></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <h2 class="title">首页展示效果</h2>
      <news :isShow="true"
            :showNewList='GIA_FocusPassage' />
      <memberActiveAndStory :showStoryList='storyPassage'
                            :showActiveList='activityPassage' />
    </div>
  </div>
</template>

<script>
import memberActiveAndStory from "../../components/home/memberActiveAndStory";
import news from "../../components/home/news";
export default {
  components: {
    news,
    memberActiveAndStory,
  },
  data () {
    return {
      // 展示各个文章类型的table的数据列表
      showProduceList: [],
      // 接口是否调用完成----是否使用loading
      isSuccess: false,
      totalSize: 0,
      // 展示各个文章类型的table的每页数据量
      size: 10,
      page: 1,
      // 展示各个文章类型的table
      tableList: [
        { name: "作者编号", key: "authorName" },
        { name: "文章标题", key: "title" },
        { name: "发布日期", key: "releaseDate" },
      ],
      // 文章类型id数组
      typeList: [],
      // 目前所在的文章type
      typeId: "",
      // 选中类型的首页展示文章列表：
      chosenHomePassage: [],
      // 选中GIA聚焦文章列表：
      GIA_FocusPassage: [],
      // 选中会员故事文章列表：
      storyPassage: [],
      // 选中会员活动文章列表：
      activityPassage: [],
    };
  },
  watch: {
    typeId () {
      this.getEssay(1);
    },
  },
  methods: {
    // 获取全部已发布文章
    async getEssay (page) {
      // 先将选中类型的首页展示文章数组清零（关于协会没有！）
      this.chosenHomePassage = [];
      this.getShowPassage();
      const { size, typeId } = this.$data;
      this.$data.isSuccess = true;
      try {
        const resultList = await this.$request.postForm({
          url: `/article/list/1`,
          data: {
            type: typeId,
            page: page,
            size: size,
          },
        });

        this.$data.showProduceList = resultList.list;
        this.$data.totalSize = resultList.totalCount;
        this.$data.isSuccess = false;
      } catch (error) {
        this.$data.isSuccess = false;
      }
    },
    // 获取全部文章类型
    async getListType () {
      const resultList = await this.$request.get({
        url: "/article/all/type",
      });
      this.$data.typeList = resultList;
      this.$data.typeId = resultList[3].id;
    },
    // 获取选中类型的首页展示文章 567:聚焦、故事、活动
    async getShowPassage () {
      const { typeId } = this.$data;
      const list = await this.$request.post({
        url: `/articleManagement/query?typeId=${typeId}`,
      });
      this.chosenHomePassage = list;
      const list1 = await this.$request.post({
        url: `/articleManagement/query?typeId=${5}`,
      });
      this.GIA_FocusPassage = list1;
      const list2 = await this.$request.post({
        url: `/articleManagement/query?typeId=${6}`,
      });
      this.storyPassage = list2;
      const list3 = await this.$request.post({
        url: `/articleManagement/query?typeId=${7}`,
      });
      this.activityPassage = list3;
    },
    // 删除该首页展示文章
    async deletePassage (id) {
      const { typeId } = this.$data;
      this.$confirm("是否删除该文章首页的展示", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const deleteHomePassage = await this.$request.post({
          url: "/articleManagement/delete",
          data: {
            typeId,
            articleId: [id],
          },
        });
        this.getShowPassage();
        this.$message({
          type: "success",
          message: `删除成功!`,
        });
      });
    },
    // 添加首页展示文章
    async addPassage (id) {
      this.$confirm("是否添加该文章首页的展示", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let typeId = this.typeId;
        const addHomePassage = await this.$request.post({
          url: "/articleManagement/select",
          data: {
            typeId,
            articleId: [id],
          },
        });
        this.getShowPassage();
        this.$message({
          type: "success",
          message: `添加成功!`,
        });
      });
    },
    // 换页
    handleCurrentChange (page) {
      this.$data.page = page;
      this.getEssay(page);
    },
  },
  async mounted () {
    await this.getListType();
  },
};
</script>
<style lang="less" scoped>
.audit-box {
  height: 100%;
  min-width: 1000px;
  overflow: scroll;
}
.title {
  margin-top: 10px;
  width: 300px;
  height: 40px;
  background-color: #4facfe;
  border-radius: 10px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  line-height: 40px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-shadow: -1px -2px 1px rgb(0, 0, 0);
}
.search-nav {
  padding: 15px 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(165, 165, 165, 0.205);
  .search-input {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    width: 200px;
    margin: 0 2px;
  }
  .search-select {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    width: 150px;
    margin: 0 2px;
  }
  .search-add {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    margin: 0 2px;
  }
}
.showTable {
  height: calc(100% - 195px);
  overflow: auto;
  .check-link {
    &:hover {
      color: #1d90fb;
      text-decoration: underline;
    }
  }
  .edit-tools {
    display: flex;
    justify-content: center;
  }
}
.pagination {
  .el-pagination {
    background-color: #fff !important;
    padding: 6px 30px;
  }
}
.tool-box {
  display: flex;
  justify-content: center;
}
</style>