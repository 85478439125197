<template>
  <div class="associator-box">
    <div class="associator-header">
      <div class="header-left">
        <img src="../image/weblogo.jpg" />
        <span>会员中心</span>
      </div>
      <div class="header-right">
        <el-popover placement="bottom-start"
                    trigger="hover">
          <ul class="base-list">
            <li @click="exitLogin"
                class="base-item">
              <i class="iconfont icon-tuichu"></i>
              <span>退出登录</span>
            </li>
          </ul>
          <el-button slot="reference"
                     class="setup-box">
            <i class="iconfont icon-change"></i>
          </el-button>
        </el-popover>
      </div>
    </div>
    <ul class="association-mastRead">
      <el-button @click="$router.push('/introduct_of_association')"
                 type="primary"
                 size="small"
                 class="base-item">
        <i class="el-icon-s-order"></i>
        <span>学会简介</span>
      </el-button>
      <el-button @click="$router.push('/articles_of_association')"
                 type="primary"
                 size="small"
                 class="base-item">
        <i class="el-icon-tickets"></i>
        <span>学会章程</span>
      </el-button>
    </ul>
    <router-view class="router-box"></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  methods: {
    exitLogin () {
      this.$confirm("确定要退出登录么", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.GIA_token = "";
        this.$router.push("/login");
      });
    },
    async getUserInfo () {
      const result = await this.$request.get({
        url: "/user/info",
      });
      const {
        memberServe,
        serviceReward,
        academicianGroup,
        vipType,
        organizationType
      } = result
      this.$data.webPhoto = result.headPhoto;
      let data = {}
      if (vipType && vipType.id == 1) {
        data = {
          ...result,
          memberServe: memberServe ? memberServe.parseToArray() : [],
          serviceReward: serviceReward ? serviceReward.parseToArray() : [],
          academicianGroup: academicianGroup ? academicianGroup.parseToArray() : [],
          organizationType: organizationType ? organizationType.parseToArray() : []
        };
      } else {
        data = result
      }
      this.$store.state.userInfo = { ...data }
      this.$store.state.userInfo_canWrite = data
    },
  },
  mounted () {
    this.getUserInfo()
  }
};
</script>
<style lang="less" scoped>
* {
  max-width: 1024px;
}
.associator-box {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  .associator-header {
    width: 100vw;
    max-width: 1000px;
    position: fixed;
    top: 0;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #f1f1f1;
    border-top: none;
    color: #909090;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 250;
    .header-left {
      --imgSize: 60px;
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        font-weight: 600;
        margin-left: 10px;
      }
      img {
        border-radius: 50%;
        width: var(--imgSize);
        height: var(--imgSize);
      }
    }
  }
  .association-mastRead {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;
  }
}
.setup-box {
  background-color: transparent;
  outline: none;
  border: none;
  &:hover {
    color: rgb(131, 131, 131);
  }
  .icon-change {
    position: relative;
    color: rgb(131, 131, 131);
    font-size: 2em;
  }
}
</style>