
<template>
  <div class="GIAIntroduction">
    <div class="GI_introductionTitle">
      <i @click="$router.back(1)" class="back el-icon-arrow-left"></i>
      <div>
        <span class="article-title">{{ content.title }}</span>
        <span class="article-status">
          {{
            content.articleStatus == 0
              ? "审核中..."
              : content.articleStatus == 1
              ? "已发布"
              : "禁止展示"
          }}
        </span>
      </div>
      <div class="GI_secondTitle">
        <i>阅读量:{{ content.readNum }}</i>
        <i>{{ content.releaseDate }}</i>
      </div>
    </div>
    <div v-html="content.context" class="GI_introductionContent"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: {
        context: "",
      },
    };
  },
  methods: {
  },
  async mounted() {
    const { newId } = this.$route.query;
    const result = await this.$request.get({
      url: "/article/query/" + newId,
    });
    this.$data.content = result;
  },
};
</script>
<style lang="less" scoped>
.GIAIntroduction {
  min-width: 1000px;
  overflow: scroll;
  width: 100%;
  height: 100%;
  .GI_introductionTitle {
    margin-top: 30px;
    text-align: center;
    border-bottom: 2px solid #cccccc60;
    position: relative;
    .back {
      left: 20px;
      position: absolute;
      font-size: 36px;
      font-weight: 600;
      color: #757575;
      cursor: pointer;
    }
    .article-title {
      font-weight: 600;
      font-size: 30px;
    }
    .article-status {
      margin-left: 15px;
      font-size: 15px;
      color: #808080;
    }
    .GI_secondTitle {
      margin-left: 50%;
      transform: translate(-50%);
      width: 250px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-around;
      color: #888;
    }
  }
  .GI_introductionContent {
    padding: 10px 20px;
    box-sizing: border-box;
    height: calc(100% - 122px);
    overflow: auto;
    line-height: 1.5em;
        text-indent: 2em;
    font-family: 宋体, SimSun;
  }
}
</style>
<style>
.GI_introductionContent img {
  max-width: 100%;
}
</style>