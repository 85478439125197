<template>
  <div class="paymentRecord" v-loading="isSuccess">
    <div class="receiveMessage">
      <div class="topSearch" style="min-width: 300px">
        <div>
          <span>审核状态：</span>
          <el-select v-model="operateState" class="selectT" placeholder="审核状态：" @change="showChange()">
            <el-option  label="未处理"  value="0"> <i class="el-icon-loading"></i>未处理</el-option>
            <el-option label="审核通过" value="1"><i class="el-icon-check"></i>审核通过</el-option>
            <el-option label="审核不通过" value="2"><i class="el-icon-close"></i>审核不通过</el-option>
          </el-select>
        </div>
      </div>
      <!--
      <div class="receiveFormPlace">
        <el-table :data="showTableData" style="width: 100%; min-width: 800px; overflow: visible"
          :default-sort="{ prop: 'date', order: 'descending' }" stripe border max-height="450">
          <el-table-column prop="id" align="center" label="专家id" width="50">
          </el-table-column>

          <el-table-column prop="name" align="center" label="姓名" width="80">
          </el-table-column>
          <el-table-column prop="expertNumber" align="center" label="专家号" width="100">
          </el-table-column>
          <el-table-column prop="userId" align="center" label="会员id" width="60">
          </el-table-column>
          <el-table-column prop="currentEmployer" align="center" label="工作单位" width="100">
          </el-table-column>
          <el-table-column prop="gaddress" align="center" label="通讯地址" width="100"></el-table-column>

          <el-table-column prop="email" align="center" label="电子邮箱" width="100">
          </el-table-column>

          <el-table-column prop="createTime" align="center" label="申请时间" width="100">
          </el-table-column>

          <el-table-column prop="modifyTime" align="center" label="审核时间" width="100">
          </el-table-column>



          <el-table-column prop="status" align="center" label="审核意见" width="200">
            <template slot-scope="rows">
              <div v-if="operateState == 1">
                <div v-if="operateState == 1" style="color: #4facfe">
                  <span>已同意</span>
                </div>
              </div>
              <div v-else-if="operateState == 2" style="color: orangered">
                <span>不同意 </span>
              </div>
              <div v-else>
                <label class="inputLabel">请输入</label>
                <input v-model="expertOpinion" placeholder="请输入发送的消息">


                <el-button type="primary" icon="el-icon-check"
                  @click="setExpert(rows.row.userId, 1, expertOpinion)"></el-button>     
                <el-button icon="el-icon-close" @click="setExpert(rows.row.userId, 2, expertOpinion)"></el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <hr style="border-top: 2px dotted rgb(200, 199, 200, 0); margin: 5px 0" />
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="pageSizes"
          :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

    -->

      <!--测试界面-->


      <div class="receiveFormPlace">
        <el-descriptions class="a-description" :column="2" :size="'mini'" border v-for="(item, index) in showTableData"
          :key="index" :title="item.name">
        

          <el-descriptions-item>
            <template slot="label">
              专家id
            </template>
            {{ item.id  }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              会员id
            </template>
            {{ item.userId}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              专家号
            </template>
            {{ item.expertNumber}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              性别
            </template>
            {{ item.vipType == 1 ? "女" : "男" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              民族
            </template>
            {{ item.nation }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              身份证
            </template>
            {{ item.idNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              毕业院校
            </template>
            {{ item.graduationInstitution }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              专业
            </template>
            {{ item.major }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              毕业时间
            </template>
            {{ item.graduationTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              当前工作单位
            </template>
            {{ item.currentEmployer }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              工作分类
            </template>
            {{ item.jobClassification }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              职务
            </template>
            {{ item.duties }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              职称等级
            </template>
            {{ item.professionalQualifications }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              是否两院院士
            </template>
            {{ item.isAcademician == 1 ? "是" : "不是" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              是否博士生导师
            </template>
            {{ item.isDoctoralsupervisor == 1 ? "是" : "不是" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              是否硕士生导师
            </template>
            {{ item.isMastersupervisor == 1 ? "是" : "不是" }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              学历学位
            </template>
            {{ item.educationDegree }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              办公电话
            </template>
            {{ item.officePhone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              个人电话
            </template>
            {{ item.mobilePhone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              通讯地址
            </template>
            {{ item.address }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              邮编
            </template>
            {{ item.zipCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              电子邮箱
            </template>
            {{ item.email }}
          </el-descriptions-item>



          <el-descriptions-item>
            <template slot="label">
              成就
            </template>
            {{ item.achievem }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label" >
             审核意见
            </template>
            
           
              <div>
                <label class="inputLabel"> 发送给该用户的消息：  </label>
                <input v-model="expertOpinion" placeholder="请输入发送的消息">


                <el-button type="primary" icon="el-icon-check"
                  @click="setExpert(item.userId, 1, expertOpinion)">审核通过</el-button> 

                <el-button icon="el-icon-close" @click="setExpert(item.userId, 2, expertOpinion)">审核不通过</el-button>

                <el-button type="primary" icon="el-icon-loading"
                  @click="setExpert(item.userId, 0, expertOpinion)">待审核</el-button>  
              </div>

           
          </el-descriptions-item>









        </el-descriptions>
        <hr style="border-top: 2px dotted rgb(200, 199, 200, 0); margin: 10px 0" />
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="pageSizes"
          :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>




    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  name: "payRecord",
  data() {
    return {
      showTableData: [
      ],
      isSuccess: false, //加载中？
      pageSize: 20, //每页数据量
      pageSizes: [20, 30, 50, 100], //可选每页数据量
      expertList: [], //从后端获取的数据数组
      total: 0, //全部数据量
      pageNow: 1, //当前所处页面
      searchMember: "", //搜索输入的会员名
      memberType: [], //会员类型搜索
      editMemberId: "", //编辑行会员id
      operateState: "0", //处理状态：0--待处理；1--已处理;2--审核不通过
      page: 1,
      size: 5,
      param1: '',  // setExpert()参数
      param2: '',
      expertOpinion:"测试",
    };
  },
  computed: {},
  methods: {
    // val为选择的每页长度
    handleSizeChange(val) {
      this.pageSize = val;
      this.ShowPageChange(this.pageNow, this.pageSize);
    },
    // val为选择的第几页
    handleCurrentChange(val) {
      this.pageNow = val;
      this.ShowPageChange(this.pageNow, this.pageSize);
    },
    // 调用展示审核、未审核列表信息改变
    showChange: async function () {
      this.pageNow = 1;
      this.pageSize = 5;
      this.ShowPageChange(this.pageNow, this.pageSize);
    },
    // 调用展示审核、未审核列表信息
    ShowPageChange: async function (status, page, size) {
      this.isSuccess = true;
      // 获取待处理或已处理的会员等级（头衔）变动列表
      let operateState = this.operateState;
      let pageNow = this.pageNow;
      let pageSize = this.pageSize;
      const result = await this.$request.get({
        url: `/expert/manager/status?status=${operateState}&page=${pageNow}&size=${pageSize}`,

      });
      console.log(result); // 添加这一行


      this.total = result.totalCount;
      this.showTableData = result.list;
      this.isSuccess = false;
    },
    async setExpert(userId, result, message) {
      try {
        const response = await this.$request.post({
          url: '/expert/manager/audit',
          data: {
            
            userId,
            result,
            message
           
          },
        });
        // 处理返回的数据
        console.log(response);
        // 根据需要进行其他操作
        this.ShowPageChange(this.pageNow, this.pageSize);
      } catch (error) {
        // 发生错误时的处理
        console.error(error);
      }
    }
  },

  mounted() {
    this.ShowPageChange(); // 初始化时调用展示列表信息
  }
};
</script>
  
  
<style lang="css" scoped="true">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.el-button {
  padding: 5px 10px;
}

.paymentRecord {
  width: 1150px;
  background-color: #fff;
  min-width: 1000px;
  overflow: scroll;
  margin-left: -50px;
  /* margin-left: 50%;
  
    transform: translate(-50%); */
}

.title {
  margin-top: 30px;
  width: 300px;
  height: 70px;
  background-color: #4facfe;
  border-radius: 10px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  line-height: 70px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-shadow: -1px -2px 1px rgb(0, 0, 0);
}

.topSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.receiveFormPlace * {
  text-align: center;
}

.receiveMessage {
  width: 1230px;
  box-shadow: 2px 2px 5px rgba(17, 34, 51, 0.4);
  padding: 50px 100px;
  overflow: auto;
}

.receiveFormPlace {
  margin-top: 30px;
  min-width: 1100px;
  overflow-x: scroll;
}

.receiveFormPlace a {
  text-decoration: none;
  color: #123;
  width: 60px;
  margin-right: 5px;
}

.selectT {
  min-width: 60px;
  max-height: 40px;
}
</style>
  