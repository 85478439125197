<template>
  <div class="payBox">
    <div class="formBox">
      <self-form-box labelText="姓 名"> {{ this.$store.state.userInfo.name }} </self-form-box>
      <self-form-box noPadding
                     noBorder
                     labelText="交费时间与标准说明">
        <el-table height="240"
                  :data="explainData"
                  border
                  style="width: 100%; border: none">
          <el-table-column prop="kind"
                           align="center"
                           label="缴费人员类型"
                           width="150">
          </el-table-column>
          <el-table-column style="padding: none"
                           prop="fee"
                           label="缴费金额(xx元/年)"
                           width="160"
                           align="center">
            <template scope="slot">
              {{ slot.row["fee"] / 100 }}
            </template>
          </el-table-column>
          <el-table-column align="center"
                           prop="note"
                           label="备注说明">
          </el-table-column>
          <el-table-column align="center"
                           width="50"
                           label="选择">
            <template scope="slot">
              <i class="choose-btn"
                 @click="chooseIndex = slot.$index"
                 :class="
                  slot.$index == chooseIndex
                    ? 'el-icon-message-solid'
                    : 'el-icon-bell'
                "></i>
            </template>
          </el-table-column>
        </el-table>
      </self-form-box>
      <self-form-box labelText="金额(元)">
        <div class="willCost">{{ expense / 100 }}</div>
      </self-form-box>
      <self-form-box labelText="交费年限">
        <el-radio-group v-model="yearLinit">
          <el-radio :label="1">1年</el-radio>
          <el-radio :label="2">2年</el-radio>
          <el-radio :label="3">3年</el-radio>
          <el-radio :label="4">4年</el-radio>
          <el-radio :label="5">5年</el-radio>
        </el-radio-group>
      </self-form-box>
      <self-form-box labelText="打印凭证">
        <div>
          <el-radio-group style="padding: 8px 0"
                          v-model="voucher">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <el-popover v-if="voucher == 1"
                      placement="right"
                      width="400"
                      trigger="click">
            <div>暂无</div>
            <el-button slot="reference"
                       style="margin-left: 15px"
                       size="small"
                       type="primary">凭证信息</el-button>
          </el-popover>
        </div>
      </self-form-box>
      <self-form-box labelText="支付方式">
        <el-radio-group v-model="payWay">
          <el-radio :label="1">微信</el-radio>
          <!-- <el-radio :label="2">支付宝</el-radio> -->
        </el-radio-group>
      </self-form-box>
      <self-form-box labelText="留言">
        <textarea class="note"
                  maxlength="120"
                  v-model="note"
                  placeholder="提示:报销凭证为中华人民共和国财政部监制的《全国性社会团体会费统一凭票》,可以报销,但不是发票" />
      </self-form-box>
    </div>
    <el-button @click="toPay"
               class="toPay"
               type="primary">去支付</el-button>
  </div>
</template>
<script>
import selfFormBox from "../../components/selfFormBox.vue";
export default {
  components: {
    selfFormBox,
  },
  data () {
    return {
      // 缴费时间说明
      explainData: [],
      // 交费年限数组
      yearLinit: 1,
      // 会费报销凭证
      voucher: 1,
      // 支付方式
      payWay: 1,
      // 留言
      note: "",
      chooseIndex: -1,
    };
  },
  methods: {
    async getChargeStandard () {
      const result = await this.$request.post({
        url: "/chargeStandard/get",
      });
      this.$data.explainData = result;
    },
    async toPay () {
      const {
        $data,
        $data: {
          explainData,
          chooseIndex,
          voucher, // 会费报销凭证
          note,
          yearLinit,
          payWay,
        },
      } = this;
      if (chooseIndex < 0) {
        this.$message({
          message: "请选择缴费人员类型",
        });
        return;
      }
      const rules = {
        voucher: "请选择是否需要会费报销凭证", // 会费报销凭证
        yearLinit: "请选择支付年限",
        payWay: "请选择支付方式",
      };
      for (const key in rules) {
        if (!$data[key] && $data[key] !== 0) {
          this.$message({
            message: rules[key],
          });
          return;
        }
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
      });
      try {
        const { id: payStandardId, fee } = explainData[chooseIndex];
        const total = fee * yearLinit;
        const result = await this.$request.post({
          url: "/order/commit",
          data: {
            year: yearLinit,
            payWayId: payWay,
            payStandardId: payStandardId,
            voucher: voucher,
            note: note,
          },
        });
        loading.close();
        const params = {
          payOrderId: result.id,
          orderNumber: result.number,
          year: yearLinit,
          fee: total,
          note: note,
          payWay: payWay == 1 ? "微信" : "支付宝",
        };
        sessionStorage.setItem("payInfo", JSON.stringify(params));
        this.$router.push({
          name: "/GIA_associator/_pay_",
          params: params
        });
      } catch (error) {
        loading.close();
        this.$message({
          message: "请求错误!",
          showClose: true,
          type: "error",
          duration: 2000,
        });
      }
    },
  },
  computed: {
    expense () {
      const { chooseIndex, explainData, yearLinit } = this.$data;
      if (chooseIndex == -1 || !explainData || !explainData.length) {
        return 0;
      }
      return yearLinit * explainData[chooseIndex].fee;
    },
  },
  mounted () {
    this.getChargeStandard();
  },
};
</script>
<style scoped lang="less">
.payBox {
  width: 100%;
  overflow: hidden;
  .formBox {
    border-top: 1px solid rgb(235, 235, 235);
    .choose-btn {
      color: #409eff;
      cursor: pointer;
    }
    .moreTable {
      padding: 10px 0;
      &:last-child {
        padding-bottom: 0;
      }
      &::after {
        content: "";
        left: 0px;
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        background-color: #ebeef5;
      }
      &:last-child::after {
        content: "";
        width: 100%;
        height: 1px;
        display: block;
        background-color: transparent;
      }
    }
    .willCost {
      font-weight: 600;
      padding-left: 10px;
    }
    .note {
      resize: none;
      box-sizing: border-box;
      width: 100%;
      font-size: 13px;
      padding: 10px;
      height: 80px;
      background-color: rgb(248, 251, 252);
      border-radius: 5px;
      border: 1px solid rgba(230, 230, 230, 0.466);
      outline: none;
    }
  }
  .toPay {
    display: block;
    margin: 10px auto;
    padding: 8px 80px !important;
  }
}
</style>