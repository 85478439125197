import { render, staticRenderFns } from "./partyBuilding.vue?vue&type=template&id=d0b044c6&scoped=true"
import script from "./partyBuilding.vue?vue&type=script&lang=js"
export * from "./partyBuilding.vue?vue&type=script&lang=js"
import style0 from "./partyBuilding.vue?vue&type=style&index=0&id=d0b044c6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0b044c6",
  null
  
)

export default component.exports