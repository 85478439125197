/* eslint-disable no-extend-native */
/* eslint-disable eqeqeq */
/**
 * 全局改变原型链方法
 */
String.prototype.parseToArray = function () {
  return this == '[]' || this == '' || this == null || this == undefined ? [] : this.replace("[", "")
    .replace("]", "")
    .replace(/\s*/g, "")
    .split(",")
}
Array.prototype.stringfyToString = function () {
  return this == '' || this == null || this == undefined ? '[]' : `[${this.toString()}]`
}
