<template>
  <div class="info-box">
    <el-form ref="form" :model="form" :rules="rules" class="base-form">
      <el-form-item
        class="form-item"
        label="姓名"
        label-width="180px"
        prop="name"
      >
        <el-input size="small" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="性别"
        label-width="180px"
        prop="sex"
      >
        <el-select v-model="form.gender" >
          <el-option
            v-for="item in form.sex"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="民族"
        label-width="180px"
        prop="nation"
      >
        <el-input size="small" v-model="form.nation"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="身份证"
        label-width="180px"
        prop="idNumber"
      >
        <el-input size="small" v-model="form.idNumber"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="毕业院校"
        label-width="180px"
        prop="graduationInstitution"
      >
        <el-input size="small" v-model="form.graduationInstitution"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="专业"
        label-width="180px"
        prop="major"
      >
        <el-input size="small" v-model="form.major"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="学历/学位"
        label-width="180px"
        prop="educationDegree"
      >
        <el-input size="small" v-model="form.educationDegree"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="毕业时间"
        label-width="180px"
        prop="graduationTime"
      >
        <el-date-picker
          v-model="form.graduationTime"
          type="datetime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="现工作单位"
        label-width="180px"
        prop="currentEmployer"
      >
        <el-input size="small" v-model="form.currentEmployer"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="现工作单位行业分类"
        label-width="180px"
        prop="jobClassification"
      >
        <el-input size="small" v-model="form.jobClassification"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="职务"
        label-width="180px"
        prop="duties"
      >
        <el-input size="small" v-model="form.duties"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="职称等级"
        label-width="180px"
        prop="professionalQualifications"
      >
        <el-input
          size="small"
          v-model="form.professionalQualifications"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="是否是两院院士"
        label-width="180px"
        prop="isAcademician"
      >
        <el-radio v-model="form.isAcademician" label="1">是</el-radio>
        <el-radio v-model="form.isAcademician" label="0">否</el-radio>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="是否博士生导师"
        label-width="180px"
        prop="isDoctoralsupervisor"
      >
        <el-radio v-model="form.isDoctoralsupervisor" label="1">是</el-radio>
        <el-radio v-model="form.isDoctoralsupervisor" label="0">否</el-radio>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="是否硕士生导师"
        label-width="180px"
        prop="isMastersupervisor"
      >
        <el-radio v-model="form.isMastersupervisor" label="1">是</el-radio>
        <el-radio v-model="form.isMastersupervisor" label="0">否</el-radio>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="专业领域"
        label-width="180px"
        prop="professionalField"
      >
        <el-input size="small" v-model="form.professionalField"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="办公电话"
        label-width="180px"
        prop="officePhone"
      >
        <el-input size="small" v-model="form.officePhone"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="手机"
        label-width="180px"
        prop="mobilePhone"
      >
        <el-input size="small" v-model="form.mobilePhone"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="联系地址"
        label-width="180px"
        prop="address"
      >
        <el-input size="small" v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="邮编"
        label-width="180px"
        prop="zipCode"
      >
        <el-input size="small" v-model="form.zipCode"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="电子邮箱"
        label-width="180px"
        prop="email"
      >
        <el-input size="small" v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="个人简介"
        label-width="180px"
        prop="personalProfile"
      >
        <el-input
          style="width: 650px"
          type="textarea"
          v-model="form.personalProfile"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="个人成就"
        label-width="180px"
        prop="achievem"
      >
        <el-input
          style="width: 650px"
          type="textarea"
          v-model="form.achievem"
        ></el-input>
      </el-form-item>
    </el-form>

    <div style="display: flex; justify-content: center; align-items: center">
      <el-button @click="onSubmit()" style="width: 300px">提交</el-button>
    </div>
    <div style="display: flex; justify-content: center; align-items: center">
      <p style="font-size: small; color: #409eff ;margin-top:5px">
        *会员请先登录再进行申请操作
      </p>
    </div>
  </div>
</template>
<script>
import giaImg from "../../../components/giaImg.vue";
export default {
  components: { giaImg },
  data() {
    return {
      form: {
        sex: [
          {
            value: "0",
            label: "男",
          },
          {
            value: "1",
            label: "女",
          },
        ],
        gender: "",
        value1: "",
        name: "",
        nation: "",
        idNumber: "",
        graduationInstitution: "",
        major: "",
        graduationTime: "",
        currentEmployer: "",
        jobClassification: "",
        duties: "",
        professionalQualifications: "",
        isAcademician: "0",
        isDoctoralsupervisor: "0",
        isMastersupervisor: "0",
        professionalField: "",
        educationDegree: "",
        officePhone: "",
        mobilePhone: "",
        address: "",
        zipCode: "",
        email: "",
        personalProfile: "",
        achievem: "",
      },
      expertid: "",
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        nation: [{ required: true, message: "请输入民族", trigger: "change" }],
        idNumber: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            min: 15,
            max: 18,
            message: "请输入身份证号正确格式",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },
        ],
        graduationInstitution: [
          { required: true, message: "请填写毕业院校", trigger: "blur" },
        ],
        major: [{ required: true, message: "请填写专业名称", trigger: "blur" }],
        graduationTime: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        currentEmployer: [
          { required: true, message: "请填写现工作单位", trigger: "blur" },
        ],
        jobClassification: [
          {
            required: true,
            message: "请填写现工作单位行业分类",
            trigger: "blur",
          },
        ],
        duties: [
          { required: true, message: "请填写职务名称", trigger: "blur" },
        ],
        professionalQualifications: [
          { required: true, message: "请填写职称等级", trigger: "blur" },
        ],
        professionalField: [
          { required: true, message: "请填写专业领域", trigger: "blur" },
        ],
        officePhone: [
          { required: true, message: "请填写办公电话", trigger: "blur" },
          {
            min: 5,
            max: 11,
            message: "长度在 5 到 11 个数字",
            trigger: "blur",
          },
        ],
        mobilePhone: [
          { required: true, message: "请填写手机号", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "请输入合法手机号/电话号",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请填写联系地址", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请填写电子邮箱", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "请输入正确邮箱",
          },
        ],
        personalProfile: [
          { required: true, message: "请填写个人简介", trigger: "blur" },
        ],
        achievem: [
          { required: true, message: "请填写个人成就", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      /* json格式提交： */
      // let formData = JSON.stringify(this.formMess);

      /* formData格式提交： */
      this.$refs.form.validate((valid) => {
        if (valid) {
          const result = this.$request
            .post({
              url: `/user/applyExpert?userId=${this.expertid}`,
              headers: {
                Authorization: "Bearer " + localStorage.GIA_token,
              },
              data: this.form,
            })
            .then((res) => {
              console.log(res);
              console.log(this.expertid);
            });
        }
      });
    },
  },
  mounted() {
    // this.expertid = this.$store.state.expertid;
    this.expertid = localStorage.getItem('userid');
    console.log( this.expertid, "zz");
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.info-box {
  padding: 24px 24px 24px 0px;
  width: 100%;
  border: 2px solid rgba(17, 34, 51, 0.252);
  margin-bottom: 100px;
  .title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 5px 0;
  }
  .base-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .form-item {
      .el-input {
        width: 205px;
      }
      .image-box {
        width: 205px;
      }
      .textarea {
        flex: 1;
      }
    }
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }
  .img-box {
    display: flex;
    justify-content: space-around;
  }
  .tools-box {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }
}
</style>