var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"self-top-box"},[_c('div',{staticClass:"self-top-nav"},[_c('ul',{staticClass:"ful",on:{"mouseleave":function($event){_vm.nowHover = ''}}},_vm._l((this.$store.state.routerlist),function(routeItem,index1){return _c('li',{key:index1,staticClass:"fli",class:_vm.nowHover === index1 || routeItem.route == '/'+ _vm.routePath[1]
            ? 'hover'
            : '',on:{"mouseenter":function($event){_vm.nowHover = index1}}},[_c('router-link',{staticClass:"route-1",attrs:{"to":""}},[_vm._v(_vm._s(routeItem.title))]),(routeItem.children)?_c('ul',{staticClass:"sul",style:({
            height:
              _vm.nowHover === index1 ? `${routeItem.children.length * 36}px` : 0,
            overflow: _vm.nowHover === index1 ? '' : 'hidden',
          })},_vm._l((routeItem.children),function(routeItem2,index2){return _c('li',{key:index2,staticClass:"sli"},[_c('router-link',{staticClass:"route-2",attrs:{"to":`${routeItem.route}${routeItem2.to}`}},[_vm._v(_vm._s(routeItem2.title))])],1)}),0):_vm._e()],1)}),0),_c('div',{staticClass:"menu-right-nav"},[_c('router-link',{attrs:{"to":{
           path: '/partyBuilding/PartyBuildingActivities',
          }}},[_vm._v("学会党建 ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }