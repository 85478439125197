<template>
  <div class="info-box">
    <p class="title">基本信息</p>
    <el-form class="base-form">
      <el-form-item class="form-item"
                    :label="item.label + ':'"
                    label-width="180px"
                    v-for="(item, key) in baseInfo"
                    :key="key">
        <el-input disabled
                  size="small"
                  :placeholder="personalInfo[key]?personalInfo[key].name:'暂无'"
                  v-if="item.type == 'object'">
        </el-input>
        <el-date-picker v-model="personalInfo[key]"
                        size="small"
                        v-else-if="item.type == 'DatePicker'"></el-date-picker>
        <el-input v-model="personalInfo[key]"
                  size="small"
                  v-else
                  :placeholder="`${item.label}`"
                  disabled>
        </el-input>
      </el-form-item>
    </el-form>
    <p class="title">证书照片</p>
    <div class="img-box">
      <div class="img-once-box"
           v-for="img in showImg"
           :key="img.label">
        <gia-img :imgSrc="personalInfo[img]"
                 :label="img.label" />
      </div>
    </div>
  </div>
</template>
<script>
import giaImg from "../../components/giaImg.vue";
export default {
  components: { giaImg },
  data () {
    return {
      baseInfo: {
        vipType: {
          label: "会员类型",
          type: "object",
        },
        vipRank: {
          label: "会员级别",
          type: "object"
        },
        startTime: {
          label: "生效时间",
        },
        endTime: {
          label: "有效期至",
        },
        recommender: {
          label: "推荐人的会员号",
        },
        totalPay: {
          label: "总的缴费金额(分)",
        },
        vipNumber: {
          label: "会员号",
        },
        channel: {
          label: "入会渠道",
        },
      },
      showImg: {
        companyCert: {
          label: "企业机构代码证书",
        },
        legalPersonIdCart: {
          label: "法人身份证图片",
        },
        legalPersonCert: {
          label: "法人营业证明",
        },
      },
    };
  },
  methods: {},
  computed: {
    personalInfo () {
      console.log(this.$store.state.userInfo,'zzz');
      return this.$store.state.userInfo;
    },
  },
};
</script>
<style lang="less" scoped>
.info-box {
  padding: 24px;
  .title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 5px 0;
  }
  .base-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .form-item {
      .el-input {
        width: 205px;
      }
      .image-box {
        width: 205px;
      }
      .textarea {
        flex: 1;
      }
    }
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }
  .img-box {
    display: flex;
    justify-content: space-around;
  }
  .tools-box {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }
}
</style>