<template>
  <div class="new-page">
    <left-menu-nav :nowRoute="nowRoute" />
    <router-view class="route-contain"></router-view>
  </div>
</template>
<script>
import leftMenuNav from "../../components/home/leftMenuNav.vue";
export default {
  components: { leftMenuNav },
  data() {
    return {
      nowRoute: {
        title: "学会党建",
        route: "/partyBuilding",
        children: [
          {
            title: "党建活动",
            to: "/PartyBuildingActivities",
          },
        ],
      },
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.new-page{
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    .route-contain{
        width: 1000px;
    }
}
</style>