import { render, staticRenderFns } from "./GIA_home.vue?vue&type=template&id=3f810a96&scoped=true"
import script from "./GIA_home.vue?vue&type=script&lang=js"
export * from "./GIA_home.vue?vue&type=script&lang=js"
import style0 from "./GIA_home.vue?vue&type=style&index=0&id=3f810a96&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f810a96",
  null
  
)

export default component.exports