<template>
  <div class="edit-content">
    <div class="edit-head">
      <i @click="$router.back(1)" class="back el-icon-arrow-left"></i>
      <input
        class="title-input"
        placeholder="请输入文章标题..."
        maxlength="40"
        v-model="title"
      />
      <el-select
        class="select-type"
        v-model="typeIds"
        collapse-tags
        multiple
        placeholder="请选择文章类型"
      >
        <el-option
          v-for="item in typeLists"
          :key="item.id"
          :label="item.type"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button
        class="publish-news"
        size="small"
        type="primary"
        @click="publishNews()"
        >发布</el-button
      >
      <el-popover placement="right" width="400" trigger="click">
        <div>
          <p style="font-size: 18px; font-weight: 600; padding: 0 5px">
            添加背景图片
          </p>
          <div class="photo-select">
            <el-input
              v-model="webPhoto"
              placeholder="输入背景图片地址"
            ></el-input>
            <el-button
              @click="
                if (webPhoto) {
                  headPhoto = webPhoto;
                }
              "
              class="sure-photo"
              type="primary"
              size="mini"
              >确定</el-button
            >
          </div>
          <el-upload
            :action="'/api/file/upload/image'"
            class="upload-demo"
            drag
            :on-success="addPhoto"
            :limit="1"
            :on-exceed="exceedFn"
            :file-list="fileList"
            list-type="picture"
            :on-remove="removePhoto"
          >
            <i class="el-icon-upload"></i>
            <p>或</p>
            <div class="el-upload__text">
              将报告拖到此处,或<em>点击上传</em>
            </div>
          </el-upload>
        </div>
        <el-button
          slot="reference"
          style="font-size: 20px"
          circle
          size="small"
          icon="el-icon-picture-outline"
          type="primary"
        ></el-button>
      </el-popover>
    </div>
    <div class="edit-box">
      <div
        class="el-notification"
        v-show="showTip"
        style="
          width: 250px;
          position: absolute;
          top: 40px;
          right: -15px;
          z-index: 2000;
        "
      >
        <div class="el-notification__group">
          <h2 class="el-notification__title">全屏👆</h2>
          <div class="el-notification__content">
            <p>可点击全屏编辑方便操作!</p>
          </div>
          <div
            @click="showTip = false"
            class="el-notification__closeBtn el-icon-close"
          ></div>
        </div>
      </div>
      <Editor
        id="tinymce"
        placeholder="11"
        v-model="context"
        :init="init"
        :loading="loading"
      ></Editor>
    </div>
  </div>
</template>
<script>
//#region
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver";
//--------------------插入引入--------------------
import "tinymce/plugins/image"; // 插入上传图片插件
import "tinymce/plugins/media"; // 插入视频插件
import "tinymce/plugins/table"; // 插入表格插件
import "tinymce/plugins/link"; //超链接插件
import "tinymce/plugins/code"; //代码块插件
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/contextmenu"; //右键菜单插件
import "tinymce/plugins/wordcount"; // 字数统计插件
import "tinymce/plugins/colorpicker"; //选择颜色插件
import "tinymce/plugins/textcolor"; //文本颜色插件
import "tinymce/plugins/fullscreen"; //全屏
import "tinymce/plugins/help";
import "tinymce/plugins/charmap";
import "tinymce/plugins/paste";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/hr";
import "tinymce/plugins/anchor";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/autosave";
import "tinymce/plugins/fullpage";
import "tinymce/plugins/toc";
import "tinymce/icons/default";
//#endregion
import Vue from "vue";

export default {
  data() {
    return {
      webPhoto: "",
      showTip: true,
      loading: false,
      init: {
        selector: "Editor", //选择HTML元素
        language_url: "/Tinymce/langs/zh_CN.js", //导入语言文件
        language: "zh_CN", //语言设置
        skin_url: "/Tinymce/skins/ui/oxide", //主题样式
        height: 600, //高度
        menubar: false, // 隐藏最上方menu菜单
        browser_spellcheck: true, // 拼写检查
        branding: false, // 去水印
        statusbar: false, // 隐藏编辑器底部的状态栏
        elementpath: false, //禁用下角的当前标签路径
        paste_data_images: true, // 允许粘贴图像
        plugins: [
          "lists image media table wordcount code fullscreen help  toc fullpage autosave nonbreaking insertdatetime visualchars visualblocks searchreplace spellchecker pagebreak link charmap paste print preview hr anchor contextmenu",
        ],
        toolbar: [
          "undo redo | formatselect| bold italic underline strikethrough removeformat |codeformat blockformats| superscript subscript  | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | hr bullist numlist |  lists image media table link |  help fullscreen preview",
        ],
        images_upload_handler: function (blobInfo, success, failure) {
          // 这个函数主要处理word中的图片，并自动完成上传；
          // ajaxUpload是自己定义的一个函数；在回调中，记得调用success函数，传入上传好的图片地址；
          // blobInfo.blob() 得到图片的file对象；
          ajaxUpload(blobInfo.blob())
            .then((data) => {
              // 上传成功后，调用success函数传入图片地址
              success(data.uploadedImageUrl);
            })
            .catch((err) => {
              failure(err);
            });
        },
      },
      context: "",
      title: "",
      headPhoto: "",
      typeIds: [],
      typeLists: [],
      content: {
        context1: "",
      },
    };
  },
  components: {
    Editor,
  },
  async mounted() {
    setTimeout(() => {
      this.$data.showTip = false;
    }, 6000);
    tinymce.init({
      contextmenu: [
        "paste | link image inserttable | cell row column deletetable",
      ],
    });
    const data = await this.$request.get({
      url: "/article/all/type",
    });
    //获取新闻信息
    this.$data.typeLists = data;
    const { newId } = this.$route.query;
    console.log(newId);
    if (newId !=undefined) {
      
    const result = await this.$request.get({
      url: "/article/query/" + newId,
    });
    this.$data.content = result;
    this.context=this.$data.content.context;
    this.title=this.$data.content.title;
    this.typeIds=this.$data.content.typeIds;
    this.headPhoto=this.$data.content.headPhoto;
    }
    
  },
  methods: {
    async publishNews() {
      const {
        $data,
        $data: { context, title, headPhoto, typeIds },
      } = this;
      const rulesObj = {
        context: "正文",
        title: "标题",
        headPhoto: "背景图片",
        typeIds: "文章类型",
      };
      for (const key in rulesObj) {
        if (!$data[key] || !$data[key].length) {
          this.$message({
            showClose: true,
            message: rulesObj[key] + "不能为空",
            duration: 2000,
          });
          return;
        }
      }
      const firstSentence =
        context.match(/<p.*?>([^<]+)</)[1] ||
        context.match(/<h[1-6].*?>([^<]+)</)[1];
      const loading = this.$loading({
        lock: true,
        text: "Loading",
      });
      try {
        const result = await this.$request.post({
          url: "/article/publish",
          data: {
            context,
            title,
            headPhoto,
            typeIds,
            firstSentence: firstSentence.substring(0, 30),
          },
        });
        loading.close();
        this.$router.push({
          path: "/GIA_associator/media_essay",
          query: { newId: result },
        });
      } catch (error) {
        loading.close();
        this.$message({
          message: "请求错误!",
          showClose: true,
          type: "error",
          duration: 2000,
        });
      }
    },
    removePhoto() {
      this.$data.headPhoto = "";
    },
    addPhoto(response) {
      const { message, data } = response;
      this.$data.headPhoto = data;
      this.$data.webPhoto = data;
      this.$message({
        showClose: true,
        message: message,
        duration: 2000,
      });
    },
    exceedFn() {
      this.$message({
        showClose: true,
        message: "请先将已上传的图片删除",
        duration: 2000,
      });
    },
  },
  computed: {
    fileList() {
      const { headPhoto, title } = this.$data;
      if (!headPhoto) {
        return [];
      }
      return [
        {
          url: headPhoto,
          title: title.length > 5 ? title.substring(0, 5) + "..." : title,
        },
      ];
    },
  },
};
async function ajaxUpload(data) {
  return new Promise((resolve) => {
    // Vue.prototype.$request.
    const loading = Vue.prototype.$loading({
      lock: true,
      text: "Loading",
    });
    try {
      Vue.prototype.$request
        .postForm({
          url: "/file/upload/image",
          data: {
            file: data,
          },
        })
        .then((res) => {
          loading.close();
          resolve({
            uploadedImageUrl: res,
          });
        });
    } catch (error) {
      loading.close();
      Vue.prototype.$message({
        message: "图片加载失败!",
        type: "error",
        duration: 2000,
        showClose: true,
      });
    }
  });
}
</script>
<style scoped lang="less">
.edit-content {
  height: 100vh;
  min-height: 730px;
  background-color: rgba(253, 253, 253);
  .edit-head {
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 1200px;
    box-sizing: border-box;
    padding: 20px 50px;
    box-shadow: 0 2px 5px 1px rgba(204, 204, 204, 0.336);
    margin-bottom: 20px;
    position: relative;
    .back {
      left: 10px;
      position: absolute;
      font-size: 30px;
      font-weight: 600;
      color: #757575;
      cursor: pointer;
    }
    .title-input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 25px;
      font-weight: 600;
    }
    .select-type {
      margin: 0 5px;
    }
    .publish-news {
      margin: 0 15px;
    }
  }
  .edit-box {
    position: relative;
    max-width: 1300px !important;
    margin: 0 auto;
  }
}
.photo-select {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 5px;
}
</style>