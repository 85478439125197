<template>
  <div v-loading="passage_loading" class="audit-box">
    <div class="search-nav">
      <!-- <div class="search-input">
        <slot name="search-input"
          ><el-input
            size="mini"
            v-model="searchText"
            type="text"
            placeholder="请输入查找内容"
            clearable
          ></el-input
        ></slot>
      </div> -->
      <div class="search-select">
        <slot name="search-select">
          <el-select
            size="mini"
            class="select-type"
            v-model="typeId"
            placeholder="请选择文章类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.type"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </slot>
      </div>
    </div>
    <el-table
      v-loading="loading"
      class="showTable"
      :data="showProduceList"
      style="width: 100%"
    >
      <el-table-column
        v-for="item in tableList"
        :prop="item.key"
        align="center"
        :key="item.key"
        :label="item.name"
      >
      </el-table-column>
      <el-table-column ref="edit" width="250" label="检阅🔍" align="center">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: '/manage/audit_essay',
              query: { newId: scope.row.id },
            }"
            class="check-link"
            >查看</router-link
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="totalSize"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showProduceList: [],
      passage_loading: false,
      totalSize: 0,
      size: 9,
      page: 1,
      tableList: [
        { name: "作者", key: "authorName" },
        { name: "文章标题", key: "title" },
        { name: "发布日期", key: "releaseDate" },
        { name: "文章状态的相关备注", key: "node" },
      ],
      // 搜索
      typeList: [],
      typeId: "",
      loading: false,
    };
  },
  watch: {
    typeId() {
      this.getEssay(1);
    },
  },
  methods: {
    async getEssay(page) {
      const { size, typeId } = this.$data;
      this.$data.passage_loading = true;
      try {
        const resultList = await this.$request.postForm({
          url: `/article/list/0`,
          data: {
            type: typeId,
            page: page,
            size: size,
          },
        });
        this.$data.showProduceList = resultList.list;
        this.$data.totalSize = resultList.totalCount;
        this.$data.passage_loading = false;
      } catch (error) {
        this.$data.passage_loading = false;
      }
    },
    async getListType() {
      const resultList = await this.$request.get({
        url: "/article/all/type",
      });
      this.$data.typeList = resultList;
      this.$data.typeId = resultList[0].id;
    },
    handleCurrentChange(page) {
      this.$data.page = page;
      this.getEssay(page);
    },
  },
  async mounted() {
    await this.getListType();
  },
};
</script>
<style lang="less" scoped>
.audit-box {
  height: 100%;
  min-width: 1000px;
  overflow: scroll;
}
.title {
  margin-top: 30px;
  width: 300px;
  height: 70px;
  background-color: #4facfe;
  border-radius: 10px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  line-height: 70px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-shadow: -1px -2px 1px rgb(0, 0, 0);
}
.search-nav {
  padding: 15px 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(165, 165, 165, 0.205);
  .search-input {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    width: 200px;
    margin: 0 2px;
  }
  .search-select {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    width: 150px;
    margin: 0 2px;
  }
  .search-add {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    margin: 0 2px;
  }
}
.showTable {
  height: calc(100% - 195px);
  overflow: auto;
  .check-link {
    &:hover {
      color: #1d90fb;
      text-decoration: underline;
    }
  }
  .edit-tools {
    display: flex;
    justify-content: center;
  }
}
.pagination {
  .el-pagination {
    background-color: #fff !important;
    padding: 6px 30px;
    transform: translate(30%);
  }
}
.tool-box {
  display: flex;
  justify-content: center;
}
</style>