<template>
  <div>
    <div class="memberActiveAndStoryBox">
      <div class="mASB_left">
        <div class="mASB_leftNews">
          <div class="mASB_lN_Top">
            <div class="title">
              <p>会员风采</p>
              <div class="bottomLine"></div>
            </div>
            <router-link to="/news/Media_list"
                         class="moreNews">More</router-link>
          </div>
          <ul class="mASB_lN_T_newsList">
            <li class=""
                v-for="oneStory in showStoryList"
                :key="oneStory.newsId">
              <router-link :to="{
                  path: '/news/media_essay',
                  query: { newId: oneStory.id },
                }"
                           class="mASB_lN_T_nL_aNew">
                <i class="el-icon-setting"></i>
                <p class="titleSpan">{{ oneStory.title }}</p>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="mASB_right">
        <div class="mASB_leftNews">
          <div class="mASB_lN_Top">
            <div class="title">
              <p>行业动态</p>
              <div class="bottomLine"></div>
            </div>
            <router-link to="/news/Media_list"
                         class="moreNews">More</router-link>
          </div>
          <ul class="mASB_lN_T_newsList">
            <li class=""
                v-for="(oneActive, index) in showActiveList"
                :key="index">
              <router-link :to="{
                  path: '/news/media_essay',
                  query: { newId: oneActive.id },
                }"
                           class="mASB_lN_T_nL_aNew">
                <i class="el-icon-setting"></i>
                <p class="titleSpan">{{ oneActive.title }}</p>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showStoryList: Array,
    showActiveList: Array
  },
  data () {
    return {
      // showStoryList: [
      //   {
      //     title: "【GIA会员故事-87】因为遇见，从此值得",
      //     to: "",
      //   },
      // ],
      // showActiveList: [
      //   {
      //     title: "GIA@U第825场：周珏嘉、林峰、许辰人走进河北工程技术学院",
      //     to: "",
      //   },
      // ],
    };
  },
  methods: {},
};
</script>
<style scoped lang='less'>
.memberActiveAndStoryBox {
  display: flex;
  justify-content: space-between;
  .mASB_leftNews {
    width: 550px;
    .mASB_lN_Top {
      display: flex;
      justify-content: space-between;
      .title {
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }
        .bottomLine {
          width: 80px;
          height: 4px;
          background-color: #123;
        }
      }
      .moreNews {
        margin-top: 15px;
      }
    }
    .mASB_lN_T_newsList {
      background-color: #fff;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 17px;
      border: 2px solid rgba(118, 118, 118, 0.2);
      li {
        .mASB_lN_T_nL_aNew {
          display: flex;
          color: rgb(33, 34, 35);
          line-height: 40px;
          height: 40px;
          i {
            color: rgba(0, 255, 255, 0.463);
            font-size: 24px;
            display: block;
            margin-right: 10px;
            margin-top: 7px;
          }
        }
        .mASB_lN_T_nL_aNew:hover {
          color: rgb(0, 85, 233);
          i {
            color: rgb(0, 85, 233);
          }
        }
      }
    }
  }
}
.titleSpan {
  display: block;
  width: 490px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>