<template>
  <div>
    <article-box :content="{ title: '个人会员' }">
      <h2>一、入会权益</h2>
      <div class="txt">
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 宋体, SimSun;
            font-size: 16px;
          "
        >
          <span style="font-family: 'times new roman'"
            >GISF（中国计算机学会）是致力于为计算机领域专业人士提供服务的专业组织，也是计算机领域追求专业发展人士的首选平台。GISF治会理念是：由会员构成、由会员治理、为会员服务，在GISF，会员是第一位的。GISF目前拥有近80000名付费个人会员，遍布全国千余所高校、研究所、企业等。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >成为GISF会员，你将获得<span style="font-size: 24px">8</span
            >大专属权益：</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun; font-size: 20px"
              >01触手可及</span
            ></strong
          >
        </p>
        <p
          style="
            white-space: normal;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">&nbsp;</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF会员可免费享受遍布全国的服务网络：</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >分布在35个城市的GISF会员活动中心，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >分布在28个城市的青年科技论坛分论坛(GISF YOCSEF)，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >分布在63所高校的GISF学生分会，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun"
              >新冠疫情期间，分部、分会组织大量线上活动，</span
            ></strong
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun"
              >GISF会员均可免费参加。</span
            ></strong
          >
        </p>
        <p
          style="
            white-space: normal;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">&nbsp;&nbsp;&nbsp;</span>
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p style="white-space: normal; text-align: center">
          <span style="font-family: 宋体, SimSun; font-size: 16px"
            >点击图片查看GISF会员活动中心动态</span
          >
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p style="white-space: normal; text-align: center">
          <span style="font-family: 宋体, SimSun; font-size: 16px"
            >点击图片查看GISF学生分会动态</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun"
                >02前沿赠刊&nbsp;</span
              ></strong
            ></span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun"></span></strong></span
          >&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF会员免费获取前沿技术月刊。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >CGISF（中国计算机学会通讯)是GISF每月发行的旗舰刊物，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >助力GISF会员及时把握计算技术前沿资讯。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          &nbsp;&nbsp;
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">点击图片查看CGISF电子刊</span
          >&nbsp;
        </p>
        <p style="white-space: normal">&nbsp;</p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p style="white-space: normal; text-align: center">
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun">03优免参会</span></strong
            ></span
          >
        </p>
        <p
          style="
            white-space: normal;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">&nbsp;</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF会员优惠或免费参加全年数百场活动。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >CNCC：中国计算机大会，中国计算技术领域年度盛会，2019年参会人数已超过8000人。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >FCES：未来计算机教育峰会，计算机教育领域内的年度高端盛会。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >TF：GISF技术前线，由企业界具有实战经验的顶级专家，面向企业第一线从事技术工作的工程师为期一天的技术讲座。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >YEF：青年精英大会，青年精英展示的舞台，一年一次。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >ADL：学科前沿讲习班，国内外一流专家授课，让青年学者短期内深刻了解一个领域发展动态。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >CCD：计算机课程改革导教班，致力于中国计算机高等教育质量的提高。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">……</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          &nbsp;&nbsp;
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p style="white-space: normal; text-align: center; font-size: 16px">
          <span style="font-family: 宋体, SimSun"
            >点击图片了解CNCC2020相关资讯</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun; font-size: 20px"
              >04&nbsp;专业助力</span
            ></strong
          >
        </p>
        <p
          style="
            white-space: normal;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          &nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF助力会员精准匹配和发展专业。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >基于计算技术领域专业方向设立的38个专业委员会，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >涵盖人工智能、大数据、区块链、高性能等38个专业方向，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >39个专委会每年组织数百场线下活动，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >助力会员精准匹配和发展专业。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p style="white-space: normal; text-align: center">
          <a
            href="/upload/resources/image/2020/05/15/120594.jpg"
            target="_blank"
          ></a
          >&nbsp;
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p style="white-space: normal; text-align: center">
          <span style="font-size: 16px; font-family: 宋体, SimSun"
            >点击图片查看专业委员会动态</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun; font-size: 20px"
              >05&nbsp;数图免费</span
            ></strong
          >
        </p>
        <p
          style="
            white-space: normal;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">&nbsp;</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF会员足不出户、踏遍专业路。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF数字图书馆，汇集GISF硬核数字资源，收录近千名国内外知名专家的报告、近两万篇会议文集，目前对会员全部免费开放。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >点击图片进入GISF 数字图书馆</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun"
                >06&nbsp;晋级机会</span
              ></strong
            ></span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          &nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">GISF会员晋级体系：</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF为会员构建完整的内部晋级体系，个人会员级别包括：学生会员、专业会员、高级会员、杰出会员和会士。单位会员级别包括：金质会员、银质会员、铜质会员。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">GISF专业认证/竞赛：</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >CSP：考研和就业的重要认证参考，一年三次，2019年超过2.5万人参加。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >CCSP：全国最高水平的大学生计算机竞赛，一年举办一次分赛一次总决赛。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >NOI：面向学有余力优秀中学生的智力竞赛。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">GISF权威奖项：</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF设有王选奖等13个受业界广泛认可的权威奖项，所有奖项实行推荐制，坚持客观、公正。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p style="white-space: normal; text-align: center">
          <a
            href="/upload/resources/image/2020/05/15/120596.jpg"
            target="_blank"
          ></a
          >&nbsp;
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p style="white-space: normal; text-align: center">
          <span style="font-family: 宋体, SimSun; font-size: 16px"
            >点击图片查看GISF杰出会员名单</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun"
                >07&nbsp;共治共事</span
              ></strong
            ></span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun"></span></strong></span
          >&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF会员全年专享8000+志愿者工作机会，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >通过担任志愿者可获取和业内优秀同行共事机会。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          &nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun; font-size: 20px"
              >08价值实现</span
            ></strong
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span
              style="font-family: 宋体, SimSun; font-size: 20px"
            ></span></strong
          >&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF会员之间非常讲究平等、互助，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF日常运行和管理涉及的志愿者职务大多通过民主差额选举产生，</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF时刻关注社会问题并敢于承担社会责任。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p style="white-space: normal; text-align: center"></p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">GISF第十二次会员代表大会</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun">入会资格</span></strong
            ></span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF专业会员：计算机及其相关技术领域从业人员或爱好者。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF学生会员：注册在读的计算机及相关专业大学生或研究生。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >GISF公司会员：具有一定数量计算机领域从业人员的企业。</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-size: 20px"
            ><strong
              ><span style="font-family: 宋体, SimSun">会费标准</span></strong
            ></span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">个人会员：</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >专业会员/高级会员/杰出会员/会士：200元/年</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">学生会员：50元/年</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">公司会员：</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >G级（金质）会员：10万元/年</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >S级（银质）会员：5万元/年</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >B级（铜质）会员：1万元/年</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >会员资格以自然年度计算，即从每年1月1日至12月31日</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"></span>&nbsp;
        </p>
        <p
          style="
            white-space: normal;
            text-align: center;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <strong
            ><span style="font-family: 宋体, SimSun; font-size: 20px"
              >入会优惠</span
            ></strong
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">3-8月入会享超值优惠：</span>
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun"
            >1.5年会费=有效期至明年年底&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
          >
        </p>
        <p
          style="
            white-space: normal;
            text-indent: 2em;
            font-family: 'times new roman';
            font-size: 16px;
          "
        >
          <span style="font-family: 宋体, SimSun">&nbsp;&nbsp;&nbsp;</span>
        </p>
        <p
          style="
            text-indent: 0em;
            white-space: normal;
            text-align: center;
            font-family: 'times new roman';
            font-size: 16px;
          "
        ></p>
        <p
          style="white-space: normal; text-align: center; text-indent: 2em"
        ></p>
        <p style="white-space: normal; text-indent: 2em"><br /></p>
        <p style="text-indent: 2em; font-size: 16px">
          <span style="font-family: 宋体, SimSun; text-indent: 2em"
            >GIA会员部</span
          >
        </p>
        <p style="text-indent: 2em; font-size: 16px">
          <span style="font-family: 宋体, SimSun; text-indent: 2em"
            >联系热线：020-84887464</span
          >
        </p>
        <p style="text-indent: 2em; font-size: 16px">
          <span style="font-family: 宋体, SimSun"
            >Email：GISF_GZ@163.com</span
          >
        </p>
        <p style="white-space: normal">
          <span style="font-size: 16px; font-family: 宋体, SimSun">&nbsp;</span>
        </p>
        <p style="white-space: normal">&nbsp;</p>
        <p style="white-space: normal">&nbsp;</p>
        <p><br /></p>
      </div>
      <h2>二、会费标准</h2>
      <div class="txt">
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            ><strong>一、 会费标准：</strong
            ><span style="font-family: 宋体, SimSun; font-size: 16px"
              ><br /></span
          ></span>
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            >专业会员/高级会员/杰出会员/会士：200元/年</span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)">学生会员：50元/年</span>
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 16px"
        >
          <span
            style="
              color: rgb(0, 0, 0);
              font-family: 宋体, SimSun;
              font-size: 18px;
            "
            >会员资格以自然年度计算，即从每年1月1日至12月31日。</span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 16px"
        >
          <span style="color: rgb(0, 0, 0)"
            ><strong
              ><span
                style="
                  color: rgb(0, 0, 0);
                  font-family: 宋体, SimSun;
                  font-size: 18px;
                "
                >二、 交费时间与交费金额</span
              ></strong
            ></span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 16px"
        >
          <span style="color: rgb(0, 0, 0); font-size: 18px"
            >对新加入GISF的会员：</span
          >
        </p>
        <table
          width="558"
          class="borderVisible"
          cellspacing="0"
          cellpadding="0"
        >
          <tbody>
            <tr class="firstRow">
              <td
                align="center"
                valign="middle"
                style="padding: 0px; border: rgb(0, 0, 0)"
              >
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >交费时间</span
                  >
                </p>
              </td>
              <td
                align="center"
                valign="middle"
                style="padding: 0px; border: rgb(0, 0, 0)"
              >
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >交费金额</span
                  >
                </p>
              </td>
              <td
                align="center"
                valign="middle"
                style="padding: 0px; border: rgb(0, 0, 0)"
              >
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >有效期截止日</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >1～2月</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >200元</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >当年12月31日（从交费当月起赠送纸质CGISF）</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)" rowspan="2">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >3～8月</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >200元</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >当年12月31日（从交费当月起赠送纸质CGISF）</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >300元（推荐）</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >次年12月31日（从当年7月起赠送纸质CGISF）</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >9～12月</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >200元</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >次年12月31日（从次年1月起赠送纸质CGISF）</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style="font-family: 宋体, SimSun; font-size: 16px">
          <span style="color: rgb(0, 0, 0); font-size: 18px"
            >对新加入GISF的学生会员：</span
          >
        </p>
        <table
          width="558"
          class="borderVisible"
          cellspacing="0"
          cellpadding="0"
        >
          <tbody>
            <tr class="firstRow">
              <td
                align="left"
                valign="top"
                style="padding: 0px; border: rgb(0, 0, 0)"
              >
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >交费时间</span
                  >
                </p>
              </td>
              <td
                align="left"
                valign="top"
                style="padding: 0px; border: rgb(0, 0, 0)"
              >
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >交费金额</span
                  >
                </p>
              </td>
              <td
                align="left"
                valign="top"
                style="padding: 0px; border: rgb(0, 0, 0)"
              >
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >有效期截止日</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >1～2月</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px">50元</span>
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >当年12月31日</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)" rowspan="2">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >3～8月</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px">50元</span>
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >当年12月31日</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >75元（推荐）</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >次年12月31日</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >9～12月</span
                  >
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px">50元</span>
                </p>
              </td>
              <td style="padding: 0px; border: rgb(0, 0, 0)">
                <p style="font-family: 宋体, SimSun; font-size: 16px">
                  <span style="color: rgb(0, 0, 0); font-size: 18px"
                    >次年12月31日</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            ><strong
              ><span style="color: rgb(0, 0, 0); font-size: 18px"
                >三、 交费方式</span
              ></strong
            ></span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)">可采用以下方式之一交费：</span>
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 16px"
        >
          <span
            style="
              color: rgb(0, 0, 0);
              font-family: 宋体, SimSun;
              font-size: 18px;
            "
            >1、 微信支付：关注GISF公众号，登录/注册后支付</span
          >
        </p>
        <div align="center" style="position: relative">
          <p>
            <a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><img
                width="217"
                height="216"
                title="110110_500x500"
                style="
                  margin: 0px 0px 6px;
                  width: 217px;
                  height: 216px;
                  float: left;
                  display: block;
                  max-width: 100%;
                "
                alt="110110_500x500"
                src="/upload/resources/image/2019/12/20/110379_500x500.jpg"
                suffix="jpg" /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br /></a
            ><a
              style="display: block"
              href="/upload/resources/image/2019/12/20/110379.jpg"
              target="_blank"
              ><br
            /></a>
          </p>
          <p
            style="
              margin: 0px;
              width: 100%;
              text-align: center;
              bottom: 0px;
              color: rgb(255, 255, 255);
              overflow: hidden;
              white-space: nowrap;
              position: absolute;
              -ms-text-overflow: ellipsis;
              text-shadow: 2px 2px 10px #333, 2px -2px 10px #333,
                -2px 2px 10px #333, -2px -2px 10px #333;
            "
          >
            <br />
          </p>
        </div>
        <p style="font-family: 宋体, SimSun; font-size: 16px">
          <span style="color: rgb(0, 0, 0)">&nbsp;</span>
        </p>
        <p style="font-family: 宋体, SimSun; font-size: 16px"><br /></p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0); font-size: 18px">2、 在线支付</span>
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            >登录GISF会员系统选择支付宝或易支付进行交费。</span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            >若使用公务卡，请选择易支付中银联支付</span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            >3、 银行转账（不支持支付宝对公转账）：</span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)">开户行：北京银行北京大学支行</span>
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)">户名：中国计算机学会</span>
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            >账号：01090519 5001 201 097 020 28</span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)">行号：313100000431</span>
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 18px"
        >
          <span style="color: rgb(0, 0, 0)"
            >个人转账请留言：姓名+单位/卡号</span
          >
        </p>
        <p style="line-height: 1.5em">
          <span
            style="
              color: rgb(0, 0, 0);
              font-family: 宋体, SimSun;
              font-size: 18px;
            "
            >单位集体转账请留言：**单位**人会费，并将对应名单及票据收件人信息发至</span
          ><a
            style="
              color: rgb(0, 0, 0);
              font-family: 宋体, SimSun;
              font-size: 18px;
              text-decoration: underline;
            "
            ><span style="color: rgb(0, 0, 0)">membership@GISF.org.cn</span></a
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 16px"
        >
          <span
            style="
              color: rgb(0, 0, 0);
              font-family: 宋体, SimSun;
              font-size: 18px;
            "
            >由于支付宝对公转账不显示汇款人及附言等信息，请勿用支付宝对公转账，建议使用方式2的在线支付宝。</span
          >
        </p>
        <p
          style="line-height: 1.5em; font-family: 宋体, SimSun; font-size: 16px"
        >
          <span style="color: rgb(0, 0, 0); font-family: 宋体, SimSun"
            >&nbsp;</span
          >
        </p>
        <p>
          <span style="font-family: 宋体, SimSun; font-size: 16px"></span>&nbsp;
        </p>
        <p><br /></p>
        <p><span style="font-family: 宋体, SimSun">&nbsp;</span></p>
        <p><br /></p>
        <p><span style="font-family: 宋体, SimSun">&nbsp;</span></p>
        <p><br /></p>
        <p><span style="font-family: 宋体, SimSun">&nbsp;</span></p>
      </div>
    </article-box>
  </div>
</template>
<script>
import articleBox from "../../../components/home/articleBox.vue";
export default {
  components: { articleBox },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>