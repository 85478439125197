var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"memberActiveAndStoryBox"},[_c('div',{staticClass:"mASB_left"},[_c('div',{staticClass:"mASB_leftNews"},[_c('div',{staticClass:"mASB_lN_Top"},[_vm._m(0),_c('router-link',{staticClass:"moreNews",attrs:{"to":"/news/Media_list"}},[_vm._v("More")])],1),_c('ul',{staticClass:"mASB_lN_T_newsList"},_vm._l((_vm.showStoryList),function(oneStory){return _c('li',{key:oneStory.newsId},[_c('router-link',{staticClass:"mASB_lN_T_nL_aNew",attrs:{"to":{
                path: '/news/media_essay',
                query: { newId: oneStory.id },
              }}},[_c('i',{staticClass:"el-icon-setting"}),_c('p',{staticClass:"titleSpan"},[_vm._v(_vm._s(oneStory.title))])])],1)}),0)])]),_c('div',{staticClass:"mASB_right"},[_c('div',{staticClass:"mASB_leftNews"},[_c('div',{staticClass:"mASB_lN_Top"},[_vm._m(1),_c('router-link',{staticClass:"moreNews",attrs:{"to":"/news/Media_list"}},[_vm._v("More")])],1),_c('ul',{staticClass:"mASB_lN_T_newsList"},_vm._l((_vm.showActiveList),function(oneActive,index){return _c('li',{key:index},[_c('router-link',{staticClass:"mASB_lN_T_nL_aNew",attrs:{"to":{
                path: '/news/media_essay',
                query: { newId: oneActive.id },
              }}},[_c('i',{staticClass:"el-icon-setting"}),_c('p',{staticClass:"titleSpan"},[_vm._v(_vm._s(oneActive.title))])])],1)}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_vm._v("会员风采")]),_c('div',{staticClass:"bottomLine"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_vm._v("行业动态")]),_c('div',{staticClass:"bottomLine"})])
}]

export { render, staticRenderFns }