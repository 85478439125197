<template>
  <div>
    <el-table :data="tableData" border  class="customer-table" >
      
      <el-table-column
        prop="title"
        label="文章标题"
        width="800"
        align="center"
      ></el-table-column>
       <el-table-column ref="edit"
                       width="200"
                       label="操作"
                       align="center">
        <template slot-scope="scope" >
          <router-link :to="{
              path: '/news/media_essay',
              query: { newId: scope.row.id },
            }"
                       class="check-link"><p class="viewPassage">查看</p></router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[1, 4, 8]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      resData: [],
      searchValue: "",
      tableData: [],
      currentPage: 1,
      pageSize: 8,
      totalItems: 0,
      filterTableData: [],
      flag: false,
    };
  },
  methods: {
    async getexpertlist() {
      const result = await this.$request.get({
         url: `/articleManagement/query?typeId=${1}`,
        headers: {
          Authorization: "Bearer " + localStorage.GIA_token,
        },
      });
      this.resData=result;
      console.log(this.resData, "zzz");
       this.totalItems = this.resData.length; // 注意： 这里mock数据是写在data里的，请求需考虑异步的情况
      console.log(this.resData.length, "zzz");
      if (this.totalItems > this.pageSize) {
        for (let index = 0; index < this.pageSize; index++) {
          this.tableData.push(this.resData[index]);
        }
      } else {
        this.tableData = this.resData;
      }
    },
    // 前端搜索功能需要区分是否检索,因为对应的字段的索引不同
    doFilter() {
      this.tableData = [];
      this.filterTableData = [];
      this.resData.filter((item) => {
        if ("expertNumber" in item || "name" in item) {
          // 按编号或地区查询 注意：根据实际数据 灵活调整字母大小写
          if (
            item.expertNumber
              .toUpperCase()
              .indexOf(this.searchValue.toUpperCase()) > -1 ||
            item.name.indexOf(this.searchValue) > -1
          ) {
            this.filterTableData.push(item);
          }
        }
      });
      // 页面数据改变重新统计数据数量和当前页
      this.currentPage = 1;
      this.totalItems = this.filterTableData.length;
      // 渲染表格,根据值
      this.currentChangePage(this.filterTableData);
      // 页面初始化数据需要判断是否检索过
      this.flag = true;
    },
    // 每页显示条数改变触发
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.handleCurrentChange(1);
    },
    // 当前页改变触发
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      // 判断是否为搜索的数据,传入对应值
      if (!this.flag) {
        this.currentChangePage(this.resData);
      } else {
        this.currentChangePage(this.filterTableData);
      }
    },
    // 根据分页，确定当前显示的数据
    currentChangePage(list) {
      let fromNum = (this.currentPage - 1) * this.pageSize;
      let toNum = this.currentPage * this.pageSize;
      this.tableData = [];
      for (; fromNum < toNum; fromNum++) {
        if (list[fromNum]) {
          this.tableData.push(list[fromNum]);
        }
      }
    },
  },
  beforeMount() {
    this.getexpertlist();
  },

};
</script>
<style lang="less" scoped>
.title {
  margin: 10px 0;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.search{
  margin: 20px 10px 10px 10px;
  .search_input{
    margin-right: 10px;
  }
}
/// 去掉表格单元格边框
.customer-table th{
    border:none;
  }
.customer-table td,.customer-table th.is-leaf {
  border:none;
}
// 表格最外边框
.el-table--border, .el-table--group{
  border: none;
}
// 头部边框
.customer-table thead tr th.is-leaf{
  // border: 1px solid #EBEEF5;
  border-right: none;
}
.customer-table thead tr th:nth-last-of-type(2){
  // border-right: 1px solid #EBEEF5;
}
// 表格最外层边框-底部边框
.el-table--border::after, .el-table--group::after{
  width: 0;
}
.customer-table::before{
  width: 0;
}
.customer-table .el-table__fixed-right::before,.el-table__fixed::before{
  width: 0;
}
// 表格有滚动时表格头边框
.el-table--border th.gutter:last-of-type {
    border: 1px solid #EBEEF5;  
    border-left: none;
}
.viewPassage{
  color: #006699;
}
</style>
