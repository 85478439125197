<template>
  <div class="selfFormBox">
    <div class="formItem">
      <div :style="{ minWidth:`${labelWidth}px`,width: `${labelWidth}px` }" class="leftBox">
        <div class="labelText">{{ labelText }}</div>
      </div>
      <div
        :style="{
          padding: `${noPadding ? 'none' : '10px'}`,
          borderBottom: `${noBorder ? 'none' : 'var(--borderStyle)'}`,
          borderRight: `${noBorder ? 'none' : 'var(--borderStyle)'}`,
        }"
        class="rightBox"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    labelText: String,
    labelWidth: {
      default: 200,
      type: Number,
    },
    noBorder: {
      default: false,
      type: Boolean,
    },
    noPadding: {
      default: false,
      type: Boolean,
    },
  },
  methods: {},
};
</script>
<style scoped>
.selfFormBox {
  font-size: 15px;
  --borderStyle: 1px solid rgb(236, 236, 236);
}
.formItem {
  display: flex;
}
.leftBox {
  background-color: rgba(248, 251, 252);
  display: flex;
  color: #818181;
  justify-content: center;
  align-items: center;
  border-right: var(--borderStyle);
  border-bottom: var(--borderStyle);
}
.labelText {
  width: 40%;
  text-align: justify;
  text-justify: distribute-all-lines; /*ie6-8*/
  text-align-last: justify; /* ie9*/
  -moz-text-align-last: justify; /*ff*/
  -webkit-text-align-last: justify; /*chrome 20+*/
}
.labelText::after {
  content: ":";
  position: absolute;
}
.rightBox {
  flex: 1;
  border-right: var(--borderStyle);
  border-bottom: var(--borderStyle);
}
</style>