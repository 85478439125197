<template>
  <div class="img-once">
    <span class="demonstration" v-if="label">{{ label }}</span>
    <el-image class="img" :style="{height:size+'px',width:size+'px',borderRadius:`${circular?'50%':'4px'}`,}" :src="imgSrc">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    imgSrc: String,
    size:{
        type:String,
        default:'150'
    },
    circular:{
        type:Boolean,
        default:false
    }
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.img-once {
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px !important;
    color: #999;
    background-color: rgb(228, 228, 228);
    border-radius: 4px;
  }
}
</style>