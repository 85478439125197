<template>
  <div class="institutionalFramework">
    <!-- <div class="IF_institutionalMemberBox">
      <div
        class="IF_institutionalMember"
        v-for="(memberGroup, index) in institutionalMember"
        :key="index"
        :item="memberGroup"
      >
        <p class="IM_position">{{ memberGroup.rank }}</p>
        <div class="IF_IM_memberGroup">
          <div
            class="MG_aMemberMEssage"
            v-for="(item, index2) in memberGroup.rankUserList"
            :key="index2"
          >
            <img :src="item.photoUrl" class="AM_avatar" />
            <div class="AM_memberMEssage">
              <p class="AM_name">{{ item.name }}</p>
              <div class="AM_background">{{ item.duty }}</div>
              <div class="AM_personAddress">{{ item.personAddress }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="member-container">
    
    <div v-for="(person, index) in institutionalMember[0].members" :key="index" class="member-item">  
      <img v-if="person.avatar" :src="person.avatar" alt="头像" class="avatar"/>
      <h2>{{ person.name }}</h2>  
      <p>{{ person.background }}</p>  
      <p>{{ person.intro }}</p>
      <p><a :href="person.personAddress" target="_blank" title="点击查看介绍信息">详细介绍</a></p>
    
    </div>
    </div>  
  </div>
</template>
<script>
import cll from "../../../image/initiator/cll.jpg";
import za from "../../../image/initiator/za.jpg";
import gy from "../../../image/initiator/gy.jpg";
import lxc from "../../../image/initiator/lxc.jpg";
import cx from "../../../image/initiator/cx.jpg";
export default {
  data() {
    return {
      
      institutionalMember: [
        {
          position: "理事会",
          members: [
            {
              avatar: cll,
              name: "程良伦",
              background: "会长",
              personAddress: "https://gisf.org.cn/news/media_essay?newId=206",
              intro:"任广东工业大学教授（二级），博士研究生导师，国务院政府特殊津贴专家，南粤优秀教师，广东省跨世纪人才“千百十”工程国家级培养对象，智能制造信息物理融合系统集成技术国家地方联合工程研究中心主任，广东省信息物理融合系统重点实验室主任，中国航天系统院钱学森创新委员会副主任。",
              
            },
            {
              avatar: lxc,
              name: "刘星成",
              background: "监事长",
              personAddress: "https://gisf.org.cn/news/media_essay?newId=209",
              intro:"刘星成，博士，教授，博士生导师。先后赴英国Southampton大学和美国Oregon州立大学做博士后和访问学者。主要从事物联网/无线传感器网络、信道编码技术、智能信息处理、无线通信新技术等方面研究。近年来主持完成了多个国家自然科学基金项目和省市级科研项目，主持在研国家级项目2项和省市级项目2项，发表学术论文150多篇，合编中英文专著5本，获国家发明专利30多项，获广东省科技进步奖一等奖。是国家科技奖、国家自然科学基金项目和多个省市科技项目的评审专家。",
            },
            {
              avatar: za,
              name: "曾安",
              background: "秘书长",
              personAddress: "https://gisf.org.cn/news/media_essay?newId=207",
              intro:"曾安，女，博士后、硕士生导师、教授。毕业于华南理工大学获得计算机应用技术专业，工学博士学位，现任广东工业大学计算机学院副院长。曾于2008年9月至2010年8月在加拿大达尔豪斯大学计算机学院和医学院开展博士后研究工作；于2016年12月至2017年12月期间作为第17批博士服务团成员赴贵州财经大学挂职锻炼，挂任校长助理、大数据金融学院副院长；主持国家自然科学基金2项、广东省自然科学基金2项、广东省省级科技计划项目1项，广州市科技计划项目1项等。在《IEEE Intelligent Systems》、《Journals of Gerontology: Medical Sciences》和《电子学报》等刊物上发表论文50余篇。中国生物医学工程学会人工智能分会（委员）、广东省生物医学工程学会理事、CCF协同计算专委会（委员）、CCF人工智能专委会通讯委员、中国人工智能学会生物信息学与人工生命专业委员会（委员）。",
            },
            {
              avatar: gy,
              name: "高英",
              background: "副会长",
              personAddress: "https://gisf.org.cn/news/media_essay?newId=205",
              intro:"高英：教授、博士生导师，副院长。计算机学会会员，IEEE会员。主要的学术研究方向包括：软件开发环境与信息集成、人工智能应用技术、网络空间安全等。曾获广东省科技进步奖一等奖、三等奖，中国爆破协会科技进步奖特等奖、一等奖。主持和参与国家自然科学基金、国家重点研发计划、工业互联网创新发展工程项目、公安部科技计划、公安部重点实验室开放课题、广东省重点研发计划、广东省自然科学基金、广东省重点研发计划、广东省重点实验室、广东省省部产学研、广州市重点研发计划、产业技术重大攻关计划、广州市民生科技攻关计划等 30 多项科研项目，承担 30 多项大型企事业单位委托的大型项目的研究与开发工作。发表SCI或EI收录的学术论文90多篇，申请国家发明专利和软件著作权 70 多项，授权国家发明专利28项。",
            },
            {
              avatar: cx,
              name: "陈旭",
              background: "副会长",
              personAddress: "https://gisf.org.cn/news/media_essay?newId=208",
              intro:"陈旭，中山大学计算机学院教授、先进网络与计算系统研究所所长、国家地方联合工程实验室副主任。迄今在IEEE Journal on Selected Areas in Communications、IEEE/ACM Transactions on Networking、IEEE Transactions on Mobile Computing、 IEEE INFOCOM、IEEE ICDCS、ACM MOBIHOC、 ACM MM、WWW、Ubicom等国际高水平会议与权威期刊发表论文超过180篇, 谷歌学术引用超过13000次，单篇引用最高超过2400次。 获得IEEE杰出讲师、德国洪堡学者科研奖金、香港青年科学家奖、IEEE通信学会亚太区杰出青年学者奖、中国计算机学会分布式计算与系统专委会杰出青年学者、IEEE计算机学会年度最佳论文奖亚军、IEEE通信学会亚太区杰出论文奖、IEEE通信学会Young Professional最佳论文奖, IEEE Internet of Things Journal最佳论文亚军奖、CCF A类国际会议IEEE INFOCOM的最佳论文亚军奖、IEEE ICC最佳论文奖、IEEE ISI的最佳论文荣誉提名奖。担任国家重点研发计划专项会评专家、科技部创新人才推进计划评审专家、 国家自然科学基金评审专家、广东省工业软件学会副会长、中国运筹学会智能工业数据解析与优化分会理事、广东省重点研发计划“芯片、软件与计算”重大专项专家组成员、中国电子科技集团电子科学研究院智库首席专家等兼职，并多次出任包括国际会议ACM MOBIHOC、INFOCOM、以及IEEE旗舰会议ICDCS、GLOBECOM、ICC、WCNC等大会技术程序委员会成员。"
            },
          ],
        },
        {
          position: "理事长",
          members: [
            {
              // avatar: wudi,
              name: "anne",
              background: "广东工业大学",
              personAddress: "anne@gia.edu.cn111111222545112",
            },
          ],
        },
      ],
    };
  },
  methods: {
    async getInstitutionalFramework() {
      const result = await this.$request.post({
        url: "/rank/getUsersByRank",
      });
      this.$data.institutionalMember = result[0].rankListVoList;
    },
  },
  mounted() {
    this.getInstitutionalFramework();
  },
};
</script>
<style lang="less" scoped>
.structure_img {
  height: 80%;
  width: 90%;
  padding: 50px;
}
a {
    color: blue; /* 或具体的颜色值，如：black，继承父元素的颜色 */
    text-decoration: none; /* 移除下划线 */
  }

  /* 当鼠标悬停在超链接上时的样式 */
a:hover {
    color: blue; /* 鼠标悬停时文本颜色变为蓝色 */
    // text-decoration: underline; /* 可选：添加下划线效果 */
  }
.institutionalFramework { 
  margin-left: 0px;
  width: 100%;
  border: 2px solid rgba(17, 34, 51, 0.252);
  margin-bottom: 100px;
  // display: flex;
  // justify-content: space-between;
  // .IF_frameworks {
  //   text-align: left;
  //   width: 120px;
  //   li {
  //     border: 1px solid rgba(128, 127, 127, 0.2);
  //     height: 30px;
  //     line-height: 30px;
  //     margin-bottom: 7px;
  //     padding-left: 5px;
  //   }
  // }
  .member-container {    
  text-align: center;  
  display: flex;  
  flex-wrap: wrap; /* 如果需要换行，可以添加这个属性 */  
  /* 其他样式，如间距、对齐等 */  
  justify-content: center;
   }  
  
  .member-item {  
  /* 根据需要设置宽度、间距等 */
  margin: 100px; /* 简化为单边外边距，以便上下的间距也一致 */
  display: flex; /* 让成员项内部可以使用弹性布局 */
  flex-direction: column; /* 设置为列布局，便于垂直居中内容 */
  align-items: center; /* 垂直居中对齐内部内容 */
  /* 其他样式，如padding、border等 */ 
  }  
  
/* 如果不希望最后一个元素有右侧间距，可以添加一个类并移除间距 */  
  .member-item:last-child {  
  margin-right: 0;  
  }  
  .IF_institutionalMemberBox {
    border: 1px solid rgba(128, 127, 127, 0.2);
    width: 992px;
    margin: 0;
    .IF_institutionalMember {
      margin: 10px 20px;
      .IM_position {
        font-size: 20px;
        font-weight: 600;
        color: rgb(8, 88, 169);
      }
      .IF_IM_memberGroup {
        display: flex;
        flex-wrap: wrap;
        width: 1000px;
        .MG_aMemberMEssage {
          display: flex;
          justify-content: space-between;
          margin: 10px;
          width: 300px;
          height: 180px;
          .AM_avatar {
            width: 140px;
            height: 160px;
          }
          .AM_memberMEssage {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 150px;
            .AM_name {
              width: 150px;
            }
            .AM_background {
              width: 150px;
            }
            .AM_personAddress {
              width: 150px;
              font-size: 15px;
              word-wrap: break-word;
              word-break: normal;
            }
          }
        }
      }
    }
  }
}
</style>