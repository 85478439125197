import axios from "axios";
import router from "../router"


const baseUrl = '/api';
const handle444 = () => {
  localStorage.GIA_token = ''
  router.replace('/login')
}
const request = (vm) => {
  const newAxios = {
    // get请求
    get: (params) => new Promise((resolve, reject) => {
      const { url, data, header } = params;
      let headers = { ...header }
      switch (url) {
        case '/none':
          // case '/article/all/type':

          break;
        default:
          headers.Authorization = localStorage.GIA_token
          break;
      }
      axios.get(
        baseUrl + url,
        {
          params: data,
          headers: headers
        },
      ).then(
        res => {
          switch (res.data.code) {
            case 200:
              if (res.data.success == true) {
                resolve(res.data.data)
              } else {
                vm.prototype.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "error",
                  duration: 2000,
                });
                reject(res.message)
              }
              break;
            case 444:
              vm.prototype.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
                duration: 2000,
              });

              handle444()
              break;
            default:
              vm.prototype.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
                duration: 2000,
              });
              break;
          }
        }
      ).catch(
        err => {
          vm.prototype.$message({
            showClose: true,
            message: "服务器繁忙!请刷新重试!",
            type: "error",
            duration: 2000,
          });
          reject(err)
        }
      )
    }),
    // post请求
    post: (params) => new Promise((resolve, reject) => {
      const { url, data, header } = params;
      let headers = { ...header }
      switch (url) {
        case '/user/login':
        case '/articlePhotoManagement/query':
          break;
        default:
          headers.Authorization = localStorage.GIA_token
          break;
      }
      axios.post(
        baseUrl + url,
        data,
        {
          headers: headers
        }
      ).then(
        res => {
          //成功回调函数停止加载
          switch (res.data.code) {
            case 200:
              if (res.data.success == true) {
                resolve(res.data.data)
              } else {
                vm.prototype.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "error",
                  duration: 2000,
                });
                reject(res.message)
              }
              break;
            case 444:
              vm.prototype.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
                duration: 2000,
              });
              handle444()
              break;
            default:
              vm.prototype.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
                duration: 2000,
              });
              reject(res.data);
              break;
          }
        }
      ).catch(
        err => {
          vm.prototype.$message({
            showClose: true,
            message: "服务器繁忙!请刷新重试!",
            type: "error",
            duration: 2000,
          });
          reject(err)
        }
      )
    }),
    // post的form请求
    postForm: (params) => new Promise((resolve, reject) => {
      const { url, data } = params;
      let Form = new FormData();
      for (const key in data) {
        Form.append(key, data[key]);
      }
      axios.post(
        baseUrl + url,
        Form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.GIA_token
          }
        }
      ).then(
        res => {
          //成功回调函数停止加载
          switch (res.data.code) {
            case 200:
              if (res.data.success == true) {
                resolve(res.data.data)
              } else {
                vm.prototype.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "error",
                  duration: 2000,
                });
                reject(res.message)
              }
              return;
            case 444:
              vm.prototype.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
                duration: 2000,
              });
              handle444()
              break;
            default:
              vm.prototype.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
                duration: 2000,
              });
              break;
          }
          reject(res.data.message)
        }
      ).catch(
        err => {
          vm.prototype.$message({
            showClose: true,
            message: "服务器繁忙!请刷新重试!",
            type: "error",
            duration: 2000,
          });
          reject(err)
        }
      )
    }),
    // postPDF请求-----下载PDF
    postPDF: (params) => new Promise((resolve) => {
      const { url, data, header, pdfName } = params;
      let headers = { ...header }
      let URL0 = baseUrl + url
      headers.Authorization = localStorage.GIA_token
      axios({
        // 指定请求方式
        method: 'post',
        url: URL0,
        // 请求类型为blob
        responseType: 'blob',
        data: data,
        headers
      })
        .then(function (res) {
          let blob = new Blob([res.data], { type: "application/pdf;chartset=UTF-8" });
          // window.location.href = objectUrl;
          const a = document.createElement('a')
          // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
          const URL = window.URL || window.webkitURL
          // 根据解析后的blob对象创建URL 对象
          const herf = URL.createObjectURL(blob)
          // 下载链接
          a.href = herf
          // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
          a.download = pdfName + '.pdf'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          // 在内存中移除URL 对象
          window.URL.revokeObjectURL(herf)
          resolve(res.data)
        })
        .catch(function () {
          vm.prototype.$message({
            showClose: true,
            message: "获取失败!",
            type: "error",
            duration: 2000,
          });
        })
    }),
    // postExcel请求-----下载excel
    postExcel: (params) => new Promise((resolve,reject) => {
      const { url, data, header,excelName } = params;
      let headers = { ...header }
      let URL0 = baseUrl + url
      headers.Authorization = localStorage.GIA_token
      axios({
        // 指定请求方式
        method: 'post',
        url: URL0,
        // 请求类型为blob
        responseType: 'blob',
        data: data,
        headers
      })
        .then(function (res) {
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });  // res就
          //是接口返回的文件流了
          // let objectUrl = URL.createObjectURL(blob);
          // window.location.href = objectUrl;
          const a = document.createElement('a')
          // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
          const URL = window.URL || window.webkitURL
          // 根据解析后的blob对象创建URL 对象
          const herf = URL.createObjectURL(blob)
          // 下载链接
          a.href = herf
          a.download = excelName + '.xls'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          // 在内存中移除URL 对象
          window.URL.revokeObjectURL(herf)
          resolve(res.data);
        })
        .catch(function () {
          vm.prototype.$message({
            showClose: true,
            message: "服务器繁忙!请刷新重试!",
            type: "error",
            duration: 2000,
          });
        })
    }),
  }
  return newAxios;
}


export default request

// import axios from "axios";
// import router from "../router"
// import { Message } from 'element-ui'
// /**
//  * 统一处理网络请求的响应拦截处理方式，
//  * 下载与常规接口格式封装
//  */
// // 请求全局配置
// axios.defaults.timeout = 12000// 配置请求超时
// // 请求拦截
// axios.interceptors.request.use(
//   config => {
//     config.headers.Authorization =
//       localStorage.GIA_token // config里就是可以统一配置request请求的参数，headers就可以在这设置
//     return config
//   },
//   error => Promise.reject(error)
// )

// // 响应拦截
// axios.interceptors.response.use(
//   (result) => {
//     return new Promise((resolve, reject) => {
//       console.log(result);
//       const {
//         data: {
//           code,
//           message,
//           success,
//           data
//         },
//         status
//       } = result
//       // 判断是否是下载类型
//       if (!code) {
//         switch (status) {
//           case 200:
//             resolve(result.data)
//             return
//           default:
//             reject(new Error('请求失败!'))
//             return
//         }
//       }
//       switch (code) {
//         case 200:
//           if (success) {
//             resolve(data)
//           }
//           break
//         case 504:
//         case 404:
//           break
//         case 444:
//           // 服务器丢失
//           Message({
//             showClose: true,
//             message: message,
//             type: 'warning'
//           });
//           reject(message)
//           localStorage.GIA_token = ''
//           router.replace('/')
//           //   无权限
//           break
//         case 401:
//           // 无权限
//           Message({
//             showClose: true,
//             message: message,
//             type: 'warning'
//           });
//           reject(message)
//           router.replace('/')
//           break
//         default:
//           // 未登录
//           if (message) {
//             Message({
//               showClose: true,
//               message: message,
//               type: 'warning'
//             });
//           } else {
//             Message({
//               showClose: true,
//               message: '未知错误',
//               type: 'warning'
//             });
//           }
//           reject(message)
//           break
//       }
//     })
//   },
//   error => {
//     console.log(error);
//     const {
//       status,
//       data: {
//         message
//       }
//     } = error.response
//     switch (status) {
//       case 504:
//       case 404:
//         break
//       case 444:
//         // 服务器丢失
//         Message({
//           showClose: true,
//           message: message,
//           type: 'warning'
//         });
//         localStorage.GIA_token = ''
//         router.replace('/')
//         //   无权限
//         break
//       case 401:
//         // 无权限
//         Message({
//           showClose: true,
//           message: message,
//           type: 'warning'
//         });
//         router.replace('/')
//         break
//       default:
//         // 未登录
//         if (message) {
//           Message({
//             showClose: true,
//             message: message,
//             type: 'warning'
//           });
//         } else {
//           Message({
//             showClose: true,
//             message: '未知错误',
//             type: 'warning'
//           });
//         }
//         break
//     }
//     throw Error
//   }

// )

// const baseUrl = '/api';
// // const handle444 = () => {
// //   localStorage.GIA_token = ''
// //   router.replace('/login')
// // }
// const request = (vm) => {
//   const newAxios = {
//     // get请求
//     get: (params) => new Promise((resolve, reject) => {
//       const { url, data } = params;
//       axios.get(
//         baseUrl + url,
//         {
//           params: data,
//         },
//       ).then(
//         res => {
//           resolve(res)
//         }
//       ).catch(
//         err => {
//           reject(err)
//         }
//       )
//     }),
//     // post请求
//     post: (params) => new Promise((resolve, reject) => {
//       const { url, data } = params;
//       axios.post(
//         baseUrl + url,
//         data,
//       ).then(
//         res => {
//           //成功回调函数停止加载
//           resolve(res)
//         }
//       ).catch(
//         err => {
//           reject(err)
//         }
//       )
//     }),
//     // post的form请求
//     postForm: (params) => new Promise((resolve, reject) => {
//       const { url, data } = params;
//       let Form = new FormData();
//       for (const key in data) {
//         Form.append(key, data[key]);
//       }
//       axios.post(
//         baseUrl + url,
//         Form,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           }
//         }
//       ).then(
//         res => {
//           //成功回调函数停止加载
//           resolve(res)
//         }
//       ).catch(
//         err => {
//           reject(err)
//         }
//       )
//     }),
//     // postPDF请求-----下载PDF
//     postPDF: (params) => new Promise((resolve) => {
//       const { url, data, pdfName } = params;
//       let URL0 = baseUrl + url
//       axios({
//         // 指定请求方式
//         method: 'post',
//         url: URL0,
//         // 请求类型为blob
//         responseType: 'blob',
//         data: data,
//       })
//         .then(function (res) {
//           let blob = new Blob([res.data], { type: "application/pdf;chartset=UTF-8" });
//           // window.location.href = objectUrl;
//           const a = document.createElement('a')
//           // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
//           const URL = window.URL || window.webkitURL
//           // 根据解析后的blob对象创建URL 对象
//           const herf = URL.createObjectURL(blob)
//           // 下载链接
//           a.href = herf
//           // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
//           a.download = pdfName + '.pdf'
//           document.body.appendChild(a)
//           a.click()
//           document.body.removeChild(a)
//           // 在内存中移除URL 对象
//           window.URL.revokeObjectURL(herf)
//           resolve(res.data)
//         })
//         .catch(function (error) {
//           console.log(error);
//           vm.prototype.$message({
//             showClose: true,
//             message: "获取失败!",
//             type: "error",
//             duration: 2000,
//           });
//         })
//     }),
//     // postExcel请求-----下载excel
//     postExcel: (params) => new Promise(() => {
//       const { url, data, header } = params;
//       let headers = { ...header }
//       let URL0 = baseUrl + url
//       headers.Authorization = localStorage.GIA_token
//       axios({
//         // 指定请求方式
//         method: 'post',
//         url: URL0,
//         // 请求类型为blob
//         responseType: 'blob',
//         data: data,
//         headers
//       })
//         .then(function (res) {
//           let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });  // res就
//           //是接口返回的文件流了
//           let objectUrl = URL.createObjectURL(blob);
//           window.location.href = objectUrl;
//         })
//         .catch(function () {

//           vm.prototype.$message({
//             showClose: true,
//             message: "服务器繁忙!请刷新重试!",
//             type: "error",
//             duration: 2000,
//           });
//         })
//     }),
//   }
//   return newAxios;
// }


// export default request


