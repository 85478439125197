<template>
  <div class="institutionalFramework">
    <!--<div class="IF_institutionalMemberBox">
      <div
        class="IF_institutionalMember"
        v-for="(memberGroup, index) in institutionalMember"
        :key="index"
        :item="memberGroup"
      >
        <p class="IM_position">{{ memberGroup.rank }}</p>
        <div class="IF_IM_memberGroup">
          <div
            class="MG_aMemberMEssage"
            v-for="(item, index2) in memberGroup.rankUserList"
            :key="index2"
          >
            <img :src="item.photoUrl" class="AM_avatar" />
            <div class="AM_memberMEssage">
              <p class="AM_name">{{ item.name }}</p>
              <div class="AM_background">{{ item.duty }}</div>
              <div class="AM_personAddress">{{ item.personAddress }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    
  </div>
</template>
<script>
import img from "../../../image/avatar.png";
import wudi from "../../../image/initiator/wudi.jpg";
import gaoying from "../../../image/initiator/gaoying.jpg";
import wukaishun from "../../../image/initiator/wukaishun.jpg";
import jiangyuncheng from "../../../image/initiator/jiangyuncheng.jpg";
export default {
  data() {
    return {
      institutionalMember: [
        {
          position: "发起人",
          members: [
            {
              avatar: wudi,
              name: "吴迪",
              background: "中山大学计算机学院副院长",
              personAddress: "13729846087",
            },
            {
              avatar: gaoying,
              name: "高英",
              background: "华南理工大学计算机科学与工程学院副院长",
              personAddress: "13632282293",
            },
            {
              avatar: wukaishun,
              name: "伍楷舜",
              background: "深圳大学特聘教授",
              personAddress: "13570057116",
            },
            {
              avatar: jiangyuncheng,
              name: "蒋运承",
              background: "华南师范大学教授",
              personAddress: "13073063125",
            },
          ],
        },
        {
          position: "理事长",
          members: [
            {
              avatar: img,
              name: "anne",
              background: "广东工业大学",
              personAddress: "anne@gia.edu.cn111111222545112",
            },
          ],
        },
      ],
    };
  },
  methods: {
    async getInstitutionalFramework() {
      const result = await this.$request.post({
        url: "/rank/getUsersByRank",
      });
      this.$data.institutionalMember = result[0].rankListVoList;
    },
  },
  mounted() {
    this.getInstitutionalFramework();
  },
};
</script>
<style lang="less" scoped>
.structure_img {
  height: 80%;
  width: 90%;
  padding: 50px;
}
.institutionalFramework {
  margin-left: 0px;
  width: 100%;
  border: 2px solid rgba(17, 34, 51, 0.252);
  margin-bottom: 100px;
  // display: flex;
  // justify-content: space-between;
  // .IF_frameworks {
  //   text-align: left;
  //   width: 120px;
  //   li {
  //     border: 1px solid rgba(128, 127, 127, 0.2);
  //     height: 30px;
  //     line-height: 30px;
  //     margin-bottom: 7px;
  //     padding-left: 5px;
  //   }
  // }
  .IF_institutionalMemberBox {
    border: 1px solid rgba(128, 127, 127, 0.2);
    width: 992px;
    margin: 0;
    .IF_institutionalMember {
      margin: 10px 20px;
      .IM_position {
        font-size: 20px;
        font-weight: 600;
        color: rgb(8, 88, 169);
      }
      .IF_IM_memberGroup {
        display: flex;
        flex-wrap: wrap;
        width: 1000px;
        .MG_aMemberMEssage {
          display: flex;
          justify-content: space-between;
          margin: 10px;
          width: 300px;
          height: 180px;
          .AM_avatar {
            width: 140px;
            height: 160px;
          }
          .AM_memberMEssage {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 150px;
            .AM_name {
              width: 150px;
            }
            .AM_background {
              width: 150px;
            }
            .AM_personAddress {
              width: 150px;
              font-size: 15px;
              word-wrap: break-word;
              word-break: normal;
            }
          }
        }
      }
    }
  }
}
</style>