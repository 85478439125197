
<template>
    <div class="Group">
        <div class="receiveMessage">

            <div class="receiveFormPlace">



                <el-button type="primary" icon="el-icon-arrow-down"
                    @click="ShowPageChange(page, size)">查询所有项目</el-button><!-- 分组按钮 -->

                <div class="controlContainer">
                    <div class="inputContainer">

                        <label>新建/修改项目： </label>
                        <input v-model="projectName" placeholder="请输入项目名字">
                        <input v-model="tag"   type="number" placeholder="项目分类（整数）">
                        <input v-model="startTime" placeholder="项目开始时间">
                        <input v-model="endTime" placeholder="项目结束时间">
                        <input v-model="personInCharge" placeholder="请输入项目负责人">
                        <input v-model="personPhone" placeholder="负责人联系方式">
                        <input v-model="expertNum" placeholder="请输入专家人数">


                        <el-button :disabled="isSubmitting" type="primary" icon="el-icon-plus"
                            @click="submitForm">新建项目</el-button>


                        <el-button :disabled="isSubmitting" type="primary" icon="el-icon-edit"
                            @click="submitFormUpdate">修改项目</el-button>
                            <span>修改时选择</span>
                    <select v-model="id">
                
                    <option value="">请选择修改的项目</option>
                   
                    <option v-for="Group in Groups" :value="Group.id">{{ Group.id }}：{{ Group.projectName }}</option>
                  </select>

                    </div>
                    <span style="color: green; font-size: small;">
                        使用方法： 1.新建项目：输入项目信息后，点击新建项目，其中项目开始时间、项目结束时间的输入格式为：yyyy-MM-dd HH:mm ，例如2023-07-12 18:21 </span>
                    <span style="color: green; font-size: small;"> 2.修改项目：需要额外输入需修改的项目id，点击修改按钮。
                    </span>
                </div>






                <div class="messageContainer">
                    <div v-if="isSubmitting" class="message">正在提交...</div>
                    <div v-if="isSuccess" class="message">加载中...</div>
                </div>
            </div>




        </div>


        <div class="receiveFormPlace">
            <el-table :data="Groups">
                <el-table-column prop="id" align="center" label="项目ID" width="50">
                </el-table-column>
                <el-table-column prop="projectName" align="center" label="项目名字" width="100">
                </el-table-column>
                <el-table-column prop="tag" align="center" label="项目分类" width="80">
                </el-table-column>
                <el-table-column prop="personInCharge" align="center" label="项目负责人" width="90">
                </el-table-column>
                <el-table-column prop="personPhone" align="center" label="负责人联系方式" width="130">
                </el-table-column>
                <el-table-column prop="expertNum" align="center" label="专家人数" width="50">
                </el-table-column>
                <el-table-column prop="startTime" align="center" label="项目开始时间" width="100">
                </el-table-column>

                <el-table-column prop="endTime" align="center" label="项目结束时间" width="100">
                </el-table-column>


                <el-table-column prop="createTime" align="center" label="创建时间" width="100">
                </el-table-column>

                <el-table-column prop="modifyTime" align="center" label="修改时间" width="100">
                </el-table-column>

                <el-table-column align="center" label="删除项目" width="100">
                    <template slot-scope="scope">
                        <div>
                            <el-button :disabled="isSubmitting" icon="el-icon-delete" type="primary"
                                @click=" moveProject(scope.row.id);">删除</el-button>
                        </div>
                    </template>
                </el-table-column>




            </el-table>


            <label class="inputLabel">页码：</label>
            <input v-model="page" placeholder="请输入数字">

            <label class="inputLabel">页大小：</label>
            <input v-model="size" placeholder="请输入数字">





        </div>

    </div>
</template>



  
  
  
<script>
import axios from 'axios';

export default {
    name: "Group",
    data() {
        return {
            groups: [],// 将groups声明为一个空数组，用来存储获取到的分组数据
            Groups: [],   //展开分组
            totalList: [],
            isSuccess: false, //加载中？
            page: 1,
            size: 100,
            isSubmitting: false,
            groupName: "",
            isTableExpanded: true,
            groupId: 2,
            classsifyExpertName: "", // 用户输入的专家名称
            classsifyExpertId: "", // 用户输入的专家ID
            classsifyGroupId: "", // 用户输入的分组ID
            expertIdList: [], // 存储专家对象的数组
            id:1, //项目id初始化



        }

    },
    computed: {},
    methods: {
        async moveProject(projectId) {//删除项目


            try {
                const confirmed = confirm('确定要删除该项目吗？'); // 弹出确认框

                if (!confirmed) {
                    return; // 用户取消删除操作
                }
                const resultList = await this.$request.post({
                    url: `/project/delete?projectId=${projectId}`,

                });
                this.$message.success('删除成功,刷新页面');

                location.reload(); // 重新加载页面

            } catch (error) {
                console.error(error);
            }






        },

        ShowPageChange: async function (page, size) {
            this.isSuccess = true;

            const result = await this.$request.get({
                url: `/project/allproject?page=${page}&size=${size}`,
            });

            console.log(result);
            this.$message.success('查询全部项目成功');
            this.Groups = result.list;

            console.log(this.Groups);
            this.isSuccess = false;
        },



        getAllGroups: async function () {

            // 获取待处理或已处理的会员等级（头衔）变动列表
            this.isSuccess = true;
            const result = await this.$request.get({
                url: '/group/allGroup',

            });

            console.log(result); // 添加这一行
            this.groups = result.list; // 修改部分：将获取到的分组数据赋值给groups数组

            console.log(this.groups);
            this.isSuccess = false;

        },

        async newProject(projectName, tag, startTime, endTime, personInCharge, personPhone, expertNum) {//添加分组

            const resultList = await this.$request.post({
                url: `/project/new`,
                data: {

                    projectName,
                    tag,
                    startTime,
                    endTime,
                    personInCharge,
                    personPhone,
                    expertNum,
                }
            },

            );
            this.$message.success('新建项目成功，请刷新页面');

        },
        async updateProject(id, projectName, tag, startTime, endTime, personInCharge, personPhone, expertNum) {//添加分组

            const resultList = await this.$request.post({
                url: `/project/update`,
                data: {
                    id,
                    projectName,
                    tag,
                    startTime,
                    endTime,
                    personInCharge,
                    personPhone,
                    expertNum,
                }
            },

            );
            this.$message.success('修改项目成功，请刷新页面');
            location.reload(); // 重新加载页面
        },

        async deleteGroup(groupId) {//删除分组

            const resultList = await this.$request.post({
                url: `/group/deleteGroup?groupId=${groupId}`,

            });
            this.$message.success('删除分组成功，请刷新页面');

        },



        classsifyGroup: async function (groupId, expertIdList) {

            const expert = {
                expertName: this.classsifyExpertName,
                expertId: parseInt(this.classsifyExpertId)
            };
            this.expertIdList.push(expert);
            try {
                const response = await this.$request.post({
                    url: '/group/classify',
                    data: {
                        groupId,
                        expertIdList,
                    },
                });
                this.expertList = response;
                // 处理返回的数据
                console.log(response);
                this.$message.success('测试成功');
                // 根据需要进行其他操作

            } catch (error) {
                // 发生错误时的处理
                console.error(error);
            }
        },


        submitForm() {
            if (this.isSubmitting) {
                return; // 如果正在提交，则不执行后续代码
            }
            this.isSubmitting = true;

            // 执行你的提交操作，即原来的 newGroup(groupName) 函数

            // 在操作完成后，将 isSubmitting 设置为 false，以允许下一次提交
            setTimeout(() => {
                // 模拟操作完成后的回调
                this.isSubmitting = false;

                // 其他的逻辑处理

                // 调用你的 newGroup(groupName) 函数
                this.newProject(this.projectName, this.tag, this.startTime, this.endTime, this.personInCharge, this.personPhone, this.expertNum);
            }, 3000); // 这里的2000表示操作完成需要的时间，单位是毫秒
        }
        ,

        submitFormUpdate() {
            if (this.isSubmitting) {
                return; // 如果正在提交，则不执行后续代码
            }
            this.isSubmitting = true;

            // 执行你的提交操作，即原来的 newGroup(groupName) 函数

            // 在操作完成后，将 isSubmitting 设置为 false，以允许下一次提交
            setTimeout(() => {
                // 模拟操作完成后的回调
                this.isSubmitting = false;

                // 其他的逻辑处理

                // 调用你的 newGroup(groupName) 函数
                this.updateProject(this.id, this.projectName, this.tag, this.startTime, this.endTime, this.personInCharge, this.personPhone, this.expertNum);
            }, 3000); // 这里的2000表示操作完成需要的时间，单位是毫秒
        }




    },
    mounted() {

        this.ShowPageChange(1, 100); // 初始化时调用展示列表信息
    }
};
</script>
<style lang="css" scoped="true">
.inputLabel {
    color: #999;
    font-size: 14px;
}

.input {
    color: #666;
    font-size: 12px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}



.custom-input {
    border: 1px solid #42b983;
    /* 输入框边框样式 */
    padding: 8px;
    /* 输入框内边距 */
    font-size: 16px;
    /* 输入框文字大小 */
    color: #333;
    /* 输入框文字颜色 */
}

.controlContainer {
    position: relative;
    top: 0;
    /* 可以根据需要调整 */
    right: 0;
    /* 可以根据需要调整 */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
}
</style>
  