<template>
  <div>
    <p class="title">查看或发表文章</p>
    <div class="search-nav">
      <div class="search-input">
        <slot name="search-input">
          <el-input size="mini"
                    v-model="searchText"
                    type="text"
                    placeholder="请输入查找内容"
                    clearable></el-input>
        </slot>
      </div>
      <div class="search-select">
        <slot name="search-select">
          <el-select size="mini"
                     v-model="searchType"
                     placeholder="类型">
            <el-option value=""
                       key=""
                       label=""></el-option>
            <el-option v-for="(item, key) in tableList"
                       :key="key"
                       :label="item.name"
                       :value="item.key"></el-option>
          </el-select>
        </slot>
      </div>
    </div>
    <el-table v-loading="loading"
              class="showTable"
              :data="showProduceList"
              style="width: 100%">
      <el-table-column v-for="item in tableList"
                       :prop="item.key"
                       align="center"
                       :key="item.key"
                       :label="item.name">
        <template slot-scope="scope">
          <span v-if="item.key === 'fee'">
            {{ scope.row[item.key] / 100 }} (元)
          </span>
          <span v-else>
            {{ scope.row[item.key] }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column ref="edit" label="编辑" align="center">
        <template slot-scope="scope">
          <el-button
            class="editButton"
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="deleteItem(scope.row)"
          ></el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="pagination">
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="produceList.length">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      totalList: [],
      // 添加表单
      addForm: {
        landName: null,
        landArea: null,
        organicMatterContent: null,
        waterContent: null,
        soilPhValue: null,
        cationExchangeCec: null,
        farmBaseId: null,
        landTestReport: null,
      },
      pageSize: 7,
      currentPage: 1,
      tableList: [
        { name: "文章题目", key: "title" },
        { name: "发表人", key: "name" },
        { name: "发表时间", key: "time" },
        { name: "类型", key: "type" },
        { name: "缴费金额", key: "fee" },
        { name: "缴费方式 ", key: "payWay" },
        { name: "创建时间", key: "createTime" },
        { name: "到款时间", key: "revenueTime" },
      ],
      // 搜索
      searchText: "",
      searchType: "",
      loading: false,
    };
  },
  methods: {
    handleCurrentChange (currentPage) {
      this.$data.currentPage = currentPage;
    },
    async getPayRecord () {
      const resultList = await this.$request.post({
        url: "/order/query",
      });
      this.totalList = resultList;
    },
    // async deleteItem(data) {

    // },
  },
  mounted () {
    this.getPayRecord();
  },
  computed: {
    showProduceList () {
      const {
        produceList,
        $data: { currentPage, pageSize },
      } = this;
      return produceList.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );
    },
    produceList () {
      const { totalList, searchType, searchText } = this.$data;
      if (searchText == "" || searchType == "") {
        return totalList;
      }
      return totalList.filter((item) => {
        return item[searchType].indexOf(searchText.trim()) != -1;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  margin: 10px 0;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.search-nav {
  padding: 15px 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(165, 165, 165, 0.205);
  .search-input {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    width: 200px;
    margin: 0 2px;
  }
  .search-select {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    width: 150px;
    margin: 0 2px;
  }
  .search-add {
    display: inline-block;
    padding: 2px 2px 2px 5px;
    margin: 0 2px;
  }
}
.pagination {
  .el-pagination {
    background-color: #fff !important;
    padding: 6px 30px;
  }
}
.tool-box {
  display: flex;
  justify-content: center;
}
</style>