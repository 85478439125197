import VueRouter from "vue-router";

import Vue from "vue";
Vue.use(VueRouter)
import rules from './rule'

export default new VueRouter({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   redirect: '/login'
    // },
    ...rules,
    // 双重定向
    {
      path: '/GIA_associator/GIA_redirect',
      redirect: '/GIA_associator/personal_info'
    },
    {
      path: '/about/GIA_redirect',
      redirect: '/about/introduct'
    },
    {
      path: '/news/GIA_redirect',
      redirect: '/news/Media_list'
    },
    {
      path: '/_login_',
      redirect: '/manage/login'
    },
    {
      path: '/manage/GIA_redirect',
      redirect: '/manage/login'
    },
    {
      path: '/manage/login',
      component: () => import('../pc_view/_login_.vue'),
    },
    {
      path: '/:cathAll(.*)',
      name: '404',
      component: () => import('../pc_view/404.vue')
    },
  ]
})

