<template>
  <div class="partners-box">
    <div v-for="(item, index) in partners"
         :key="index"
         class="partner-side">
      <div class="side">{{ item.side }}</div>
      <ul class="partners-item-box">
        <li class="partners-item"
            v-for="(item2, index2) in item.partnersItem"
            :key="index2">
          <div class="pic">
            <img :src="item2.imgUrl"
                 alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div class="bottomShowCompany"
         v-if="!isShow">
      <ul>
        <li class="">
          <a href="https://www.gdut.edu.cn/">
            <img src="../../image/gdut.png"
                 alt="" />
          </a>
        </li>
        <li class="">
          <a href="http://www.foshaniei.com/">
            <img src="../../image/logo1.jpg"
                 alt="" />
          </a>
        </li>
        <li class="">
          <a href="http://gsi.cssc.net.cn/">
            <img src="../../image/logo2.jpg"
                 alt="" />
          </a>
        </li>
        <li class="">
          <a href="https://gz.loongson.cn/">
            <img src="../../image/logo3.jpg"
                 alt="" />
          </a>
        </li>
        <li class="">
          <a href="https://www.apusic.com/">
            <img src="../../image/logo4.jpg"
                 alt="" />
          </a>
        </li>
        <li class="">
          <a href="">
            <img src="../../image/logo5.jpg"
                 alt="" />
          </a>
        </li>
        <li class="">
          <img src="../../image/108228.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108229.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108230.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108231.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108232.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108233.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108234.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108235.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108236.png"
               alt="" />
        </li>
        <li class="">
          <img src="../../image/108237.png"
               alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    const partners = [
      {
        side: "产学研合作",
        partnersItem: [],
      },
      {
        side: "学会活动",
        partnersItem: [],
      },
      {
        side: "入会须知",
        partnersItem: [],
      },
    ];
    return {
      partners,
    };
  },
  methods: {},
};
</script>
<style scoped lang='less'>
.side {
  font-size: 20px;
  color: #000;
  line-height: 1.5em;
  font-weight: bold;
  padding-bottom: 8px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.side::after {
  content: "";
  width: 80px;
  height: 4px;
  background-color: #006699;
  position: absolute;
  left: 0;
  bottom: 0;
}
.partners-item-box {
  margin: 36px 0;
}
.partners-item-box::after {
  display: table;
  content: "";
  clear: both;
}
.partners-item {
  float: left;
  width: 196px;
  padding-right: 20px;
  margin-bottom: 10px;
}
.pic {
  transition: 0.3s;
  padding: 0 18px;
}
.partners-item:hover {
  box-shadow: 1px 1px 2px 2px rgba(116, 116, 116, 0.144);
}
.bottomShowCompany {
  margin: 10px 0;
  ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    li {
      height: 80px;
      width: 180px;
      img {
        min-width: 100px;
        max-width: 180px;
        height: 60px;
        margin-left: 50%;
        transform: translate(-50%);
      }
      img:hover {
        box-shadow: 2px 3px 5px rgba(17, 34, 51, 0.286);
      }
    }
  }
}
</style>