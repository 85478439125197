<template>
  <div class="paymentRecord"
       v-loading="isSuccess">
    <div class="receiveMessage">
      <div class="topSearch"
           style="min-width: 300px">
        <div>
          <span>审核状态：</span>
          <el-select v-model="operateState"
                     class="selectT"
                     placeholder="审核状态："
                     @change="showChange()">
            <el-option label="未处理"
                       value="0"></el-option>
            <el-option label="已完成"
                       value="1"></el-option>
          </el-select>
        </div>
      </div>
      <div class="receiveFormPlace">
        <el-table :data="showTableData"
                  style="width: 100%; min-width: 800px; overflow: visible"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                  stripe
                  border
                  max-height="450">
          <el-table-column prop="number"
                           align="center"
                           label="会员号"
                           width="100">
          </el-table-column>
          <el-table-column prop="name"
                           align="center"
                           label="姓名"
                           width="100">
          </el-table-column>
          <el-table-column prop="userId"
                           align="center"
                           label="会员id"
                           width="70">
          </el-table-column>
          <el-table-column prop="manager"
                           align="center"
                           label="操作管理员"
                           width="100">
          </el-table-column>
          <el-table-column prop="rankBefore"
                           align="center"
                           label="原等级"
                           width="100"><template slot-scope="prop">
              {{ rankName(prop.row.rankBefore) }}
            </template>
          </el-table-column>
          <el-table-column prop="rankAfter"
                           align="center"
                           label="申请等级"
                           width="100"><template slot-scope="prop">
              {{ rankName(prop.row.rankAfter) }}
            </template>
          </el-table-column>
          <el-table-column prop="note"
                           align="center"
                           show-overflow-tooltip="true"
                           label="变动理由"
                           width="220">
          </el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           label="创建时间"
                           width="160">
          </el-table-column>
          <el-table-column prop="agree"
                           align="center"
                           label="审核意见"
                           width="110">
            <template slot-scope="rows">
              <div v-if="operateState == 1">
                <div v-if="rows.row.isAgree"
                     style="color: #4facfe">
                  <span>已同意</span>
                </div>
                <div v-else
                     style="color: orangered">
                  <span>不同意 </span>
                </div>
              </div>
              <div v-else>
                <el-button type="primary"
                           icon="el-icon-check"
                           @click="setRank(rows.row.rankId, true)"></el-button>
                <el-button icon="el-icon-close"
                           @click="setRank(rows.row.rankId, false)"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <hr style="border-top: 2px dotted rgb(200, 199, 200, 0); margin: 5px 0" />
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :page-sizes="pageSizes"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import StuMana
// import pubsub from 'pubsub-js'
export default {
  name: "payRecord",
  data () {
    return {
      isSuccess: false, //加载中？
      pageSize: 5, //每页数据量
      pageSizes: [2, 5, 15, 30, 50], //可选每页数据量
      showTableData: [
        {
          rankId: "rank的id",
          userId: "申请的会员的id",
          name: "会员的姓名",
          number: "会员的会员号",
          manager: "操作的管理员",
          rankBefore: "之前的等级",
          rankAfter: "升级后的等级",
          note: "理由或备注",
          agree: "是否同意",
          createTime: "会员申请升级的或被降级的时间",
        },
        {
          rankId: "2212253k",
          userId: "123666221",
          name: "会员的姓名",
          number: "123456789A",
          manager: "操作的管理员",
          rankBefore: "普通一级会员1号",
          rankAfter: "高级会员SVIP3号",
          note: "已经充值足够切开始讲课的教案课件的卡斯柯的健康洒家打开就卡死肯德基卡斯柯的健康撒娇的卡斯柯的健康撒娇的空间看到急啊看手机打开洒家打开急啊看手机打开时间打卡机",
          agree: "同意",
          createTime: "2022-01-16 22:12:32",
        },
      ], //展示的数据
      total: 0, //全部数据量
      pageNow: 1, //当前所处页面
      searchMember: "", //搜索输入的会员名
      memberType: [], //会员类型搜索
      editMemberId: "", //编辑行会员id
      operateState: "0", //处理状态：0--待处理；1--已处理
      rankList: [], //选中的头衔列表
      rankLists: [], //头衔列表
    };
  },
  computed: {},
  methods: {
    // 根据传入的rankid获取对应的职位名字
    rankName (rankId) {
      let { rankLists } = this.$data;
      for (let i = 0; i < rankLists.length; i++) {
        if (rankLists[i].id == rankId) {
          return rankLists[i].rank;
        }
      }
    },
    // val为选择的每页长度
    handleSizeChange (val) {
      this.pageSize = val;
      this.showPageChange(this.pageNow, this.pageSize);
    },
    // val为选择的第几页
    handleCurrentChange (val) {
      this.pageNow = val;
      this.showPageChange(this.pageNow, this.pageSize);
    },
    typeChange: async function (rankId) {
      const result = await this.$request.post({
        url: `/rank/showUsers?id=${rankId}`,
      });
      this.showTableData = result;
    },

    // 调用展示审核、未审核列表信息改变
    showChange: async function () {
      this.pageNow = 1;
      this.pageSize = 5;
      this.showPageChange(this.pageNow, this.pageSize);
    },
    async getRanks () {
      // 获取所有头衔列表
      const result1 = await this.$request.post({
        url: "/rank/showRanks",
      });
      this.rankLists = result1;
    },
    // 调用展示审核、未审核列表信息
    showPageChange: async function (page, size) {
      this.isSuccess = true;
      // 获取待处理或已处理的会员等级（头衔）变动列表
      let operateState = this.operateState;
      const result = await this.$request.post({
        url: `/rank/manage/getRanks?type=${operateState}`,
        data: {
          page,
          size,
        },
      });
      this.total = result.totalCount;
      this.showTableData = result.list;
      this.isSuccess = false;
    },
    // 是否同意职位变动
    async setRank (rankId, agree) {
      await this.$request.post({
        url: `/rank/manage/setRank?id=${rankId}&agree=${agree}`,
      });
      this.showPageChange(this.pageNow, this.pageSize);
    },
  },
  mounted () {
    // 默认展示全部会员交费记录--获取全部职位信息
    this.showPageChange(this.pageNow, this.pageSize);
    this.getRanks();
  },
};
</script>

<style lang="css" scoped="true">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.el-button {
  padding: 5px 10px;
}
.paymentRecord {
  width: 1150px;
  background-color: #fff;
  min-width: 1000px;
  overflow: scroll;
  margin-left: -50px;
  /* margin-left: 50%;

  transform: translate(-50%); */
}

.title {
  margin-top: 30px;
  width: 300px;
  height: 70px;
  background-color: #4facfe;
  border-radius: 10px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  line-height: 70px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-shadow: -1px -2px 1px rgb(0, 0, 0);
}
.topSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.receiveFormPlace * {
  text-align: center;
}
.receiveMessage {
  width: 1230px;
  box-shadow: 2px 2px 5px rgba(17, 34, 51, 0.4);
  padding: 50px 100px;
  overflow: auto;
}
.receiveFormPlace {
  margin-top: 30px;
  min-width: 1100px;
  overflow-x: scroll;
}
.receiveFormPlace a {
  text-decoration: none;
  color: #123;
  width: 60px;
  margin-right: 5px;
}
.selectT {
  min-width: 60px;
  max-height: 40px;
}
</style>
