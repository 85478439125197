<template>
  <div class="slideBox">
    <div class="slide">
      <div class="block">
        <el-carousel height="400px">
          <el-carousel-item v-for="item in images"
                            :key="item.articleId">
            <router-link :to="{
                  path: '/news/media_essay',
                  query: { newId: item.articleId },
                }"
                         class="check-link">
              <el-image :src="item.url"
                        class="slidingImage">
              </el-image>
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- <div class="dataForm"
         v-if="!isShow">
      <el-calendar>
        <template slot="dateCell"
                  slot-scope="{ data }">
          <p :class="data.isSelected ? 'is-selected' : ''">
            {{ data.day.split("-").slice(1).join("-") }}
            {{ data.isSelected ? "✔️" : "" }}
          </p>
        </template>
      </el-calendar>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "slidingImage",
  props: {
    images: Array,
    isShow: Boolean, //用于判断：true：管理端展示轮播图。false：首页
  },
  data () {
    return {
      fileList: [],
    };
  },
  methods: {
  },
  mounted () {
  },
};
</script>
<style lang='less'>
.slideBox {
  display: flex;
  justify-content: space-between;
  .slide {
    width: 700px;
    margin: 0 auto;
    .slidingImage {
      width: 800px;
      height: 400px;
    }
  }
  .dataForm {
    width: 500px;
    height: 400px;
    overflow: auto;
    .is-selected {
      color: #1989fa;
    }
  }
}
</style>