<template>
  <div class="upgrade-box">
    <div class="title">
      <span>申请升级会员</span>
      <i @click="$router.back(1)"
         class="back el-icon-arrow-left"></i>
    </div>
    <div class="upgrade-form">
      <p class="name">欲申请的头衔:</p>
      <el-select size="small"
                 v-model="addForm.rankAfter"
                 collapse-tags
                 placeholder="请选择申请头衔">
        <el-option v-for="item in rankList"
                   :key="item.id"
                   :label="item.rank"
                   :value="item.id">
        </el-option>
      </el-select>
      <p class="name">证件照:</p>
      <el-upload :action="'/api/file/upload/image'"
                 class="upload-demo"
                 drag
                 :on-success="addPhoto"
                 :limit="1"
                 :on-exceed="exceedFn"
                 :file-list="fileList"
                 list-type="picture"
                 :on-remove="removePhoto">
        <i class="el-icon-upload"></i>
        <p>或</p>
        <div class="el-upload__text">
          将报告拖到此处,或<em>点击上传</em>
        </div>
      </el-upload>
      <p class="name">申请理由:</p>
      <el-input :autosize="{
        minRows: 5,
        maxRows: 10,
      }"
                v-model="addForm.note"
                placeholder="请输入申请理由"
                resize="none"
                type="textarea"
                class="upgrade-reason-box">
      </el-input>
      <div class="sub-btn">
        <el-button :disabled="addForm.note == '' ? true : false"
                   type="primary"
                   @click="commitRank()"
                   icon="el-icon-check"
                   size="small">提交</el-button>
        <el-button :disabled="addForm.note == '' ? true : false"
                   type="primary"
                   @click="addForm.note = ''"
                   icon="el-icon-refresh-right"
                   size="small">重置</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      rankList: [],
      addForm: {
        note: "",
        rankAfter: "",
        photoUrl: ""
      }
    };
  },
  methods: {
    async commitRank () {
      const { addForm } = this.$data;
      await this.$request.post({
        url: "/rank/commitRank",
        data: addForm,
      });
      this.$message({
        showClose: true,
        message: "申请成功!",
        type: "success",
        duration: 2000,
      });
      this.$router.back(1);
    },
    removePhoto () {
      this.$data.addForm.photoUrl = "";
    },
    addPhoto (response) {
      const { message, data } = response;
      this.$data.addForm.photoUrl = data;
      this.$message({
        showClose: true,
        message: message,
        duration: 2000,
      });
    },
    exceedFn () {
      this.$message({
        showClose: true,
        message: "请先将已上传的图片删除",
        duration: 2000,
      });
    },
    async getRank () {
      const result = await this.$request.post({
        url: "/rank/showRanks",
      });
      this.$data.rankList = result;
    },
  },
  mounted () {
    this.getRank();
  },
  computed: {
    fileList () {
      const { photoUrl } = this.$data.addForm;
      if (!photoUrl) {
        return [];
      }
      return [
        {
          url: photoUrl,
          title: "证件照.png",
        },
      ];
    },
  },
};
</script>
<style lang="less" scoped>
.upgrade-box {
  padding: 20px;
  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin: 18px 0;
    position: relative;
    .back {
      left: 10px;
      top: 2px;
      position: absolute;
      font-size: 30px;
      font-weight: 600;
      color: #757575;
      cursor: pointer;
    }
  }
  .upgrade-form {
    padding: 20px 80px;
    margin: 10px 60px;
    border: 1px solid rgba(75, 75, 75, 0.1);
    border-radius: 15px;
    .name {
      color: #757575;
      font-size: 13px;
    }
    .sub-btn {
      margin: 5px 0;
      display: flex;
      justify-content: center;
    }
  }
}
</style>