<template>
  <div v-loading='isSuccess'>
    <div class="showPlace">
      <div class="payCertification">
        <el-descriptions title="会员交费凭证"
                         :column="3"
                         border
                         size="min">
          <el-descriptions-item contentStyle="border: 1px solid #123; text-align:center;min-width:135px;"
                                labelStyle="min-width:80px;border: 1px solid #123; text-align:center;background-color:rgb(200,200,200,0.3);color:black;font-weight: bold;"
                                label="姓名">{{ name }}</el-descriptions-item>
          <el-descriptions-item contentStyle="border: 1px solid #123; text-align:center;"
                                labelStyle="min-width:80px ;background-color:rgb(200,200,200,0.3);color:black;font-weight: bold;border: 1px solid #123;  text-align:center;"
                                label="会员号">{{ idNumber }}</el-descriptions-item>
          <el-descriptions-item contentStyle="border: 1px solid #123; text-align:center;"
                                labelStyle="min-width:80px ;background-color:rgb(200,200,200,0.3);color:black;font-weight: bold;border: 1px solid #123;  text-align:center;"
                                label="任职单位">{{ workPlace }}</el-descriptions-item>
          <el-descriptions-item contentStyle="border: 1px solid #123; text-align:center;"
                                labelStyle="min-width:80px ;background-color:rgb(200,200,200,0.3);color:black;font-weight: bold;border: 1px solid #123;  text-align:center;"
                                label="交费日期"
                                min-width="120px">
            {{ startTime }}
          </el-descriptions-item>
          <el-descriptions-item contentStyle="border: 1px solid #123; text-align:center;"
                                labelStyle="min-width:80px ;background-color:rgb(200,200,200,0.3);color:black;font-weight: bold;border: 1px solid #123;  text-align:center;"
                                label="交费金额">{{ payNumber }}
            <p class="danwei">(单位：分)
            </p>
          </el-descriptions-item>
          <el-descriptions-item contentStyle="border: 1px solid #123; text-align:center;"
                                labelStyle="min-width:80px ;background-color:rgb(200,200,200,0.3);color:black;font-weight: bold;border: 1px solid #123;  text-align:center;"
                                label="有效期至">{{ endTime }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="deposit">
          <h3>广东省工业软件学会</h3>
        </div>
      </div>
    </div>
    <el-button class="downLoad"
               @click="downLoadIdCard"
               type="primary">下载</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isSuccess: false,
      name: "",
      idNumber: " ",
      workPlace: "",
      startTime: "",
      endTime: "",
      payNumber: "",
    };
  },
  methods: {
    async downLoadIdCard () {
      this.$message({
        message: "自动生成PDF中，请耐心等待",
        type: "success",
      });
      this.isSuccess = true;
      const result = await this.$request.postPDF({
        url: '/downloadPdf/PaymentVoucher',
        pdfName: "GIA_缴费凭证",
      });
      this.isSuccess = false;
    },
    async getMess () {
      const result = await this.$request.get({
        url: '/user/info'
      });
      this.name = result.name;
      this.idNumber = result.vipNumber;
      this.workPlace = result.workPlace;
      this.startTime = result.startTime;
      this.endTime = result.endTime;
      this.payNumber = result.totalPay;
    }
  },
  mounted () {
    this.getMess();
  },
};
</script>

<style scoped>
.showPlace {
  width: 700px;
  height: 660px;
  margin: 20px 0 0;
  overflow-y: auto;
  padding: 40px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(136, 136, 136, 0.319);
  box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.5);
  margin-left: 50%;
  transform: translate(-50%);
}
.payCertification {
  width: 600px;
  margin: 0 0 0 50%;
  transform: translate(-50%);
}
.el-descriptions__title {
  margin-left: 50%;
  transform: translate(-50%);
}
.deposit {
  width: 200px;
  margin-left: 400px;
}
.downLoad {
  margin: 20px 0 0 50%;
  transform: translate(-50%);
}
.danwei {
  font-size: 10px;
}
</style>