import { render, staticRenderFns } from "./articles_of_association.vue?vue&type=template&id=9b216474&scoped=true"
import script from "./articles_of_association.vue?vue&type=script&lang=js"
export * from "./articles_of_association.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b216474",
  null
  
)

export default component.exports