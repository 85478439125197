<template>
  <div class="route-content">
    <p class="title">
      <span>会员申请升级提交记录</span>
      <el-button @click="$router.push('/GIA_associator/upgrade_member')"
                 class="route-btn"
                 size="small"
                 type="primary">
        申请升级
      </el-button>
    </p>
    <el-table v-loading="loading"
              class="showTable"
              :data="showProduceList"
              style="width: 100%">
      <el-table-column v-for="item in tableList"
                       :prop="item.key"
                       align="center"
                       :key="item.key"
                       :label="item.name">
        <template slot-scope="scope">
          <el-popover v-if="item.key == 'photoUrl'">
            <gia-img :imgSrc="scope.row.photoUrl " />
            <el-button size="small"
                       slot="reference">查看图片</el-button>
          </el-popover>
          <span v-else-if="item.key == 'isAgree'">
            {{ scope.row.isAgree == true ? "同意":scope.row.isAgree == false ?"拒绝":"审核中" }}
          </span>
          <span v-else>
            {{ scope.row[item.key] }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import GiaImg from '../../components/giaImg.vue';
export default {
  components: { GiaImg },
  data () {
    return {
      // 添加表单
      addForm: {
        landName: null,
        landArea: null,
        organicMatterContent: null,
        waterContent: null,
        soilPhValue: null,
        cationExchangeCec: null,
        farmBaseId: null,
        landTestReport: null,
      },
      pageSize: 7,
      currentPage: 1,
      totalCount: 0,
      tableList: [
        { name: "审核的管理员", key: "manager" },
        { name: "之前的等级", key: "before" },
        { name: "之后的等级", key: "after" },
        { name: "第几届", key: "term" },
        { name: "会员申请理由 ", key: "note" },
        { name: "是否同意", key: "isAgree" },
        { name: "证件照", key: "photoUrl" },
      ],
      // 搜索
      showProduceList: [],
      rankList: [],
      loading: false,
    };
  },
  methods: {
    handleCurrentChange (currentPage) {
      this.$data.currentPage = currentPage;
      this.getRankRecord(currentPage)
    },
    async getRank () {
      const result = await this.$request.post({
        url: "/rank/showRanks",
      });
      this.$data.rankList = result;
    },
    async getRankRecord (currentPage) {
      this.$data.loading = true
      const { pageSize } = this.$data
      try {
        const resultList = await this.$request.post({
          url: "/rank/user/getRanks",
          data: {
            page: currentPage,
            size: pageSize
          }
        });
        const { list, totalCount } = resultList
        this.$data.showProduceList = list;
        this.$data.totalCount = totalCount;
        this.$data.loading = false;
      } catch (error) {
        this.$data.loading = false;
        this.$message({
          message: "serve net error!"
        })
      }
    },
  },
  mounted () {
    this.getRankRecord(1);
    this.getRank()
  },
};
</script>
<style lang="less" scoped>
.route-content {
  height: 100%;
  padding: 0.1px;
  .title {
    margin: 10px 0;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    position: relative;
    .route-btn {
      position: absolute;
      right: 40px;
    }
  }
  .showTable {
    height: calc(100% - 100px);
  }
  .pagination {
    .el-pagination {
      background-color: #fff !important;
      padding: 6px 30px;
    }
  }
  .tool-box {
    display: flex;
    justify-content: center;
  }
}
</style>