<template>
  <div style="padding:20px">

    <div class="return-home"
         style="position:fixed;top:20px;width:20px;height:20px;background:#fff "
         @click="$router.back(1)"> <i class="el-icon-arrow-left"></i> </div>

    <div class="GI_introductionContent">
      <p align="center"
         class="MsoNormal"
         style="text-align:center;line-height:20.0pt;&#10;layout-grid-mode:char">
        <span style="font-size:18.0pt;font-family:方正小标宋简体;&#10;color:black">广东省工业软件学会简介</span>
      </p>
      <p>
        广东省工业软件学会（Guangdong Industrial Software
        Federation）成立于2022年1月，登记管理机关为广东省民政厅，独立社团法人，广东省科学技术协会成员。
      </p>
      <p>
        学会是由广东省从事工业软件科研、教学、工程与应用相关的高等院校、科研院所、企业、产业园区等机构和专家自愿组成的学术性的非营利性社会团体。旨于践行社会主义核心价值观，弘扬爱国主义精神，深入落实国家软件发展战略，发挥科研机构、高校、高科技企业资源优势，主动服务国家战略需求，推动工业软件领域的知识普及和技术创新，促进工业软件科技成果转化与产业化，为助推我省及大湾区经济、社会发展发挥积极的作用。
      </p>
      <p>
        学会工作的出发点和落脚点是服务大湾区和业界专业人士，会员由广东省内个人会员和单位会员组成。其中个人会员为工业软件领域的专家、学者、从业人员，单位会员为从事工业软件领域教学、研究、应用的高等院校、科研院所或企业。
      </p>
      <p>
        学会是广东省工业软件及相关领域的学术团体，宗旨是为本领域专业人士的学术和职业发展提供服务；学会以学术引领产业服务为主导，推动工业软件领域专业人士的学术和职业发展；推动学术进步和技术成果的应用与转化；进行学术评价，引领学术方向；促进技术和产业应用学者、技术人员的交流和互动；对在学术和技术方面有突出成就的个人、企业和单位给予认可和表彰。主要工作包括：
      </p>

      <p>
        （一）服务国家战略，开展工业软件科学技术的创新研究，推动工业软件科研成果转化，承接相关机构委托的转向课题、项目；
      </p>
      <p>
        （二）服务经济社会，承接政府职能转移和购买服务，为政府提供工业软件、人工智能等领域高水平科技服务；
      </p>
      <p>
        （三）服务产业发展，制定工业软件科学技术标准与产业规划，为相关机构提供人才培训、职称评定、投融资与咨询服务；
      </p>
      <p>
        （四）提供交流平台，开展科学技术交流活动，举办国内外工业软件相关领域交流讨论、论坛、竞赛和展会活动，出版相关刊物与专业杂志。
      </p>
      <p>
        藉此，学会下设多个产业研究院，引领产业发展。依托立体多维的治理架构，服务于广东省、粤港澳大湾区、全国乃至全球的工业软件专业人士、企业、科研机构和组织，提升对专业人士的服务广度和拓展区域经济的服务深度，积极承担社会责任，关注产业、教育、人才、学术评价等公共话题，就重大政策及相关问题向政府建言。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
      content: {
        title: "GIA简介",
      },
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.GI_introductionContent {
  text-indent: 2em;
  font-size: 12px;
  line-height: 20px;
  p {
    margin: 5px 0;
  }
}
</style>