
<template>
  <div class="Group">
    <div class="receiveMessage">
      <div class="topSearch" style="min-width: 300px">
        <el-button icon="el-icon-arrow-down" @click="isTableExpanded = !isTableExpanded"><span style="font-size: 15px; ">
            {{ isTableExpanded ? '收起分组列表' : '展开分组列表' }}
          </span>
        </el-button>
      </div>
      <div class="inputContainer">
        <div>
          ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        </div>
        <label>添加分组： </label>
        <input v-model="groupName" placeholder="请输入添加分组名字">
        <el-button :disabled="isSubmitting" icon="el-icon-plus" type="primary" @click="submitForm">添加分组</el-button>
      </div>
      <div class="receiveFormPlace">

        <template v-if="isTableExpanded">
          <el-table :data="groups">

            <el-table-column prop="id" align="center" label="分组id" width="90">
            </el-table-column>

            <el-table-column prop="groupName" align="center" label="分组名字" width="100">
            </el-table-column>

            <el-table-column prop="createTime" align="center" label="创建时间" width="160">
            </el-table-column>

            <el-table-column prop="modifyTime" align="center" label="修改时间" width="160">
            </el-table-column>



            <!-- ...省略其他列 -->
            <el-table-column align="center" label="展开分组" width="200">
              <template slot-scope="scope">
                <div>
                  <el-button type="primary" icon="el-icon-arrow-down"
                    @click="ShowPageChange(scope.row.id, page, size)">展开</el-button><!-- 分组按钮 -->
                </div>
              </template>
            </el-table-column>

            <el-table-column align="center" label="分组管理" width="200">
              <template slot-scope="scope">
                <div>
                  <el-button icon="el-icon-delete" type="primary" @click="deleteGroup(scope.row.id)">删除分组</el-button>
                </div>
              </template>
            </el-table-column>



          </el-table>
        </template>






        <div class="controlContainer">






          <div class="messageContainer">
            <div v-if="isSubmitting" class="message">正在提交...</div>
            <div v-if="isSuccess" class="message">加载中...</div>
          </div>
        </div>



      </div>
      <div> </div>
      <div>
        ----------------------------------------------------------------------------------------------------------------------------------------------------------------
      </div>
      <div class="receiveFormPlace">
        <el-table :data="Groups">
          <el-table-column prop="groupId" align="center" label="分组id" width="70">
          </el-table-column>

          <el-table-column prop="expertId" align="center" label="专家id" width="70">
          </el-table-column>

          <el-table-column prop="expertName" align="center" label="专家名字" width="100">
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="创建时间" width="160">
          </el-table-column>

          <el-table-column prop="modifyTime" align="center" label="修改时间" width="160">
          </el-table-column>

          <el-table-column align="center" label="添加至分组" width="150">
            <template slot-scope="scope">
              <div>

                <div>

                  <select v-model="classsifyGroupId">
                    <option value="">请选择</option>
                    <option v-for="group in groups" :value="group.id">{{ group.id }}：{{ group.groupName }}</option>
                  </select>
                  <el-button :disabled="isSubmitting" icon="el-icon-plus" type="primary"  
                  @click="  assignClasssifyExpertName(scope.row.expertName, scope.row.expertId); classsifyGroup(classsifyGroupId, expertIdList);">添加到分组</el-button>
                </div>
                








              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" label="移除出分组" width="150">
            <template slot-scope="scope">
              <div>
                <el-button :disabled="isSubmitting" icon="el-icon-delete" type="primary"
                  @click=" moveGroup(scope.row.groupId, scope.row.expertId);">移除</el-button>

              </div>
            </template>
          </el-table-column>





        </el-table>


        <label class="inputLabel">页码：</label>
        <input v-model="page" placeholder="请输入数字">

        <label class="inputLabel">页大小：</label>
        <input v-model="size" placeholder="请输入数字">

        <label>{{ '总记录数(含重复)：' + totalCount }}</label>





      </div>

    </div>
  </div>
</template>





<script>
import axios from 'axios';

export default {
  name: "Group",
  data() {
    return {
      groups: [],// 将groups声明为一个空数组，用来存储获取到的分组数据
      Groups: [],    //展开分组
      totalList: [],
      isSuccess: false, //加载中？
      page: 1,
      size: 100,
      isSubmitting: false,
      groupName: "",
      isTableExpanded: true,
      groupId: 2,
      classsifyExpertName: "", // 用户输入的专家名称
      classsifyExpertId: "", // 用户输入的专家ID
      classsifyGroupId:1, // 用户输入的分组ID
      expertIdList: [],
      totalCount: 0, //总记录数



    }

  },
  computed: {},
  methods: {

    async moveGroup(groupId, expertId) {//移除出分组

      const resultList = await this.$request.post({
        url: `/group/move?groupId=${groupId}&expertId=${expertId}`,

      });
      this.$message.success('移除成功,请刷新页面');

      //location.reload(); // 重新加载页面
    },

    assignClasssifyExpertName(expertName, expertId) {
      this.classsifyExpertName = expertName;
      this.classsifyExpertId = expertId;

    },

    ShowPageChange: async function (groupId, page, size) {//查询某个分组
      this.isSuccess = true;

      const result = await this.$request.get({
        url: `/group/expertOfGroup?groupId=${groupId}&page=${page}&size=${size}`,
      });

      console.log(result);
      this.$message.success('展开成功');

      // 创建一个Map对象来存储已经出现的expertId
      const expertIds = new Map();
      const filteredGroups = [];

      for (const item of result.list) {
        if (!expertIds.has(item.expertId)) {
          expertIds.set(item.expertId, true);
          filteredGroups.push(item);
        }
      }
      this.totalCount = result.totalCount;
      console.log(filteredGroups);
      this.Groups = filteredGroups;

      this.isSuccess = false;
     // this.getAllGroups();
      // this.isTableExpanded = false;    是否展开后收起列表
    }
    ,



    getAllGroups: async function () {

      // 获取待处理或已处理的会员等级（头衔）变动列表
      this.isSuccess = true;
      const result = await this.$request.get({
        url: '/group/allGroup',

      });

      console.log(result); // 添加这一行
      this.groups = result.list; // 修改部分：将获取到的分组数据赋值给groups数组

      console.log(this.groups);
      this.isSuccess = false;

    },

    async newGroup(groupName) {//添加分组

      const resultList = await this.$request.post({
        url: `/group/newGroup?groupName=${groupName}`,

      });
      this.$message.success('添加分组成功，请刷新页面');
      location.reload(); // 重新加载页面
    },

    async deleteGroup(groupId) {
      try {
        const confirmed = confirm('确定要删除该分组吗？'); // 弹出确认框

        if (!confirmed) {
          return; // 用户取消删除操作
        }

        const resultList = await this.$request.post({
          url: `/group/deleteGroup?groupId=${groupId}`,
        });

        this.$message.success('删除分组成功，请刷新页面');
        location.reload(); // 重新加载页面
      } catch (error) {
        console.error(error);
      }
    },


    classsifyGroup: async function (groupId, expertIdList) {
      if (this.isSubmitting) {
        return; // 如果已经有一个请求正在进行中，则不执行后续的请求
      }
      this.isSubmitting = true; // 将变量设置为正在提交请求的状态
      const expert = {
        expertName: this.classsifyExpertName,
        expertId: this.classsifyExpertId,

      };
      this.expertIdList.push(expert);
      try {
        const response = await this.$request.post({
          url: '/group/classify',
          data: {
            groupId,
            expertIdList, // 将当前专家的信息作为单独的数组元素传递,
          },
        });
        this.expertList = response;
        // 处理返回的数据
        console.log(response);
        this.$message.success(`专家添加到分组成功`);
        // 根据需要进行其他操作
        setTimeout(() => {
          this.isSubmitting = false; // 10秒后将变量设置为未提交请求的状态
        }, 2000);
        this.expertIdList = []; // 清空数组

      } catch (error) {
        // 发生错误时的处理
        console.error(error);
      }
    },


    submitForm() {
      if (this.isSubmitting) {
        return; // 如果正在提交，则不执行后续代码
      }
      this.isSubmitting = true;

      // 执行你的提交操作，即原来的 newGroup(groupName) 函数

      // 在操作完成后，将 isSubmitting 设置为 false，以允许下一次提交
      setTimeout(() => {
        // 模拟操作完成后的回调
        this.isSubmitting = false;

        // 其他的逻辑处理

        // 调用你的 newGroup(groupName) 函数
        this.newGroup(this.groupName);
      }, 3000); // 这里的2000表示操作完成需要的时间，单位是毫秒
    }





  },
  mounted() {
    this.getAllGroups(); // 初始化时调用展示列表信息
  }
};
</script>
<style lang="css" scoped="true">
.inputLabel {
  color: #999;
  font-size: 14px;
}

.input {
  color: #666;
  font-size: 12px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.controlContainer {
  position: fixed;
  bottom: 0;
  right: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
