<template>
  <div>
    <div class="GIAIntroduction">
      <!-- <div class="GI_introductionTitle"> -->
        <!-- <p>{{ content.title }}</p> -->
        <!-- <div class="GI_I_titleLine"></div> -->
      <!-- </div> -->
      <div class="GI_introductionContent">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "article",
  props: {
    content: Object,
  },
  data() {
    return {
      isCollected: true,
    };
  },
  methods: {
    collection() {
      this.isCollected = !this.isCollected;
    },
  },
};
</script>
<style lang="less" scoped>
.GIAIntroduction {
  width: 100%;
  border: 2px solid rgba(17, 34, 51, 0.252);
  margin-bottom: 100px;
  .GI_introductionTitle {
    margin: 30px 0;
    text-align: center;
    p {
      font-weight: 600;
      font-size: 30px;
    }
    .GI_secondTitle {
      margin-left: 50%;
      transform: translate(-50%);
      width: 450px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-around;
      color: #888;
      .GI_ST_collections {
        height: 50px;
        display: flex;
        .el-icon-star-on {
          font-size: 25px;
          display: block;
          margin: 0 3px;
          line-height: 50px;
        }
        div {
          color: #000;
        }
        .collected {
          color: rgb(255, 225, 0);
        }
      }
    }
    .GI_I_titleLine {
      width: 100%;
      height: 0;
      border: 1px solid rgba(17, 34, 51, 0.252);
      margin: 20px 0;
    }
  }
  .GI_introductionContent{
    padding: 50px;
  }
}
</style>