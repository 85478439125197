<template>
  <div class="self-top-box">
    <div class="self-top-nav">
      <ul @mouseleave="nowHover = ''" class="ful">
        <!-- 循环头部栏 -->
        <li
          class="fli"
          v-for="(routeItem, index1) in this.$store.state.routerlist"
          @mouseenter="nowHover = index1"
          :class="
            nowHover === index1 || routeItem.route == '/'+ routePath[1]
              ? 'hover'
              : ''
          "
          :key="index1"
        >
          <router-link to="" class="route-1">{{ routeItem.title }}</router-link>
          <!-- 二级表单 -->
          <ul
            class="sul"
            v-if="routeItem.children"
            :style="{
              height:
                nowHover === index1 ? `${routeItem.children.length * 36}px` : 0,
              overflow: nowHover === index1 ? '' : 'hidden',
            }"
          >
            <li
              class="sli"
              v-for="(routeItem2, index2) in routeItem.children"
              :key="index2"
            >
              <router-link
                class="route-2"
                :to="`${routeItem.route}${routeItem2.to}`"
                >{{ routeItem2.title }}</router-link
              >
            </li>
          </ul>
        </li>
      </ul>
      <div class="menu-right-nav">
        <router-link :to="{
             path: '/partyBuilding/PartyBuildingActivities',
            }"
                >学会党建
                <!-- path: '/partyBuilding/PartyBuildingActivities', -->
        </router-link></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nowHover: "",
      //
    };
  },
  props: {
    routePath: Array,
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="less" scoped>
.self-top-box {
  .self-top-nav {
    background-color: #006699;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .ful {
      display: flex;
      .fli {
        position: relative;
        transition: 0.3s;
        .route-1 {
          display: block;
          font-size: 18px;
          color: #fff;
          font-weight: bold;
          line-height: 1.5em;
          overflow: hidden;
          position: relative;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 15px;
          padding-right: 15px;
          -webkit-transition: 0.3s;
          -moz-transition: 0.3s;
          -ms-transition: 0.3s;
          -o-transition: 0.3s;
          transition: 0.3s;
        }
        .sul {
          position: absolute;
          top: 50px;
          left: 0;
          z-index: 1060;
          width: 160px;
          background-color: #fff;
          -webkit-transition: 0.1s;
          -moz-transition: 0.1s;
          -ms-transition: 0.1s;
          -o-transition: 0.1s;
          transition: 0.1s;
          .sli {
            display: flex;
            justify-content: space-between;
            position: relative;
            font-size: 16px;
            color: #000;
            line-height: 35px;
            border-bottom: 1px #dde0e4 solid;
            padding: 0 15px;
            white-space: nowrap;
            text-overflow: ellipsis;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            -ms-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            .route-2 {
              display: block;
            }

            &:hover {
              color: #1677cb;
            }
            &:hover > .tul {
              display: block;
            }
          }
        }
      }
      .fli.hover {
        background-color: #ed6c23;
      }
    }
    .menu-right-nav {
      display: block;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      line-height: 1.5em;
      overflow: hidden;
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 15px;
      float: right;
      font-size: 20px;
      font-family: "楷体", "楷体_GB2312";
    }
  }
}
</style>