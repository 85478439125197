<template>
  <div
    class="register-content"
    :style="{ backgroundImage: 'url(/image/background.png)' }"
  >
    <div class="header-box">
      <div class="header-title">
        <div class="header-left">
          <img src="../image/weblogo.jpg" />
          <router-link to="/login" class="left-title">返回登录</router-link>
        </div>
        <span class="header-right">忘记密码</span>
      </div>
    </div>
    <div class="register-form-box">
      <p class="title">
        <span>重设密码</span>
      </p>
      <el-form
        ref="forgetRef"
        :rules="rules"
        :model="forgetForm"
        label-width="120px"
      >
        <el-form-item label="验证方式:">
          <el-radio-group v-model="authentication" size="small">
            <el-radio-button label="mobilePhone">手机号</el-radio-button>
            <el-radio-button label="email">邮箱</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="authentication == 'email'"
          label="邮箱:"
          prop="email"
        >
          <el-input
            placeholder="请输入邮箱"
            type="email"
            v-model="forgetForm.email"
          ></el-input>
        </el-form-item>
        <el-form-item v-else label="手机号:" prop="mobilePhone">
          <el-input
            placeholder="请输入手机号"
            type="mobilePhone"
            v-model="forgetForm.mobilePhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码:" class="code" prop="code">
          <el-input
            class="codeInput"
            placeholder="验证码"
            v-model="forgetForm.code"
            type="code"
            maxlength="8"
          ></el-input>
          <el-button
            class="code-send"
            type="primary"
            @click="() => sendCode()"
            :disabled="timeout ? true : false"
            >{{ timeout ? timeout + "s" : "获取验证码" }}</el-button
          >
        </el-form-item>
        <el-form-item label="设置密码:" prop="password">
          <el-input
            placeholder="请输入密码,数字和字母组合"
            type="password"
            v-model="forgetForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="resure">
          <el-input
            placeholder="请再次输入密码"
            type="password"
            v-model="forgetForm.resure"
          ></el-input>
        </el-form-item>
        <el-button class="register-btn" @click="() => findPwd()" type="primary"
          >提交修改</el-button
        >
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timeout: 0,
      authentication: "email",
      forgetForm: {
        // 密码
        password: "",
        // 确认密码
        resure: "",
        // 验证方式
        // 邮箱
        email: "",
        // 手机号
        mobilePhone: "",
        // 验证码
        code: "",
        // 确认阅读
        sureRead: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        resure: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              // 判断是否密码相同
              const { password } = this.$data.forgetForm;
              if (value !== password) {
                callback(new Error("密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
          {
            type: "email",
            message: "邮箱格式错误",
            trigger: "blur",
          },
        ],
        mobilePhone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, cb) => {
              let reg =
                /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
              if (reg.test(value)) return cb();
              cb(new Error("手机号码格式不正确"));
            },
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    sendCode() {
      const { authentication, forgetForm } = this.$data;
      this.$refs["forgetRef"].validateField(authentication, async (valid) => {
        if (!valid) {
          // 验证的值
          this.$data.timeout = 60;
          this.sendCodeInterval = setInterval(() => {
            this.$data.timeout -= 1;
            if (this.$data.timeout == 0) {
              clearInterval(this.sendCodeInterval);
              this.sendCodeInterval = null;
            }
          }, 1000);
          try {
            const checkingNumber = forgetForm[authentication];
            if (authentication == "email") {
              await this.$request.post({
                url: "/user/sendCode/" + checkingNumber,
              });
            } else {
              await this.$request.post({
                url: "/user/phone/code",
                header: {
                  "Content-Type": "multipart/form-data",
                },
                data: {
                  phone: "+86" + checkingNumber,
                },
              });
            }
          } catch (error) {
            this.$data.timeout = 0;
            clearInterval(this.sendCodeInterval);
            this.sendCodeInterval = null;
          }
        }
      });
    },
    findPwd() {
      // 调用父组件的changeActive并切换路由
      this.$refs["forgetRef"].validate(async (valid) => {
        if (valid) {
        //   const {
        //     authentication,
        //     forgetForm,
        //     forgetForm: { password, code },
        //   } = this.$data;
          
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.register-content {
  position: relative;
  overflow: auto;
  min-width: 1536px;
  min-height: 750px;
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  .header-box {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: calc(var(--logoSize) + 10px);
    --logoSize: 80px;
    box-shadow: 0 2px 5px 1px rgba(204, 204, 204, 0.3);
    .header-title {
      max-width: 1200px;
      margin: 5px auto;
      width: 100%;
      display: flex;
      align-items: center;
      .header-left {
        display: flex;
        align-items: center;
        img {
          width: var(--logoSize);
          height: var(--logoSize);
        }
        .left-title {
          font-size: 30px;
          font-weight: 600;
          color: rgba(30, 50, 150);
        }
      }
      .header-right {
        margin-left: 15px;
        font-size: 28px;
        font-weight: 600;
        color: #5c5b5b;
        padding-left: 10px;
        border-left: 3px solid #6b6b6b80;
      }
    }
  }
  .register-form-box {
    margin-top: 50px;
    background-color: #fff;
    width: 600px;
    max-height: 550px;
    border-radius: 8px;
    padding: 10px 30px;
    overflow: auto;
    box-shadow: 2px 2px 4px 1px #cccccc46;
    .title {
      margin: 5px 0;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      position: relative;
      .iconfont {
        font-size: 25px !important;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    .register-btn {
      margin: 5px auto;
      display: block;
    }
    .regulations {
      color: #409eff;
    }
    .code {
      position: relative;
    }
    .code-send {
      position: absolute;
      right: 0;
    }
  }
}
.photo-select {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 5px;
}
</style>
