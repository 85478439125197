<template>
  <div class="member-page">
    <left-menu-nav :nowRoute="nowRoute" />
    <router-view class="route-contain"></router-view>
  </div>
</template>
<script>
import leftMenuNav from "../../components/home/leftMenuNav.vue";
export default {
  components: { leftMenuNav },
  data() {
    return {
      nowRoute: {
        title: "会员",
        route: "/member",
        children: [
          {
            title: "个人会员",
            to: "/individual_member",
          },
          {
            title: "单位会员",
            to: "/group_member",
          },
          {
            title: "如何加入",
            to: "/how_join",
          },
        ],
      },
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.member-page {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  .route-contain {
    width: 1000px;
  }
}
</style>