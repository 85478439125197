import { render, staticRenderFns } from "./Set_home_article.vue?vue&type=template&id=30af1cae&scoped=true"
import script from "./Set_home_article.vue?vue&type=script&lang=js"
export * from "./Set_home_article.vue?vue&type=script&lang=js"
import style0 from "./Set_home_article.vue?vue&type=style&index=0&id=30af1cae&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30af1cae",
  null
  
)

export default component.exports