<template>
  <div class="homeBox">
    <div class="homePage">
      <div class="topNav">
        <ul>
          <li>
            <router-link v-if="hasLogin"
                         to="/GIA_associator">个人主页</router-link>
            <router-link v-else
                         to="/login">会员登录</router-link>
          </li>
          <li class="line"></li>
          <li>
            <router-link
                         to="/login_">专家系统</router-link></li>
          <li>GIA招聘</li>
          <li>常见问题</li>
          <li>联系我们</li>
          <li>站点地图</li>
          <li>English</li>
        </ul>
        <router-link v-if="!isHome"
                     to="/"
                     class="toHome"><i class="el-icon-d-arrow-left"></i> 回到首页</router-link>
      </div>
      <div class="logoAndSearch">
        <div class="logo">
          <img class="logo-img"
               src="../image/weblogo.jpg" />
          <div>
            <p class="name">广东省工业软件学会</p>
            <p class='smallLogoName'>Guangdong Industrial Software
              Federation</p>
          </div>
          <div class='titleService'>
            为广东省工业软件相关领域的专业人士服务
          </div>
        </div>
      </div>
      <top-menu-nav :routePath="routePath" />
      <div class="mainPlace">
        <router-view></router-view>
      </div>
    </div>
    <home-footer />
  </div>
</template>


<script>
//#region
import homeFooter from "../components/home/homeFooter.vue";
import topMenuNav from "../components/home/topMenuNav.vue";
export default {
  components: {
    homeFooter,
    topMenuNav,
  },
  data () {
    return {
      input2: "",
      // 是不是首页
      isHome: true,
      // 当前的路由
      routePath: [],
      hasLogin: false
    };
  },
  watch: {
    // 处理路由跳转
    $route: {
      handler (to) {
        if (to.path != "/") {
          this.$data.isHome = false;
        } else {
          this.$data.isHome = true;
        }
        this.$data.routePath = to.path.substr(1).split("/");
        // 处理头部
        document.documentElement.scrollTop = 0;
      },
    },
  },
  mounted () {
    // this.judgeLogin()
    const { path } = this.$route;
    if (path != "/") {
      this.$data.isHome = false;
    } else {
      this.$data.isHome = true;
    }
    if(localStorage.GIA_token){
      this.$data.hasLogin = true
    }else{
      this.$data.hasLogin = false
    }
    this.$data.routePath = path.substr(1).split("/");
  },
  methods: {
    handleCommand (command) {
      this.$message("click on item " + command);
    },
    // async judgeLogin () {
    //   try {
    //     const result = await this.$request.get({
    //       url: "/user/judge/token"
    //     })
    //     const { flag, newToken } = result
    //     if (flag == true) {
    //       this.$data.hasLogin = true
    //       localStorage.GIA_token = newToken
    //     }
    //   } catch (error) {
    //     this.$data.hasLogin = false
    //   }
    // }
  },
};
//#endregion
</script>
<style lang="less" scoped>
.homeBox {
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: #e9e9e947;
  .homePage {
    width: 1140px;
    margin: 0 auto;
    /* 顶部导航条 */
    .topNav {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      background-color: rgba(128, 213, 255, 0.422);
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        // width: 100%;
        display: flex;
        flex-direction: row-reverse;
        li {
          margin: 5px 20px;
          height: 30px;
          line-height: 30px;
          font-weight: 600;
          color: rgb(0, 74, 148);
        }
        .line {
          margin: 5px 0;
          border: 1px solid rgb(0, 74, 148);
        }
      }
      .toHome {
        line-height: 40px;
        font-weight: 600;
        color: rgb(0, 74, 148);
        padding-left: 10px;
      }
    }
    /* 顶部导航条 */
    /* 图标、搜索、加入 */
    .logoAndSearch {
      display: flex;
      height: 100px;
      justify-content: space-between;
      background-color: #fff;
      .logo {
        display: flex;
        color: rgb(0, 74, 148);
        font-weight: 600;
        align-items: center;
        .logo-img {
          width: 100px;
          height: 100px;
        }
        .name {
          font-size: 34px;
          margin-right: 20px;
        }
        .smallLogoName {
          font-size: 14px;
        }
        .titleService {
          font-size: 17px;
          margin-top: 20px;
        }
      }
      .search {
        .el-input {
          transform: translate(0, 80%);
        }
      }
      .joinUs {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>