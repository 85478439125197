<template>
  <div class="left-nav-box">
    <div class="form-top">
      <span>您当前的位置:</span>
      <ul class="route-list">
        <li class="route-item">
          <router-link to="/">首页</router-link>
        </li>
        <li class="route-item">
          <span>{{
            nowRoute.title
          }}</span>
        </li>
        <li class="route-item">
          <span>{{ nowRoute.children[nowActive].title }}</span>
        </li>
      </ul>
    </div>
    <div class="left-nav">
      <ul class="left-route-list">
        <li
          class="left-route-item"
          v-for="(item, index) in nowRoute.children"
          :key="index"
          :class="`${index == nowActive ? 'active' : ''}`"
        >
          <router-link :to="`${nowRoute.route}${item.to}`">
            <span>{{ item.title }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nowActive: 0,
    };
  },
  props: {
    nowRoute: Object,
  },
  watch: {
    // 处理路由跳转
    $route: {
      handler(to) {
        const { path } = to;
        const { children } = this.$props.nowRoute;
        const __route = path.split("/")[2];
        // 处理头部
        children.forEach((item, index) => {
          if (item.to == '/'+__route) {
            this.$data.nowActive = index;
          }
        });
      },
    },
  },
  mounted() {
    const {
      $props: {
        nowRoute: { children },
      },
      $route: { path },
    } = this;
    const __route = path.split("/")[2];
    children.forEach((item, index) => {
      if (item.to == '/'+__route) {
        this.$data.nowActive = index;
      }
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
// 下方页头栏
.left-nav-box {
  width: 150px;
  padding: 0 10px;
  position: relative;
  .form-top {
    display: flex;
    position: absolute;
    top: -40px;
    width: 500px;
    left: 0;
    .route-list {
      display: flex;
      .route-item {
        padding: 0 10px;
        position: relative;
        &:after {
          content: ">";
          color: #000;
          display: block;
          position: absolute;
          right: -5px;
          top: 0;
          font-size: 15px;
        }
        &:last-child::after {
          content: "";
        }
      }
    }
  }
  .left-nav {
    .left-route-list {
      .left-route-item {
        display: block;
        line-height: 28px;
        font-size: 16px;
        color: #000;
        text-align: left;
        border: 1px #dad6d6 solid;
        background-color: #fff;
        padding: 0 5px;
        margin-bottom: 3px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .active {
        color: #26569d;
      }
    }
  }
}
</style>