<template>
  <div class="paymentRecord"
       v-loading="isSuccess">
    <div class="receiveMessage">
      <div class="topSearch"
           style="min-width: 1000px">
        <div>
          <span>会员类型：</span>
          <el-select v-model="rankList"
                     class="selectT"
                     placeholder="请选择会员类型"
                     @change="typeChange">
            <el-option v-for="(data, index) in rankLists"
                       :key="index"
                       :value="data.id"
                       :label="data.rank"></el-option>
          </el-select>
        </div>
        <div style='display:none'>
          <span>姓名：</span>
          <el-input v-model="searchMember"
                    type="text"
                    size="normal"
                    placeholder=" 请输入会员姓名"
                    style="width: 200px" />
        </div>
        <el-button style='display:none'
                   type="primary"
                   size="small"
                   icon="el-icon-search"
                   @click="searchMess">搜索</el-button>
      </div>
      <div class="receiveFormPlace">
        <el-descriptions class="a-description"
                         :column="4"
                         :size="size"
                         border
                         v-for="(item,index) in showTableData"
                         :key="index"
                         :title="item.name">
          <el-descriptions-item>
            <template slot="label">
              会员类型
            </template>
            {{ item.vipType == 1 ? "个人会员" : "企业会员" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              账号状态
            </template>
            {{ item.accountStatus == 1 ? "禁用" : "正常" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              会员号
            </template>
            {{ item.vipNumber}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              推荐人的会员号
            </template>
            {{ item.recommender}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              电子邮箱
            </template>
            {{ item.email}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              通讯地址
            </template>
            {{ item.address}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              手机号
            </template>
            {{ item.mobilePhone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              办公电话
            </template>
            {{ item.telephone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              入会渠道
            </template>
            {{ item.channel}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              注册备注
            </template>
            {{ item.note}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              总的缴费金额
            </template>
            {{ item.totalPay}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              正式会员生效时间
            </template>
            {{ item.startTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              正式会员有效日期至
            </template>
            {{ item.endTime}}
          </el-descriptions-item>
        </el-descriptions>
        <hr style="border-top: 2px dotted rgb(200, 199, 200, 0); margin: 10px 0" />
      </div>
    </div>
  </div>
</template>

<script>
// import StuMana
// import pubsub from 'pubsub-js'
export default {
  name: "payRecord",
  data () {
    return {
      isSuccess: false, //加载中？
      // pageSize: 5, //每页数据量
      // pageSizes: [5, 15, 30, 50], //可选每页数据量
      showTableData: [], //展示的数据
      // total: 0, //全部数据量
      // pageNow: 1, //当前所处页面
      searchMember: "", //搜索输入的会员名
      editMemberId: "", //编辑行会员id
      rankList: 1, //选中的头衔
      rankLists: [], //头衔列表
    };
  },
  computed: {},
  methods: {
    // 根据传入的rankid获取对应的职位名字
    rankName (rankId) {
      let { rankLists } = this.$data;
      for (let i = 0; i < rankLists.length; i++) {
        if (rankLists[i].id == rankId) {
          return rankLists[i].rank;
        }
      }
    },
    // val为选择的每页长度
    handleSizeChange (val) {
      this.pageSize = val;

    },
    // val为选择的第几页
    handleCurrentChange (val) {
      this.pageNow = val;

    },
    searchMess () {
      if (!this.searchMember) {
        this.$message({
          message: "请输入搜索内容！",
          type: "error",
        });
      } else {
        this.$message({
          message: "未添加根据名称获取职位会员信息接口！",
          type: "error",
        });
      }
    },
    // 不同会员类型展示数据
    typeChange: async function (rankId) {
      this.isSuccess = true;

      const result = await this.$request.post({
        url: `/rank/showUsers?id=${rankId}`,
      });
      this.showTableData = result;
      this.isSuccess = false;
    },
  },
  async mounted () {
    // 获取全部职位信息
    const result1 = await this.$request.post({
      url: "/rank/showRanks",
    });
    this.rankLists = result1;
    // 默认展示全部会员--获取全部职位信息
    this.typeChange(1);
  },
};
</script>

<style lang="css" scoped="true">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.a-description {
  margin: 20px;
}
.el-button {
  padding: 5px 10px;
}
.paymentRecord {
  width: 1230px;
  background-color: #fff;
  min-width: 1000px;
  overflow: scroll;
}

.title {
  margin-top: 30px;
  width: 300px;
  height: 70px;
  background-color: rgba(133, 219, 255, 0.871);
  border-radius: 10px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  line-height: 70px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-shadow: -1px -2px 1px rgb(0, 0, 0);
}
.topSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.receiveFormPlace {
  margin-top: 30px;
}
.receiveFormPlace * {
  text-align: center;
}
.receiveMessage {
  width: 1230px;
  box-shadow: 2px 2px 5px rgba(17, 34, 51, 0.4);
  padding: 50px 100px;
  overflow: auto;
}
.receiveFormPlace {
  width: 1180px;
  overflow-x: scroll;
  margin-left: 50%;
  transform: translate(-50%);
}
.receiveFormPlace a {
  text-decoration: none;
  color: #123;
  width: 60px;
  margin-right: 5px;
}
.selectT {
  min-width: 60px;
  max-height: 40px;
}
</style>
