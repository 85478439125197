import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const actions = {
}
const mutations = {
  ADD_PERSON (state, value) {

    state.wholeData.unshift(value)

  },
  Change_PERSON (state, value) {

    for (let i = 0; i < state.wholeData.length; i++) {
      if (state.wholeData[i].num == value.num) {
        state.wholeData[i] = value
        break;
      }
    }
  },
}
// 首页的路由
const homeTopRoutes = [
  {
    title: "关于GISF",
    route: "/about",
    children: [
      {
        title: "学会简介",
        to: "/introduct",
      },
      {
        title: "组织架构",
        to: "/institutionalFramework",
      },
      {
        title: "学会章程",
        to: "/purpose_charter",
      },
      {
        title: "如何入会",
        to: "/howtojion",
      },
      {
        title: "发展历程",
        to: "/history",
      },
      {
        title: "理事会",
        to: "/council",
      },
      {
        title: "学会大事记",
        to: "/Recording",
      },
      {
        title: "年报",
        to: "/annual_report",
      },
     // {
       //  title: "学会宗旨章程",
      //   to: "/purpose_charter",
      // },
    ]
  },
  {
    title: "行业政策",
    route: "/news",
    children: [
      {
        title: "最新通知",
        to: "/GIA_redirect"
      },
      {
        title: "政策解读",
        to: "/PolicyInterpretation"
      }
    ]
  },{
    title: "评价奖励",
    route: "",
    children: [
      {
        title: "成果评价"
      },
      {
        title: "相关奖励"
      },
    ]
  },
  {
    title: "技术标准",
    route: "",
    children: [
      {
        title: "团体标准"
      },
      {
        title: "行业标准"
      },
    ]
  },
  {
    title: "职称评审",
    route: "",
    children: [
      {
        title: "申报条件"
      },
      {
        title: "相关政策"
      },
      {
        title: "申报入口"
      },
      {
        title: "自助查询"
      },
    ]
  },
  {
    title: "专家库",
    route: "/expertLibrary",
    children: [
      {
        title: "专家申请入库",
        to: "/expertApply",
      }
      

    ]
  },
  /*{
    title: "合作",
    route: "",
    children: [

    ]
  }*/
  /*{
    title: "会员",
    route: "/member",
    children: [
      {
        title: "个人会员",
        to: "/individual_member",
      },
      {
        title: "单位会员",
        to: "/group_member",
      },
      {
        title: "如何加入",
        to: "/how_join"
      }
    ]
  },*/
  
]


const state = {
  // 首页头部路由数据
  routerlist: homeTopRoutes,
  userInfo: { sex: {} },//当前用户信息
  userInfo_canWrite: {
    sex: {}
  },// 可修改的信息
  user: {},
  registerUserRes: [],//待审批的注册用户
  expertid:'',
}
const getters = {
}

//创建并暴露store
export default new Vuex.Store({
  actions,
  mutations,
  state,
  getters
})