<template>
  <div class="route-fixed-box">
    <div style="width: 100%; height: 100%">
      <el-backtop :bottom="60">
        <i class="el-icon-caret-top"></i>
      </el-backtop>
      <router-view class="route-box" />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data () {
    return {};
  },
  methods: {},
  computed: {},
  watch: {},
  beforeDestroy () { }
};
</script>

<style lang="less">
@import url("./css/iconfont.css");
* {
  --buttonColor: rgb(71, 71, 236);
}
.el-tabs__item.is-active {
  color: var(--buttonColor) !important;
}
.el-tabs__active-bar {
  background-color: var(--buttonColor) !important;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.app {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
ol,
ul {
  list-style: none;
}
input,
textarea {
  -webkit-appearance: none;
}
blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
.el-message-box {
  width: auto;
}
.v-modal {
  max-width: 100vw;
}
.el-message-box__wrapper {
  max-width: 100vw;
}
.el-loading-mask.is-fullscreen {
  max-width: 100vw;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a:active {
  text-decoration: none;
  color: inherit;
}

textarea {
  resize: none;
}

*:hover {
  &::-webkit-scrollbar-thumb {
    display: block;
  }
}

*::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  display: none;
  background: rgba(204, 204, 204, 0.65);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(204, 204, 204, 1);
}
.el-table::before {
  width: 0;
  height: 0;
}
.route-fixed-box {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  background-color: #fff;
  .route-box {
    background-color: #fff;
    position: absolute;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }
}
</style>