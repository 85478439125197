<template>
  <div class="info-box">
    <p class="title">个人信息</p>
    <el-form ref="form" :model="form" class="base-form">
      <el-form-item class="form-item" label="姓名" label-width="180px">
        <el-input size="small" :placeholder="form.name"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="性别" label-width="180px">
        <el-select v-model="form.value" :placeholder="form.sex.label">
          <el-option
            v-for="item in form.sex"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="民族" label-width="180px">
        <el-input size="small" :placeholder="form.nation"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="身份证" label-width="180px">
        <el-input size="small" :placeholder="form.idNumber"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="毕业院校" label-width="180px">
        <el-input size="small" :placeholder="form.graduationInstitution"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="专业" label-width="180px">
        <el-input size="small" :placeholder="form.major"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="学历/学位" label-width="180px">
        <el-input size="small" :placeholder="form.educationDegree"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="毕业时间" label-width="180px">
        <el-date-picker
          v-model="form.value1"
          type="datetime"
          :placeholder="form.graduationTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item" label="现工作单位" label-width="180px">
        <el-input size="small" :placeholder="form.currentEmployer"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="现工作单位行业分类"
        label-width="180px"
      >
        <el-input size="small" :placeholder="form.jobClassification"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="职务" label-width="180px">
        <el-input size="small" :placeholder="form.duties"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="职称等级" label-width="180px">
        <el-input size="small" :placeholder="form.professionalQualifications"></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="是否是两院院士"
        label-width="180px"
      >
        <el-radio v-model="form.isAcademician" label="1">是</el-radio>
        <el-radio v-model="form.isAcademician" label="0">否</el-radio>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="是否博士生导师"
        label-width="180px"
      >
        <el-radio v-model="form.isDoctoralsupervisor" label="1">是</el-radio>
        <el-radio v-model="form.isDoctoralsupervisor" label="0">否</el-radio>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="是否硕士生导师"
        label-width="180px"
      >
        <el-radio v-model="form.isMastersupervisor" label="1">是</el-radio>
        <el-radio v-model="form.isMastersupervisor" label="0">否</el-radio>
      </el-form-item>
      <el-form-item class="form-item" label="专业领域" label-width="180px">
        <el-input size="small" :placeholder="form.professionalField"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="办公电话" label-width="180px">
        <el-input size="small" :placeholder="form.officePhone"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="手机" label-width="180px">
        <el-input size="small" :placeholder="form.mobilePhone"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="联系地址" label-width="180px">
        <el-input size="small" :placeholder="form.address"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="邮编" label-width="180px">
        <el-input size="small" :placeholder="form.zipCode"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="电子邮箱" label-width="180px">
        <el-input size="small" :placeholder="form.email"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="个人简介" label-width="180px">
        <el-input style="width: 650px;" type="textarea"  v-model="form.achievem"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import giaImg from "../../components/giaImg.vue";
export default {
  components: { giaImg },
  data() {
    return {
      baseInfo: {
        vipType: {
          label: "会员类型",
          type: "object",
        },
        vipRank: {
          label: "会员级别",
          type: "object",
        },
        startTime: {
          label: "生效时间",
        },
        endTime: {
          label: "有效期至",
        },
        recommender: {
          label: "推荐人的会员号",
        },
        totalPay: {
          label: "总的缴费金额(分)",
        },
        vipNumber: {
          label: "会员号",
        },
        channel: {
          label: "入会渠道",
        },
      },
      showImg: {
        companyCert: {
          label: "企业机构代码证书",
        },
        legalPersonIdCart: {
          label: "法人身份证图片",
        },
        legalPersonCert: {
          label: "法人营业证明",
        },
      },
      form:{
        sex: [
        {
          value: "0",
          label: "男",
        },
        {
          value: "1",
          label: "女",
        },
      ],
      value: "",
      value1: "",
      name:"",
      nation:"",
      idNumber:"",
      graduationInstitution:"",
      major:"",
      graduationTime:"",
      currentEmployer:"",
      jobClassification:"",
      duties:"",
      professionalQualifications:"",
     isAcademician:'0',
      isDoctoralsupervisor:'0',
      isMastersupervisor:'0',
      professionalField:"",
      educationDegree:"",
      officePhone:"",
      mobilePhone:"",
      address:"",
      zipCode:"",
      email:"",
      personalProfile:"",
      achievem:""

      },
      expertid:''

      
    };
  },
  methods: {
    async getUserInfo (id) {
        
      const result = await this.$request.get({
        url: `/expert/detailInfo?userId=${id}`,
         headers: {
             Authorization: "Bearer " + localStorage.GIA_token,
           },
      });
      this.form.name=result.name;
      this.form.sex.value=result.gender;
      this.form.address=result.address;
      this.form.currentEmployer=result.currentEmployer;
      this.form.duties=result.duties;
      this.form.email=result.email;
      this.form.graduationTime=result.graduationTime;
      this.form.idNumber=result.idNumber;
      this.form.isAcademician=result.isAcademician;
      this.form.isDoctoralsupervisor=result.isDoctoralsupervisor;
      this.form.isMastersupervisor=result.isMastersupervisor;
      this.form.jobClassification=result.jobClassification;
      this.form.major=result.major;
      this.form.mobilePhone=result.mobilePhone;
      this.form.nation=result.nation;
      this.form.officePhone=result.officePhone;
      this.form.personalProfile=result.personalProfile;
      this.form.professionalField=result.isDoctoralsupervisorprofessionalField;
      this.form.graduationInstitution=result.graduationInstitution;
      this.form.professionalQualifications=result.professionalQualifications;
      this.form.zipCode=result.zipCode;
      this.form.achievem=result.achievem;


    },
  },
  mounted () {
    this.expertid=this.$store.state.expertid;
    console.log(this.expertid,'zzzzzzz');
    this.getUserInfo(this.expertid);
  },
  computed: {
    personalInfo() {
      return this.$store.state.userInfo;
    },
  },
};
</script>
<style lang="less" scoped>
.info-box {
  padding: 24px;
  .title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 5px 0;
  }
  .base-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .form-item {
      .el-input {
        width: 205px;
      }
      .image-box {
        width: 205px;
      }
      .textarea {
        flex: 1;
      }
    }
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }
  .img-box {
    display: flex;
    justify-content: space-around;
  }
  .tools-box {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }
}
</style>