<template>
  <div>
    <div class="search">
      <el-input
      v-model="searchValue"
      size="medium"
      clearable
      placeholder="请输入专家姓名"
      style="width: 250px"
      class="search_input"
    ></el-input>
    <el-button type="primary" size="medium" class="search_btn" @click="doFilter">搜索</el-button>
    </div>
    
         <el-table
        :data="tableData"
        :stripe="true"
        
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="expertId"
          label="id"
          width="auto"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="expertNumber"
          label="专家号"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit == scope.$index"
              v-model="scope.row.expertNumber"
              placeholder="请输入内容"
              style="text-align: center"
            ></el-input>
            <span v-if="isEdit != scope.$index">{{
              scope.row.expertNumber
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="专家姓名"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit == scope.$index"
              v-model="scope.row.name"
              placeholder="请输入内容"
              style="text-align: center"
            ></el-input>
            <span v-if="isEdit != scope.$index">{{ scope.row.name }}</span>
          </template> </el-table-column
        ><el-table-column
          prop="createTime"
          label="注册时间"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit == scope.$index"
              v-model="scope.row.createTime"
              placeholder="请输入内容"
              style="text-align: center"
            ></el-input>
            <span v-if="isEdit != scope.$index">{{
              scope.row.createTime
            }}</span>
          </template> </el-table-column
        ><el-table-column
          prop="professionalQualifications"
          label="学历"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit == scope.$index"
              v-model="scope.row.professionalQualifications"
              placeholder="请输入内容"
              style="text-align: center"
            ></el-input>
            <span v-if="isEdit != scope.$index">{{
              scope.row.professionalQualifications
            }}</span>
          </template> </el-table-column
        ><el-table-column
          prop="educationDegree"
          label="职称等级"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit == scope.$index"
              v-model="scope.row.educationDegree"
              placeholder="请输入内容"
              style="text-align: center"
            ></el-input>
            <span v-if="isEdit != scope.$index">{{
              scope.row.educationDegree
            }}</span>
          </template> </el-table-column
        ><el-table-column
          prop="email"
          label="邮箱"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit == scope.$index"
              v-model="scope.row.email"
              placeholder="请输入内容"
              style="text-align: center"
            ></el-input>
            <span v-if="isEdit != scope.$index">{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mobilePhone"
          label="手机号"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit == scope.$index"
              v-model="scope.row.mobilePhone"
              placeholder="请输入内容"
              style="text-align: center"
            ></el-input>
            <span v-if="isEdit != scope.$index">{{
              scope.row.mobilePhone
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="auto"
          align="center"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-if="isEdit == scope.$index"
                size="mini"
                @click="handleEdit(scope.$index, scope.row, 1, scope.row.expertId,scope.row.expertNumber,
                scope.row.name,scope.row.createTime,scope.row.professionalQualifications,
                scope.row.educationDegree,scope.row.email,scope.row.mobilePhone)"
                >保存</el-button
              >
              <el-button
                v-if="isEdit != scope.$index"
                size="mini"
                @click="handleEdit(scope.$index, scope.row, 0, scope.row.expertId,scope.row.expertNumber,
                scope.row.name,scope.row.createTime,scope.row.professionalQualifications,
                scope.row.educationDegree,scope.row.email,scope.row.mobilePhone)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="
                  handleDelete(scope.$index, scope.row.expertId, scope.row)
                "
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <el-button
          slot="append"
          style="
            width: 100%;
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
          "
          @click="appendNew"
          >点击追加一行</el-button
        >
      </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[1, 4, 8]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      resData: [],
      searchValue: "",
      tableData: [],
      currentPage: 1,
      pageSize: 8,
      totalItems: 0,
      filterTableData: [],
      flag: false,
      isEdit: -99,
    };
  },
  methods: {
    async getexpertlist() {
      const result = await this.$request.get({
        url: "/expert/allexpert",
        headers: {
          Authorization: "Bearer " + localStorage.GIA_token,
        },
      });
      this.resData = result;
      console.log(this.resData.length);
       this.totalItems = this.resData.length; // 注意： 这里mock数据是写在data里的，请求需考虑异步的情况
      if (this.totalItems > this.pageSize) {
        for (let index = 0; index < this.pageSize; index++) {
          this.tableData.push(this.resData[index]);
        }
      } else {
        this.tableData = this.resData;
      }
    },
    // 前端搜索功能需要区分是否检索,因为对应的字段的索引不同
    doFilter() {
      this.tableData = [];
      this.filterTableData = [];
      this.resData.filter((item) => {
        if ( "name" in item) {
          // 按编号或地区查询 注意：根据实际数据 灵活调整字母大小写
          if (
            
            item.name.indexOf(this.searchValue) > -1
          ) {
            this.filterTableData.push(item);
          }
        }
      });
      // 页面数据改变重新统计数据数量和当前页
      this.currentPage = 1;
      this.totalItems = this.filterTableData.length;
      // 渲染表格,根据值
      this.currentChangePage(this.filterTableData);
      // 页面初始化数据需要判断是否检索过
      this.flag = true;
    },
    // 每页显示条数改变触发
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.handleCurrentChange(1);
    },
    // 当前页改变触发
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      // 判断是否为搜索的数据,传入对应值
      if (!this.flag) {
        this.currentChangePage(this.resData);
      } else {
        this.currentChangePage(this.filterTableData);
      }
    },
    // 根据分页，确定当前显示的数据
    currentChangePage(list) {
      let fromNum = (this.currentPage - 1) * this.pageSize;
      let toNum = this.currentPage * this.pageSize;
      this.tableData = [];
      for (; fromNum < toNum; fromNum++) {
        if (list[fromNum]) {
          this.tableData.push(list[fromNum]);
        }
      }
    },
    handleEdit(index, row, status, expertId,expertNumber,
                name,createTime,professionalQualifications,
                educationDegree,email,mobilePhone) {
      switch (status) {
        case 0:
          this.isEdit = index;
          break;
        case 1:
          this.isEdit = -99;
           const result =  this.$request.post({
              url: `/expert/updateInfo?expertId=${expertId}`,
              data: {
                expertNumber,
                name,
                professionalQualifications,
                createTime,
                educationDegree,
                email,
                mobilePhone,
              },
            });
            console.log(result,"11111");
          break;
      }
    },
     handleDelete(index, expertId, row) {
       this.$confirm("这将会永久删除该行数据，是否继续?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
         const result =  this.$request.post({
         url: `/expert/delete?expertId=${expertId}`,
           
      });
      console.log(result,"zzz");
          this.getexpertlist();
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    appendNew() {
      this.tableData.push({
        expertNumber: "",
        name: "",
        createTime: "",
        professionalQualifications: "",
        educationDegree: "",
        email: "",
        mobilePhone: "",
      });
      this.isEdit = this.tableData.length - 1;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
  },
  beforeMount() {
    this.getexpertlist();
  },

};
</script>
<style lang="less" scoped>
.title {
  margin: 10px 0;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.search{
  margin: 20px 10px 10px 10px;
  .search_input{
    margin-right: 10px;
  }
}
</style>
