<template>
  <div class="footer">
    <!-- <div class="ft-top">
      <div class="ft-top-container">
        <div
          class="ft-top-tool"
          v-for="(item, index) in tool"
          v-bind:key="index"
        >
          <div class="title">
            <span>{{ item.title }}</span>
          </div>
          <div v-for="(item2, index) in item.children" :key="index">
            <router-link class="ft-tool-item" :to="item2.to">{{
              item2.show
            }}</router-link>
          </div>
        </div>
        <div class="ft-top-bottom">
          <div class="img-item"></div>
        </div>
      </div>
    </div> -->
    <div class="ft-bottom">
      <!-- 展示说明 -->
      <div class="ft-bottom-contain">
        <div class="explain" v-for="(item, index) in explain" :key="index">
          <!-- <div v-if="item.children">
            <span v-for="(item2, index2) in item.children" :key="index2">
              <router-link class="span" :to="item2.to" v-if="item2.isA">{{
                item2.show
              }}</router-link>
              <span class="span" v-else>{{ item2.show }}</span>
            </span>
          </div>
          <div v-else>
            <router-link class="span" :to="item.to" v-if="item.isA">{{
              item.show
            }}</router-link>
            <span class="span" v-else>{{ item.show }}</span>
          </div> -->
          <div>
            <router-link class="span" :to="item.to" v-if="item.isA">{{
              item.show
            }}</router-link>
            <span class="span" v-else>{{ item.show }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // 底部跳转栏
    // const tool = [
    //   {
    //     title: "活动",
    //     children: [
    //       {
    //         show: "会议",
    //         to: "",
    //       },
    //       {
    //         show: "竞赛",
    //         to: "",
    //       },
    //       {
    //         show: "认证",
    //         to: "",
    //       },
    //       {
    //         show: "培训",
    //         to: "",
    //       },
    //       {
    //         show: "YOCSEF",
    //         to: "",
    //       },
    //       {
    //         show: "TF",
    //         to: "",
    //       },
    //       {
    //         show: "吕梁扶贫",
    //         to: "",
    //       },
    //     ],
    //   },
    //   {
    //     title: "活动",
    //     children: [
    //       {
    //         show: "会议",
    //         to: "",
    //       },
    //       {
    //         show: "竞赛",
    //         to: "",
    //       },
    //       {
    //         show: "认证",
    //         to: "",
    //       },
    //       {
    //         show: "培训",
    //         to: "",
    //       },
    //       {
    //         show: "YOCSEF",
    //         to: "",
    //       },
    //       {
    //         show: "TF",
    //         to: "",
    //       },
    //       {
    //         show: "吕梁扶贫",
    //         to: "",
    //       },
    //     ],
    //   },
    //   {
    //     title: "活动",
    //     children: [
    //       {
    //         show: "会议",
    //         to: "",
    //       },
    //       {
    //         show: "竞赛",
    //         to: "",
    //       },
    //       {
    //         show: "认证",
    //         to: "",
    //       },
    //       {
    //         show: "培训",
    //         to: "",
    //       },
    //       {
    //         show: "YOCSEF",
    //         to: "",
    //       },
    //       {
    //         show: "TF",
    //         to: "",
    //       },
    //       {
    //         show: "吕梁扶贫",
    //         to: "",
    //       },
    //     ],
    //   },
    //   {
    //     title: "活动",
    //     children: [
    //       {
    //         show: "会议",
    //         to: "",
    //       },
    //       {
    //         show: "竞赛",
    //         to: "",
    //       },
    //       {
    //         show: "认证",
    //         to: "",
    //       },
    //       {
    //         show: "培训",
    //         to: "",
    //       },
    //       {
    //         show: "YOCSEF",
    //         to: "",
    //       },
    //       {
    //         show: "TF",
    //         to: "",
    //       },
    //       {
    //         show: "吕梁扶贫",
    //         to: "",
    //       },
    //     ],
    //   },
    //   {
    //     title: "活动",
    //     children: [
    //       {
    //         show: "会议",
    //         to: "",
    //       },
    //       {
    //         show: "竞赛",
    //         to: "",
    //       },
    //       {
    //         show: "认证",
    //         to: "",
    //       },
    //       {
    //         show: "培训",
    //         to: "",
    //       },
    //       {
    //         show: "YOCSEF",
    //         to: "",
    //       },
    //       {
    //         show: "TF",
    //         to: "",
    //       },
    //       {
    //         show: "吕梁扶贫",
    //         to: "",
    //       },
    //     ],
    //   },
    //   {
    //     title: "活动",
    //     children: [
    //       {
    //         show: "会议",
    //         to: "",
    //       },
    //       {
    //         show: "竞赛",
    //         to: "",
    //       },
    //       {
    //         show: "认证",
    //         to: "",
    //       },
    //       {
    //         show: "培训",
    //         to: "",
    //       },
    //       {
    //         show: "YOCSEF",
    //         to: "",
    //       },
    //       {
    //         show: "TF",
    //         to: "",
    //       },
    //       {
    //         show: "吕梁扶贫",
    //         to: "",
    //       },
    //     ],
    //   },
    // ];
    const explain = [
      {
        show: "版权所有 广东省工业软件学会  技术支持:P&A工作室",
      },
      {
        show: "网站建设合作联系电话:(+86)18102637002 邮箱号:3089385529@qq.com",
      },
      // {
      //   children: [
      //     {
      //       show: "京公网安备 11010802032778号",
      //       isA: true,
      //       to: "",
      //     },
      //     {
      //       show: "京ICP备13000930号-4",
      //       isA: true,
      //       to: "",
      //     },
      //   ],
      // },
      {
        show: "网站建议或者意见请发送邮件:984232407@qq.com",
      },
      {
        show: "投诉请联系:984232407@qq.com",
      },
    ];
    return {
      // tool,
      explain,
    };
  },
  methods: {},
};
</script>
<style scoped>
.footer {
  width: 100%;
}
/* .ft-top {
  background-color: rgba(127, 127, 127);
  overflow: hidden;
  padding: 30px 0 16px;
}
.ft-top-container {
  display: flex;
  width: 1140px;
  margin: 0 auto;
}
.ft-top-tool {
  flex: 1;
} */
.title {
  font-size: 18px;
  color: #fff;
  line-height: 1.5em;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  margin-bottom: 14px;
}
.title span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  border-bottom: 2px #b9b6b6 solid;
}
.ft-tool-item {
  display: block;
  font-size: 16px;
  text-align: center;
  color: #fff;
  line-height: 1.75em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ft-tool-item:hover {
  text-decoration: underline;
}
.ft-bottom {
  overflow: hidden;
  background-color: #555555;
  font-size: 14px;
  color: #fff;
  line-height: 1.5em;
  text-align: center;
  padding: 47px 0 50px;
}
.ft-bottom-contain {
  width: 1170px;
  margin: 0 auto;
}
.span {
  margin-left: 10px;
}
</style>