<template>
  <div class="mainContainer">
    <slidingImage :images="images" />
    <news :showNewList="chosenHomePassage" />
    <memberActiveAndStory :showStoryList="storyPassage"
                          :showActiveList="activityPassage" />
    <partnersShow />
  </div>
</template>
<script>
import slidingImage from "../../components/home/slidingImage.vue";
import partnersShow from "../../components/home/partnersShow.vue";
import news from "../../components/home/news.vue";
import memberActiveAndStory from "../../components/home/memberActiveAndStory.vue";
export default {
  components: {
    slidingImage,
    partnersShow,
    news,
    memberActiveAndStory,
  },
  data () {
    return {
      // 首页轮播图列表
      images: [],
      // 选中类型的首页展示文章列表：
      chosenHomePassage: [],
      storyPassage: [],
      activityPassage: [],
    };
  },
  methods: {
    getImages: async function () {
      const result = await this.$request.post({
        url: "/articlePhotoManagement/query",
      });
      this.images = result;
    },
    // 获取选中类型的首页展示文章 567:聚焦、故事、活动
    getShowPassage: async function () {
      const list = await this.$request.post({
        url: `/articleManagement/query?typeId=${5}`,
      });
      this.chosenHomePassage = list;

      const list2 = await this.$request.post({
        url: `/articleManagement/query?typeId=${6}`,
      });
      this.storyPassage = list2;
      const list3 = await this.$request.post({
        url: `/articleManagement/query?typeId=${7}`,
      });
      this.activityPassage = list3;
    },
  },
  mounted () {
    this.getImages();
    this.getShowPassage();
  },
};
</script>
<style scoped>
</style>