<template>
  <div class="paymentRecord" v-loading="isSuccess">
    <div class="receiveMessage">
      <div class="topSearch" style="min-width: 1080px">
        <div>
          <span>姓名查询：</span>
          <el-input
            v-model="searchMemberByName"
            type="text"
            size="normal"
            placeholder=" 请输入会员姓名"
            style="width: 200px; margin-top: 10px"
          />
        </div>
        <div>
          <span>会员号查询：</span>
          <el-input
            v-model="searchMemberByNumber"
            type="text"
            size="normal"
            placeholder=" 请输入会员号"
            style="width: 200px; margin-top: 10px"
          />
        </div>
        <div class="block">
          <span class="demonstration">查询日期范围：</span>
          <el-date-picker
            v-model="searchMemberByTimeRange"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            style="width: 400px; margin-top: 10px"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchMess"
          style="height: 40px; margin-top: 30px"
          >搜索</el-button
        >
      </div>
      <div class="Type">
        <div class="changeType" v-if="isSearching == 0">
          <el-switch
            style="display: block"
            v-model="payType"
            active-color="#00f2fe"
            inactive-color="#96e6a1"
            active-text="企业"
            active-value="1"
            inactive-text="个人"
            inactive-value="0"
            @change="changeType()"
          >
          </el-switch>
        </div>
        <div class="showToAll" v-else>
          <el-button size="mini" type="primary" plain @click="showToAll"
            >全部</el-button
          >
        </div>
        <div class="Flow">
          <el-select
            v-model="chosenType"
            placeholder="请选择"
            size="mini"
            width="10px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            size="mini"
            icon="el-icon-download"
            type="primary"
            plain
            @click="flowOutAll"
            >导出全部</el-button
          >
        </div>
      </div>
      <div class="receiveFormPlace">
        <el-table
          :data="showTableData"
          style="width: 83%"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @select="selectRow"
          @select-all="selectRow"
          stripe
          border
          max-height="450"
          highlight-current-row
        >
          <el-table-column type="selection" />
          <el-table-column prop="number" label="会员号" width="100">
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="100">
          </el-table-column>
          <el-table-column prop="role" label="会员类型" width="100">
          </el-table-column>
          <el-table-column prop="payWay" label="支付类型" width="100">
          </el-table-column>
          <el-table-column prop="fee" label="支付金额" width="100">
          </el-table-column>
          <el-table-column prop="tele" label="联系方式" width="100">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="120">
          </el-table-column>
          <el-table-column prop="revenueTime" label="到款日期" width="190">
          </el-table-column>
        </el-table>
        <div class="totalFee" v-if="isSearching != 2">
          <span
            >{{
              isSearching == 0 ? (payType == 0 ? "个人" : "企业") : ""
            }}缴费总额:{{ totalFee }}</span
          >
        </div>
        <el-pagination
          class="departPage"
          v-if="isSearching != 2"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import StuMana
// import pubsub from 'pubsub-js'
export default {
  name: "payRecord",
  data() {
    return {
      isSuccess: false, //加载中？
      pageSize: 20, //每页数据量
      pageSizes: [20, 30, 50, 100], //可选每页数据量
      showTableData: [], //展示的数据
      total: 60, //全部数据量
      pageNow: 1, //当前所处页面
      selectedRow: [], //选中的行
      searchMemberByName: "", //搜索输入的会员名
      searchMemberByNumber: "", //搜索输入的会员号
      searchMemberByTimeRange: [], //搜索输入的时间范围
      memberType: [], //会员类型搜索
      editMemberId: "", //编辑行会员id
      payType: 0, //展示的支付记录----0：个人  1：企业
      totalFee: "2", //列表总缴费金额
      options: [
        {
          value: "0",
          label: "全部个人会员",
        },
        {
          value: "1",
          label: "全部企业会员",
        },
      ],
      // 日期范围选项
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      chosenType: "", //导出的文件类型
      isSearching: 0, //判断分页状态:0--全部,1--按时间分页,2--其它条件搜索
    };
  },
  computed: {},
  methods: {
    // val为选择的每页长度
    handleSizeChange(val) {
      this.pageSize = val;
      // 判断是日期筛选状态的分页还是全部会员信息的分页
      if (this.isSearching == 0) {
        this.showPageChange(this.pageNow, this.pageSize, this.payType);
      } else {
        let time = this.searchMemberByTimeRange;
        this.showTimeSearch(time, this.pageNow, this.pageSize);
      }
    },
    // val为选择的第几页
    handleCurrentChange(val) {
      // 页面跳转函数
      this.pageNow = val;
      // 判断是日期筛选状态的分页还是全部会员信息的分页
      if (this.isSearching == 0) {
        this.showPageChange(this.pageNow, this.pageSize, this.payType);
      } else {
        let time = this.searchMemberByTimeRange;
        this.showTimeSearch(time, this.pageNow, this.pageSize);
      }
    },
    // 按时间段查询缴费记录与缴费总额
    showTimeSearch: async function (time, page, size) {
      this.isSuccess = true;
      let startTime = time[0];
      let endTime = time[1];
      const result = await this.$request.post({
        url: `/order/manage/queryByTime?startTime=${startTime}&endTime=${endTime}`,
        data: {
          page,
          size,
        },
      });
      // 修改展示数据/总共数据量
      this.total = result.totalCount;
      this.showTableData = result.list;
      this.getTimeCount(time);

      this.isSuccess = false;
    },
    // 按会员号查询缴费记录
    showNumberSearch: async function (number) {
      const result = await this.$request.post({
        url: `/order/manage/queryByNumber?number=${number}`,
      });
      // 修改展示数据/总共数据量
      this.total = result.length;
      this.showTableData = result;
      this.isSearching = 2;
    },
    // 按会员名称查询缴费记录
    showNameSearch: async function (name) {
      const result = await this.$request.post({
        url: `/order/manage/queryByName?name=${name}`,
      });
      // 修改展示数据/总共数据量
      this.total = result.length;
      this.showTableData = result;
      this.isSearching = 2;
    },
    // 获取列表全部缴费总额----全部
    getAllCount: async function () {
      let type = this.payType;
      const result = await this.$request.post({
        url: `/order/manage/queryTotalFee?type=${type}`,
      });
      // 修改展示数据/总共数据量
      this.totalFee = result;
    },
    // 获取列表全部缴费总额----日期范围内全部
    getTimeCount: async function (time) {
      let startTime = time[0];
      let endTime = time[1];
      let type = this.payType;
      const result = await this.$request.post({
        url: `/order/manage/queryTotalFeeByTime?type=${type}&startTime=${startTime}&endTime=${endTime}`,
      });
      // 修改展示数据/总共数据量
      this.totalFee = result;
    },
    // 选中行的记入数组selectedRow
    selectRow(selection) {
      let selectArr = [];
      if (selection.length > 0) {
        for (var i = 0; i < selection.length; i++) {
          selectArr.push(selection[i].num);
        }
      }
      this.selectedRow = selectArr;
    },
    // 点击搜索
    searchMess: async function () {
      if (
        !this.searchMemberByName &&
        !this.searchMemberByNumber &&
        this.searchMemberByTimeRange != null &&
        this.searchMemberByTimeRange.length == 0
      ) {
        this.$message({
          message: "请输入搜索内容",
          type: "warning",
        });
      } else if (this.searchMemberByName) {
        let name = this.searchMemberByName;
        this.showNameSearch(name);
      } else if (this.searchMemberByNumber) {
        let number = this.searchMemberByNumber;
        this.showNumberSearch(number);
      } else if (this.searchMemberByTimeRange.length > 0) {
        this.isSearching = 1;
        let time = this.searchMemberByTimeRange;
        this.showTimeSearch(time, this.pageNow, this.pageSize);
      }
      this.searchMemberByName = "";
      this.searchMemberByNumber = "";
    },
    // 点击切换个人/企业交费记录展示
    changeType() {
      this.showPageChange(this.pageNow, this.pageSize, this.payType);
    },
    // 按时间搜索分页中返回全部
    showToAll: async function () {
      this.pageNow = 1;
      this.pageSize = 5;
      this.payType = 0;
      this.showPageChange(this.pageNow, this.pageSize, this.payType);
    },
    // 导出选中类型全部会员数据
    flowOutAll: async function () {
      let chosenType = this.chosenType;

      if (chosenType == "") {
        this.$message({
          message: "请选择导出会员类型",
          type: "warning",
        });
      } else {
        let excelName=''
        if(chosenType==0){
          excelName='全部个人会员缴费记录'
        }else{
          excelName='全部企业会员缴费记录'
        }
        const result = await this.$request.postExcel({
          url: `/order/manage/exportAll?type=${chosenType}`,
          excelName
        });
      }
    },
    // 展示全部分页交费记录
    showPageChange: async function (page, size, payType) {
      this.isSuccess = true;
      const result = await this.$request.post({
        url: `/order/manage/query?type=${payType}`,
        data: {
          page,
          size,
        },
      });
      // 修改展示数据/总共数据量
      this.total = result.totalCount;
      this.showTableData = result.list;
      this.isSearching = 0;
      this.getAllCount();
      this.isSuccess = false;
    },
  },
  mounted() {
    // 默认展示全部会员交费记录
    this.showPageChange(this.pageNow, this.pageSize, this.payType);
  },
};
</script>

<style lang="css" scoped="true">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.el-button {
  padding: 5px 10px;
}
.paymentRecord {
  background-color: #fff;
  min-width: 1000px;
  margin-left: -50px;
}
.title {
  margin-top: 30px;
  width: 300px;
  height: 70px;
  background-color: #4facfe;
  border-radius: 10px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  line-height: 70px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-shadow: -1px -2px 1px rgb(0, 0, 0);
}
.topSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.receiveFormPlace {
  margin-top: 10px;
}
.receiveFormPlace * {
  text-align: center;
}
.receiveMessage {
  padding: 50px 100px;
  overflow: hidden;
}
.Type {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 70px;
}
.changeType {
  width: 150px;
  padding-top: 10px;
  margin-left: -85px;
}
.receiveFormPlace {
  width: 1180px;
}
.receiveFormPlace a {
  text-decoration: none;
  color: #123;
  width: 60px;
  margin-right: 5px;
}
.selectT {
  min-width: 300px;
  max-height: 40px;
}
.totalFee {
  padding: 10px;
  float: right;
  margin: 20px 50px 10px 0;
  border: 1px solid rgb(72, 158, 243);
  border-radius: 10px;
  color: rgb(63, 73, 84);
  font-size: 14px;
}
.departPage {
  margin: 40px;
}
</style>
