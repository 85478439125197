<template>
  <div class="info-box">
    <el-form :rules="rules" ref="updataRef" :model="personalInfo">
      <p class="title">
        完善{{
          personalInfo.vipType && personalInfo.vipType.id == 1
            ? "法人"
            : "个人"
        }}信息
      </p>
      <div class="base-form" label-width="140px">
        <el-form-item
          class="form-item"
          v-show="
            !(
              personalInfo.vipType &&
              personalInfo.vipType.id == 1 &&
              key == 'birthday'
            )
          "
          :label="item.label + ':'"
          label-width="140px"
          v-for="(item, key) in addInfo"
          :prop="key"
          :key="key"
        >
          <label v-if="item.type == 'image'" for="headerPhoto">
            <div class="img-once">
              <img
                class="img"
                v-show="personalInfo[key]"
                :src="personalInfo[key]"
              />
              <div v-show="!personalInfo[key]" class="empty-img">
                <i class="el-icon-plus"></i>
              </div>
              <input
                id="headerPhoto"
                hidden
                @change="changeHead"
                type="file"
                accept="image/*"
              />
            </div>
          </label>
          <el-select
            size="small"
            v-model="personalInfo[key].id"
            v-else-if="item.type == 'select'"
          >
            <el-option
              v-for="(label, index) in item.option"
              :key="index"
              :value="index"
              :label="label"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="personalInfo[key]"
            size="small"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            v-else-if="item.type == 'DatePicker'"
          ></el-date-picker>
          <el-input
            v-model="personalInfo[key]"
            size="small"
            v-else
            :maxlength="item.maxLength || 400"
            :type="item.type || 'input'"
            :placeholder="`${item.label}`"
            :disabled="item.disabled"
          >
          </el-input>
        </el-form-item>
      </div>
      <p class="title">联系方式</p>
      <div class="base-form">
        <el-form-item
          class="form-item"
          :prop="key"
          :label="item.label + ':'"
          label-width="140px"
          v-for="(item, key) in contactInfo"
          :key="key"
        >
          <el-input
            placeholder="请输入内容"
            v-model="personalInfo[key]"
            class="input-with-select"
            :disabled="item.disabled"
            size="small"
          >
            <el-button
              slot="append"
              size="small"
              icon="el-icon-edit"
              @click="
                () => {
                  if (key == 'mobilePhone') {
                    $message({ message: '暂不开放!' });
                  } else {
                    showContactForm(key);
                  }
                }
              "
            ></el-button>
          </el-input>
        </el-form-item>
      </div>
      <p
        class="title"
        v-if="personalInfo.vipType && personalInfo.vipType.id == 1"
      >
        企业会员信息补充
      </p>
      <p
        class="title"
        v-else-if="personalInfo.vipType && personalInfo.vipType.id == 0"
      >
        个人会员信息补充
      </p>
      <div
        v-if="personalInfo.vipType && personalInfo.vipType.id == 1"
        class="base-form"
      >
        <el-form-item
          class="form-item"
          :label="item.label + ':'"
          label-width="140px"
          :prop="key"
          v-for="(item, key) in enterpriseInfo"
          :key="key"
        >
          <el-select
            size="small"
            v-model="personalInfo[key]"
            multiple
            v-if="item.type == 'select'"
          >
            <el-option
              v-for="(label, index) in item.option"
              :key="index"
              :value="label"
              :label="label"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="personalInfo[key]"
            size="small"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            v-else-if="item.type == 'DatePicker'"
          ></el-date-picker>
          <el-input
            v-else
            v-model="personalInfo[key]"
            :type="item.type || 'input'"
            :maxlength="item.maxLength || 400"
            size="small"
            :placeholder="`${item.label}`"
          />
        </el-form-item>
      </div>
      <div
        v-else-if="personalInfo.vipType && personalInfo.vipType.id == 0"
        class="base-form"
      >
        <el-form-item
          class="form-item"
          v-for="(item, key) in personageInfo"
          :class="item.type == 'textarea' ? 'textareaBox' : ''"
          :prop="key"
          :key="key"
        >
          <p class="personalInfo-title">{{ item.label }}</p>
          <el-select
            size="small"
            class="personalInfo-input"
            v-model="personalInfo[key]"
            v-if="item.type == 'select'"
          >
            <el-option
              v-for="(label, index) in item.option"
              :key="index"
              :value="label"
              :label="label"
            ></el-option>
          </el-select>
          <el-input
            type="textarea"
            class="textarea"
            :autosize="{
              minRows: 3,
              maxRows: 3,
            }"
            v-else
            :maxlength="item.maxLength || 400"
            v-model="personalInfo[key]"
            resize="none"
            :placeholder="`${item.label}`"
            :disabled="item.disabled"
          ></el-input>
        </el-form-item>
      </div>
      <p
        v-if="personalInfo.vipType && personalInfo.vipType.id == 0"
        class="title"
      >
        说明
      </p>
      <div
        v-if="personalInfo.vipType && personalInfo.vipType.id == 0"
        class="title"
      >
        <el-form-item
          v-for="(item, key) in textareaInfo"
          class="textareaBox"
          :prop="key"
          :key="key"
        >
          <span class="demonstration">{{ item.label }}</span>
          <el-input
            type="textarea"
            class="textarea"
            :maxlength="item.maxLength || 400"
            :autosize="{
              minRows: 3,
              maxRows: 3,
            }"
            v-model="personalInfo[key]"
            resize="none"
            :placeholder="`${item.label}`"
            :disabled="item.disabled"
          ></el-input>
        </el-form-item>
      </div>

      <div class="tools-box">
        <el-button
          @click="updata"
          size="small"
          type="primary"
          icon="el-icon-check"
          >提交</el-button
        >
        <el-button
          @click="applyPDF"
          type="success"
          size="small"
          plain
          icon="el-icon-download"
          >下载pdf</el-button
        >
        <label for="uploadPDF" style="margin-left: 10px">
          <input
            type="file"
            hidden="hidden"
            accept="application/pdf"
            id="uploadPDF"
            @click="deletePDF"
            @change="uploadPDF"
          />
          <div class="upload-btn">
            <i class="el-icon-upload"></i>
            <span>上传盖章件</span>
          </div>
        </label>
      </div>
    </el-form>
    <el-form
      :rules="rules"
      :model="changeContactForm"
      v-show="showChange"
      class="shade-box"
    >
      <div class="form-box">
        <p class="form-title">
          修改{{ changeType == "email" ? "邮箱" : "手机号" }}
        </p>
        <div
          ref="contactRef"
          :rules="rules"
          class="input-ul"
          label-width="70px"
        >
          <el-form-item
            prop="value"
            :label="changeType == 'email' ? '邮箱:' : '手机号:'"
          >
            <el-input
              v-model="changeContactForm.value"
              size="small"
              style="width: 255px"
            />
          </el-form-item>
          <el-form-item prop="code" label="验证码:">
            <el-input
              size="small"
              v-model="changeContactForm.code"
              style="width: 160px"
            />
            <el-button
              style="margin-left: 5px"
              size="small"
              @click="sendCode"
              :disabled="changeContactForm.timeout ? true : false"
              >{{
                changeContactForm.timeout
                  ? changeContactForm.timeout + "s"
                  : "获取验证码"
              }}</el-button
            >
          </el-form-item>
        </div>
        <div class="tools-box">
          <el-button @click="changeContact()" size="small" type="primary"
            >修改</el-button
          >
          <el-button @click="showChange = false" size="small" plain
            >取消</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    const rules = {
      value: [
        {
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            const { changeType } = this.$data;
            const type_c_name = changeType == "email" ? "邮箱" : "手机号";
            if (!value) {
              callback(new Error(`${type_c_name}不能为空`));
            } else {
              const regEmail =
                /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
              const regPhone = /^1[3|4|5|7|8][0-9]{9}$/;
              if (
                (changeType == "email" && regEmail.test(value)) ||
                (changeType == "mobilePhone" && regPhone.test(value))
              ) {
                callback();
              } else {
                callback(new Error(`请输入正确的${type_c_name}格式`));
              }
            }
          },
        },
      ],
      code: [
        {
          required: true,
          trigger: "blur",
          message: "验证码不能为空",
        },
      ],
      professionalTitle:[
        {
          required: true,
          message: "请输入职称",
          trigger: "blur",
        },
      ],
      workPlace:[
        {
          required: true,
          message: "请输入工作单位",
          trigger: "blur",
        },
      ],
      highestEducation:[
        {
          required: true,
          message: "请输入学历",
          trigger: "blur",
        },
      ],
      idNumber: [
        {
          required: true,
          message: "请输入身份证",
          trigger: "blur",
        },
        {
          validator: (rule, val, callback) => {
            // 大陆身份证
            if (
              /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/.test(
                val
              )
            ) {
              callback();
            }
            // 港澳身份证
            else if (/^([A-Z]\d{6,10}(\(\w{1}\))?)$/.test(val)) {
              callback();
            }
            // 台湾身份证
            else if (/^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/.test(val)) {
              callback();
            }
            // 护照
            else if (/^([a-zA-z]|[0-9]){5,17}$/.test(val)) {
              callback();
            }
            // 军官证
            else if (
              /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/.test(val)
            ) {
              callback();
            }
            // 户口本
            else if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val)) {
              callback();
            }
            // 判断其他类型
            else {
              callback(new Error(`身份证格式有误`));
            }
          },
          message: "请输入正确的身份证格式！",
          trigger: "blur",
        },
      ],
      postCode: [
        {
          validator: (rule, val, callback) => {
            if (/^\d{6}$/.test(val)) {
              callback();
            } else {
              callback(new Error(`邮政编码填写错误`));
            }
          },
          message: "邮政编码格式有误",
          trigger: "blur",
        },
      ],
      telephone: [
        {
          required: true,
          message: "请输入电话",
          trigger: "blur",
        },
        {
          validator: (rule, val, callback) => {
            if (/^\d{6,14}$/.test(val)) {
              callback();
            } else {
              callback(new Error(`电话格式有误`));
            }
          },
          message: "电话格式有误",
          trigger: "blur",
        },
      ],
      workLength: [
        {
          validator: (rule, val, callback) => {
            if (/^[1-9]\d{0,1}$/.test(val)) {
              callback();
            } else {
              callback(new Error(`电话格式有误`));
            }
          },
          message: "工作年限不合理",
          trigger: "blur",
        },
      ],
      leaderEmail: [
        {
          type: "email",
          required: true,
          message: "邮箱格式有误",
          trigger: "blur",
        },
        {
          max: 50,
          message: "邮箱格式有误",
          trigger: "blur",
        },
      ],
      contactEmail: [
        {
          required: true,
          type: "email",
          message: "邮箱格式有误",
          trigger: "blur",
        },
        {
          max: 50,
          message: "邮箱格式有误",
          trigger: "blur",
        },
      ],
      leaderPhone: [
        {
          validator: (rule, val, callback) => {
            if (/^\d{6,14}$/.test(val)) {
              callback();
            } else {
              callback(new Error(`电话格式有误`));
            }
          },
          trigger: "blur",
        },
      ],
      contactPhone: [
        {
          required: true,
          message: "请输入身份证",
          trigger: "blur",
        },
        {
          validator: (rule, val, callback) => {
            if (/^\d{6,14}$/.test(val)) {
              callback();
            } else {
              callback(new Error(`电话格式有误`));
            }
          },
          trigger: "blur",
        },
      ],
      yearMoney: [
        {
          validator: (rule, val, callback) => {
            if (/^\d{0,11}$/.test(val)) {
              callback();
            } else {
              callback(new Error(`年收入输入不合理`));
            }
          },
          trigger: "blur",
        },
      ],
    };
    return {
      rules,
      showChange: false,
      changeType: "email",
      changeContactForm: {
        value: "",
        code: "",
        timeout: 0,
      },
      addInfo: {
        name: {
          label: "姓名",
          maxLength: 20,
        },
        headPhoto: {
          label: "证件照",
          type: "image",
        },
        idNumber: {
          label: "身份证号码",
        },
        sex: {
          label: "性别",
          type: "select",
          option: ["未知", "男", "女"],
        },
        birthday: {
          label: "生日",
          type: "DatePicker",
        },
        address: {
          label: "地址",
          maxLength: 50,
        },
        postCode: {
          label: "邮政编码",
          type: "number",
          maxLength: 6,
        },
        telephone: {
          label: "办公电话",
          type: "number",
        },
        highestEducation: {
          label: "最高学历",
          maxLength: 20,
        },
        getTime: {
          label: "获得最高学历时间",
          type: "DatePicker",
        },
        workPlace: {
          label: "任职单位",
          maxLength: 50,
        },
        duty: {
          label: "现任职务",
          maxLength: 50,
        },
        workLength: {
          label: "工作年限(年)",
          type: "number",
        },
        professionalTitle: {
          label: "职称类别",
          maxLength: 50,
        },
      },
      personageInfo: {
        associationExpertWilling: {
          label: "是否愿意成为本学会专家",
          type: "select",
          option: ["愿意", "不愿意"],
        },
        projectExpertWilling: {
          label: "是否愿意被推荐为国家、省部级项目评审专家",
          type: "select",
          option: [
            "项目评审",
            "奖项评审",
            "政府咨询",
            "技术服务与职称评审",
            "否",
          ],
        },
        advantage: {
          label: "个人会员专业优势",
          type: "select",
          option: [
            "设计仿真测试软件工程",
            "智能装置装备软件工程",
            "系统与平台软件工程",
          ],
        },
      },
      contactInfo: {
        email: {
          label: "绑定的电子邮箱",
          disabled: true,
        },
        mobilePhone: {
          label: "绑定的手机号",
          disabled: true,
        },
      },
      enterpriseInfo: {
        yearMoney: {
          label: "企业上年收入(元)",
          maxLength: 20,
          type: "number",
        },
        leaderName: {
          label: "企业分管领导姓名",
          maxLength: 20,
        },
        leaderPhone: {
          label: "企业分管领导电话",
          type: "number",
        },
        leaderEmail: {
          label: "企业分管领导邮箱",
          type: "email",
        },
        cantactName: {
          label: "企业联系人姓名",
          maxLength: 20,
        },
        contactPhone: {
          label: "企业联系人电话",
          type: "number",
        },
        contactEmail: {
          label: "企业联系人邮箱",
          type: "email",
        },
        registerAddress: {
          label: "企业注册地址",
          maxLength: 50,
        },
        birthday: {
          label: "企业成立时间",
          type: "DatePicker",
        },
        organizationType: {
          label: "所属领域",
          type: "select",
          option: [
            "设计仿真测试软件工程",
            "智能装置装备软件工程",
            "系统与平台软件工程",
          ],
        },
        memberServe: {
          label: "会员服务",
          type: "select",
          option: [
            "科技政策周报、导报",
            "标准研制",
            "重大项目选型闭门分析会",
            "一对一融资服务",
            "高精尖产业技能提升专项培训",
            "高管专家库推荐",
            "优先纳入国家和市级人才政策体系与人才服务绿色通道",
            "科技服务奖励资金",
            "科创中心专题研修班",
          ],
        },
        memberServeOther: {
          label: "会员服务补充",
          maxLength: 400,
          type: "other",
          related: "memberServe",
        },
        serviceReward: {
          label: "科技服务奖励补贴",
          type: "select",
          option: [
            "科技咨询服务",
            "科技金融服务",
            "知识产权服务",
            "法律服务",
            "财税服务",
            "人才服务",
            "检测服务",
            "认证服务",
          ],
        },
        serviceRewardOther: {
          label: "科技服务奖励补贴补充",
          maxLength: 200,
          type: "other",
          related: "serviceReward",
        },
        academicianGroup: {
          label: "企业院士专家组",
          type: "select",
          option: [
            "组织院士、专家及其团队与研发人员开展联合研究",
            "开展产业发展及学科发展战略咨询服务",
            "引进院士、专家及其团队具有自主知识产权的科技成果，共同进行转化和产业化",
            "与院士、专家及其团队联合培养科技创新型人才",
            "与院士、专家及其团队联合开展高层次学术或技术交流活动",
            "建立院士工作站",
          ],
        },

        productIntroduce: {
          label: "公司主要产品介绍",
          maxLength: 400,
        },

        governmentFunding: {
          label: "政府经费项目资助情况",
          maxLength: 400,
        },
        credit: {
          label: "企业社会信用情况",
          maxLength: 400,
        },
      },
      textareaInfo: {
        resume: {
          label: "工作简历",
          maxLength: 400,
          type: "textarea",
        },
        personalPage: {
          label: "个人主页介绍",
          maxLength: 400,
          type: "textarea",
        },
        achievement: {
          label: "主要业绩、成就与著作",
          type: "textarea",
          maxLength: 400,
        },
        otherDuty: {
          label: "其它社会职务",
          maxLength: 400,
          type: "textarea",
        },
      },
    };
  },
  computed: {
    personalInfo() {
      return this.$store.state.userInfo_canWrite;
    },
  },
  methods: {
    async applyPDF() {
      let userId = this.$store.state.userInfo.id;
      let name = this.$store.state.userInfo.name;
      const loading = this.$loading({
        lock: true,
        text: "自动生成PDF中,请耐心等待...",
      });
      try {
        await this.$request.postPDF({
          url: `/downloadPdf/Application?userId=${userId}`,
          pdfName: name + "申请表",
        });
        loading.close();
      } catch (error) {
        loading.close();
      }
    },
    deletePDF(e) {
      e.target.value = "";
    },
    async uploadPDF(e) {
      const { files } = e.target;
      const loading = this.$loading({
        lock: true,
        text: "上传PDF文件中，请稍后...",
      });
      try {
        const result = await this.$request.postForm({
          url: "/file/upload/wap",
          data: {
            file: files[0],
            name: files[0].name,
          },
        });
        await this.$request.postForm({
          url: "/user/change/pdfId",
          data: {
            pdfId: result,
          },
        });
        this.$message({
          typr: "success",
          message: "注册成功!",
        });
        loading.close();
      } catch (error) {
        loading.close();
      }
    },
    showContactForm(key) {
      this.$data.showChange = true;
      this.$data.changeType = key;
      this.$data.changeContactForm = {
        value: "",
        code: "",
        timeout: 0,
      };
      clearInterval(this.sendCodeInterval);
      this.sendCodeInterval = null;
    },
    //#region  修改联系方式
    changeContact() {
      this.$refs["contactRef"].validate(async (valid) => {
        if (valid) {
          const {
            changeType,
            changeContactForm: { value, code },
          } = this.$data;
          if (changeType == "email") {
            await this.$request.postForm({
              url: "/user/change/email",
              data: {
                email: value,
                code: code,
              },
            });
          } else {
            await this.$request.post({
              url: "/user/change/phone",
              data: {
                phone: value,
                code: code,
              },
            });
          }
          this.showChange = false;
          this.$message({
            showClose: true,
            message: "修改成功!",
            type: "success",
            duration: 2000,
          });
          this.$store.state.userInfo_canWrite[changeType] = value;
          this.$store.state.userInfo[changeType] = value;
        }
      });
    },
    sendCode() {
      this.$refs["contactRef"].validateField("value", async (valid) => {
        if (!valid) {
          this.$data.changeContactForm.timeout = 60;
          this.sendCodeInterval = setInterval(() => {
            this.$data.changeContactForm.timeout -= 1;
            if (this.$data.changeContactForm.timeout == 0) {
              clearInterval(this.sendCodeInterval);
              this.sendCodeInterval = null;
            }
          }, 1000);
          const {
            changeContactForm: { value },
            changeType,
          } = this.$data;
          try {
            if (changeType == "email") {
              await this.$request.post({
                url: "/user/sendCode/" + value,
              });
            } else {
              await this.$request.post({
                url: "/user/phone/code",
                header: {
                  "Content-Type": "multipart/form-data",
                },
                data: {
                  phone: "+86" + value,
                },
              });
            }
          } catch (error) {
            this.$data.changeContactForm.timeout = 0;
            clearInterval(this.sendCodeInterval);
            this.sendCodeInterval = null;
          }
        }
      });
    },
    //#endregion
    async updata() {
      this.$refs.updataRef.validate(async (vaild) => {
        if (vaild) {
          const {
            userInfo_canWrite,
            userInfo_canWrite: {
              sex,
              vipType,
              memberServe,
              serviceReward,
              academicianGroup,
              organizationType,
            },
          } = this.$store.state;
          let data = {};
          if (vipType && vipType.id == 1) {
            data = {
              ...userInfo_canWrite,
              sex: sex.id || 0,
              organizationType: organizationType.stringfyToString(),
              memberServe: memberServe.stringfyToString(),
              serviceReward: serviceReward.stringfyToString(),
              academicianGroup: academicianGroup.stringfyToString(),
            };
          } else if (vipType && vipType.id == 0) {
            data = {
              ...userInfo_canWrite,
              sex: sex.id || 0,
            };
          }
          try {
            await this.$request.post({
              url: "/user/change/info",
              data: data,
            });
            this.$store.state.userInfo = { ...userInfo_canWrite };
            this.$message({
              message: "修改成功!请下载PDF并盖章后再扫描上传",
              showClose: true,
              type: "success",
              duration: 2000,
            });
          } catch {
            console.log();
          }
        } else {
          this.$message({
            type: "warning",
            message: "请完善信息后再提交",
          });
        }
      });
    },
    async changeHead(e) {
      const { files } = e.target;
      const result = await this.$request.postForm({
        url: "/file/upload/image",
        data: {
          file: files[0],
        },
      });
      this.$store.state.userInfo.headPhoto = result;
      this.$store.state.userInfo_canWrite.headPhoto = result;
      const {
        userInfo,
        userInfo: {
          sex,
          vipType,
          memberServe,
          serviceReward,
          academicianGroup,
          organizationType,
        },
      } = this.$store.state;
      let data = {};
      if (vipType && vipType.id == 1) {
        data = {
          ...userInfo,
          sex: sex.id || 0,
          memberServe: memberServe.stringfyToString(),
          serviceReward: serviceReward.stringfyToString(),
          academicianGroup: academicianGroup.stringfyToString(),
          organizationType: organizationType.stringfyToString(),
        };
      } else {
        data = {
          ...userInfo,
          sex: sex.id || 0,
        };
      }
      try {
        await this.$request.post({
          url: "/user/change/info",
          data: data,
        });
        this.$message({
          message: "修改成功!请下载PDF并盖章后再扫描上传",
          showClose: true,
          type: "success",
          duration: 2000,
        });
      } catch {
        console.log();
      }
    },
  },
};
</script>
<style scoped lang="less">
.shade-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  .form-box {
    background-color: #fff;
    width: 420px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 30px;
    .form-title {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      margin: 5px 0;
    }
  }
}
.info-box {
  .title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 1rem 0 2rem 0;
  }
  .textareaBox {
    padding: 0 1.5rem;
    width: 100vw;
    max-width: 100%;
    box-sizing: border-box;
  }
  .base-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .form-item {
      .personalInfo-title {
        text-align: center;
        width: 365px;
        margin: 0 auto;
      }
      .personalInfo-input {
        display: block;
        margin: 0 auto;
      }
      .image-box,
      .el-select.el-select--small,
      .el-input {
        width: 225px;
      }
      .textarea {
        width: 100%;
      }
      #el-input__inner {
        opacity: 0;
      }
      .img-once {
        width: 225px;
        .img {
          width: 120px;
          height: 160px;
          border-radius: 4px;
        }
        .empty-img {
          width: 120px;
          height: 160px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 50px !important;
          color: #999;
          background-color: rgb(228, 228, 228);
          border-radius: 4px;
        }
      }
    }
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }
  .img-box {
    display: flex;
    justify-content: space-around;
  }
  .tools-box {
    display: flex;
    margin: 2rem 0;
    justify-content: center;
    .upload-btn {
      color: #e6a23c;
      background: #fdf6ec;
      border: 1px solid #f5dab1;
      padding: 9px 15px;
      border-radius: 3px;
      span {
        font-size: 12px !important;
      }
    }
  }
}
</style>
<style>
.el-select-dropdown.el-popper {
  max-width: 100vw;
}
.el-form-item__label {
  font-size: 12px;
}
</style>
<style>