<template>
  <div v-loading="isSuccess">
    <div class="showPlace">
      <div class="payNotice">
        <p class="title">会员编号: {{ idNumber }}</p>
        <p class="title">广东省工业软件学会交费通知</p>
        <p style="text-indent: 2em">{{ introduction }}</p>
        <p style="text-indent: 2em">{{ instruction }}</p>
        <ul>
          <li>
            <p style="margin:18px 0">会费标准如下：</p>
            <ul>
              <li v-for="item in payMent" :key="item">
                {{ item }}
              </li>
              <li>会员资格以自然年计算，即注册日起至次年同一日期</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <el-button class="downLoad"
        @click="downLoadPayNotice"
        type="primary">下载</el-button>
  </div>
  <!-- <router-view /> -->
</template>

<script>
export default {
  data () {
    return {
      isSuccess: false,
      idNumber: "59661M",
      introduction:
        "学会介绍：广东省工业软件学会（Guangdong Industrial Software Federation）成立于2022年1月，学会是由广东省从事工业软件科研、教学、工程与应用相关的高等院校、科研院所、企业、产业园区等机构和专家自愿组成的学术性的非营利性社会团体。学会工作的出发点和落脚点是服务大湾区和业界专业人士。会员由广东省内个人会员和单位会员组成。其中个人会员为工业软件领域的专家、学者、从业人员。单位会员为从事工业软件领域教学、研究、应用的高等院校、科研院所或企业。",
      instruction:
        "《广东省工业软件学会会费标准（草案）》：",
      payMent:[
        '（一）会长每年缴纳会费 ￥50000 元；',
        '（二）常务副会长、副会长、秘书长、监事长每年缴纳会费 ￥20000 元；',
        '（三）常务理事、理事、监事每年缴纳会费 ￥10000 元；',
        '（一）单位会员每年缴纳会费 ￥2000 元；',
        '（一）个人会员每年缴纳会费 ￥500 元；',
      ],
      normalPrize: 200, //非学生会员年费
      studentPrice: 50, //学术会员年费
    };
  },
  methods: {
    async downLoadPayNotice () {
      this.$message({
        message: "自动生成PDF中，请耐心等待",
        type: "success",
      });
      this.isSuccess = true;
      const result = await this.$request.postPDF({
        url: "/downloadPdf/PaymentNotice",
        pdfName: "GIA_缴费通知书",
      });
      this.isSuccess = false;
    },
    async getMess () {
      const result = await this.$request.get({
        url: "/user/info",
      });
      this.idNumber = result.vipNumber;
    },
  },
  mounted () {
    this.getMess();
  },
};
</script>

<style scoped>
.payNotice ul {
  list-style: none;
}
.payNotice li {
  height: 30px;
}
.payNotice {
  width: 600px;
  margin-left: 50%;
  transform: translate(-50%);
}
.downLoad {
  margin: 20px 0 0 50%;
  transform: translate(-50%);
}
.payNotice .title {
  margin-top: 0;
  font-weight: bold;
  text-align: center;
}
.showPlace {
  width: 700px;
  height: 660px;
  margin: 20px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(136, 136, 136, 0.319);
  box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.5);
  margin-left: 50%;
  transform: translate(-50%);
}
</style>