<template>
  <div>
    <article-box :content="{title:'如何加入'}">
      <div class="txt">
        <p style="text-align: left; text-indent: 0em; font-size: 18px">
          <span style="font-family: 宋体, SimSun">（1）会员注册</span>
        </p>
        <p style="text-align: left; text-indent: 0em">
          <span style="font-size: 18px; font-family: 宋体, SimSun"
            >在GIA网站</span
          ><a
            href=""
            target="_blank"
            style="
              text-decoration: underline;
              font-family: 宋体, SimSun;
              font-size: 18px;
              color: rgb(79, 129, 189);
            "
            ><span style="color: rgb(79, 129, 189)">首页</span></a
          ><span style="font-size: 18px; font-family: 宋体, SimSun"
            >，点击“</span
          ><a
            href=""
            target="_blank"
            style="
              text-decoration: underline;
              font-family: 宋体, SimSun;
              font-size: 18px;
              color: rgb(79, 129, 189);
            "
            ><span style="color: rgb(79, 129, 189)">加入GIA</span></a
          ><span style="font-size: 18px; font-family: 宋体, SimSun"
            >”按钮，进入</span
          ><span style="font-family: 宋体, SimSun; font-size: 18px"
            >会员注册页面</span
          ><span
            style="text-indent: 2em; font-size: 18px; font-family: 宋体, SimSun"
            >或扫描GIA公众号二维码加入，根据实际情况，选择“会员注册”、“学生会员注册”进行注册；</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em">
          <span style="font-size: 18px; font-family: 宋体, SimSun">（2）</span
          ><a
            href=""
            target="_blank"
            style="
              text-decoration: underline;
              font-family: 宋体, SimSun;
              color: rgb(79, 129, 189);
              font-size: 18px;
            "
            >交费方式</a
          ><span
            style="
              line-height: 1.6;
              text-indent: 2em;
              font-size: 18px;
              font-family: 宋体, SimSun;
            "
            >有在线交费（包含网银、支付宝、公务卡银联支付）、手机端微信交费、银行转账三种，推荐使用在线和微信交费。</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em; font-size: 18px">
          <span style="font-family: 宋体, SimSun"
            >（3）交纳会费后，若使用在线和微信交费，可实现自动实时生效；银行转账方式需要5个工作日到账后，业务人员办理会员资格生效手续。</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em; font-size: 18px">
          <span style="font-family: 宋体, SimSun">（4）补充个人信息</span>
        </p>
        <p style="text-align: left; text-indent: 0em; font-size: 18px">
          <span style="font-family: 宋体, SimSun"
            >为提供更好服务，请及时补充和维护个人信息。</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em">
          <a
            href=""
            target="_blank"
            style="
              font-family: inherit;
              font-size: 16px;
              text-indent: 2em;
              outline: 0px;
              transition: all 0.5s ease 0s;
              color: rgb(46, 62, 78);
              text-decoration-line: none;
              box-sizing: border-box;
            "
            ><img
              width="169"
              height="167"
              title="公众号二维码（小）"
              style="
                outline: 0px;
                transition: 0.5s;
                width: 169px;
                height: 167px;
                vertical-align: middle;
                max-width: 100%;
                box-sizing: border-box;
              "
              alt="公众号二维码（小）"
              src="/upload/resources/image/2019/11/07/105630_500x500.jpg"
              suffix="jpg"
              data-bd-imgshare-binded="1"
          /></a>
        </p>
        <p
          style="
            outline: 0px;
            text-align: left;
            color: rgb(38, 37, 37);
            font-family: 'Microsoft YaHei', 微软雅黑;
            font-size: 16px;
            white-space: normal;
            box-sizing: border-box;
            text-indent: 2em;
          "
        >
          <span style="font-family: 宋体, SimSun; font-size: 18px"
            ><strong
              style="
                font-family: inherit;
                white-space: normal;
                text-indent: 2em;
              "
              ><span style="font-family: 宋体, SimSun; text-indent: 0em"
                >GIA微信公众号</span
              ></strong
            ></span
          >
        </p>
        <p style="text-align: center; text-indent: 2em"><br /></p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center">
          <a
            href=""
            target="_blank"
          ></a>
        </p>
        <p style="text-align: center">
          <a
            href=""
            target="_blank"
          ></a>
        </p>
        <p style="text-align: center">
          <a
            href=""
            target="_blank"
          ></a>
        </p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center"><br /></p>
        <p>&nbsp;</p>
      </div>
    </article-box>
  </div>
</template>
<script>
import ArticleBox from '../../../components/home/articleBox.vue';
export default {
    components:{
        ArticleBox
    },
  data() {
    return {
      
    };
  },
  mounted(){

  }
};
</script>
<style lang="less" scoped>
</style>