<template>
  <div class="GIA_associator-box">
    <div class="associator-header">
      <div class="header-left">
        <router-link to="/">
          <img src="../image/weblogo.jpg" />
        </router-link>
        <span>会员中心</span>
      </div>
      <div class="header-right">
        <el-popover placement="bottom-start"
                    trigger="hover">
          <ul class="base-list">
            <li @click="$router.push('/GIA_associator/change_pass')"
                class="base-item">
              <i class="iconfont icon-xiugaimima"></i>
              <span>修改密码</span>
            </li>
            <li @click="exitLogin"
                class="base-item">
              <i class="iconfont icon-tuichu"></i>
              <span>退出登录</span>
            </li>
          </ul>
          <el-button slot="reference"
                     class="setup-box">
            <i class="iconfont icon-change"></i>
          </el-button>
        </el-popover>
      </div>
    </div>
    <div class="main">
      <div class="left-nav">
        <div class="nav-header">
          <el-popover placement="right"
                      trigger="hover">
            <div>
              <p style="font-size: 18px; font-weight: 600; padding: 0 5px">
                添加会员logo
              </p>
              <div class="photo-select">
                <el-input v-model="webPhoto"
                          placeholder="输入会员logo地址"></el-input>
                <el-button @click="
                    () => addPhoto({ message: '修改成功', data: webPhoto })
                  "
                           class="sure-photo"
                           type="primary"
                           size="mini">确定</el-button>
              </div>
              <el-upload :action="'/api/file/upload/image'"
                         class="upload-demo"
                         drag
                         :on-success="addPhoto"
                         :limit="1"
                         list-type="picture">
                <i class="el-icon-upload"></i>
                <p>或</p>
                <div class="el-upload__text">
                  将报告拖到此处,或<em>点击上传</em>
                </div>
              </el-upload>
            </div>
            <gia-img slot="reference"
                     :imgSrc="$store.state.userInfo.headPhoto"
                     :size="'80'"
                     :circular="true" />
          </el-popover>
          <router-link style="width: 100%"
                       to="/edit_news">
            <el-button icon="el-icon-edit"
                       style="width: 100%"
                       type="primary">写新闻
            </el-button>
          </router-link>
        </div>
        <el-menu active-text-color="#1d90fb"
                 :default-active="defaultActive"
                 class="el-menu">
          <div v-for="firstItem in menuList"
               :key="firstItem.path"
               class="menu-once">
            <el-submenu v-if="firstItem.children"
                        :index="firstItem.title">
              <template slot="title">
                <i class="iconfont"
                   :class="firstItem.icon_name"></i>
                <span slot="title">{{ firstItem.title }}</span>
              </template>
              <router-link v-for="secondItem in firstItem.children"
                           :key="`${secondItem.path}`"
                           :to="`/GIA_associator${secondItem.path}`">
                <el-menu-item :index="`${secondItem.path}`">
                  <i class="iconfont"
                     :class="secondItem.icon_name"></i>
                  <span>{{ secondItem.title }}</span>
                </el-menu-item>
              </router-link>
            </el-submenu>
            <router-link v-else
                         :to="`/GIA_associator${firstItem.path}`">
              <el-menu-item :index="firstItem.path">
                <i class="iconfont"
                   :class="firstItem.icon_name"></i>
                <span>{{ firstItem.title }}</span>
              </el-menu-item>
            </router-link>
          </div>
        </el-menu>
      </div>
      <div class="route-box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import giaImg from "../components/giaImg.vue";
export default {
  components: { giaImg },
  data () {
    const menuList = [
      {
        icon_name: "icon-chuangzuozhongxin",
        path: "/my_creation",
        title: "我的创作",
      },
      {
        icon_name: "icon-geren",
        title: "信息",
        children: [
          {
            icon_name: "icon-jibenxinxixiugaimima",
            path: "/personal_info",
            title: "基本信息",
          },
          {
            icon_name: "icon-0bianjizha66nghu",
            path: "/information_maintenance",
            title: "个人信息维护",
          },
        ],
      },
      {
        icon_name: "icon-chuangzuozhongxin",
        path: "/article",
        title: "查看或发表文章",
      },
      {
        icon_name: "icon-huiyuan",
        title: "会员",
        children: [
          {
            icon_name: "icon-membership",
            path: "/membership",
            title: "会员证书",
          },
          {
            icon_name: "icon-pingzhengzhongxin",
            path: "/pay_certificate",
            title: "交费凭证",
          },
          {
            icon_name: "icon-yaoqinghan-queren",
            path: "/member_confirm",
            title: "会员资格确认函",
          },
          {
            icon_name: "icon-shoulitongzhishu",
            path: "/pay_notice",
            title: "交费通知书",
          },
        ],
      },
      {
        icon_name: "icon-huiyuan",
        title: "交费",
        children: [
          {
            icon_name: "icon-xufeijigoumai",
            path: "/renewal_member",
            title: "会员交费续费",
          },
          {
            icon_name: "icon-zanwuchongzhijilu",
            path: "/payment_record",
            title: "交费记录",
          },
        ],
      },
      {
        icon_name: "icon-01_huiyuanshengjimingxiguanli",
        path: "/upgrade_record",
        title: "升级会员",
      },
    ];
    return {
      defaultActive: "",
      menuList,
      webPhoto: "",
    };
  },
  methods: {
    async getUserInfo () {
      const result = await this.$request.get({
        url: "/user/info",
         headers: {
             Authorization: "Bearer " + localStorage.GIA_token,
           },
      });
      this.$data.webPhoto = result.headPhoto;
      this.$store.state.userInfo = result;
      this.$store.state.userInfo_canWrite = { ...result };
    },
    async addPhoto (response) {
      const { data, message } = response;
      const {
        userInfo_canWrite,
        userInfo_canWrite: { sex, vipRank, vipType },
      } = this.$store.state;
      const form = {
        ...userInfo_canWrite,
        sex: sex.id,
        vipRank: vipRank.id,
        vipType: vipType.id, headPhoto: data,
      };
      await this.$request.post({
        url: "/user/change/info",
        data: form
      });
      this.$store.state.userInfo.headPhoto = data;
      this.$data.webPhoto = data;
      this.$store.state.userInfo_canWrite.headPhoto = data;
      this.$message({
        showClose: true,
        message: message,
        duration: 2000,
      });
    },
    exitLogin () {
      this.$confirm("确定要退出登录么", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.GIA_token = "";
        this.$router.push("/login");
      });
    },
  },
  mounted () {
    this.getUserInfo();
    const { path } = this.$route;
    this.$data.defaultActive = "/" + path.split("/")[2];
  },
};
</script>
<style scoped lang="less">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 690px;
}
.GIA_associator-box {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #f4f5f5;
  .associator-header {
    background: #fff;
    border-bottom: 1px solid #f1f1f1;
    color: #909090;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 250;
    box-shadow: 0 2px 5px 1px rgba(204, 204, 204, 0.336);
    .header-left {
      --imgSize: 65px;
      display: flex;
      align-items: center;
      span {
        font-size: 25px;
        font-weight: 600;
        margin-left: 10px;
      }
      img {
        border-radius: 50%;
        width: var(--imgSize);
        height: var(--imgSize);
      }
    }
  }

  .main {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    .nav-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 24px;
      height: 175px;
      justify-content: space-around;
    }
    .left-nav {
      position: fixed;
      width: 240px;
      top: 94px;
      bottom: 0;
      background: #fff;
      z-index: 2;
      overflow: hidden;
      margin-bottom: 14px;
      box-shadow: 2px 2px 4px 1px #cccccc46;
      border-radius: 4px;
      .el-menu {
        height: calc(100% - 195px);
        overflow: auto;
        border-right: none !important;
        .menu-once {
          a {
            width: 100%;
            height: 100%;
            display: block;
          }
          .iconfont {
            font-size: 18px !important;
            margin-right: 5px;
          }
        }
      }
    }
    .route-box {
      border-radius: 4px;
      margin: 94px 0 0 264px;
      width: calc(100% - 264px);
      height: calc(100vh - 108px);
      overflow: auto;
      box-shadow: 2px 2px 4px 1px #cccccc46;
      background-color: #fff;
    }
  }
}
.el-popover.el-popper {
  width: 100px !important;
  min-width: 100px !important;
  .base-list {
    width: 100px;
    margin: 0 auto;
    .base-item {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
    }
  }
}
.setup-box {
  background-color: transparent;
  outline: none;
  border: none;
  &:hover {
    color: rgb(131, 131, 131);
  }
  .icon-change {
    position: relative;
    color: rgb(131, 131, 131);
    font-size: 40px;
  }
}
.photo-select {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 5px;
}
</style>