<template>
  <div class="forget-form-box">
    <p class="title">
      <span>重设密码</span>
    </p>
    <el-form
      ref="forgetRef"
      :rules="rules"
      :model="forgetForm"
      label-width="120px"
    >
      <!-- <el-form-item label="验证方式:">
        <el-radio-group v-model="authentication" size="small">
          <el-radio-button label="mobilePhone">手机号</el-radio-button>
          <el-radio-button label="email">邮箱</el-radio-button>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="绑定的邮箱:">
        <el-input
          placeholder="请输入邮箱"
          disabled
          v-model="$store.state.userInfo.email"
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码:" class="code" prop="code">
        <el-input
          class="codeInput"
          placeholder="验证码"
          v-model="forgetForm.code"
          type="code"
          maxlength="8"
        ></el-input>
        <el-button
          class="code-send"
          type="primary"
          @click="() => sendCode()"
          :disabled="timeout ? true : false"
          >{{ timeout ? timeout + "s" : "获取验证码" }}</el-button
        >
      </el-form-item>
      <el-form-item label="设置密码:" prop="password">
        <el-input
          placeholder="请输入密码,数字和字母组合"
          type="password"
          v-model="forgetForm.password"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="resure">
        <el-input
          placeholder="请再次输入密码"
          type="password"
          v-model="forgetForm.resure"
        ></el-input>
      </el-form-item>
      <el-button class="forget-btn" @click="() => findPwd()" type="primary"
        >提交修改</el-button
      >
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timeout: 0,
      forgetForm: {
        email:"",
        // 密码
        password: "",
        // 确认密码
        resure: "",
        // 验证码
        code: "",
        accountType:""
      },
      rules: {
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        resure: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              // 判断是否密码相同
              const { password } = this.$data.forgetForm;
              if (value !== password) {
                callback(new Error("密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async sendCode() {
      // 验证的值
      this.$data.timeout = 60;
      this.sendCodeInterval = setInterval(() => {
        this.$data.timeout -= 1;
        if (this.$data.timeout == 0) {
          clearInterval(this.sendCodeInterval);
          this.sendCodeInterval = null;
        }
      }, 1000);
      try {
        const { email } = this.$store.state.userInfo;
        await this.$request.post({
          url: "/user/sendCode/" + email,
        });
      } catch (error) {
        this.$data.timeout = 0;
        clearInterval(this.sendCodeInterval);
        this.sendCodeInterval = null;
      }
    },
    findPwd() {
      // 调用父组件的changeActive并切换路由
      this.$refs["forgetRef"].validate(async (valid) => {
        if (valid) {
          const {email, password, code, accountType } = this.$data.forgetForm;
          await this.$request.post({
            url: "/user/findPwdByMail",
            data: {
              email : email,
              code: code,
              newPwd: password,
              accountType:accountType
            },
          });
          this.$message({
            showClose: true,
            message: "修改成功!",
            type: "success",
            duration: 2000,
          });
        }
      });
    },
  },
  mounted(){
    this.forgetForm.email=this.$store.state.userInfo.email;
    this.forgetForm.accountType=this.$store.state.userInfo.vipType.id;
  }
};
</script>
<style lang="less" scoped>
.forget-form-box {
  margin: 0 auto;
  background-color: #fff;
  width: 600px;
  max-height: 550px;
  border-radius: 8px;
  padding: 10px 30px;
  overflow: auto;
  .title {
    margin: 50px 0;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    position: relative;
    .iconfont {
      font-size: 25px !important;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .forget-btn {
    margin: 5px auto;
    display: block;
  }
  .regulations {
    color: #409eff;
  }
  .code {
    position: relative;
  }
  .code-send {
    position: absolute;
    right: 0;
  }
}
</style>