<template>
  <div>
    <articleBox
    :content='content'
    >
    <div class="gia">
      <ul>
        <li>广东省工业软件学会</li>
        <li>联系人：刘老师</li>
        <li>联系电话：（020）84887464</li>
        <li>微信同号：18102637002</li>
        <li>地址：广州市番禺区小谷围大学城外环西路318号3栋220室</li>
        <li>邮箱：gisf_gz@163.com</li>
        
      </ul>
      
    </div>
    <img src="../../../image/schoolPlace.png" alt="">
    </articleBox>
  </div>
</template>
<script>

import articleBox from "../../../components/home/articleBox.vue"
export default {
  components: {
    articleBox
  },
  data () {
    return {
      content:{
        title: "联系我们"
      }
    };
  },
  methods:{

  }
}
</script>
<style lang="less" scoped>
.gia{
      font-size: 20px;
    /* padding: 30px 100px 100px 100px; */
    line-height: 35px;
}
  img{
    width: 600px;
    height: 500px;
    margin-top: 50px;
  }
</style>