import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './request'
import less from 'less'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './css/iconfont.css'
import './prototype.js'
import * as echarts from 'echarts'
import qs from 'qs'
Vue.prototype.$echarts = echarts


Vue.use(less)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$baseUrl = 'http://101.33.202.28:7023'
Vue.prototype.$request = request(Vue)
new Vue({
  router,
  render: h => h(App),
  store,
  beforeCreate () {
  }   //全局事件
}).$mount('#app')
