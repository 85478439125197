<template>
  <div>
    <articleBox :content="content">
        <div class="WordSection1" style="layout-grid: 21.85pt">
          <p
            align="center"
            class="MsoNormal"
            style="text-align:center;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span
              style="font-size:22.0pt;font-family:方正小标宋简体;&#10;color:black"
              >广东省工业软件学会章程</span
            >
          </p>

          <p
            align="center"
            class="MsoNormal"
            style="text-align:center;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span style="font-family: 仿宋_GB2312; color: black">（草案）</span>
          </p>

          <p class="MsoBodyText"><span lang="EN-US">&nbsp;</span></p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第一章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">总则</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第一条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;本会的名称是广东省工业软件学会。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会是由广东省从事工业软件教学、研究、应用相关的高等院校、科研院所、企业、产业园区等机构和专家自愿组成的学术性的非营利性社会团体法人。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的宗旨：</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >遵守国家的法律、法规和政策，践行社会主义核心价值观，遵守社会道德风尚，弘扬爱国主义精神。</span
            ><u><span style="font-family: 仿宋_GB2312; color: black"> </span></u
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >深入落实国家软件发展战略，发挥科研机构、高校、高科技企业资源优势，</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >推动工业软件领域的知识普及和技术创新，</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >促进工业软件科技成果转化与产业化，为助推我省工业软件高质量发展发挥积极的作用。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会坚持中国共产党的全面领导，根据中国共产党章程的规定，设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;本会的登记管理机关是广东省民政厅</span
            ><span style="font-family: 仿宋_GB2312; color: black">，</span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >业务主管单位是广东省科学技术协会</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black">。</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会接受登记管理机关、业务主管单位以及行业管理部门和其他部门依法在其职权范围内的监督管理和指导服务。</span
            >
          </p>

          <p class="MsoNormal" style="text-indent: 32pt; line-height: 28pt">
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六条</span
            ><span lang="EN-US" style="font-family:&#10;仿宋_GB2312;color:black"
              >&nbsp; </span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会根据工作需要设立分支机构、代表机构。</span
            >
          </p>

          <p class="MsoNormal" style="text-indent: 32pt; line-height: 28pt">
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第七条</span
            ><span lang="EN-US" style="font-family:&#10;仿宋_GB2312;color:black"
              >&nbsp; </span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会的活动地域为广东省。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的住所设在</span
            ><span style="font-family: 仿宋_GB2312"
              >广州市番禺区<span style="color: black">。</span></span
            >
          </p>

          <p class="MsoBodyText"><span lang="EN-US">&nbsp;</span></p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第二章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">业务范围和活动原则</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第九条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的业务范围：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）开展工业软件科学技术的创新研究；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）开展工业软件科学技术的学术交流活动；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）推动工业软件科研成果转化；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）制定工业软件科学技术标准；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（五）编辑并依法出版工业软件科学技术的刊物。</span
            >
          </p>

          <p class="MsoNormal" style="text-indent: 32pt; line-height: 28pt">
            <span style="font-family: 仿宋_GB2312; color: black"
              >以上业务范围涉及法律法规规章规定须经批准的事项，依法经批准后方可开展。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的活动原则：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）<span style="letter-spacing: -0.3pt"
                >法人治理应当符合国家有关法律法规的规定；</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）按照登记管理机关核准的章程开展非营利性活动，不从事商品销售，经费用于本章程规定的业务范围，不在会员中和负责人当中分配；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）建立决策机构、执行机构及监督机构相互监督机制，实行民主选举、民主决策、民主监督；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）开展业务活动时，遵循诚实守信、公正公平原则，不弄虚作假，不损害国家、本会和会员利益；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（五）遵循科学办会原则，不从事封建迷信宣传和活动。</span
            >
          </p>

          <p class="MsoBodyText"><span lang="EN-US">&nbsp;</span></p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第三章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">会员</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十一条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span
              style="font-family:&#10;仿宋_GB2312;color:black;letter-spacing:.15pt"
              >本会会员由省内</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >个人会员和单位会员</span
            ><span
              style="
                font-family: 仿宋_GB2312;
                color: black;
                letter-spacing: 0.15pt;
              "
              >组成。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十二条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span
              style="font-family:&#10;仿宋_GB2312;color:black;letter-spacing:.15pt"
              >申请加入本会，应当拥护本会章程，有加入本会意愿。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >个人会员具备下列条件：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）遵守国家宪法和有关法律、法规；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）具备工业软件领域的专业技能；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）在工业软件领域内具有一定的影响力。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >单位会员具备下列条件：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）遵守国家宪法和有关法律、法规；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）从事工业软件领域教学、研究、应用的高等院校、科研院所或企业；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）在广东省内依法登记注册。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十三条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >会员入会的程序是：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）提交入会申请书；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）经理事会讨论通过；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）由理事会或其授权的机构颁发会员证；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）载入会员名册，及时在本会网站、通讯刊物等予以公告。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十四条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;本会建立全体会员名册，明确会员、理事、常务理事、监事长、监事以及会长、副会长、秘书长等负责人职务，作为证明其资格的充分证据。会员资格发生变化的，及时修改名册并予以公告。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十五条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >会员享有下列权利：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）出席会员代表大会，参加本会的活动权；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）本会的选举权、被选举权和表决权；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）获得本会服务的优先权；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）入会自愿、退会自由权；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（五）查阅本会章程、会员名册、会议记录、会议决议、财务审计报告等知情权；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（六）对本会工作的提议案权、批评建议权和监督权；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（七）法律、法规、规章以及本会章程规定的其他权利。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十六条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >会员履行下列义务：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）遵守本会的章程；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）执行本会的决议；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）维护本会的合法权益；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）完成本会交办的工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（五）向本会反映情况，提供有关资料；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（六）按规定交纳会费；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black">（七）</span
            ><span style="font-family: 仿宋; color: black"
              >法律、法规、规章以及本会章程规定的其他义务</span
            ><span style="font-family: 仿宋_GB2312; color: black">。</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十七条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >会员退会应书面通知本会，并交回会员证。会员超过<span lang="EN-US"
                >1</span
              >年不履行义务的，可视为自动退会。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第十八条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;会员有下列情形之一的，其相应会员资格自动丧失：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）不符合本会会员条件的；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）被行政机关吊销许可证件的；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）受到刑事处罚的；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（四）申请退会的；</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（五）</span
            ><span style="font-family:&#10;仿宋_GB2312"
              >严重违反本会章程及有关规定，给本会造成重大名誉损失和经济损失的；</span
            ><span style="font-family:&#10;仿宋_GB2312"> </span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（六） </span
            ><span style="font-family:&#10;仿宋"
              >拒不参加本会的各种活动、不配合本会工作的。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第十九条</span
            ><span
              lang="EN-US"
              style='&#10;font-family:"Times New Roman",serif;color:black'
              >&nbsp; </span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >会员违反法律法规，或严重违反本会章程及有关规定，给本会造成重大名誉损失和经济损失的，经理事会表决通过，给予下列处分：</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312;color:black"
              >（一）警告；</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312;color:black"
              >（二）通报批评；</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312;color:black"
              >（三）暂停行使会员权利；</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312;color:black"
              >（四）除名。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312;color:black"
              >会员如对本会的处分决定不服，可提出申诉，由理事会经审议后作出答复，必要时提交会员代表大会审议。</span
            >
          </p>

          <p style="text-indent: 32pt; line-height: 28pt">
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第二十条</span
            ><span lang="EN-US" style="&#10;font-family:仿宋_GB2312;color:black"
              >&nbsp; </span
            ><span style="&#10;font-family:仿宋_GB2312;color:black"
              >会员退会、自动丧失会员资格或者被除名后，其在本会相应的职务、权利、义务自行终止。会员资格终止后，本会收回其会员证，并及时修改会员名册，且在本会网站、通讯刊物上更新会员名单。</span
            >
          </p>

          <p style="text-indent: 32pt; line-height: 28pt">
            <span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp;</span
            >
          </p>

          <p style="text-indent: 32pt; line-height: 28pt">
            <span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp;</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第四章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">组织机构</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二十一条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会实行民主办会。领导机构的产生和重大事项的决策，须经民主表决通过，按少数服从多数的原则作出决定。</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <em><span style="font-family: 宋体">第一节</span></em
            ><em
              ><span lang="EN-US" style="font-family: '等线 Light'"
                >--</span
              ></em
            ><em><span style="font-family: 宋体">会员代表大会</span></em>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二十二条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的最高权力机构是会员代表大会。会员代表大会由会员代表组成，其议事表决采取无记名投票的方式。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >会员代表大会行使下列职权：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）制订或修改章程；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）决定本会的工作目标和发展规划；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）制定和修改会员代表、理事、常务理事、负责人及监事长、监事选举办法；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）选举或者罢免理事、监事长、监事，罢免会长、副会长、秘书长；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（五）审议理事会的工作报告和财务报告；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（六）审议监事会的工作报告；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（七）制定、修改会费缴纳标准；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（八）对本会变更、合并、分立、解散（终止）和清算等事项作出决议；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（九）改变或者撤销理事会不适当的决定；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（十）决定其他重大事宜。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >会员代表大会的职权不得通过授权由理事会或其他机构和个人代为行使，但法律、法规另有规定和本章程特别授权的除外。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第二十三条</span
            ><span lang="EN-US" style="&#10;font-family:仿宋_GB2312;color:black"
              >&nbsp; </span
            ><span style="&#10;font-family:仿宋_GB2312;color:black"
              >会员代表大会</span
            ><span style="&#10;font-family:仿宋_GB2312"
              >原则上每<span lang="EN-US">1</span>年召开<span lang="EN-US"
                >1</span
              >次。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312"
              >因特殊情况需提前或延期换届的，须由理事会表决通过，报业务主管单位审查同意，经登记管理机关批准。但延期换届最长不超过<span
                lang="EN-US"
                >1</span
              >年。</span
            >
          </p>

          <p class="MsoNormal" style="text-indent: 32pt; line-height: 28pt">
            <span style="font-family: 仿宋_GB2312; color: black"
              >五分之一以上会员代表、三分之一以上理事、监事会提议召开临时会议的，应当在<span
                lang="EN-US"
                >15</span
              >个工作日内召开临时会员代表大会。</span
            >
          </p>

          <p class="MsoNormal" style="text-indent: 32pt; line-height: 28pt">
            <span style="font-family: 仿宋_GB2312; color: black"
              >会员代表大会由理事会或常务理事会召集，会长主持；会长不能或不主持的，由副会长主持；副会长不能或不主持的，由提议召集人推举一名负责人主持。</span
            >
          </p>

          <p class="MsoNormal" style="text-indent: 32pt; line-height: 28pt">
            <span style="font-family: 仿宋_GB2312; color: black"
              >理事会或常务理事会不能或不履行召集会员代表大会职责的，由监事会召集和主持；监事会不能或不召集和主持的，五分之一以上会员代表、三分之一以上理事可以自行召集，并推举一名负责人主持。</span
            >
          </p>

          <p class="MsoNormal" style="line-height: 28pt">
            <span lang="EN-US" style="font-family: 仿宋_GB2312"
              >&nbsp;&nbsp; <span style="color: black">&nbsp;</span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >五分之一以上会员代表或三分之一以上理事联名提议召开会员代表大会临时会议时，应提交由全体联名会员代表或理事签名的提议函。监事会或监事提议召开会员代表大会临时会议时，应递交由监事会盖章或过半数监事签名的提议函。提议召开会员代表大会临时会议的提议者均应提出事由及议题。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二十四条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >会员代表大会必须有全体会员代表的三分之二以上出席方为有效，其决议须经到会会员代表的过半数以上通过。修改章程、会费缴纳标准和决定本会合并、分立、终止等重大事项须经出席会议的到会会员代表三分之二以上表决通过。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >会员代表可以委托他人代理出席会员代表大会，代理人应当出示授权委托书并交本会秘书处备案，在授权范围内行使表决权。每名代理人只能接受一份委托。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >本会召开会员代表大会，须提前<span lang="EN-US">20</span
              >日将本会会议召开的时间、地点和审议的事项通知会员代表。临时会员代表大会须提前<span
                lang="EN-US"
                >15</span
              >日通知会员代表。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >会员代表大会不得对前款通知中未列明的事项作出决议。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >会员代表大会应当对所议事项的决定作会议纪要，出席会议的理事应当在会议记录上签名，并向会员代表公告。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二十五条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >会员代表大会换届，应当在大会召开前</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312">3</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >个月，由理事会提名，成立由党员代表、理事代表、监事代表和会员代表组成的换届工作领导小组。理事会不能或不召集，按本章程第二十三条第五款的方式召集，成立由<span
                lang="EN-US"
                >1/5</span
              >以上理事、监事、本会党组织班子成员或党建联络员组成的换届工作领导小组，负责换届选举工作。换届工作领导小组拟定换届方案，应在会员代表大会召开前<span
                lang="EN-US"
                >2</span
              >个月报业务主管单位审查同意、登记管理机关审核。经同意方可召开换届会员代表大会。</span
            ><span style="font-family: 仿宋_GB2312"
              >确因特殊情况不能按时换届的，应经本会理事会通过，报业务主管单位审查同意、登记管理机关审核。</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <em><span style="font-family: 宋体">第二节</span></em
            ><em
              ><span lang="EN-US" style="font-family: '等线 Light'"
                >--</span
              ></em
            ><em><span style="font-family: 宋体">理事会</span></em>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二十六条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会设理事会。理事会由会长、副会长、秘书长、理事组成。理事会为会员代表大会的执行机构，依照会员代表大会的决议和本会章程的规定履行职责。理事会任期</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312">5</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black">年</span
            ><span style="font-family: 仿宋_GB2312">。</span>
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312"
              >每个理事单位只能选派一名代表担任理事。单位调整理事代表，由其书面通知本会，报理事会或者常务理事会备案。该单位同时为常务理事的，其代表一并调整。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋; color: black"
              >根据会员代表大会的授权，理事会在届中可以增补、罢免部分理事，最高不超过原理事总数的</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >1/5</span
            ><span style="font-family: 仿宋; color: black">。</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二十七条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >理事会的职权是：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）筹备和召集</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会，负责换届选举工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（二）执行会员代表大会的决议，并向会员代表大会报告工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（三）选举会长、副会长、秘书长；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="line-height: 28pt; layout-grid-mode: char"
          >
            <span lang="EN-US" style="font-family: 仿宋_GB2312"
              >&nbsp;&nbsp;&nbsp; </span
            ><span style="font-family: 仿宋_GB2312"
              >（四）决定本会具体的工作业务；
            </span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（五）向会员代表大会报告工作和财务<span style="color: black"
                >状况；</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（六）拟定本会的年度财务预算方案、决算、变更、解散和清算等事项的方案；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（七）制订本会章程修改草案和增（减）注册资金的方案，提交</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会<span style="color: black">审议；</span></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（八）决定提前或延期换届；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（九）审议年度工作报告和工作计划；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（十）审议年度财务收支预算、决算；
            </span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（十一）决定本会各内部机构、分支机构、代表机构的设立、变更和终止，并领导各机构开展工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（十二）决定新申请人的入会和对会员的处分；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（十三）决定本<span style="color: black"
                >会分支机构主要负责人；根据秘书长提名，聘任或者解聘副秘书长和本会办事机构、代表机构主要负责人，决定其报酬事项；选举和罢免常务理事；</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（十四）制订本会内部管理制度；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（十五）改变或者撤销常务理事会不适当的决定。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:31.35pt;&#10;line-height:28.0pt"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第二十八条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >理事会原则上每半年至少召开一次会议，</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >由会长召集和主持。</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >会长因故不能出席会议的，由会长授权的副会长或秘书长主持。召开理事会会议，会长或召集人需提前<span
                lang="EN-US"
                >3</span
              >日通知全体理事并告知会议议题。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:31.35pt;&#10;line-height:28.0pt"
          >
            <span style="font-family:仿宋_GB2312;&#10;color:black"
              >理事会须有三分之二以上理事出席方能召开，其决议须经到会理事三分之二以上表决通过方能生效。理事会应当对决议形成会议纪要，</span
            ><span style="font-family: 仿宋; color: black"
              >出席会议的理事应当在会议记录上签名</span
            ><span style="font-family: 仿宋_GB2312; color: black">，并向</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >全体理事公告。</span
            >
          </p>

          <p style="text-indent: 31.35pt; line-height: 28pt">
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第二十九条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >五分之一以上</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表、三分之一以上理事或监事会提议召开临时会议的，会长应当</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312">5</span
            ><span style="font-family: 仿宋_GB2312">个工作日内</span
            ><span style="font-family: 仿宋_GB2312">召集和主持理事会会议；</span
            ><span style="font-family: 仿宋_GB2312">会长认为必要时，亦可</span
            ><span style="font-family: 仿宋_GB2312">召集和主持临时会议。</span>
          </p>

          <p
            style="text-indent: 32pt; line-height: 28pt; layout-grid-mode: char"
          >
            <span style="font-family: 仿宋_GB2312"
              >会长不能或不召集和主持的，由副会长召集和主持；副会长不能或不召集和主持的，由提议召集人推举一名负责人召集和主持。</span
            >
          </p>

          <p
            style="text-indent: 32pt; line-height: 28pt; layout-grid-mode: char"
          >
            <span style="font-family: 仿宋_GB2312">五分之一以上会员代表或</span
            ><span style="font-family: 仿宋_GB2312"
              >三分之一以上理事联名提议召开理事会临时会议时，应提交由全体联名</span
            ><span style="font-family: 仿宋_GB2312">会员代表或</span
            ><span style="font-family: 仿宋_GB2312"
              >理事签名<span style="color: black">的提议函。</span></span
            ><span style="font-family: 仿宋_GB2312; color: black">监事会或</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >监事提议召开理事会临时会议时，应递交由监事会盖章或过半数监事签名的提议函。提议召开理事会临时会议的提议者均应提出事由及议题。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >理事会会议，应由理事本人出席。因故未能出席的理事，可以书面委托他人出席，代理人应当出示授权委托书，在授权范围内行使表决权。每名代理人只能接受一份委托。</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <em><span style="font-family: 宋体">第三节</span></em
            ><em
              ><span lang="EN-US" style="font-family: '等线 Light'"
                >--</span
              ></em
            ><em><span style="font-family: 宋体">常务理事会</span></em>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十一条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会设常务理事会，是理事会的常设机构，由会长、副会长、秘书长、常务理事组成。常务理事会在理事会闭会期间行使本章程第二十七条</span
            ><span style="font-family: 仿宋_GB2312; color: black">第</span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >一、二、四、五、六、七、九、</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >十、十一、十二、十四项职权，对理事</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >会负责。常务理事会与理事会任期一致。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十二条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >常务理事会原则上每半年召开一次会议，</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >由会长召集和主持。</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >会长因故不能出席会议的，由会长授权的副会长或秘书长主持。召开理事会会议，会长或召集人需提前<span
                lang="EN-US"
                >3</span
              >日通知全体理事并告知会议议题。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >常务理事会须有三分之二以上常务理事出席方能召开，其决议必须经到会常务理事三分之二以上通过。常务理事会应当对所议事项的决定作会议记录，出席会议的理事应当在会议记录上签名，并向全体理事公告。</span
            >
          </p>

          <p style="text-indent: 31.35pt; line-height: 28pt">
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第三十三条</span
            ><span style="&#10;font-family:仿宋_GB2312;color:black"> </span
            ><span style="&#10;font-family:黑体;color:black">&nbsp;</span
            ><span style="&#10;font-family:仿宋_GB2312;color:black"
              >五分之一以</span
            ><span style="&#10;font-family:仿宋_GB2312"
              >上会员代表、<span style="color: black"
                >三分之一以上理事或监事会提议召开临时会议的，会长应当</span
              ></span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >5</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >个工作日内</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >召集和主持常务理事会会议；</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >会长认为必要时，亦可</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >召集和主持常务理事会临时会议。</span
            >
          </p>

          <p
            style="text-indent: 32pt; line-height: 28pt; layout-grid-mode: char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >会长不能或不召集和主持的，由副会长召集和主持；副会长不能或不召集和主持的，由提议召集人推举一名负责人召集和主持。</span
            >
          </p>

          <p
            style="text-indent: 32pt; line-height: 28pt; layout-grid-mode: char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >五分之一以上</span
            ><span style="font-family: 仿宋_GB2312">会员代表或</span
            ><span style="font-family: 仿宋_GB2312"
              >三分之一以上理事联名提议召开常务理事会临时会议时，应提交由全体联名</span
            ><span style="font-family: 仿宋_GB2312">会员代表或</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >理事签名的提议函。</span
            ><span style="font-family: 仿宋_GB2312; color: black">监事会</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >提议召开理事会临时会议时，应递交由监事会盖章或过半数监事签名的提议函。提议召开理事会临时会议的提议者均应提出事由及议题。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十四条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >常务理事会会议，应由常务理事本人出席。因故未能出席的常务理事，可以书面委托他人出席，代理人应当出示授权委托书，在授权范围内行使表决权。每名代理人只能接受一份委托。</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <em><span style="font-family: 宋体">第四节</span></em
            ><em
              ><span lang="EN-US" style="font-family: '等线 Light'"
                >--</span
              ></em
            ><em><span style="font-family: 宋体">监事会</span></em>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十五条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会设监事会。监事会设监事长<span lang="EN-US">1</span>名，</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >监事<span lang="EN-US">3<u> &nbsp;</u></span
              >名</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >。监事任期与理事任期一致，期满可以连任。</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family: 仿宋_GB2312"
              >本会接受并支持委派监事的监督指导。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">监事从会员中选举产生，</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会的负责人、理事、常务理事、副秘书长和财务人员不得兼任监事。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十六条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >监事会行使下列职权：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）列席理事会、常务理事会会议，对理事会、常务理事会决议事项提出质询或建议；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）对理事、常务理事执行本会职务的行为进行监督，对违反法律法规和本会章程或者</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会决议的人员提出依程序罢免的建议；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（三）检查本会的财务报告，向会员代表大会<span
                style="color: black"
                >报告监事工作和提出建议；</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）对负责人、常务理事、理事、财务人员损害本会利益的行为，及时予以纠正<span
                lang="EN-US"
                >;</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（五）向业务主管单位和登记管理机关以及税务、会计主管等有关部门反映本会工作中存在的问题；</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family:仿宋_GB2312;&#10;color:black">（六）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >提议召开临时</span
            ><span style="font-family: 仿宋_GB2312">会员代表大会</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >和理事会会议；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（七）决定其他应由监事会审议的事项。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十七条 </span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >&nbsp;监事会议原则上每<span lang="EN-US">6</span>个月召开<span
                lang="EN-US"
                >1</span
              >次会议。监事会议须有三分之二以上监事出席方能召开，其决议须经全体监事过半数表决通过方为有效。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >监事会的决议事项应当做出记录，出席会议的监事及记录员应在会议记录上签名。监事可以要求在会议记录上对其在会议上的发言做出某些说明性记载。监事会的决定、决议及会议记录等应当妥善保管，并向全体会员公开。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十八条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black">本会</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会、<span style="color: black"
                >理事会、监事会进行表决，应当采取民主方式进行。选举理事、常务理事、监事长、监事以及负责人，应当采取现场无记名投票方式进行。</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >以上会议应当制作会议记录，形成决议的，应当制作会议纪要和会议决议。理事会、常务理事会、监事会的会议决议应当由出席会议的理事、常务理事、监事当场审阅签名。会员有权查阅本会章程、规章制度、各种会议决议、会议纪要和财务会计报告。</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <em><span style="font-family: 宋体">第五节</span></em
            ><em
              ><span lang="EN-US" style="font-family: '等线 Light'"
                >--</span
              ></em
            ><em><span style="font-family: 宋体">负责人</span></em>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第三十九条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的负责人是指会长<span lang="EN-US">1</span
              >名、副会长若干名、秘书长<span lang="EN-US">1</span>名。</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family: 仿宋_GB2312"
              >会长、副会长、秘书长每届任期与</span
            ><span style="font-family: 仿宋_GB2312">会员代表大会</span
            ><span style="font-family: 仿宋_GB2312"
              >任期相同，会长、秘书长连任不得超过两届。因特殊情况需延长任期的，须采取差额选举方式，经出席</span
            ><span style="font-family: 仿宋_GB2312">会员代表大会</span
            ><span style="font-family: 仿宋_GB2312"
              >表<span style="color: black"
                >决通过，报业务主管单位审查同意、登记管理机关备案后方可任职。</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会负责人应当遵守法律、法规和章程的规定，忠实履行职责，维护本会的权益，遵守下列行为准则：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）在职务范围内行使权利，不越权；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）不得利用职权为自己或他人谋取不正当利益；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）不得从事损害本会利益的活动；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）国家机关工作人员或退休干部兼任包括名誉职务、负责人、常务理事、理事、监事等，须按干部管理权限审批或备案后方可兼职；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十一条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会负责人需具备下列条件：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（一）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >坚持党的路线、方针、政策；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（二）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >业内公认具有丰富的专业知识，良好的组织领导能力及协调能力，社会信用良好；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（三）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >在本会业务领域内有较大的影响和较高的声誉；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（四）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >最高任职年龄一般不超过<span lang="EN-US">70</span
              >周岁，身体健康，能坚持正常工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（五）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >未受过剥夺政治权利的刑事处罚的；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（六）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >具有完全民事行为能力；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（七）</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >能够勤勉履行职责、维护本会和会员的合法权益；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:0cm;text-indent:32.0pt;line-height:28.0pt;&#10;layout-grid-mode:char"
          >
            <span lang="EN-US">（八）</span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >无法律法规规章和政策规定不得担任的其他情形。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十二条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会负责人的任期与理事会的届期相同，会长、法定代表人连任一般不超过两届。因特殊情况需继续连任的，</span
            ><span style="font-family: 仿宋_GB2312">须采取差额选举方式，</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black">经</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会<span style="color: black"
                >表决通过，报业务主管单位审查同意、经登记管理机关审批备案后，方可任职。</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十三条 </span
            ><span style="font-family: 仿宋_GB2312; color: black">&nbsp;</span
            ><span style="font-family:&#10;仿宋_GB2312"
              >本会会长为法定代表人。如因特殊情况需由副会长或秘书长担任法定代表人时，应经理事会讨论表决通过，报业务主管单位审查同意、登记管理机关批准后，方可担任并行使法定代表人的职权。<span
                style="color: black"
                >本会法定代表人不得同时担任其它社会团体的法定代表人。法定代表人应当由广东省内工作或生活的中国内地居民担任。</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十四条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >需要本会法定代表人做出决定而法定代表人因特殊原因不能履行职责的，由理事会按少数服从多数的原则做出决定并形成决议。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >法定代表人任职期间，本会发生违反法律、《社会团体登记管理条例》和本章程的行为，法定代表人应当承担相关责任。因法定代表人失职，导致本会发生违法行为或财产损失的，法定代表人应当承担个人责任。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >本会的负责人被罢免或卸任后，不再履行本会法定代表人的职权。由本会在其被罢免或卸任后的<span
                lang="EN-US"
                >20</span
              >日内，报业务主管单位审查同意后，向登记管理机关办理变更登记。</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family:仿宋_GB2312;&#10;color:black"
              >原任法定代表人不予配合办理法定代表人变更登记的，本会可根据理事会同意变更的决议，报业务主管单位审查同意后，向登记管理机关申请变更登记。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十五条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会会长行使下列职权：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）召集、主持理事会（常务理事会）；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）检查各项会议决议的落实情况；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）领导理事会（常务理事会）工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）章程规定的其他职权。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; font-weight: 600">第四十六条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312">&nbsp; </span
            ><span style="font-family: 仿宋_GB2312"
              >本会法定代表人行使下列职权：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（一）代表本会签署重要文件；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（二）法律法规规章和章程规定的其他职权。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十七条</span
            ><span
              lang="EN-US"
              style='font-family:"Times New Roman",serif;&#10;color:black'
              >&nbsp; </span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会的秘书长采用选任制，会长不得兼任秘书长。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十八条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;本会<span style="letter-spacing:&#10;.15pt"
                >秘书长在理事会领导下开展工作，行使下列职权：</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）主持内设机构开展日常工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）提名副秘书长及内设机构和实体机构主要负责人，交理事会决定；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）<span style="letter-spacing: -0.3pt"
                >提议专职工作人员的聘免，交</span
              >理事会<span style="letter-spacing:&#10;-.3pt">决定；</span></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（四）<span style="letter-spacing: -0.3pt"
                >拟定年度工作报告和计划，报</span
              >理事会<span style="letter-spacing:&#10;-.3pt">审议；</span></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（五）拟订内部管理规章制度，报理事会批准；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（六）拟订年度财务预算、决算报告，报理事会审议；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（七）协调各分支机构、代表机构、实体机构开展工作；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-left:86.0pt;text-indent:-54.0pt;line-height:&#10;28.0pt;layout-grid-mode:char"
          >
            <span lang="EN-US" style="font-family:仿宋_GB2312;&#10;color:black"
              >（八）<span style="font: 7pt 'Times New Roman'"
                >&nbsp;
              </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >处理其他日常事务。</span
            >
          </p>

          <p class="MsoBodyText"><span lang="EN-US">&nbsp;</span></p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第五章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">内部管理</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第四十九条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会设日常办事机构秘书处，处理本会日常事务性工作。秘书处办公会议各项议题，应形成会议纪要，抄送理事会和监事会。秘书处下设日常办事机构须经理事会同意。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;本会分支机构的设立、变更及终止，应当按照章程的规定，履行民主程序，提交理事会或常务理事会审议批准并形成决议，并向全体会员公布。</span
            ><span style="font-family: 仿宋_GB2312"
              >各分支机构的名称应冠以本会的名称，分支机构可以称分会、专业委员会、工作委员会等。代表机构可以称代表处、办事处、联络处等。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >本会不设立地域性分会，不冠以行政区划名称，不带有地域性特征。分支机构不再下设分支机构、代表机构。各分支机构根据本会章程规定的宗旨、任务和业务范围的需要设置，有明确的名称、负责人、业务范围、管理办法和组织机构等，报理事会表决通过并形成决议。</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family: 仿宋_GB2312"
              >本会的分支机构、代表机构是本会的组成部分，不具有法人资格、不另行制定章程、依据本会的授权开展活动、法律责任由本会承担。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char;background:white;text-autospace:ideograph-other"
          >
            <span style="font-family:&#10;黑体;color:black ;font-weight: 600;"
              >第五十</span
            ><span style="font-family: 黑体; color: black">一</span
            ><span style="font-family: 黑体; color: black">条</span
            ><span lang="EN-US" style="font-family:&#10;仿宋_GB2312;color:black"
              >&nbsp; </span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会应当按《劳动合同法》的规定与专职工作人员订立劳动合同。本会专职工作人员应当参加相关岗位培训，熟悉和了解社会团体法律、法规和政策，努力提高业务能力。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.25pt;&#10;line-height:28.0pt"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十二条</span
            ><span style="font-family: 'Times New Roman', serif; color: black">
              &nbsp;</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会建立各项内部管理制度，完善相关管理规程。建立《会员管理办法》《会员代表选举办法》《会费管理办法》《会员代表大会选举规程》《理事会选举规程》《财务管理制度》《分支机构、代表机构管理办法》和《办事机构管理办法》等相关制度和文件。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十三条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会建立健全证书、印章、档案、文件等内部管理制度，并将以上物品和资料妥善保管于本会场所，任何单位、个人不得非法侵占。管理人员调动工作或者离职时，必须与接管人员办清交接手续。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十四条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会证书、印章遗失时，经理事会三分之二以上理事表决通过，在登记管理机关所在行政区域公开发行的报刊声明作废，并向登记管理机关申请补领。如被个人非法侵占，应通过法律途径要求返还。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十</span
            ><span style="font-family: 黑体; color: black">五</span
            ><span style="font-family:黑体;&#10;color:black">条 </span
            ><span style="font-family: 仿宋; color: black">&nbsp;</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会建立民主协商和内部矛盾解决机制。如发生内部矛盾不能经过协商解决的，可以通过调解、诉讼等途径依法解决。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十六条</span
            ><span style="color: black"> &nbsp;</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >重大活动备案报告。</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会开展重大活动如召开会员（代表）大会，修改章程，涉及领导机构及负责人的选举，法定代表人和秘书长以上负责人变更等会议；举办大型研讨论坛，组织展览展销活动，创办经济实体，参与竞拍、投资或承接大型项目，开展涉外（包括港澳台地区）活动，接受境外捐赠或赞助，发生对本会有重大影响的诉讼活动等，应按有关规定提前<span
                lang="EN-US"
                >30</span
              >天向登记管理机关和相关业</span
            ><span style="font-family: 仿宋; color: black">务</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >部门作书面报告，并自觉接受相关业务部门的指导；</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会重大事项备案报告均采用书面形式，主要内容包括：活动的内容、方式、规模、参加人员、时间、地点、经费等方面。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十七条</span
            ><span lang="EN-US" style="color: black">&nbsp; </span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >评比达标表彰备案。面向会员开展评比达标表彰活动的，按照《社会组织评比达标表彰活动管理暂行规定》办理相关申报手续。须提前<span
                lang="EN-US"
                >60</span
              >天向登记管理机关备案。</span
            >
          </p>

          <p class="MsoBodyText"><span lang="EN-US">&nbsp;</span></p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <em
              ><span
                style="font-family: 宋体; font-style: normal"
                class="remark"
                >第六章</span
              ></em
            ><em
              ><span
                lang="EN-US"
                style="font-family: '等线 Light'; font-style: normal"
                >-</span
              ></em
            ><em
              ><span style="font-family: 宋体; font-style: normal"
                >财产管理和使用</span
              ></em
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十八条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的收入来源于：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（一）会费；</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（二）</span
            ><span style="font-family:&#10;仿宋"
              >自然人、法人或其他组织自愿捐赠</span
            ><span style="font-family: 仿宋_GB2312">；</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（三）</span
            ><span style="font-family:&#10;仿宋">政府购买服务或政府资助</span
            ><span style="font-family: 仿宋_GB2312">；</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（四）</span
            ><span style="font-family:&#10;仿宋"
              >在核准的业务范围内开展活动或服务的收入</span
            ><span style="font-family: 仿宋_GB2312">；</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（五）</span
            ><span style="font-family:&#10;仿宋">利息</span
            ><span style="font-family: 仿宋_GB2312">；</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312">（六）</span
            ><span style="font-family:&#10;仿宋">其他合法收入</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
            ><span style="font-family: 仿宋_GB2312">。</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第五十九条 </span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >&nbsp;本会依据章程规定的业务范围、工作成本和会员承受能力等因素，合理制定会费标准，遵循合理负担、权利义务对等的原则。会费须采用固定标准，不具有浮动性，采取无记名投票方式进行表决。自通过会费标准决议之日起<span
                lang="EN-US"
                >30</span
              >日内，向全体会员公开。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会会费标准如下：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black">（一）</span
            ><span style="font-family: 仿宋_GB2312"
              >会长<span style="color: black">每年缴纳会费</span>￥<span
                lang="EN-US"
                >50000</span
              >元<span style="color: black">；</span></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（二）常务副会长、副会长、秘书长、监事长每年缴纳会费￥<span
                lang="EN-US"
                >20000</span
              >元；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（三）常务理事、理事、监事每年缴纳会费￥<span lang="EN-US"
                >10000</span
              >元；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（四）单位会员每年缴纳会费￥<span lang="EN-US">2000</span
              >元；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.8pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >（五）个人会员每年缴纳<span style="color: black">会费</span
              >￥<span lang="EN-US">500</span>元。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.25pt;&#10;line-height:28.0pt"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十一条</span
            ><span
              lang="EN-US"
              style='font-family:"Times New Roman",serif;&#10;color:black'
              >&nbsp; </span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会经费必须用于本章程规定的业务范围和事业的发展，财产以及其他收入受法律保护，任何单位、个人不得侵占、私分和挪用。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.25pt;&#10;line-height:28.0pt"
          >
            <span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会开展评比表彰等活动，不收取任何费用。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.25pt;&#10;line-height:28.0pt"
          >
            <span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会收取的会费额度和标准应当明确，不具有浮动性。</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family:仿宋_GB2312;&#10;color:black"
              >本会分支机构、代表机构不单独制定会费标准，不重复收取</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;line-height:28.0pt"
          >
            <span style="font-family: 仿宋_GB2312; color: black">会费。</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十二条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的收入及其使用情</span
            ><span style="font-family: 仿宋_GB2312"
              >况应当定期向会员代表大会公布，接受会员代表大会的监督检查。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >经费来源属于财政拨款或社会捐赠、资助的，应当接受财政、审计机关的监督，并将有关情况以适当方式向社会公布。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十三条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会取得的收入除用于与本会有关的、合理的支出外，全部用于登记核定及本章程规定的非营利性或公益性事业，不得在会员中分配。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十四条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会的财产及其孳息不用于分配，但不包括合理的工资薪金支出。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十五条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会专职工作人员的工资和保险、福利待遇，由理事会按照国家相应的政策规定制定执行。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十</span
            ><span style="font-family: 黑体; color: black">六</span
            ><span style="font-family:黑体;&#10;color:black">条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会的资产，任何单位、个人不得侵占、私分和挪用。</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十</span
            ><span style="font-family: 黑体; color: black">七</span
            ><span style="font-family:黑体;&#10;color:black">条</span
            ><span style="color: black"> </span
            ><span style="font-family:&#10;仿宋;color:black">&nbsp;</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会执行《民间非营利组织会计制度》，依法进行会计核算、建立健全内部会计监督制度，保证会计资料合法、真实、准确、完整。本会使用国家规定的票据。本会接受税务、会计主管部门依法实施的税务监督和会计监督。</span
            >
          </p>

          <p
            class="MsoBodyText"
            style="text-align:justify;text-justify:inter-ideograph;&#10;text-indent:32.0pt;line-height:28.0pt"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十八条</span
            ><span style="font-family: 仿宋_GB2312; color: black"> &nbsp;</span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会每年聘请会计师事务所进行财务审计，审计结果向全体会员公告。本</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >会向聘用的会计师事务所提供真实、完整的会计凭证、会计账簿、财务会计报告及其他会计资料，不得拒绝、隐匿、谎报。本会聘用、解聘承办审计业务的会计师事务所，应由理事会表决通过。理事会就解聘会计师事务所进行表决时，允许会计师事务所陈述意见。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第六十九条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会财务实行统一核算，发生的各项经费在依法设置的会计帐薄上统一登记、核算。除法定的会计账簿外，不另立会计账簿。本会的资产，不以任何个人名义开立账户存储。本会的银行帐号、账户不得出租、出借或转让其他单位或个人使用。未经理事会批准，不得以本会名义借贷，不得将公款借给外单位，不得以本会名义对其他单位和个人提供经济担保。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第七十条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会配备具有专业资格的会计人员。会计不兼任出纳，实行账、钱、物分人管理。会计人员必须进行会计核算，实行会计监督。财务人员的调动和离职，必须按《会计法》的有关规定办理交接手续。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第七十一条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会每年<span lang="EN-US">1</span>月<span lang="EN-US">1</span
              >日至<span lang="EN-US">12</span>月<span lang="EN-US">31</span
              >日为业务及会计年度，每年<span lang="EN-US">3</span>月<span
                lang="EN-US"
                >31</span
              >日前，理事会对下列事项进行审定：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）上年度业务报告及经费收支决算；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（二）本年度业务计划及经费收支预算；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）财产清册。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第七十二条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会保证会计资料合法、真实、准确、完整。对会计凭证、会计帐薄、财务会计报告和其他会计资料应建立档案，妥善保管。会计凭证登记要清晰、工整，符合《会计基础工作规范》要求。所附原始凭证要求内容真实准确，取得的发票应为合格、有效。对不真实、不合法的原始凭证有权不接受，并向会长及法定代表人等相关负责人报告；对记载不准确、不完整的原始凭证予以退回，并要求按照国家统一的会计制度的规定更正、补充。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第七十三条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会建立财务收支情况报告制度，定期向会长、理事会、常务理事会、监事会</span
            ><span style="font-family: 仿宋_GB2312; color: #0070c0">、</span
            ><span style="font-family:&#10;仿宋_GB2312;color:black">以及</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会<span style="color: black"
                >报告，同时接受登记管理机关和相关部门的监督检查。登记管理机关及其他部门为履行监督管理职责，需要提交有关业务活动或财务情况的报告时，本会予以配合。</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第七十</span
            ><span style="font-family: 黑体; color: black">四</span
            ><span style="font-family:黑体;&#10;color:black">条 </span
            ><span style="font-family: 仿宋_GB2312; color: black">&nbsp;</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会进行换届、更换法定代表人，应当进行财务审计，并将审计报告报送业务主管单位和登记管理机关。本会注销清算前，应当进行清算财务审计。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第七十</span
            ><span style="font-family:&#10;黑体;color:black">五</span
            ><span style="font-family:黑体;&#10;color:black"
              >条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-size:&#10;16.0pt;font-family:仿宋;color:black"
              >本</span
            ><span style="&#10;font-family:仿宋_GB2312;color:black"
              >会重大资产配置、处置须经过理事会审议批准，必要时提交</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会<span style="color: black">审议批准。</span></span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span
              lang="EN-US"
              style="font-size:&#10;16.0pt;font-family:仿宋_GB2312;color:black"
              >&nbsp;</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第七章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">信息公开与信用承诺</span>
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第七十</span
            ><span style="font-family:&#10;黑体">六</span
            ><span style="font-family: 黑体; color: black">条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312">&nbsp; </span
            ><span style="font-family: 仿宋_GB2312"
              >本会依据有关政策法规，履行信息公开义务，建立信息公开制度，及时向会员公开年度工作报告、第三方机构出具的报告、会费收支情况以及经理事会研究认为有必要公开的其他信息，及时向社会公开登记事项、章程、组织机构、接受捐赠、信用承诺、政府转移或委托事项、可提供服务事项及运行情况等信息。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:黑体 ;font-weight: 600;">第七十</span
            ><span style="font-family:黑体;&#10;color:black">七</span
            ><span style="font-family: 黑体">条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312">&nbsp; </span
            ><span style="font-family: 仿宋_GB2312"
              >本会建立年度报告制度，应当于每年<span lang="EN-US">5</span
              >月<span lang="EN-US">31</span
              >日前，按照登记管理机关要求报送上一年度工作报告，并通过登记管理机关统一的信息平台将年度报告内容向社会公开，接受社会公众的查询、监督。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312;color:black"
              >本会对所公开信息的真实性、准确性、完整性、及时性负责，保证不存在虚假记载、误导性陈述或者重大遗漏。对涉及国家秘密、商业秘密、个人隐私的信息，以及捐赠人不同意公开的姓名、名称、住所、通讯方式等信息，本会依法不予公开。</span
            >
          </p>

          <p class="MsoNormal" style="text-indent: 32pt; line-height: 28pt">
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第七十八条<span lang="EN-US">&nbsp; </span></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会建立新闻发言人制度，经理事会或常务理事会通过，任命或指定<span
                lang="EN-US"
                >1</span
              >名负责人作为新闻发言人，就本会的重要活动、重大事件或热点问题，通过定期或不定期举行新闻发布会、吹风会、接受采访等形式主动回应社会关切。新闻发布内容应由本会法定代表人或主要负责人审定，确保正确的舆论导向。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:黑体;color:black ;font-weight: 600;"
              >第七十</span
            ><span style="font-family:&#10;黑体;color:black">九</span
            ><span style="font-family:黑体;&#10;color:black"
              >条<span lang="EN-US">&nbsp; </span></span
            ><span
              style="font-size:&#10;16.0pt;font-family:仿宋_GB2312;color:black"
              >本会重点围绕服务内容、服务方式、服务对象和收费标准等建立信用承诺制度，并向社会公开信用承诺内容。</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span
              lang="EN-US"
              style="font-size:&#10;16.0pt;font-family:仿宋_GB2312;color:black"
              >&nbsp;</span
            >
          </p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第八章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">党建工作</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会按照党章规定，有正式党员<span lang="EN-US">3</span
              >名以上时，经上级党组织批准，单独建立党组织，并由党员负责人担任党组织书记；负责人中没有党员时，应推荐对党忠诚、干净担当，业务能力强、群众基础好的党员担任党组织书记。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >正式党员人数不足<span lang="EN-US">3</span
              >名时，采取联合组建等方式，建立党组织，在本会开展党的工作。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >暂无正式党员时，支持配合上级组织开展党的工作，为建立党组织创造条件。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十一条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              本会党组织负责人应参加或列席理事会会议。党组织对本会重要事项决策、重要业务活动、大额经费开支、接收大额捐赠、开展涉外活动等提出意见。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十二条 </span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本会变更、撤并或注销，党组织要及时向上级党组织报告，并做好党员组织关系转移等相关工作。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十三条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              本会为党组织开展活动、做好工作提供必要的场地、人员和经费支持。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十四条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              本会支持建立工会、共青团、妇联组织，做好联系职工群众等工作。</span
            >
          </p>

          <p class="MsoBodyText"><span lang="EN-US">&nbsp;</span></p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第九章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">终止和剩余财产处理</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十五条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会有以下情形之一，应当终止：</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（一）章程规定的解散事由出现的；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black">（二）</span
            ><span style="font-family: 仿宋_GB2312"
              >会员代表大会<span style="color: black">决议解散的；</span></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（三）因分立、合并需要解散的；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black">（四）</span
            ><span style="font-family: 仿宋_GB2312"
              >依法被撤销登记或吊销登记证书的；</span
            >
          </p>

          <p
            style="text-align:justify;text-justify:inter-ideograph;text-indent:32.0pt;&#10;line-height:28.0pt;layout-grid-mode:char"
          >
            <span style="&#10;font-family:仿宋_GB2312"
              >（五）无法按照章程规定的宗旨继续开展工作的；</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >（六）因其他原因终止的。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十六条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会终止，应当由理事会提出终止动议，</span
            ><span style="font-family: 仿宋_GB2312"
              >经会员代表大会表决通过，并</span
            ><span style="font-family: 仿宋_GB2312">报业务主管单位</span
            ><span style="font-family: 仿宋_GB2312">审查同意。</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十七条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;本会终止前，</span
            ><span style="font-family: 仿宋_GB2312"
              >理事会应当在终止情形出现之日起<span lang="EN-US">30</span
              >日内</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >在业务主管单位及有关机关指导下成立清算组织，</span
            ><span style="font-family: 仿宋_GB2312"
              >清算组应当自成立之日起<span lang="EN-US">10</span
              >日内通知债权人，并于<span lang="EN-US">60</span
              >日内向社会公告，</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312"
              >本会未及时清算的，登记管理机关可以申请人民法院指定有关人员组成清算组进行清算。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 仿宋_GB2312; color: black"
              >本会应在清算结束之日起<span lang="EN-US">15</span
              >日内到登记管理机关办理注销登记手续。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十八条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会完成清算工作后，应向登记管理机关申请办理注销登记手续，完成注销登记后即为终止。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第八十九条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312; color: black"
              >&nbsp; </span
            ><span style="font-family:&#10;仿宋_GB2312;color:black"
              >本会注销后的剩余财产，</span
            ><span style="font-family:仿宋_GB2312;&#10;color:black"
              >在业务主管单位和登记管理机关的共同监督下<span lang="EN-US"
                >,</span
              ></span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >按照国家有关规定，用于公益性或者非营利性目的或者转赠给与本会性质、宗旨相同的组织，并向社会公告。</span
            >
          </p>

          <p class="MsoBodyText"><span lang="EN-US">&nbsp;</span></p>

          <p class="MsoTitle"><span lang="EN-US">&nbsp;</span></p>

          <p style="text-align: center; font-weight: 600; font-size: 20px">
            <span style="font-family: 宋体; font-weight: 600">第十章</span
            ><span lang="EN-US">-</span
            ><span style="font-family: 宋体">附则</span>
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; font-weight: 600">第九十条</span
            ><span lang="EN-US" style="font-family: 仿宋_GB2312">&nbsp; </span
            ><span style="font-family: 仿宋_GB2312"
              >本章程经<u> <span lang="EN-US">&nbsp;&nbsp;&nbsp;</span></u
              >年<u> &nbsp;&nbsp;</u>月<u> &nbsp;&nbsp;</u
              >日第一届第一次会员大会表决通过<span style="color: black"
                >。</span
              ></span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第九十一条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              本章程规定如与国家法律、法规和政策不符，以国家法律、法规和政策为准。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第九十二条 &nbsp;</span
            ><span style="font-family: 仿宋_GB2312; color: black"
              >本章程的解释权属于本会理事会。</span
            >
          </p>

          <p
            class="MsoNormal"
            style="text-indent:32.0pt;line-height:28.0pt;layout-grid-mode:&#10;char"
          >
            <span style="font-family: 黑体; color: black; font-weight: 600"
              >第九十三条</span
            ><span style="font-family: 仿宋_GB2312; color: black">
              &nbsp;本章程自登记管理机关核准之日起生效。</span
            >
          </p>
        </div>
    </articleBox>
  </div>
</template>
<script>
import articleBox from "../../../components/home/articleBox.vue";
export default {
  components: {
    articleBox,
  },
  data() {
    return {
      content: {
        title: "宗旨章程",
      },
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>