<template>
  <div v-loading="isSuccess">
    <div class="showPlace">
      <!-- 卡片 -->
      <div class="IdCart">
        <!-- 顶部图标和协会名 -->
        <div class="iconAndName">
          <img src="../.././image/gisf_logo.png" class="badge" />
          <div class="organizeName">
            <p class="organizeChineseName">广东省工业软件学会</p>
            <p class="organizeEnglishName">
              Guangdong Industrial Software Federation
            </p>
          </div>
        </div>
        <div class="identification">
          <p class="vip">会员证</p>
          <p>MEMBERSHIP CARD</p>
        </div>
        <div class="idDetail">
          <div class="name">{{ name }}</div>
          <div class="title">
            <div class="line"></div>
            <div class="member">
              <p class="vip">{{ vipRank.name }}</p>
            </div>
          </div>
        </div>
        <div class="numberAndTime">
          <div>
            <span>NO.</span>
            <span class="number">{{ idNumber }}</span>
          </div>
          <div>
            <span>有效期至</span>
            <span class="time">{{ time }}</span>
          </div>
        </div>
      </div>
      <!-- 注释 -->
      <div class="annotation">
        <div class="attentions">
          <p>使用注意事项</p>
          <ul>
            <li>
              1. 本卡为GISF会员身份凭证，二维码含有个人信息，打印后请妥善保管；
            </li>
            <li>
              2.
              如需要，参加GISF活动应出示本卡，重要活动还需持卡人同时出示身份证件；
            </li>
            <li>3. 本卡仅限本人使用，请勿转借；</li>
            <li>
              4.
              请在有效期内使用此卡，如果办理了资格延续手续，请重新打印会员卡。
            </li>
          </ul>
        </div>
        <div class="connectionForMembers">
          <p>会员服务热线</p>
          <ul>
            <li>Tel:020-84887464</li>
            <li>E-mail:GISF_GZ@163.com</li>
          </ul>
        </div>
        <div class="connectionOfAssociation">
          <p>学会联系方式</p>
          <ul>
            <li>地址: 广州市 广东工业大学工一666</li>
            <li>通信: 大学城外环西路101 000111</li>
            <li>Tel:020-84887464(总机)</li>
            <li>Fax:010-6256 1234</li>
            <li>https://www.gisf.org.cn</li>
          </ul>
        </div>
      </div>
    </div>
    <el-button class="downLoad" @click="downLoadIdCard" type="primary"
      >下载</el-button
    >
  </div>
</template>


<script>
export default {
  data() {
    return {
      name: "",
      idNumber: "",
      time: "",
      vipRank: {},
      isSuccess: false,
    };
  },
  methods: {
    async applyPDF() {
      // alert(userid)
      this.$message({
        message: "自动生成PDF中，请耐心等待",
        type: "success",
      });
      this.isSuccess = true;
      let userid = this.$store.state.user.id;
      const result = await this.$request.postPDF({
        url: `/downloadPdf/VipCard`,
        pdfName: "申请表",
      });
      this.isSuccess = false;
    },
    async downLoadIdCard() {
      this.$message({
        message: "自动生成PDF中，请耐心等待",
        type: "success",
      });
      this.isSuccess = true;
      const result = await this.$request.postPDF({
        url: "/downloadPdf/VipCard",
        pdfName: "GIA_会员证",
      });
      this.isSuccess = false;
    },
    async getMess() {
      const result = await this.$request.get({
        url: "/user/info",
      });
      this.name = result.name;
      this.idNumber = result.vipNumber;
      this.time = result.endTime;
      this.vipRank = result.vipRank || { name: "普通会员" };
    },
  },
  mounted() {
    this.getMess();
    // this.applyPDF();
  },
};
</script>


<style scoped>
.showPlace {
  width: 700px;
  height: 660px;
  margin: 20px 0 0;
  overflow-y: auto;
  padding: 40px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(136, 136, 136, 0.319);
  box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.5);
  margin-left: 50%;
  transform: translate(-50%);
}
.downLoad {
  margin: 20px 0 0 50%;
  transform: translate(-50%);
}
.annotation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.IdCart {
  width: 375px;
  height: 220px;
  border: 2px dotted #888;
  border-radius: 15px;
  margin: 0 0 30px 50%;
  transform: translate(-50%, 2%);
  overflow: hidden;
  /* background-color: red; */
  background-image: linear-gradient(to right, #96ceff 0%, #78f8ff 100%);
}
.iconAndName {
  width: 100%;
  background-color: rgb(80, 168, 255);
  box-sizing: border-box;
  padding: 5px 25px 0;
  height: 50px;
  display: flex;
  border-bottom: 2px solid rgba(17, 34, 51, 0.422);
}
.iconAndName .badge {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.iconAndName .organizeName {
  width: 300px;
  height: 50px;
  margin-top: 8px;
}
.iconAndName .organizeName .organizeChineseName,
.organizeEnglishName {
  font-weight: bold;
  font-size: 2px;
  padding-left: 7px;
}
.iconAndName .organizeName .organizeChineseName {
  font-size: 20px;
  line-height: 12px;
  margin-bottom: 6px;
}
.identification {
  width: 60%;
  height: 60px;
  margin-left: 50%;
  transform: translate(-50%);
  text-align: center;
  font-weight: 600;
}
.identification p {
  font-size: 10px;
  margin: 0;
}
.identification .vip {
  font-size: 30px;
  margin: 0;
  margin-top: 5px;
}
.annotation {
  width: 600px;
  margin-left: 30px;
}
.annotation ul {
  margin-left: 30px;
}
.annotation div p {
  font-weight: bold;
  margin: 5px 0;
}
.annotation li {
  min-width: 270px;
}
.connectionForMembers ul {
  display: flex;
}
.connectionOfAssociation ul {
  display: flex;
  flex-wrap: wrap;
}
.idDetail {
  display: flex;
  min-width: 280px;
  margin-left: 50%;
  transform: translate(-50%);
  margin-top: 10px;
}
.idDetail .name {
  font-size: 20px;
  line-height: 22px;
  width: 160px;
  text-align: right;
  line-height: 40px;
  margin-right: 10px;
}
.idDetail .title {
  display: flex;
  justify-content: space-between;
}
.idDetail .title .line {
  border-left: 2px solid red;
  margin-right: 10px;
  height: 40px;
}
.idDetail .title .vip {
  /* letter-spacing: 32px; */
  line-height: 40px;
}
.numberAndTime {
  width: 80%;
  margin-left: 50%;
  transform: translate(-50%);
  margin-top: 5px;
}
.numberAndTime .number,
.time {
  margin-left: 10px;
}
</style>