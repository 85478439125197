<template>
  <div>
    <article-box :content="{title:'如何加入'}">
      <div class="txt">
        <p style="text-align: left; text-indent: 0em; font-size: 20px">
          <span style="font-family: 宋体, SimSun">（1）会员注册</span>
        </p>
        <p style="text-align: left; text-indent: 0em">
          <span style="font-size: 20px; font-family: 宋体, SimSun"
            >在GISF网站</span
          ><a
            href=""
            target="_blank"
            style="
              text-decoration: underline;
              font-family: 宋体, SimSun;
              font-size: 20px;
              color: rgb(79, 129, 189);
            "
            ><span style="color: rgb(79, 129, 189)">首页</span></a
          ><span style="font-size: 20px; font-family: 宋体, SimSun"
            >，点击“</span
          ><a
            href=""
            target="_blank"
            style="
              text-decoration: underline;
              font-family: 宋体, SimSun;
              font-size: 20px;
              color: rgb(79, 129, 189);
            "
            ><span style="color: rgb(79, 129, 189)">会员登录</span></a
          ><span style="font-size: 20px; font-family: 宋体, SimSun"
            >”按钮，进入</span
          ><span style="font-family: 宋体, SimSun; font-size: 20px"
            >会员注册页面</span
          ><span
            style="text-indent: 2em; font-size: 20px; font-family: 宋体, SimSun"
            >根据实际情况，选择“个人会员注册”、“企业会员注册”进行注册；注册完成以后，进入“会员中心”完善个人信息</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em">
          <span style="font-size: 20px; font-family: 宋体, SimSun">（2）</span
          ><a
            href=""
            target="_blank"
            style="
              text-decoration: underline;
              font-family: 宋体, SimSun;
              color: rgb(79, 129, 189);
              font-size: 20px;
            "
            >缴纳会费</a
          ><span
            style="
              line-height: 1.6;
              text-indent: 2em;
              font-size: 20px;
              font-family: 宋体, SimSun;
            "
            >,可以选择在线交费（包含网银、支付宝、银联支付）或银行转账，在线缴费在“会员中心”交费栏选择相应的会员类别缴纳会费。</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em; font-size: 20px">
          <span style="font-family: 宋体, SimSun"
            >交纳会费后，若使用在线交费，可实现自动实时生效生成会员号；银行转账方式需要5个工作日到账后，学会秘书处将会办理会员资格生效手续。</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em; font-size: 20px">
          <span style="font-family: 宋体, SimSun">（3）学会秘书处审核完成后函告申请人《广东省工业软件学会入会缴费复函》。</span>
        </p>
        <p style="text-align: left; text-indent: 0em; font-size: 20px">
          <span style="font-family: 宋体, SimSun">（4）如会员需要发票，可在一周后联系学会秘书处开具发票。</span>
        </p>
        <p style="text-align: left; text-indent: 0em; font-size: 20px">
          <span style="font-family: 宋体, SimSun">（5）如申请的是理事会职位，将由学会秘书处发放理事牌匾。</span>
        </p>
        <!--<p style="text-align: left; text-indent: 0em; font-size: 20px">
          <span style="font-family: 宋体, SimSun"
            >为提供更好服务，请及时补充和维护个人信息。</span
          >
        </p>
        <p style="text-align: left; text-indent: 0em">
          <a
            href=""
            target="_blank"
            style="
              font-family: inherit;
              font-size: 16px;
              text-indent: 2em;
              outline: 0px;
              transition: all 0.5s ease 0s;
              color: rgb(46, 62, 78);
              text-decoration-line: none;
              box-sizing: border-box;
            "
            ><img
              width="169"
              height="167"
              title="公众号二维码（小）"
              style="
                outline: 0px;
                transition: 0.5s;
                width: 169px;
                height: 167px;
                vertical-align: middle;
                max-width: 100%;
                box-sizing: border-box;
              "
              alt="公众号二维码（小）"
              src="/upload/resources/image/2019/11/07/105630_500x500.jpg"
              suffix="jpg"
              data-bd-imgshare-binded="1"
          /></a>
        </p>
        <p
          style="
            outline: 0px;
            text-align: left;
            color: rgb(38, 37, 37);
            font-family: 'Microsoft YaHei', 微软雅黑;
            font-size: 16px;
            white-space: normal;
            box-sizing: border-box;
            text-indent: 2em;
          "
        >
          <span style="font-family: 宋体, SimSun; font-size: 20px"
            ><strong
              style="
                font-family: inherit;
                white-space: normal;
                text-indent: 2em;
              "
              ><span style="font-family: 宋体, SimSun; text-indent: 0em"
                >GIA微信公众号</span
              ></strong
            ></span
          >
        </p>-->
        <p style="text-align: center; text-indent: 2em"><br /></p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center">
          <a
            href=""
            target="_blank"
          ></a>
        </p>
        <p style="text-align: center">
          <a
            href=""
            target="_blank"
          ></a>
        </p>
        <p style="text-align: center">
          <a
            href=""
            target="_blank"
          ></a>
        </p>
        <p style="text-align: center"><br /></p>
        <p style="text-align: center"><br /></p>
        <p>&nbsp;</p>
      </div>
    </article-box>
  </div>
</template>
<script>
import ArticleBox from '../../../components/home/articleBox.vue';
export default {
    components:{
        ArticleBox
    },
  data() {
    return {
      
    };
  },
  mounted(){

  }
};
</script>
<style lang="less" scoped>
</style>