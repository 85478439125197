<template>
  <div class="login-content">
    <div class="login-wrap">
      <div class="title-head">
        <span> 广东省工业软件学会会员管理系统 </span>
      </div>
      <div class="toGISF">
        <router-link class="GISF"
                     to="/introduct_of_association">学会简介</router-link>
        <router-link class="GISF"
                     to="/articles_of_association">学会章程</router-link>
      </div>
      <div class="login-form">
        <el-tabs v-model="accountType"
                 :stretch="true">
          <el-tab-pane label="个人登录"
                       name="0"></el-tab-pane>
          <el-tab-pane label="企业登录"
                       name="1"></el-tab-pane>
        </el-tabs>
        <!-- 头部 -->
        <div class="size-box">
          <div class="login-radius"
               @click="changeLoginSize('username')"
               :class="loginSize == 'username' ? 'active' : ''">
            账号登录
          </div>
          <div class="login-radius"
               @click="changeLoginSize('email')"
               :class="loginSize == 'email' ? 'active' : ''">
            邮箱登录
          </div>
        </div>
        <div v-if="loginSize == 'username'"
             class="login-box">
          <el-form ref="usernameRef"
                   :rules="rules"
                   :model="usernameForm">
            <el-form-item prop="username">
              <el-input placeholder="绑定的手机号/邮箱号"
                        type="username"
                        v-model="usernameForm.username"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="密码"
                        type="password"
                        v-model="usernameForm.password"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div v-else
             class="login-box">
          <el-form ref="emailRef"
                   :rules="rules"
                   :model="emailForm">
            <el-form-item prop="email">
              <el-input placeholder="请输入邮箱"
                        type="email"
                        v-model="emailForm.email"></el-input>
            </el-form-item>
            <el-form-item class="code"
                          prop="code">
              <el-input class="codeInput"
                        placeholder="验证码"
                        v-model="emailForm.code"
                        type="code"
                        maxlength="8"></el-input>
              <el-button class="codeSend"
                         @click="() => sendCode()"
                         :disabled="timeout ? true : false"
                         :style="{
                  opacity: timeout == 0 ? '1' : '0.5',
                }">{{ timeout ? timeout + "s" : "获取验证码" }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div @click="handleLogin()"
             class="login-btn">登录</div>
        <div class="other-route">
          <router-link to="/register">立即注册</router-link>
          <router-link to="/forget">忘记密码</router-link>
        </div>
      </div>
      <p class="tips">
        温馨提示：使用广东省工业软件学会会员管理系统需要身份验证，如无账号请先行注册！
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data () {
    return {
      timeout: 0,
      loginSize: "username",
      accountType: 0,
      emailForm: {
        email: "",
        code: "",
      },
      usernameForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
          {
            type: "email",
            message: "邮箱格式错误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    changeLoginSize (loginSize) {
      this.$data.loginSize = loginSize;
      if (loginSize == "username") {
        this.$data.usernameForm.username = "";
        this.$data.usernameForm.password = "";
      } else {
        this.$data.emailForm.email = "";
        this.$data.emailForm.code = "";
      }
    },
    handleLogin () {
      const { loginSize } = this.$data;
      this.$refs[loginSize + "Ref"].validate(async (vaild) => {
        if (vaild) {
          if (loginSize == "username") {
            const {
              accountType,
              usernameForm: { username, password },
            } = this.$data;
            const result = await this.$request.post({
              url: "/user/login",
              data: {
                username: username,
                password: password,
                roleId: 4,
                accountType: accountType,
              },
            });
            const { token, registerUserRes, user } = result;
            localStorage.GIA_token = token;
            this.$store.state.registerUserRes = registerUserRes;
            this.$store.state.user = user;
            this.$router.push("/GIA_associator");
          } else {
            const {
              accountType,
              emailForm: { email, code },
            } = this.$data;
            const result = await this.$request.post({
              url: "/user/email/login",
              data: {
                email: email,
                code: code,
                roleId: 4,
                accountType: accountType,
              },
            });
            const { token, registerUserRes, user } = result;
            localStorage.GIA_token = token;
            this.$store.state.registerUserRes = registerUserRes;
            this.$store.state.user = user;
            this.$router.push("/GIA_associator");
          }
        } else {
          // throw( "账号或密码错误");
        }
      });
    },
    sendCode () {
      this.$refs["emailRef"].validateField("email", async (valid) => {
        if (!valid) {
          // 验证的值
          this.$data.timeout = 60;
          this.sendCodeInterval = setInterval(() => {
            this.$data.timeout -= 1;
            if (this.$data.timeout == 0) {
              clearInterval(this.sendCodeInterval);
              this.sendCodeInterval = null;
            }
          }, 1000);
          try {
            const { email } = this.$data.emailForm;
            await this.$request.post({
              url: "/user/sendCode/" + email,
            });
          } catch (error) {
            this.$data.timeout = 0;
            clearInterval(this.sendCodeInterval);
            this.sendCodeInterval = null;
          }
        }
      });
    },
    isWx () {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.$alert('请使用手机浏览器打开以使用文件相关内容！', '微信浏览器不支持本网站PDF下载！', {
          confirmButtonText: '确定'
        });
        //在微信中打开需要的操作
      } else {

        //不在微信中打开
      }

      var u = navigator.appVersion;
      var qq = u.split('MQQBrowser/').length > 1 ? 2 : 0;
      if (qq) {
        this.$alert('请使用其它浏览器打开以使用文件下载相关内容！', 'QQ浏览器不支持本网站PDF下载！', {
          confirmButtonText: '确定'
        });

      }
    },
  },
  created () {
    document.onkeydown = () => {
      var key = window.event.keyCode;
      if (key == 13) {
        this.handleLogin();
      }
    }; // 按下回车执行登录按钮点击事件
  },
  mounted () {
    this.isWx();
  },
};
</script>

<style scoped lang="less">
.login-content {
  overflow: auto;
  height: 100%;
  width: 100vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgba(250, 250, 250);
  .login-wrap {
    width: 100%;
    height: 100%;
    .title-head {
      background-image: linear-gradient(
        to top,
        rgb(85, 90, 226) 0%,
        rgba(94, 102, 255) 50%,
        rgb(85, 90, 226) 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      height: 7rem;
      max-height: 100px;
      color: #fff;
    }
    .toGISF {
      width: 48%;
      font-size: 14px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .GISF {
        margin-top: 20px;
        width: 48%;
        border-radius: 3px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        display: block;
        background: linear-gradient(
          to top,
          rgb(85, 90, 226) 0%,
          rgba(94, 102, 255) 50%,
          rgb(85, 90, 226) 100%
        );
        color: #fff;
      }
    }
    .login-form {
      position: relative;
      box-sizing: border-box;
      z-index: 1;
      border-radius: 10px;
      max-width: 450px;
      margin: 0 auto;
      margin-bottom: 7rem;
      background-color: #fff;
      padding: 40px;
      z-index: 1;
      margin-top: 8rem;
      box-shadow: 0 2px 5px 1px rgba(204, 204, 204, 0.336);
      .size-box {
        color: #fff;
        font-size: 14px;
        display: flex;
        height: 35px;
        line-height: 35px;
        border-radius: 4px;
        overflow: hidden;
        text-align: center;
        background-color: rgb(216, 214, 214);
        .login-radius {
          flex: 1;
          cursor: pointer;
          &.active {
            background-color: var(--buttonColor);
          }
        }
      }
      .login-box {
        margin: 40px 15px;
        .code {
          position: relative;
          .codeSend {
            position: absolute;
            color: #fff;
            right: 0;
            background-color: var(--buttonColor);
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
      .login-btn {
        background: linear-gradient(
          to top,
          rgb(85, 90, 226) 0%,
          rgba(94, 102, 255) 50%,
          rgb(85, 90, 226) 100%
        );
        width: 100%;
        height: 45px;
        line-height: 45px;
        border-radius: 6px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        cursor: pointer;
      }
      .other-route {
        display: flex;
        justify-content: space-between;
        font-size: 13.5px;
        margin: 3px 0;
      }
    }
    .tips {
      width: 100%;
      max-width: 1000px;
      font-size: 1.4rem;
      padding: 0.2rem;
      background: #f3f6f9;
      color: #205cb2;
      text-align: left;
    }
  }
}
</style>