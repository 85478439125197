<template>
  <div class="GIA_associator-box">
    <div class="associator-header">
      <div class="header-left">
        <router-link to="/">
          <img src="../image/weblogo.jpg" />
        </router-link>
        <span>广东省工业软件学会管理系统</span>
      </div>
      <div class="header-right">
        <el-popover placement="bottom-start"
                    trigger="hover">
          <ul class="base-list">
            <!-- <li
              @click="$router.push('/manage/change_pass')"
              class="base-item"
            >
              <i class="iconfont icon-xiugaimima"></i>
              <span>修改密码</span>
            </li> -->
            <li @click="exitLogin"
                class="base-item">
              <i class="iconfont icon-tuichu"></i>
              <span>退出登录</span>
            </li>
          </ul>
          <el-button slot="reference"
                     class="setup-box">
            <i class="iconfont icon-change"></i>
          </el-button>
        </el-popover>
      </div>
    </div>
    <div class="main">
      <div class="left-nav">
        <div class="nav-header">
          <img style="height:80px;width:80px;border-radius:50%;"
               src="../image/weblogo.jpg" />
          <div class="manage-title">学会管理系统导航</div>
        </div>
        <el-menu active-text-color="#1d90fb"
                 :default-active="defaultActive"
                 class="el-menu">
          <div v-for="firstItem in menuList"
               :key="firstItem.path"
               class="menu-once">
            <el-submenu v-if="firstItem.children"
                        :index="firstItem.title">
              <template slot="title">
                <i class="iconfont"
                   :class="firstItem.icon_name"></i>
                <span slot="title">{{ firstItem.title }}</span>
              </template>
              <router-link v-for="secondItem in firstItem.children"
                           :key="`${secondItem.path}`"
                           :to="`/manage${secondItem.path}`">
                <el-menu-item :index="`${secondItem.path}`">
                  <i class="iconfont"
                     :class="secondItem.icon_name"></i>
                  <span>{{ secondItem.title }}</span>
                </el-menu-item>
              </router-link>
            </el-submenu>
            <router-link v-else
                         :to="`/manage${firstItem.path}`">
              <el-menu-item :index="firstItem.path">
                <i class="iconfont"
                   :class="firstItem.icon_name"></i>
                <span>{{ firstItem.title }}</span>
              </el-menu-item>
            </router-link>
          </div>
        </el-menu>
      </div>
      <div class="route-box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    const menuList = [
      {
        icon_name: "icon-huiyuanguanli",
        path: "/memberMessageList",
        title: "会员管理",
      },
      {
        icon_name: "icon-jiaofeiguanli",
        path: "/paymentRecord",
        title: "交费记录",
      },
      {
        icon_name: "icon-shenhe",
        title: "审核",
        children: [
          {
            icon_name: "icon-shenhexiangmu",
            path: "/auditPassage",
            title: "文章审核",
          },
          {
            icon_name: "icon-CPshenhe",
            path: "/auditMember",
            title: "会员审核",
          },
        ],
      },
      {
        icon_name: "icon-xitongshezhi",
        title: "管理设置",
        children: [
          {
            icon_name: "icon-banjichengyuan",
            path: "/manage_list",
            title: "全部成员",
          },
          {
            icon_name: "icon-shoufeibiaozhun",
            path: "/Charging_Standard",
            title: "收费标准设置",
          },
          {
            icon_name: "icon-rili",
            path: "/activitySetting",
            title: "日历活动",
          },
          {
            icon_name: "icon-wenzhang_2",
            path: "/Set_home_article",
            title: "首页文章展示",
          },
          {
            icon_name: "icon-shouyelunbotu",
            path: "/slidingSetting",
            title: "轮播图设置",
          },
        ],
      },
    ];
    return {
      defaultActive: "",
      menuList,
    };
  },
  methods: {
    async getUserInfo () {
      const result = await this.$request.get({
        url: "/user/info",
      });
      this.$store.state.userInfo = result;
      this.$store.state.userInfo_canWrite = { ...result };
    },
    exitLogin () {
      this.$confirm("确定要退出登录么", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.GIA_token = "";
        this.$router.push("/manage/login");
      });
    },
  },
  mounted () {
    this.getUserInfo();
    const { path } = this.$route;
    this.$data.defaultActive = "/" + path.split("/")[2];
  },
};
</script>
<style scoped lang="less">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 690px;
}
.GIA_associator-box {
  overflow: auto;
  background-color: #f4f5f5;
  .associator-header {
    background: #fff;
    border-bottom: 1px solid #f1f1f1;
    color: #909090;
    position: fixed;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 250;
    box-shadow: 0 2px 5px 1px rgba(204, 204, 204, 0.336);
    .header-left {
      --imgSize: 65px;
      display: flex;
      align-items: center;
      span {
        font-size: 25px;
        font-weight: 600;
        margin-left: 10px;
      }
      img {
        border-radius: 50%;
        width: var(--imgSize);
        height: var(--imgSize);
      }
    }
  }

  .main {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1500px;
    max-height: 645px;
    height: 100%;
    .left-nav {
      .nav-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 24px;
        height: 175px;
        justify-content: center;
        .manage-title {
          color: #909090;
          font-size: 20px;
          font-weight: 600;
          margin-top: 10px;
        }
      }
      position: fixed;
      width: 240px;
      top: 94px;
      bottom: 0;
      background: #fff;
      z-index: 2;
      overflow: hidden;
      margin-bottom: 14px;
      box-shadow: 2px 2px 4px 1px #cccccc46;
      border-radius: 4px;
      .el-menu {
        height: calc(100% - 195px);
        overflow: auto;
        border-right: none !important;
        .menu-once {
          a {
            width: 100%;
            height: 100%;
            display: block;
          }
          .iconfont {
            font-size: 18px !important;
            margin-right: 5px;
          }
        }
      }
    }
    .route-box {
      border-radius: 4px;
      margin: 94px 0 0 264px;
      width: calc(100% - 264px);
      min-width: 800px;
      height: 100%;
      overflow: auto;
      box-shadow: 2px 2px 4px 1px #cccccc46;
      background-color: #fff;
    }
  }
}
.el-popover.el-popper {
  width: 100px !important;
  min-width: 100px !important;
  .base-list {
    width: 100px;
    margin: 0 auto;
    .base-item {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
    }
  }
}
.setup-box {
  background-color: transparent;
  outline: none;
  border: none;
  &:hover {
    color: rgb(131, 131, 131);
  }
  .icon-change {
    position: relative;
    color: rgb(131, 131, 131);
    font-size: 40px;
  }
}
</style>