<template>
  <div class="register-content">
    <div class="header-box">
      <div class="header-title">
        <div class="header-left">
          <router-link to="/login"
                       class="left-title">返回登录</router-link>
        </div>
      </div>
    </div>
    <div class="register-form-box">
      <el-tabs v-model="registerType"
               :stretch="true">
        <el-tab-pane label="个人注册"
                     name="0">

          <el-form ref="individualRef"
                   :rules="rules"
                   :model="individualRegister"
                   label-width="120px">
            <el-form-item label="姓名:"
                          prop="name">
              <el-input placeholder="请输入姓名"
                        v-model="individualRegister.name"></el-input>
            </el-form-item>
            <el-form-item label="设置密码:"
                          prop="password">
              <el-input placeholder="请输入密码(8-14位的数字和大小写字母组合)"
                        type="password"
                        v-model="individualRegister.password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码:"
                          prop="resure">
              <el-input placeholder="请再次输入密码"
                        type="password"
                        v-model="individualRegister.resure"></el-input>
            </el-form-item>
            <!-- <el-form-item label="联系方式:">
          <el-radio-group v-model="individualRegister.authentication"
                          size="small">
            <el-radio-button label="mobilePhone">手机号</el-radio-button>
            <el-radio-button label="email">邮箱</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
            <el-form-item label="邮箱:"
                          prop="email">
              <el-input placeholder="请输入邮箱"
                        type="email"
                        v-model="individualRegister.email"></el-input>
            </el-form-item>
            <!-- <el-form-item v-if="individualRegister.authentication == 'mobilePhone'"
                      label="手机号:"
                      prop="mobilePhone">
          <el-input placeholder="请输入手机号"
                    type="mobilePhone"
                    v-model="individualRegister.mobilePhone"></el-input>
        </el-form-item> -->
            <el-form-item label="验证码:"
                          class="code"
                          prop="code">
              <el-input class="codeInput"
                        placeholder="验证码"
                        v-model="individualRegister.code"
                        type="code"
                        maxlength="8"></el-input>
              <el-button class="code-send"
                         type="primary"
                         @click="() => sendCode_individual()"
                         :disabled="timeout ? true : false">{{ timeout ? timeout + "s" : "获取验证码" }}</el-button>
            </el-form-item>
            <!-- <el-form-item prop="sureRead">
          <el-radio-group v-model="individualRegister.sureRead">
            <el-radio label="true">我已阅读<router-link to="/404"
                           class="regulations">《会员条例》</router-link>
            </el-radio>
          </el-radio-group>
        </el-form-item> -->
            <el-button class="register-btn"
                       @click="() => registerFn_individual()"
                       type="primary">注册</el-button>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="企业注册"
                     name="1">
          <el-form ref="enterpriseRef"
                   :rules="rules"
                   :model="enterpriseRegister"
                   label-width="120px">
            <el-form-item label="法人姓名:"
                          prop="name">
              <el-input placeholder="请输入法人姓名"
                        v-model="enterpriseRegister.name"></el-input>
            </el-form-item>
            <el-form-item label="设置密码:"
                          prop="password">
              <el-input placeholder="请输入密码(8-14位的数字和大小写字母组合)"
                        type="password"
                        v-model="enterpriseRegister.password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码:"
                          prop="resure">
              <el-input placeholder="请再次输入密码"
                        type="password"
                        v-model="enterpriseRegister.resure"></el-input>
            </el-form-item>
            <el-form-item label="通讯地址:"
                          prop="address">
              <el-input placeholder="请输入通讯地址"
                        maxlength="40"
                        v-model="enterpriseRegister.address"></el-input>
            </el-form-item>
            <el-form-item label="办公电话:"
                          prop="telephone">
              <el-input placeholder="请输入办公电话"
                        v-model="enterpriseRegister.telephone"></el-input>
            </el-form-item>
            <el-form-item label="单位全称:"
                          maxlength="40"
                          prop="workPlace">
              <el-input placeholder="请输入单位全称"
                        v-model="enterpriseRegister.workPlace"></el-input>
            </el-form-item>
            <el-form-item label="单位经营范围:"
                          maxlength="40"
                          prop="professionalTitle">
              <el-input placeholder="请输入单位类别单位经营范围"
                        v-model="enterpriseRegister.professionalTitle"></el-input>
            </el-form-item>
            <el-form-item prop="organizationType"
                          label="单位类型:">
              <el-select v-model="enterpriseRegister.organizationType"
                         size="small">
                <el-option value="企业"
                           label="企业"></el-option>
                <el-option value="社会团体"
                           label="社会团体"></el-option>
                <el-option value="其他"
                           label="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="legalPersonIdCart"
                          label="法人身份证:">
              <label for="legalPersonIdCart">
                <div class="el-input img-once">
                  <img class="img"
                       v-show="enterpriseRegister.legalPersonIdCart"
                       :src="enterpriseRegister.legalPersonIdCart" />
                  <div v-show="!enterpriseRegister.legalPersonIdCart"
                       class="empty-img">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
                <input id="legalPersonIdCart"
                       hidden="hidden"
                       @change="(e)=>addPhoto(e, 'legalPersonIdCart')"
                       type="file"
                       accept="image/*" />
              </label>
            </el-form-item>
            <el-form-item prop="legalPersonCert"
                          label="法人证书图片:">
              <label for="legalPersonCert">
                <div class="el-input img-once">
                  <img class="img"
                       v-show="enterpriseRegister.legalPersonCert"
                       :src="enterpriseRegister.legalPersonCert" />
                  <div v-show="!enterpriseRegister.legalPersonCert"
                       class="empty-img">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
                <input id="legalPersonCert"
                       hidden="hidden"
                       @change="(e)=>addPhoto(e, 'legalPersonCert')"
                       type="file"
                       accept="image/*" />
              </label>
            </el-form-item>
            <el-form-item prop="companyCert"
                          label="机构代码证书:">
              <label for="companyCert">
                <div class="el-input img-once">
                  <img class="img"
                       v-show="enterpriseRegister.companyCert"
                       :src="enterpriseRegister.companyCert" />
                  <div v-show="!enterpriseRegister.companyCert"
                       class="empty-img">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
                <input id="companyCert"
                       hidden="hidden"
                       @change="(e)=>addPhoto(e, 'companyCert')"
                       type="file"
                       accept="image/*" />
              </label>
            </el-form-item>
            <!-- <el-form-item label="联系方式:">
          <el-radio-group v-model="enterpriseRegister.authentication"
                          size="small">
            <el-radio-button label="mobilePhone">手机号</el-radio-button>
            <el-radio-button label="email">邮箱</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
            <el-form-item label="邮箱:"
                          prop="email">
              <el-input placeholder="请输入邮箱"
                        type="email"
                        v-model="enterpriseRegister.email"></el-input>
            </el-form-item>
            <!-- <el-form-item  v-if="enterpriseRegister.authentication == 'mobilePhone'"
                      label="手机号:"
                      prop="mobilePhone">
          <el-input placeholder="请输入手机号"
                    type="mobilePhone"
                    v-model="enterpriseRegister.mobilePhone"></el-input>
        </el-form-item> -->
            <el-form-item label="验证码:"
                          class="code"
                          prop="commonField">
              <el-input class="commonField"
                        placeholder="验证码"
                        v-model="enterpriseRegister.commonField"
                        type="commonField"
                        maxlength="8"></el-input>
              <el-button class="code-send"
                         type="primary"
                         @click="() => sendCode_enterprise()"
                         :style="{opacity: `${timeout ? 0.5:1}`}"
                         :disabled="timeout ? true : false">{{ timeout ? timeout + "s" : "获取验证码" }}</el-button>
            </el-form-item>
            <!-- <el-form-item prop="sureRead">
          <el-radio-group v-model="individualRegister.sureRead">
            <el-radio label="true">我已阅读<router-link to="/404"
                           class="regulations">《会员条例》</router-link>
            </el-radio>
          </el-radio-group>
        </el-form-item> -->
            <el-button class="register-btn"
                       @click="() => registerFn_enterprise()"
                       type="primary">注册</el-button>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      registerType: 0,
      timeout: 0,
      individualRegister: {
        // 姓名
        name: "",
        // 密码
        password: "",
        // 确认密码
        resure: "",
        // 验证方式
        authentication: "email",
        // 邮箱
        email: "",
        // 手机号
        mobilePhone: "",
        // 验证码
        code: "",
        // 确认阅读
        sureRead: "",
      },
      enterpriseRegister: {
        commonField: "",
        password: "",
        name: "",
        authentication: "email",
        email: "",
        mobilePhone: "",
        address: "",
        telephone: "",
        workPlace: "",
        professionalTitle: "",
        organizationType: "",
        legalPersonIdCart: "",
        legalPersonCert: "",
        companyCert: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              const reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z]{8,14}/
              if (reg.test(value)) {
                callback();
              } else {
                callback(new Error("密码需要8-14位数字、大小写字母组合"));
              }
            },
            trigger: "blur",
          },
        ],
        resure: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              // 判断是否密码相同
              const { registerType } = this.$data;
              let password;
              if (registerType == 1) {
                password = this.$data.enterpriseRegister.password
              } else {
                password = this.$data.individualRegister.password
              }
              if (value !== password) {
                callback(new Error("密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
          {
            type: "email",
            message: "邮箱格式错误",
            trigger: "blur",
          },
        ],
        mobilePhone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, cb) => {
              let reg =
                /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
              if (reg.test(value)) return cb();
              cb(new Error("手机号码格式不正确"));
            },
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
        commonField: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "地址不能为空",
            trigger: "blur",
          },
        ],
        telephone: [
          {
            required: true,
            message: "办公电话不能为空",
            trigger: "blur",
          },
          {
            min: 6,
            max: 13,
            message: "电话格式有误",
            trigger: "blur",
          },
        ],
        workPlace: [
          {
            required: true,
            message: "单位全称不能为空",
            trigger: "blur",
          },
        ],
        professionalTitle: [
          {
            required: true,
            message: "单位经营范围不能为空",
            trigger: "blur",
          },
        ],
        organizationType: [
          {
            required: true,
            message: "单位类型不能为空",
          },
        ],
        legalPersonIdCart: [
          {
            required: true,
            message: "法人身份证图片不能为空",
          },
        ],
        legalPersonCert: [
          {
            required: true,
            message: "法人证书图片不能为空",
          },
        ],
        companyCert: [
          {
            required: true,
            message: "企业机构代码证书图片不能为空",
          },
        ],
      },
    };
  },
  methods: {
    changeForm () {
      this.$data.registerType = this.$data.registerType == 0 ? 1 : 0;
      this.$data.timeout = 0;
      if (this.sendCodeInterval) {
        clearInterval(this.sendCodeInterval);
        this.sendCodeInterval = null;
      }
    },
    registerFn_individual () {
      // 调用父组件的changeActive并切换路由
      this.$refs["individualRef"].validate(async (valid) => {
        if (valid) {
          const {
            individualRegister,
            individualRegister: { name, password, authentication, code },
          } = this.$data;
          const data = {
            name,
            password,
            [authentication]: individualRegister[authentication],
            code: code,
          };
          await this.$request.post({
            url: "/user/register",
            data: data,
          });
          this.successBoth();
        }
      });
    },
    sendCode_individual () {
      const { individualRegister } = this.$data;
      const { authentication } = individualRegister;
      this.$refs["individualRef"].validateField(
        authentication,
        async (valid) => {
          if (!valid) {
            // 验证的值
            this.$data.timeout = 60;
            this.sendCodeInterval = setInterval(() => {
              this.$data.timeout -= 1;
              if (this.$data.timeout == 0) {
                clearInterval(this.sendCodeInterval);
                this.sendCodeInterval = null;
              }
            }, 1000);
            try {
              const checkingNumber = individualRegister[authentication];
              if (authentication == "email") {
                await this.$request.post({
                  url: "/user/sendCode/" + checkingNumber,
                });
              } else {
                await this.$request.post({
                  url: "/user/phone/code",
                  header: {
                    "Content-Type": "multipart/form-data",
                  },
                  data: {
                    phone: "+86" + checkingNumber,
                  },
                });
              }
            } catch (error) {
              this.$data.timeout = 0;
              clearInterval(this.sendCodeInterval);
              this.sendCodeInterval = null;
            }
          }
        }
      );
    },
    sendCode_enterprise () {
      const { enterpriseRegister } = this.$data;
      const { authentication } = enterpriseRegister;
      this.$refs["enterpriseRef"].validateField(
        authentication,
        async (valid) => {
          if (!valid) {
            // 验证的值
            this.$data.timeout = 60;
            this.sendCodeInterval = setInterval(() => {
              this.$data.timeout -= 1;
              if (this.$data.timeout == 0) {
                clearInterval(this.sendCodeInterval);
                this.sendCodeInterval = null;
              }
            }, 1000);
            try {
              const checkingNumber = enterpriseRegister[authentication];
              if (authentication == "email") {
                await this.$request.post({
                  url: "/user/sendCode/" + checkingNumber,
                });
              } else {
                await this.$request.post({
                  url: "/user/phone/code",
                  header: {
                    "Content-Type": "multipart/form-data",
                  },
                  data: {
                    phone: "+86" + checkingNumber,
                  },
                });
              }
            } catch (error) {
              this.$data.timeout = 0;
              clearInterval(this.sendCodeInterval);
              this.sendCodeInterval = null;
            }
          }
        }
      );
    },
    registerFn_enterprise () {
      // 调用父组件的changeActive并切换路由
      this.$refs["enterpriseRef"].validate(async (valid) => {
        if (valid) {
          const {
            enterpriseRegister,
            enterpriseRegister: {
              name,
              password,
              authentication,
              commonField,
              address,
              telephone,
              workPlace,
              professionalTitle,
              organizationType,
              legalPersonIdCart,
              legalPersonCert,
              companyCert,
            },
          } = this.$data;
          const data = {
            name,
            password,
            [authentication]: enterpriseRegister[authentication],
            commonField,
            address,
            telephone,
            workPlace,
            professionalTitle,
            organizationType,
            legalPersonIdCart,
            legalPersonCert,
            companyCert,
          };
          await this.$request.post({
            url: "/user/team/register",
            data: data,
          });
          this.successBoth();
        }
      });
    },
    successBoth () {
      this.$message({
        showClose: true,
        message: "注册成功",
        type: "success",
        duration: 2000,
      });
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    },
    // 图片操作
    removePhoto (type) {
      this.$data.enterpriseRegister[type] = "";
    },
    async addPhoto (e, type) {
      const { files } = e.target
      const result = await this.$request.postForm({
        url: "/file/upload/image",
        data: {
          file: files[0]
        }
      })
      this.$data.enterpriseRegister[type] = result;
      this.$message({
        showClose: true,
        message: "添加成功!",
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang="less">
.register-content {
  overflow: auto;
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgba(250, 250, 250);
  .el-button--primary {
    background-color: var(--buttonColor);
  }
  .header-box {
    background-color: #fff;
    width: 100%;
    margin: 0;
    --logoSize: 7rem;
    font-size: 2rem;
    box-sizing: border-box;
    height: var(--logoSize);
    max-height: 80px;
    line-height: var(--logoSize);
    box-shadow: 0 2px 5px 1px rgba(204, 204, 204, 0.3);
    .header-title {
      padding-left: 2em;
      height: 100%;
      background-image: linear-gradient(
        to top,
        rgb(85, 90, 226) 0%,
        rgba(94, 102, 255) 50%,
        rgb(85, 90, 226) 100%
      );
      width: 100%;
      display: flex;
      align-items: center;
      .header-left {
        display: flex;
        align-items: center;
        .left-title {
          font-size: 1em;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
  .register-form-box {
    margin: 0 auto;
    margin-top: 4rem;
    background-color: #fff;
    width: 100%;
    max-width: 600px;
    max-height: 550px;
    overflow: scroll;
    position: relative;
    border-radius: 8px;
    padding: 10px 30px;
    box-shadow: 2px 2px 4px 1px #cccccc46;
    .img-once {
      .img {
        width: 120px;
        height: 160px;
        border-radius: 4px;
      }
      .empty-img {
        width: 120px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px !important;
        color: #999;
        background-color: rgb(228, 228, 228);
        border-radius: 4px;
      }
    }
    .title {
      margin: 5px 0;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      position: relative;
      .iconfont {
        font-size: 25px !important;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    .register-btn {
      margin: 5px auto;
      display: block;
    }
    .regulations {
      color: #409eff;
    }
    .code {
      position: relative;
    }
    .code-send {
      position: absolute;
      right: 0;
    }
  }
}
</style>
