<template>
  <div class="login-content"
       :style="{ backgroundImage: 'url(/image/background.png)' }">
    <div class="login-wrap">
      <div class="title-head">
        广东省工业软件学会管理系统
      </div>
      <div class="login-form">
        <el-tabs v-model="roleId"
                 :stretch="true">
          <el-tab-pane label="管理登录"
                       name="2"></el-tab-pane>
          <el-tab-pane label="超管登录"
                       name="3"></el-tab-pane>
        </el-tabs>
        <!-- 头部 -->
        <div class="size-box">
          <div class="login-radius"
               @click="changeLoginSize('username')"
               :class="loginSize == 'username' ? 'active' : ''">
            账号登录
          </div>
          <div class="login-radius"
               @click="changeLoginSize('email')"
               :class="loginSize == 'email' ? 'active' : ''">
            邮箱登录
          </div>
        </div>
        <div v-if="loginSize == 'username'"
             class="login-box">
          <el-form ref="usernameRef"
                   :rules="rules"
                   :model="usernameForm">
            <el-form-item prop="username">
              <el-input placeholder="绑定的手机号/邮箱号"
                        type="username"
                        v-model="usernameForm.username"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="密码"
                        type="password"
                        v-model="usernameForm.password"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div v-else
             class="login-box">
          <el-form ref="emailRef"
                   :rules="rules"
                   :model="emailForm">
            <el-form-item prop="email">
              <el-input placeholder="请输入邮箱"
                        type="email"
                        v-model="emailForm.email"></el-input>
            </el-form-item>
            <el-form-item class="code"
                          prop="code">
              <el-input class="codeInput"
                        placeholder="验证码"
                        v-model="emailForm.code"
                        type="code"
                        maxlength="8"></el-input>
              <el-button class="codeSend"
                         @click="() => sendCode()"
                         :disabled="timeout ? true : false"
                         :style="{
                  opacity: timeout == 0 ? '1' : '0.5',
                }">{{ timeout ? timeout + "s" : "获取验证码" }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div @click="handleLogin()"
             class="login-btn">登录</div>
        <div class="other-route">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data () {
    return {
      loginSize: "username",
      timeout: 0,
      roleId: '2',
      emailForm: {
        email: "",
        code: "",
      },
      usernameForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
          {
            type: "email",
            message: "邮箱格式错误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    changeLoginSize (loginSize) {
      this.$data.loginSize = loginSize;
      if (loginSize == "username") {
        this.$data.usernameForm.username = "";
        this.$data.usernameForm.password = "";
      } else {
        this.$data.emailForm.email = "";
        this.$data.emailForm.code = "";
      }
    },
    handleLogin () {
      const { loginSize } = this.$data;
      this.$refs[loginSize + "Ref"].validate(async (vaild) => {
        if (vaild) {
          if (loginSize == "username") {
            const {
              roleId,
              usernameForm: { username, password },
            } = this.$data;
            const result = await this.$request.post({
              url: "/user/login",
              data: {
                username: username,
                password: password,
                roleId: roleId,
                accountType: 0
              },
            });
            const { token, registerUserRes, user } = result;
            localStorage.GIA_token = token;
            this.$store.state.registerUserRes = registerUserRes;
            this.$store.state.user = user;
            this.$router.push("/manage/memberMessageList");
          } else {
            const {
              roleId,
              emailForm: { email, code },
            } = this.$data;
            const result = await this.$request.post({
              url: "/user/email/login",
              data: {
                email: email,
                code: code,
                roleId: roleId,
                accountType: 0
              },
            });
            const { token, registerUserRes, user } = result;
            localStorage.GIA_token = token;
            this.$store.state.registerUserRes = registerUserRes;
            this.$store.state.user = user;
            this.$router.push("/manage");
          }
        } else {
          // throw( "账号或密码错误");
        }
      });
    },
    sendCode () {
      this.$refs["emailRef"].validateField("email", async (valid) => {
        if (!valid) {
          // 验证的值
          this.$data.timeout = 60;
          this.sendCodeInterval = setInterval(() => {
            this.$data.timeout -= 1;
            if (this.$data.timeout == 0) {
              clearInterval(this.sendCodeInterval);
              this.sendCodeInterval = null;
            }
          }, 1000);
          try {
            const { email } = this.$data.emailForm;
            await this.$request.post({
              url: "/user/sendCode/" + email,
            });
          } catch (error) {
            this.$data.timeout = 0;
            clearInterval(this.sendCodeInterval);
            this.sendCodeInterval = null;
          }
        }
      });
    },
  },
  created () {
    document.onkeydown = () => {
      var key = window.event.keyCode;
      if (key == 13) {
        this.handleLogin("enterprise");
      }
    }; // 按下回车执行登录按钮点击事件
  },
};
</script>

<style scoped lang="less">
.login-content {
  position: relative;
  overflow: auto;
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .login-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title-head {
      color: #fff;
      margin: 50px auto;
      font-size: 25px;
      font-weight: 600;
    }
    .login-form {
      position: relative;
      width: 400px;
      box-sizing: border-box;
      z-index: 1;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      padding: 40px;
      --buttonColor: rgb(71, 71, 236);
      z-index: 1;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        display: block;
        position: absolute;
        top: -20px;
        left: 20px;
        z-index: -1;
      }
      .size-box {
        color: #fff;
        font-size: 14px;
        display: flex;
        height: 35px;
        line-height: 35px;
        border-radius: 4px;
        overflow: hidden;
        text-align: center;
        background-color: rgb(216, 214, 214);
        .login-radius {
          flex: 1;
          cursor: pointer;
          &.active {
            background-color: var(--buttonColor);
          }
        }
      }
      .login-box {
        margin: 40px 15px;
        .code {
          position: relative;
          .codeSend {
            position: absolute;
            color: #fff;
            right: 0;
            background-color: var(--buttonColor);
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
      .login-btn {
        background: linear-gradient(
          to top,
          rgb(85, 90, 226) 0%,
          rgba(94, 102, 255) 50%,
          rgb(85, 90, 226) 100%
        );
        width: 100%;
        height: 45px;
        line-height: 45px;
        border-radius: 6px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        cursor: pointer;
      }
      .other-route {
        display: flex;
        justify-content: space-between;
        font-size: 13.5px;
        margin: 3px 0;
      }
    }
  }
}
</style>
