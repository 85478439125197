<template>
  <div class="phone-adaptation-box">
    <router-view class="phone-router-box"></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  methods: {},
  async mounted () {
    const { fullPath } = this.$route
    if (fullPath == '/') {
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="less" scoped>
.phone-adaptation-box {
  width: 100%;
  height: 100vh;
  overflow: auto;
  margin: 0 auto;
  .phone-router-box {
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="less" scoped>
</style>