<template>
  <div class="activity-box"
       v-loading="isSuccess">
    <div style="width: 150px; margin: 10px 0">
      <el-button type="primary"
                 @click="showAll">全部</el-button>
    </div>
    <div class="search-days-box">
      <div class="block">
        <span class="demonstration">查询指定日期：</span>
        <el-date-picker v-model="value1"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="block">
        <span class="demonstration">查询日期范围：</span>
        <el-date-picker v-model="value2"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <el-button icon="el-icon-search"
                 type="primary"
                 @click="searchActivity"></el-button>
    </div>

    <div class="show-box">
      <el-table :data="showTableData"
                style="width: 80%; overflow: scroll; margin-bottom: 20px"
                max-height="460"
                stripe
                class="tableBox">
        <el-table-column align="center"
                         prop="time"
                         label="会议时间"
                         width="180">
        </el-table-column>
        <el-table-column align="center"
                         prop="title"
                         label="会议名称"
                         width="480">
        </el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <!-- to写文章路径跳转文章页面 -->
            <router-link :to="{
                path: '/manage/media_essay',
                query: { newId: scope.row.articleId },
              }">
              <el-button size="mini"
                         @click="handleDetail(scope.$index, scope.row)">
                详情
              </el-button>
            </router-link>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-sizes="pageSizes"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isSuccess: false, //加载中？
      pageSizes: [2, 5, 10, 20, 30], //可选每页数据量
      showTableData: [], //展示的数据
      total: 0, //全部数据量
      pageSize: 5, //每页数据量
      pageNow: 1, //当前所处页面
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "", //具体日期搜索
      value2: [], //数组-范围日期搜索
      isSearching: 2, //分页  0-具体日期搜索活动,1-日期范围搜索活动,2-全部活动
    };
  },
  methods: {
    // 单击编辑进入活动详情页面
    handleEdit (index, rowMessage) {
      this.articleId = rowMessage.articleId;
    },
    // 点击新建活动
    buildNew: async function () { },
    // 点击日期查询
    searchActivity () {
      // 调用对应的分页获取接口并将分页状态做出相应改变
      if (this.value1) {
        this.searchMessByRightTime(1, 5, this.value1);
        this.isSearching = 0;
      } else if (this.value2) {
        this.pageNow = 1;
        this.pageSize = 5;
        this.searchMessByTimes(this.pageNow, this.pageSize, this.value2);
        this.isSearching = 1;
      } else {
        this.getEvents(this.pageNow, this.pageSize);
        this.isSearching = 2;
      }
    },
    // val为选择的每页长度
    handleSizeChange (val) {
      this.pageSize = val;
      // 判断是日期筛选状态的分页还是全部会员信息的分页
      if (this.isSearching == 0) {
        this.searchMessByRightTime(this.pageNow, this.pageSize, this.value1);
      } else if (this.isSearching == 1) {
        this.searchMessByTimes(this.pageNow, this.pageSize, this.value2);
      } else {
        this.getEvents(this.pageNow, this.pageSize);
      }
    },
    // val为选择的第几页
    handleCurrentChange (val) {
      // 页面跳转函数
      this.pageNow = val;
      // 判断是日期筛选状态的分页还是全部会员信息的分页
      if (this.isSearching == 0) {
        this.searchMessByRightTime(this.pageNow, this.pageSize, this.value1);
      } else if (this.isSearching == 1) {
        this.searchMessByTimes(this.pageNow, this.pageSize, this.value2);
      } else {
        this.getEvents(this.pageNow, this.pageSize);
      }
    },
    // 根据具体日期搜索
    searchMessByRightTime: async function (page, size, time) {
      this.isSuccess = true;
      const result = await this.$request.post({
        url: `event/get?time=${time}`,
        data: {
          page,
          size,
        },
      });
      this.total = result.totalCount;
      this.showTableData = result.list;
      this.isSuccess = false;
    },
    // 根据日期范围搜索
    searchMessByTimes: async function (page, size, dataArr) {
      this.isSuccess = true;
      let startTime = dataArr[0];
      let endTime = dataArr[1];
      const result = await this.$request.get({
        url: `event/list/time?startTime=${startTime}&endTime=${endTime}`,
        data: {
          page,
          size,
        },
      });
      this.total = result.totalCount;
      this.showTableData = result.list;
      this.isSuccess = false;
    },
    // 点击返回展示全部活动
    showAll: async function () {
      this.getEvents(this.pageNow, this.pageSize);
      this.isSearching = 2;
    },
    // 单击行右端删除获取该行全部数据，传递id删除
    handleDelete (index, rows) {
      this.$confirm("此操作将取消该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const result = await this.$request.post({
            url: `/event/cancel?id=${rows.id}`,
          });
          this.getEvents(this.pageNow, this.pageSize);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取全部活动
    getEvents: async function (page, size) {
      this.isSuccess = true;
      const result = await this.$request.post({
        url: "/event/getAll",
        data: {
          page,
          size,
        },
      });
      this.total = result.totalCount;
      this.showTableData = result.list;
      this.isSuccess = false;
    },
  },
  mounted () {
    let { pageNow, pageSize } = this.$data;
    this.getEvents(pageNow, pageSize);
  },
};
</script>
<style lang="less" scoped>
.activity-box {
  margin: 50px;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(17, 34, 51, 0.1);
  min-width: 1000px;
  overflow: scroll;
  .search-days-box {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  .label-title {
    display: inline-block;
    background-color: white;
    height: 30px;
    vertical-align: top;
    border-radius: 2px;
    line-height: 30px;
    text-align: left;
    width: 25%;
    color: #c0c4cc;
  }
  .build-new {
    margin-top: 30px;
  }
}
</style>