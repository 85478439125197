const pages = {};
// rem适配
const html = document.documentElement
function setFont () {
  let cliWidth = html.clientWidth
  if (cliWidth > 900) {
    cliWidth = 900
  }
  html.style.fontSize = (cliWidth / 50) + 'px' // px转rem
}
let cliWidth = html.clientWidth
let rootFile = ""
if (cliWidth > 1000) {// 到时候改
  rootFile = 'pc_view'
  const files = require.context('@/pc_view', true, /\.vue$/)
  files.keys().forEach(key => {
    const total_route = key.replace(/(\.\/|\.vue)/g, '')
    const route_arr = total_route.split('/')
    let now_route = pages
    for (let i = 0; i < route_arr.length; i++) {
      now_route[route_arr[i]] = now_route[route_arr[i]] || {}
      now_route = now_route[route_arr[i]]
    }
    now_route.path = '/' + total_route
  })
} else {
  setFont()
  window.onresize = function () {
    setFont()
  }
  rootFile = 'phone_view'
  const files = require.context('@/phone_view', true, /\.vue$/)
  files.keys().forEach(key => {
    const total_route = key.replace(/(\.\/|\.vue)/g, '')
    const route_arr = total_route.split('/')
    let now_route = pages
    for (let i = 0; i < route_arr.length; i++) {
      now_route[route_arr[i]] = now_route[route_arr[i]] || {}
      now_route = now_route[route_arr[i]]
    }
    now_route.path = '/' + total_route
  })
}
function getRoutes (obj) {
  const routes = []
  for (const key in obj) {
    if (obj[key].path) {
      const path = obj[key].path
      const filter_path = path.replace("/GIA_home", '')
      let new_path = filter_path == '' ? '/' : filter_path
      if (new_path == '/home') {
        new_path = '/'
      }
      const _obj = {
        path: new_path,
        name: new_path,
        component: () => import(`@/${rootFile}${path}.vue`)
      }
      if (Object.keys(obj[key]).length > 1) {
        if (new_path != '/') {
          _obj.redirect = new_path + '/GIA_redirect'
        }
        // /GIA_redirect
        _obj.children = getRoutes(obj[key])
      }
      routes.push(_obj)
    }
  }
  return routes
}
export default getRoutes(pages)
