import { render, staticRenderFns } from "./articleBox.vue?vue&type=template&id=56aa9594&scoped=true"
import script from "./articleBox.vue?vue&type=script&lang=js"
export * from "./articleBox.vue?vue&type=script&lang=js"
import style0 from "./articleBox.vue?vue&type=style&index=0&id=56aa9594&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56aa9594",
  null
  
)

export default component.exports